import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { ProductService } from '../../services/manage-products.service';

@Component({
  selector: 'bodyanalytics-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  protected productData!: ProductI | null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private productService: ProductService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }
  ngOnInit(): void {
    this.getProduct();
  }

  getProduct(): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.productService
      .getProduct(id)
      .subscribe(product => ((this.productData as ProductI) = product));
  }

  // TODO: Add back once we are done switching over to stripe products.  
  // public processProductUpdate(signupDetails: any): void {
  //   const id = this.route.snapshot.paramMap.get('id') as string;
  //   this.subscriptions.barrel = this.productService
  //     .updateProduct(id, signupDetails)
  //     .pipe(
  //       tap(() => {
  //         console.log('here: processProductUpdate');
  //         // this.modalService.dismissAll();
  //         // this.submitted = true;
  //       }),
  //       catchError((error: any) => {
  //         // doc.file.hasError = true;
  //         // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
  //         // doc.file.uploading = false;
  //         // doc.file.uploaded = false;
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
}
