import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { manageEventsUiRoutes } from './lib.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';
import { EventBreakFormComponent } from '@bodyanalytics/front/admin/manage-events/ui';
import { CompleteEventComponent } from './complete-event-created/review-event.component';
import { ManageEventsComponent } from './manage-events/manage-events.component';
import { ReviewEventComponent } from './review-event/review-event.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UiCoreModule,
    RouterModule.forChild(manageEventsUiRoutes),
  ],
  declarations: [ManageEventsComponent, ReviewEventComponent, CompleteEventComponent, EventBreakFormComponent],
  exports: [],
})
export class ManageEventsUiModule {}
