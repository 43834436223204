import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { ManageOrdersDashboardComponent } from './manage-orders-dashboard/manage-orders-dashboard.component';
import { ManageOrdersComponent } from './manage-orders/manage-orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ManageOrderService, provideOrders } from '@bodyanalytics/front/admin/manage-orders/domain';

export const manageOrdersUiRoutes: Route =
  {
    path: 'auth/manage-orders',
    component: ManageOrdersComponent,
    canActivate: [AuthGuard],
    providers:[provideOrders(), ManageOrderService],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        component: ManageOrdersDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: OrderDetailsComponent,
      },
    ],
  };
