import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  BookingI,
  CreateRescheduleI,
  OrderConfirmationI,
  ReschBookingDataI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { SignupService } from '../../services/signup.service';
import { CheckoutService } from '../../services/checkout.service';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { format, utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-rechedule-verify-page',
  templateUrl: './rechedule-verify-page.component.html',
  styleUrls: ['./rechedule-verify-page.component.scss'],
  providers: [DatePipe],
})
export class RecheduleVerifyPageComponent extends BodyanalyticsBaseComponent implements OnInit {
  public eventData: TestingEventI | null = null;
  public bookingData: BookingI | null = null;
  public newTimeSlot: Date | null = null;
  public existingSlotTime: string | null = null;
  public rescheduleBookingData: ReschBookingDataI | null = null;

  public orderConfirmationData!: OrderConfirmationI;
  constructor(
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private signupService: SignupService,
    private checkoutService: CheckoutService,
    private $gaService: GoogleAnalyticsService,
    private datePipe: DatePipe,
  ) {
    super();
  }
  ngOnInit(): void {
    this.rescheduleBookingData = this.mobileTestingCalendarService.getVerifyRescheduleData();
    this.getNewEventDetails();
    this.getExistingBookingDetails();

  }

  getNewEventDetails() {
    this.eventData = this.rescheduleBookingData!.selectedEvent;
    this.newTimeSlot = this.rescheduleBookingData!.selectedTimeSlot;
  }
  getExistingBookingDetails() {
    this.bookingData = this.rescheduleBookingData!.existingBooking;
    if(this.bookingData?.slot.slotTime){
      // this.existingSlotTime = this.convertToCentralTime(this.bookingData?.slot.slotTime);

        const centralTimeZone = 'America/Chicago';

        // Convert the UTC date to Central Time
        const centralDate = utcToZonedTime(this.bookingData!.slot.slotTime, centralTimeZone);

        // Format the date to display time in 12-hour format with AM/PM
        this.existingSlotTime = format(centralDate, 'h:mm:ss a', { timeZone: centralTimeZone });

    }
    this.$gaService.pageView('rechedule-verify/'+ this.bookingData!.id, 'Reschedule Booking')

  }

  routeToSearch() {
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}/${this.bookingData!.id}`);
  }
  processReschedule() {
    const createRescheduleOrderDto: CreateRescheduleI = {
      existingBookingId: this.rescheduleBookingData!.existingBooking!.id,
      newEventId: this.rescheduleBookingData!.selectedEvent!.id,
      slotTime: this.newTimeSlot!.toString(),
      existingOrderId: this.rescheduleBookingData!.existingOrderId!,
      newEventName: this.eventData!.testEventAtClub.clubName,
      testEventDate: this.eventData!.testEventDate,
    };

    this.subscriptions.barrel = this.checkoutService.processOrderReschedule(createRescheduleOrderDto).subscribe({
      next: response => {
        console.log('Booking rescheduled successfully:', response);
        // this.mobileTestingCalendarService.clearRescheduleData();
        this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_SIGNUP_RESCHEDULE_CONFIRM}/${response.id}`);
      },
      error: error => {
        console.error('Error rescheduling booking:', error);
      },
    });
  }
  convertToCentralTime(utcDateString: string) {
    const date = new Date(utcDateString);

    return this.datePipe.transform(date, 'shortTime') as string;
  }
}
