<!-- Page content -->
<section>
  <div>
    <!-- Account security -->
    <div>
      <div class="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
        <h1 class="h2 pt-xl-1 pb-3">Security</h1>

        <!-- Password -->
        <bodyanalytics-security-form (submitFormEvent)="update($event)"></bodyanalytics-security-form>
      </div>
    </div>
  </div>
</section>
