import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OperatorI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OperatorService {
  private operatorsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_OPERATORS}`;
  private operatorsDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_OPERATORS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getOperator(id: string): Observable<OperatorI> {
    const url = `${this.appConfig.api}/${this.operatorsDetailsUrl}/${id}`;
    return this.http.get<OperatorI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched operator id=${id}`)),
      catchError(this.sharedErrorService.handleError<OperatorI>(`getOperatorI id=${id}`)),
    );
  }

  updateOperator(id: string, operator: OperatorI): Observable<any> {
    const url = `${this.appConfig.api}/${this.operatorsDetailsUrl}/${id}`;
    return this.http.patch(url, operator, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated operator id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateOperator')),
    );
  }

  deleteOperator(id: string): Observable<OperatorI> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}/${id}`;

    return this.http.delete<OperatorI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted operator id=${id}`)),
      catchError(this.sharedErrorService.handleError<OperatorI>('deleteOperator')),
    );
  }

  load(): Observable<OperatorI[]> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}`;

    return this.http.get<OperatorI[]>(url).pipe(
      tap(_ => this.sharedLogService.log('fetched operators')),
      catchError(this.sharedErrorService.handleError<OperatorI[]>('getOperatorIs', [])),
    );
  }

  addOperator(operator: OperatorI): Observable<OperatorI> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}`;

    return this.http.post<OperatorI>(url, operator, this.httpOptions).pipe(
      tap((newOperatorI: OperatorI) => this.sharedLogService.log(`added operator w/ id=${newOperatorI.id}`)),
      catchError(this.sharedErrorService.handleError<OperatorI>('addOperator')),
    );
  }
  searchOperator(term: string): Observable<OperatorI[]> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}/search/?firstName=${term}`;
    this.sharedLogService.log('here: ' + term);
    if (!term.trim()) {
      // if not search term, return empty operator array.
      return of([]);
    }

    return this.http.get<OperatorI[]>(`${url}`).pipe(
      tap(x =>
        x.length
          ? this.sharedLogService.log(`found operators matching "${term}"`)
          : this.sharedLogService.log(`no operators matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<OperatorI[]>('searchOperators', [])),
    );
  }
}
