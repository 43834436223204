import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, isDevMode } from '@angular/core';
import {
  ActivityI,
  BodyFatReportI,
  BodyFatTestI,
  CurrentStatusGoalsI,
  CustomerI,
  CustomerTestingI,
  OperatorI,
  TestSessionCheckInI,
  TestSessionSetupI,
  TestingEventI,
  TruckI,
  BodyFatTestReportI,
  ClubI,
} from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { catchError, Observable, of, Subject, tap } from 'rxjs';

@Injectable()
export class ManageBodymeasurementService {
  stepUpdated = new Subject<string>();
  testSessionData = new Subject<Array<any>>();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private env = inject(ENV);
  private bodyMeasurementUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS;
  private bodyMeasurementDetailsEndpoint = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_DETAILS_SOFT;
  private bodyMeasurementTestSessionUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION;
  private bodyMeasurementCheckInUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_CHECK_IN;
  private bodyMeasurementProcessUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_PROCESS;
  private bodyMeasurementReviewUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_REVIEW;
  private bodyMeasurementCompleteUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_COMPLETE;
  private bodyMeasurementReportUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_TEST_SESSION_REPORT;
  private bodyMeasurementCompleteAllUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_COMPLETE_ALL;
  private bodyMeasurementCompleteHistoryUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_HISTORY;
  private manageReportsApi = ApiLocalRoutesEnum.API_SUFFIX_MANAGE_REPORTS_API;
  private bodyMeasurementDetailsUrl = ApiLocalRoutesEnum.API_SUFFIX_BODY_MEASUREMENTS_DETAILS;
  private testEventListUrl = ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS;
  private customerUrl = ApiLocalRoutesEnum.API_SUFFIX_CUSTOMERS;
  private operatorsUrl = ApiLocalRoutesEnum.API_SUFFIX_OPERATORS;
  private fleetTrucksUrl = ApiLocalRoutesEnum.API_SUFFIX_TRUCKS;
  private clubUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CLUBS}`;

  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  private _testDataList: Array<any> = [];

  public get testDataList(): Array<any> {
    return this._testDataList;
  }

  public set testDataList(value: Array<any>) {
    this._testDataList = value;
  }

  public customerCheckIn(emailAddress: string): Observable<CustomerI[]> {
    const url = `${this.env.api}/${this.customerUrl}/search/${emailAddress}`;
    return this.http.get<CustomerI[]>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched customer id=${emailAddress}`)),
      catchError(this.sharedErrorService.handleError<CustomerI[]>(`getCustomer id=${emailAddress}`)),
    );
  }

  loadBodyMeasurementReport(reportId: string): Observable<BodyFatTestReportI> {
    return this.http.get<BodyFatTestReportI>(`${this.env.api}/${this.bodyMeasurementReportUrl}/${reportId}`).pipe(
      tap(_ => this.sharedLogService.log(`fetched reportId id=${reportId}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestReportI>(`findTestReport id=${reportId}`)),
    );
  }

  loadBodyMeasurementReview(reportId: string): Observable<BodyFatReportI> {
    return this.http.get<BodyFatReportI>(`${this.env.api}/${this.bodyMeasurementReviewUrl}/${reportId}`).pipe(
      tap(_ => this.sharedLogService.log(`fetched reportId id=${reportId}`)),
      catchError(this.sharedErrorService.handleError<BodyFatReportI>(`findTestReport id=${reportId}`)),
    );
  }

  loadCustomerTestHistory(emailAddress: string): Observable<BodyFatReportI> {
    return this.http
      .get<BodyFatReportI>(`${this.env.api}/${this.bodyMeasurementCompleteHistoryUrl}/${emailAddress}`)
      .pipe(
        tap(_ => this.sharedLogService.log(`fetched reportId emailAddress=${emailAddress}`)),
        catchError(this.sharedErrorService.handleError<BodyFatReportI>(`findTestReport emailAddress=${emailAddress}`)),
      );
  }

  public findCheckIn(sessionId: string): Observable<any[]> {
    const url = `${this.env.api}/${this.bodyMeasurementCheckInUrl}/${sessionId}`;
    return this.http.get<any[]>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched sessionId id=${sessionId}`)),
      catchError(this.sharedErrorService.handleError<any[]>(`findCheckIn id=${sessionId}`)),
    );
  }

  public findTestSession(sessionId: string): Observable<any[]> {
    const url = `${this.env.api}/${this.bodyMeasurementTestSessionUrl}/${sessionId}`;
    return this.http.get<any[]>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched sessionId id=${sessionId}`)),
      catchError(this.sharedErrorService.handleError<CustomerI[]>(`findTestSession id=${sessionId}`)),
    );
  }

  createBodyMeasurementDetails(bodyFatTestData: BodyFatTestI): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementDetailsEndpoint}`;

    this.sharedLogService.log('data testing createBodyMeasurementDetails: ' + bodyFatTestData);

    return this.http.post<BodyFatTestI>(url, bodyFatTestData, this.httpOptions).pipe(
      tap((newBodyMeasurementI: BodyFatTestI) =>
        this.sharedLogService.log(`calculated bodyfat numbers w/ id=${newBodyMeasurementI}`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('calculateTestData')),
    );
  }

  createBodyMeasurement(bodyFatTestData: any): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}`;

    this.sharedLogService.log('data testing createBodyMeasurement: ' + bodyFatTestData);

    return this.http.post<BodyFatTestI>(url + '', bodyFatTestData, this.httpOptions).pipe(
      tap((newBodyMeasurementI: BodyFatTestI) =>
        this.sharedLogService.log(`calculated bodyfat numbers w/ id=${newBodyMeasurementI.id}`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('calculateTestData')),
    );
  }

  createCompleteTestRecord(reportId: string): Observable<BodyFatTestI> {
    isDevMode() && this.sharedLogService.log('data testing createTestReport: ' + reportId);

    return this.http.post<BodyFatTestI>(`${this.env.api}/${this.bodyMeasurementCompleteUrl}`, { reportId }).pipe(
      tap((newTestReport: BodyFatTestI) =>
        this.sharedLogService.log(`createCompleteTestRecord w/ id=${newTestReport.id}`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('createCompleteTestRecord')),
    );
  }

  createTestReport(bodyFatTestData: any): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}`;

    this.sharedLogService.log('data testing createTestReport: ' + bodyFatTestData);

    return this.http.post<BodyFatTestI>(url + '', bodyFatTestData, this.httpOptions).pipe(
      tap((newTestReport: BodyFatTestI) =>
        this.sharedLogService.log(`calculated bodyfat numbers w/ id=${newTestReport.id}`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('calculateTestData')),
    );
  }

  sendReport({ id, email }: { id: string; email: string }): Observable<{ success: boolean; message: string }> {
    return this.http
      .post<{
        success: boolean;
        message: string;
      }>(`${this.env.api}/${this.manageReportsApi}/pdf/send-report/${id}`, { email })
      .pipe(
        tap(() => this.sharedLogService.log(`fetched BodyFatTestI id=${id}`)),
        catchError(
          this.sharedErrorService.handleError<{ success: boolean; message: string }>(`getBodyMeasurementI id=${id}`),
        ),
      );
  }

  processCheckIn(testSessionCheckInData: TestSessionCheckInI): Observable<TestSessionCheckInI> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/test-session/check-in`;
    this.sharedLogService.log('data testing testSessionCheckInData: ' + testSessionCheckInData);

    return this.http.post<any>(url, testSessionCheckInData, this.httpOptions).pipe(
      tap((checkInData: TestSessionCheckInI) => this.sharedLogService.log(`processCheckIn w/ id=${checkInData.id}`)),
      catchError(this.sharedErrorService.handleError<TestSessionCheckInI>('processCheckIn')),
    );
  }

  startTestSession(bodyFatTestData: TestSessionSetupI): Observable<TestSessionSetupI> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/test-session/setup`;

    this.sharedLogService.log('data testing calculateTestData: ' + bodyFatTestData);

    return this.http.post<TestSessionSetupI>(url, bodyFatTestData).pipe(
      tap((newBodyMeasurementI: TestSessionSetupI) =>
        this.sharedLogService.log(`startTestSession w/ id=${newBodyMeasurementI.id}`),
      ),
      catchError(this.sharedErrorService.handleError<TestSessionSetupI>('calculateTestData')),
    );
  }

  completeTestData(bodyFatTestData: BodyFatTestI): Observable<BodyFatTestI> {
    this.sharedLogService.log('data testing calculateTestData: ' + bodyFatTestData);

    return this.http
      .post<BodyFatTestI>(`${this.env.api}/${this.bodyMeasurementProcessUrl}`, bodyFatTestData, this.httpOptions)
      .pipe(
        tap((newBodyMeasurementI: BodyFatTestI) =>
          this.sharedLogService.log(`calculated bodyfat numbers w/ id=${newBodyMeasurementI.id}`),
        ),
        catchError(this.sharedErrorService.handleError<BodyFatTestI>('calculateTestData')),
      );
  }

  calculateTestData(bodyFatTestData: BodyFatTestI): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/calculate/test-session/soft`;
    this.sharedLogService.log('data testing calculateTestData: ' + bodyFatTestData);

    return this.http.post<BodyFatTestI>(url, bodyFatTestData, this.httpOptions).pipe(
      tap((newBodyMeasurementI: BodyFatTestI) =>
        // this.sharedLogService.log(`calculated bodyfat numbers w/ id=${newBodyMeasurementI.id}`),
        this.sharedLogService.log(`calculated bodyfat numbers`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('calculateTestData')),
    );
  }

  getBodyMeasurement(id: string): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementDetailsUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched BodyFatTestI id=${id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>(`getBodyMeasurementI id=${id}`)),
    );
  }

  updateBodyMeasurement(id: string, BodyFatTestI: any): Observable<any> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/details/${id}`;
    return this.http.patch(url, BodyFatTestI, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated BodyFatTestI id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateBodyMeasurementI')),
    );
  }

  deleteBodyMeasurement(id: string): Observable<BodyFatTestI> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/${id}`;

    return this.http.delete<BodyFatTestI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted BodyFatTestI id=${id}`)),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('deleteBodyMeasurementI')),
    );
  }

  loadReports(): Observable<BodyFatReportI[]> {
    return this.http.get<BodyFatReportI[]>(`${this.env.api}/${this.bodyMeasurementCompleteAllUrl}`).pipe(
      tap(_ => this.sharedLogService.log('fetched BodyFatReportI')),
      catchError(this.sharedErrorService.handleError<BodyFatReportI[]>('BodyFatReportI', [])),
    );
  }

  addBodyMeasurement(BodyFatTestI: BodyFatTestI): Observable<BodyFatTestI> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}`;

    return this.http.post<BodyFatTestI>(url, BodyFatTestI, this.httpOptions).pipe(
      tap((newBodyMeasurementI: BodyFatTestI) =>
        this.sharedLogService.log(`added BodyFatTestI w/ id=${newBodyMeasurementI.id}`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI>('addBodyMeasurementI')),
    );
  }

  searchBodyMeasurements(term: string): Observable<BodyFatTestI[]> {
    const url = `${this.env.api}/${this.bodyMeasurementUrl}/search/?firstName=${term}`;
    if (!term.trim()) {
      // if not search term, return empty BodyFatTestI array.
      return of([]);
    }

    return this.http.get<BodyFatTestI[]>(`${url}`).pipe(
      tap(x =>
        x.length
          ? this.sharedLogService.log(`found BodyMeasurementIes matching "${term}"`)
          : this.sharedLogService.log(`no BodyMeasurementIes matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<BodyFatTestI[]>('searchBodyMeasurementIs', [])),
    );
  }

  public loadOperators(): Observable<OperatorI[]> {
    return this.http.get<OperatorI[]>(`${this.env.api}/${this.operatorsUrl}`);
  }

  public loadClubs(): Observable<ClubI[]> {
    return this.http.get<ClubI[]>(`${this.env.api}/${this.clubUrl}`);
  }

  public loadTrucks(): Observable<TruckI[]> {
    return this.http.get<TruckI[]>(`${this.env.api}/${this.fleetTrucksUrl}`);
  }

  public loadEvents(): Observable<TestingEventI[]> {
    return this.http.get<TestingEventI[]>(`${this.env.api}/${this.testEventListUrl}`);
  }

  loadClientHistory(email: string) {
    return this.http.post<[]>(`${this.env.api}/${this.manageReportsApi}/client-history`, { email });
  }

  downloadPdfReport(reportId: string) {
    return this.http.get(`${this.env.api}/${this.manageReportsApi}/pdf/download/${reportId}`, {
      responseType: 'arraybuffer',
    });
  }

  public getActivities(): Observable<ActivityI[]> {
    const activitiesUrl = '';
    const url = `${this.env.api}/${activitiesUrl}`;

    return this.http.get<ActivityI[]>(url);
  }

  public getCustomerData(): Observable<CustomerI[]> {
    {
      return this.http.get<CustomerI[]>(`${this.bodyMeasurementUrl}`);
    }
  }

  public getTestData(): Observable<CustomerTestingI[]> {
    {
      return this.http.get<CustomerTestingI[]>(`${this.bodyMeasurementUrl}`);
    }
  }

  public getCurrentStatusGoalsI(): Observable<CurrentStatusGoalsI[]> {
    const currentStatusGoalsUrl = '';
    const url = `${this.env.api}/${currentStatusGoalsUrl}`;
    return this.http.get<CurrentStatusGoalsI[]>(url);
  }

  public saveTestSetupDetails(testDetails: BodyFatTestI) {
    // this.localStorageService.setItem('customerTestSession', JSON.stringify(testDetails)); //save to session
  }

  private updateTestData(_selectedCustomer: CustomerI, _setupDetails: BodyFatTestI): void {
    this.testDataList = this.testDataList.filter(x => x.age === 0);
    this.testDataList.push(_setupDetails);
  }
}
