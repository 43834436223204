import { Route } from '@angular/router';
import { EmailDashboardComponent } from './containers/email-dashboard/email-dashboard.component';
import { EmailUiComponent } from './containers/manage-email/email-ui.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { EmailDetailsComponent } from './containers/email-details/email-details.component';
import { CreateEmailComponent } from './containers/create-email/create-email.component';
import { ReviewEmailComponent } from './containers/review-email/review-email.component';

export const emailUiRoutes: Route[] = [
  {
    path: 'auth/manage-emails',
    component: EmailUiComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-email',
        component: CreateEmailComponent,
        pathMatch: 'full',
      },
      {
        path: 'review-email',
        component: ReviewEmailComponent,
        pathMatch: 'full',
      },
      // {
      //   path: 'create-complete/:id',
      //   component: CompleteEventComponent,
      //   pathMatch: 'full',
      // },
      {
        path: 'dashboard',
        component: EmailDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: EmailDetailsComponent,
      },
    ],
  },
];
