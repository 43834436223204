import { signalStore, withState, withMethods, patchState, withHooks } from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { inject } from '@angular/core';

import { tapResponse } from '@ngrx/operators';
import { ManageDiscountService } from './manage-discounts.service';
import { DiscountI } from '../../../../../shared/data-models-ui/src';

export const ManageDiscountsStore = signalStore(
  withState({ loading: false }),
  withEntities<DiscountI>(),
  withMethods((s, service = inject(ManageDiscountService)) => ({
    load: rxMethod<void>(
      pipe(
        switchMap(() => (patchState(s, { loading: true }), service.load())),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities)),
          error: () => patchState(s, { loading: false }),
          finalize: () => patchState(s, { loading: false }),
        }),
      ),
    ),
  })),
  withHooks(s => ({
    onInit: () => s.load(),
  })),
);
