<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <!-- No. -->
        <!-- <input type="checkbox"  (change)="onCheckboxChange($event)" /> -->
        <!-- <mat-checkbox class="example-margin" (change)="onCheckboxChange($event)">Checked</mat-checkbox> -->
      </th>

      <td mat-cell *matCellDef="let element">
        <input type="checkbox" [value]="element.id" (change)="onCheckboxChange($event)" />
        <!-- {{ element.id }} -->
      </td>
    </ng-container>

    <!-- Name Column -->

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>First name</th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>Last name</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="emailAddress">
      <th mat-header-cell *matHeaderCellDef>Email address</th>
      <td mat-cell *matCellDef="let element">{{ element.emailAddress }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator> -->
</div>
