import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { emailUiRoutes } from './lib.routes';
import { RouterModule } from '@angular/router';
import { EmailDashboardComponent } from './containers/email-dashboard/email-dashboard.component';
import { EmailUiComponent } from './containers/manage-email/email-ui.component';
import { ManageEmailsTableComponent } from './components/manage-emails-table/manage-emails-table.component';
import { EmailDetailsComponent } from './containers/email-details/email-details.component';
import { ManageEmailsFormComponent } from './components/manage-emails-form/manage-emails-form.component';
import { ReviewEmailComponent } from './containers/review-email/review-email.component';
import { CreateEmailComponent } from './containers/create-email/create-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UiCoreModule,
    RouterModule.forChild(emailUiRoutes),
  ],

  declarations: [
    EmailDashboardComponent,
    EmailUiComponent,
    ManageEmailsTableComponent,
    EmailDetailsComponent,
    ManageEmailsFormComponent,
    ReviewEmailComponent,
    CreateEmailComponent,
  ],
})
export class ManageEmailsUiModule {}
