<div class="invalid-feedback">Error: Please check</div>
<form class="row g-3" [formGroup]="customerDetailsForm">
  <ng-container *ngIf="item">
    <div class="row pb-2">
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="firstName" class="form-label fs-base">First name</label>
          <input
            type="text"
            for="firstName"
            [ngClass]="{ 'is-invalid': formsubmit && form.firstName.errors }"
            class="form-control form-control-lg"
            placeholder="John"
            maxlength="20"
            formControlName="firstName"
            #firstName />
          <div *ngIf="formsubmit && form.firstName.errors" class="invalid-tooltip">
            <span *ngIf="form.firstName.errors['required']">First name is required.</span>
            <span *ngIf="form.firstName.errors['pattern']">Please enter a valid first name.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="lastName" class="form-label fs-base">Last name</label>
          <input
            type="text"
            for="lastName"
            [ngClass]="{ 'is-invalid': formsubmit && form.lastName.errors }"
            class="form-control form-control-lg"
            placeholder="Doe"
            maxlength="20"
            formControlName="lastName"
            #lastName />
          <div *ngIf="formsubmit && form.lastName.errors" class="invalid-tooltip">
            <span *ngIf="form.lastName.errors['required']">Last name is required.</span>
            <span *ngIf="form.lastName.errors['pattern']">Please enter a valid last name.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="emailAddress" class="form-label fs-base">Email address</label>
          <input
            type="email"
            id="emailAddress"
            [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
            class="form-control form-control-lg"
            placeholder="john@mail.com"
            maxlength="50"
            formControlName="emailAddress"
            #emailAddress />
          <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
            <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
            <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="phoneNumber" class="form-label fs-base"
            >Phone number <small class="text-muted">(optional)</small></label
          >
          <input
            type="tel"
            id="phoneNumber"
            [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
            class="form-control form-control-lg"
            placeholder="(555)-555-5555"
            maxlength="11"
            formControlName="phoneNumber"
            #phoneNumber />

          <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
            <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
            <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="birthDate" class="form-label fs-base">Birth Date</label>
          <input
            type="date"
            id="birthDate"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.birthDate.errors }"
            formControlName="birthDate"
            #birthDate
            autocomplete="off" />
          <div *ngIf="formsubmit && form.birthDate.errors" class="invalid-tooltip">
            <span *ngIf="form.birthDate.errors['required']">Birth date is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="ethnicityDropdown" class="form-label fs-base">Ethnicity</label>

          <select
            id="ethnicityDropdown"
            class="form-control form-select form-select-lg"
            name="ethnicity"
            formControlName="ethnicity"
            [ngClass]="{ 'is-invalid': formsubmit && form.ethnicity.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let ethnic of ethnicityList" [value]="ethnic.id">
              {{ ethnic.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.ethnicity.errors" class="invalid-tooltip">
            <span *ngIf="form.ethnicity.errors['required']">Ethnicity is required.</span>
          </div>
        </div>
      </div>

      <!-- <h4> Demographics Information</h4> -->
      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="sexDropdown" class="form-label fs-base">Sex</label>
          <select
            id="sexDropdown"
            class="form-control form-select form-select-lg"
            name="sex"
            formControlName="sex"
            [ngClass]="{ 'is-invalid': formsubmit && form.sex.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let sex of sexList" [value]="sex.id">
              {{ sex.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.sex.errors" class="invalid-tooltip">
            <span *ngIf="form.sex.errors['required']">Sex is required.</span>
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="genderDropdown" class="form-label fs-base">Gender</label>
          <select
            id="genderDropdown"
            class="form-control form-select form-select-lg"
            name="gender"
            formControlName="gender"
            [ngClass]="{ 'is-invalid': formsubmit && form.gender.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let gender of genderList" [value]="gender.id">
              {{ gender.name }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.gender.errors" class="invalid-tooltip">
            <span *ngIf="form.gender.errors['required']">Gender is required.</span>
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="height" class="form-label fs-base">Height</label>
          <input
            type="text"
            for="height"
            [ngClass]="{ 'is-invalid': formsubmit && form.height.errors }"
            class="form-control form-control-lg"
            maxlength="20"
            formControlName="height"
            #height />
          <div *ngIf="formsubmit && form.height.errors" class="invalid-tooltip">
            <span *ngIf="form.height.errors['required']">Last name is required.</span>
            <span *ngIf="form.height.errors['pattern']">Please enter a valid last name.</span>
          </div>
        </div>
      </div>
      <h4>Address Information</h4>
      <div class="col-sm-10 mb-4">
        <div class="mb-3 position-relative">
          <label for="address1" class="form-label fs-base">Address 1</label>
          <input
            type="text"
            for="address1"
            [ngClass]="{ 'is-invalid': formsubmit && form.address1.errors }"
            class="form-control form-control-lg"
            maxlength="20"
            formControlName="address1"
            #address1 />
          <div *ngIf="formsubmit && form.address1.errors" class="invalid-tooltip">
            <span *ngIf="form.address1.errors['required']">Address 1 is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-10 mb-4">
        <div class="mb-3 position-relative">
          <label for="address2" class="form-label fs-base">Address 2</label>
          <input
            type="text"
            for="address2"
            [ngClass]="{ 'is-invalid': formsubmit && form.address2.errors }"
            class="form-control form-control-lg"
            maxlength="20"
            formControlName="address2"
            #address2 />
          <div *ngIf="formsubmit && form.address2.errors" class="invalid-tooltip">
            <span *ngIf="form.address2.errors['required']">Address 2 is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-4 mb-4">
        <div class="mb-3 position-relative">
          <label for="city" class="form-label fs-base">City</label>
          <input
            type="text"
            for="city"
            [ngClass]="{ 'is-invalid': formsubmit && form.city.errors }"
            class="form-control form-control-lg"
            placeholder="Doe"
            maxlength="20"
            formControlName="city"
            #city />
          <div *ngIf="formsubmit && form.city.errors" class="invalid-tooltip">
            <span *ngIf="form.city.errors['required']">Last name is required.</span>
            <span *ngIf="form.city.errors['pattern']">Please enter a valid last name.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="zipCode" class="form-label fs-base">Zipcode</label>
          <input
            type="text"
            id="zipCode"
            class="form-control form-control-lg"
            formControlName="zipCode"
            #zipCode
            maxlength="10"
            [ngClass]="{ 'is-invalid': formsubmit && form.zipCode.errors }" />
          <div *ngIf="formsubmit && form.zipCode.errors" class="invalid-tooltip">
            <span *ngIf="form.zipCode.errors['required']">Zipcode is required.</span>
            <span *ngIf="form.zipCode.errors['pattern']">Please enter a valid zip code.</span>
          </div>
        </div>
      </div>

      <div class="col-sm-3 mb-4">
        <div class="mb-3 position-relative">
          <label for="stateDropdown" class="form-label fs-base">State</label>
          <select
            id="stateDropdown"
            class="form-control form-select form-select-lg"
            name="state"
            formControlName="state"
            [ngClass]="{ 'is-invalid': formsubmit && form.state.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let state of stateList" [value]="state.stateCode">
              {{ state.stateName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.gender.errors" class="invalid-tooltip">
            <span *ngIf="form.gender.errors['required']">Gender is required.</span>
          </div>
        </div>
      </div>
      <h4>Club Preferences</h4>

      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="clubDropdown" class="form-label fs-base">Club Name</label>
          <select
            id="clubDropdown"
            class="form-control form-select form-select-lg"
            name="club"
            formControlName="club"
            [ngClass]="{ 'is-invalid': formsubmit && form.club.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let club of clubList" [value]="club.id">
              {{ club.clubName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.gender.errors" class="invalid-tooltip">
            <span *ngIf="form.gender.errors['required']">Gender is required.</span>
          </div>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isEmployeeCheckbox"
            name="isEmployeeCheckbox"
            formControlName="isEmployee" />
          <label class="form-check-label" for="isEmployeeCheckbox">IsEmployee?</label>
        </div>
      </div>

      <!-- <div class="col-md-8">
        <div>
          <label for="isAfricanAmerican" class="form-label fs-base">isAfricanAmerican</label>
          <input
            type="text"
            name="isAfricanAmerican"
            id="isAfricanAmerican"
            formControlName="isAfricanAmerican"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your isAfricanAmerican!</div>
        </div>
      </div> -->
      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="isSiri3Checkbox"
            name="isSiri3Checkbox"
            formControlName="isSiri3" />
          <label class="form-check-label" for="isSiri3Checkbox">IsSiri3</label>
        </div>
      </div>

      <div class="form-group row mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="useSiri3Checkbox"
            name="useSiri3Checkbox"
            formControlName="useSiri3" />
          <label class="form-check-label" for="useSiri3Checkbox">UseSiri3</label>
        </div>
      </div>

      <div class="col-md-4"></div>
    </div>

    <div class="d-flex mb-3">
      <!-- <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button> -->
      <button type="submit" class="btn btn-primary btn-lg" (click)="updateCustomer()">Save changes</button>
    </div>
  </ng-container>
</form>
