<article class="col-md-12 my-5">
  <div>
    <form
      class="row row-cols-lg-auto g-3 align-items-center"
      [formGroup]="searchForm"
      id="test-event-explorer"
      (ngSubmit)="searchEvent()">
      <div class="col-12">
        <label class="visually-hidden" for="inlineFormInputGroupUsername">Search</label>
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            id="inlineFormInputGroupSearch"
            placeholder="Search"
            formControlName="searchTerm"
            autocomplete="off" />
        </div>
      </div>

      <div class="col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineFormGymName"
            formControlName="preset"
            value="gymName" />
          <label class="form-check-label" for="inlineFormGymName"> Gym Name </label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineFormEventCode"
            formControlName="preset"
            value="eventCode" />
          <label class="form-check-label" for="inlineFormEventCode"> Event Code </label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="inlineFormRegion"
            formControlName="preset"
            value="region" />
          <label class="form-check-label" for="inlineFormRegion"> Region </label>
        </div>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary">Search</button>
      </div>
    </form>
  </div>
</article>
