import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EmailI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private env = inject(ENV);
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private emailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_EMAILS}`;
  private emailsDertailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_EMAILS_DETAILS}`;

  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getEmail(id: string): Observable<EmailI> {
    const url = `${this.env.api}/${this.emailsDertailsUrl}/${id}`;
    return this.http.get<EmailI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched email id=${id}`)),
      catchError(this.sharedErrorService.handleError<EmailI>(`getEmail id=${id}`)),
    );
  }

  updateEmail(id: string, email: EmailI): Observable<any> {
    const url = `${this.env.api}/${this.emailsDertailsUrl}/${id}`;
    return this.http.patch(url, email, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated email id=${id}`)),
      catchError(this.sharedErrorService.handleError<EmailI>('updateEmail')),
    );
  }

  deleteEmail(id: string): Observable<EmailI> {
    const url = `${this.env.api}/${this.emailsUrl}/${id}`;

    return this.http.delete<EmailI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted email id=${id}`)),
      catchError(this.sharedErrorService.handleError<EmailI>('deleteEmail')),
    );
  }

  getEmails(): Observable<EmailI[]> {
    const url = `${this.env.api}/${this.emailsUrl}`;
    return this.http.get<EmailI[]>(url).pipe(
      tap(_ => console.log('fetchedemail')),
      catchError(this.sharedErrorService.handleError<EmailI[]>('getEmails', [])),
    );
  }

  addEmail(emailDetails: EmailI): Observable<EmailI> {
    const url = `${this.env.api}/${this.emailsUrl}`;

    return this.http.post<EmailI>(url, emailDetails, this.httpOptions).pipe(
      tap((newEmail: EmailI) => this.sharedLogService.log(`send email`)),
      catchError(this.sharedErrorService.handleError<EmailI>('failed send email')),
    );
  }
  searchEmails(term: string): Observable<EmailI[]> {
    const url = `${this.env.api}/${this.emailsUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty email array.
      return of([]);
    }

    return this.http.get<EmailI[]>(`${url}`).pipe(
      tap(x => (x.length ? console.log(`found email matching "${term}"`) : console.log(`no email matching "${term}"`))),
      catchError(this.sharedErrorService.handleError<EmailI[]>('searchEmails', [])),
    );
  }
}
