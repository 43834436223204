import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bodyanalytics-security-form',
  templateUrl: './security-form.component.html',
  styleUrls: ['./security-form.component.scss'],
})
export class SecurityFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  formsubmit: boolean = false;
  constructor() {
    //
  }

  public securityForm = new FormGroup({
    currentPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirmPassword: new FormControl('', [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      currentPassword: this.securityForm.value.currentPassword as string,
      newPassword: this.securityForm.value.newPassword as string,
      confirmPassword: this.securityForm.value.confirmPassword as string,
    });
  }
  /**
   * Returns form
   */
  get form() {
    return this.securityForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
