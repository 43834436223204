import { createReducer, on } from '@ngrx/store';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import * as TestEventActions from './test-event.actions';
import { TestEventStateInterface } from '../types/testEventState.interface';

export const testEventFeatureKey = 'testEvent';

// export interface State {
//   events: TestingEventI[];
//   selectedEvent: TestingEventI | null;
//   error: string | null;
//   loading: boolean;
// }

const createTestEvent = (testEvents: TestingEventI[], testEvent: TestingEventI) => [...testEvents, testEvent];
const updateTestEvent = (testEvents: TestingEventI[], changes: TestingEventI) =>
  testEvents.map(testEvent => {
    return testEvent.id === changes.id ? Object.assign({}, testEvent, changes) : testEvent;
  });
const deleteTestEvent = (testEvents: TestingEventI[], testEventId: string) => {
  testEvents.filter(testEvent => testEventId !== testEvent.id);
};

export const initialState: TestEventStateInterface = {
  collection: [],
  selectedEvent: null,
  error: null,
  isLoading: false,
  currentTestEventId: null,
  query: null,
  preset: '',
};

export const testEventReducer = createReducer(
  initialState,
  on(TestEventActions.clearSelectedEvent, TestEventActions.enter, state => {
    return {
      ...state,
      currentTestEventId: null,
    };
  }),

  on(TestEventActions.selectEvent, (state, action) => {
    return {
      ...state,
      currentTestEventId: action.selectedEvent?.id,
    };
  }),
  on(TestEventActions.testEventsLoaded, (state, action) => {
    return {
      ...state,
      collection: action.testEvents,
    };
  }),
  on(TestEventActions.testEventsCreated, (state, action) => {
    return {
      ...state,
      collection: createTestEvent(state.collection, action.testEvents),
      currentTestEventId: null,
      isLoading: false,
    };
  }),
  on(TestEventActions.testEventsUpdated, (state, action) => {
    return {
      // ...state,
      collection: updateTestEvent(state.collection, action.changes),
      currentTestEventId: null,
      isLoading: false,
      selectedEvent: null,
      error: null,
      query: null,
      preset: '',
    };
  }),
  // on(TestEventActions.testEventsDeleted, (state, action) => {
  //   return {
  //     ...state,
  //     collection: deleteTestEvent(state.collection, action.testEventId),
  //     currentTestEventId: null,
  //     isLoading:false,
  //     selectedEvent: null,
  //     error: null,
  //   };
  // }),
  //  ({ ...state, isLoading: true })),
  // on(TestEventActions.getTestEventsSuccess, (state, action) => ({
  //   ...state,
  //   testEvents: action.testEvents,
  //   isLoading: false,
  // })),
  // on(TestEventActions.getTestEventsFailure, (state, action) => ({ ...state, error: action.error, isLoading: false })),
  on(TestEventActions.selectEvent, (state, { selectedEvent }) => ({ ...state, selectedEvent })),
);
// export const selectAll = (state: State) => state.collection;
// export const selectActiveBookId = (state: State) => state.activeBookId;
