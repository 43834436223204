import { Component, ChangeDetectionStrategy, output, input } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { BodyFatTestI } from '@bodyanalytics/data-models-ui';
import { NgClass } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements-form',
  standalone: true,
  templateUrl: './manage-bodymeasurements-form.component.html',
  styleUrls: ['./manage-bodymeasurements-form.component.scss'],
  imports: [ReactiveFormsModule, NgClass],
})
export class ManageBodymeasurementsFormComponent {
  item = input.required<BodyFatTestI>();
  submitFormEvent = output<any>();

  protected bodymeasurementDetailsForm = new FormGroup({
    age: new FormControl(0, [Validators.required]),
    assignedTruck: new FormControl('', [Validators.required]),
    createdAt: new FormControl('', [Validators.required]),
    dryWeight: new FormControl('', [Validators.required]),
    fatLbs: new FormControl(0, [Validators.required]),
    fatPercent: new FormControl('', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    height: new FormControl(0, [Validators.required]),
    isTraining: new FormControl(false, [Validators.required]),
    leanLbs: new FormControl(0, [Validators.required]),
    leanMassPercent: new FormControl(0, [Validators.required]),
    note: new FormControl('', [Validators.required]),
    residualLungValue: new FormControl(0, [Validators.required]),
    restingMetabolicRate: new FormControl(0, [Validators.required]),
    salinity: new FormControl('', [Validators.required]),
    testDate: new FormControl('', [Validators.required]),
    uid: new FormControl('', [Validators.required]),
    updatedAt: new FormControl('', [Validators.required]),
    waterLbs: new FormControl(0, [Validators.required]),
    waterTemperature: new FormControl(0, [Validators.required]),
    wetKg: new FormControl(0, [Validators.required]),
    weightLbs: new FormControl(0, [Validators.required]),
  });

  ngOnInit(): void {
    this.getBodyMeasurement();
  }

  public getBodyMeasurement(): void {
    // if (this.bodymeasurementDetailsForm.valid) {
    if (this.item) {
      this.bodymeasurementDetailsForm.patchValue({
        testDate: this.item()?.testDate as string,
        age: this.item()?.age,
        assignedTruck: this.item()?.assignedTruck,
        createdAt: this.item()?.createdAt as string,
        dryWeight: this.item()?.dryWeight as string,
        fatLbs: this.item()?.fatLbs,
        fatPercent: this.item()?.fatPercent as string,
        sex: this.item()?.sex as string,
        gender: this.item()?.gender as string,
        height: this.item()?.height,
        isTraining: this.item()?.isTraining,
        leanLbs: this.item()?.leanLbs,
        leanMassPercent: this.item()?.leanMassPercent,
        note: this.item()?.note,
        residualLungValue: this.item()?.residualLungValue,
        restingMetabolicRate: this.item()?.restingMetabolicRate,
        salinity: this.item()?.salinity,
        uid: this.item()?.uid,
        updatedAt: this.item()?.updatedAt as string,
        waterLbs: this.item()?.waterLbs,
        waterTemperature: this.item()?.waterTemperature,
        wetKg: this.item()?.wetKg,
      });
    }
  }

  public updateBodyMeasurement(): void {
    console.log(`ManageBodymeasurementsFormComponent: updateBodyMeasurement`);

    this.submitFormEvent.emit({
      testDate: this.bodymeasurementDetailsForm.value.testDate as string,
      age: this.bodymeasurementDetailsForm.value.age,
      assignedTruck: this.bodymeasurementDetailsForm.value.assignedTruck,
      createdAt: this.bodymeasurementDetailsForm.value.createdAt as string,
      dryWeight: this.bodymeasurementDetailsForm.value.dryWeight as string,
      fatLbs: this.bodymeasurementDetailsForm.value.fatLbs,
      fatPercent: this.bodymeasurementDetailsForm.value.fatPercent as string,
      gender: this.bodymeasurementDetailsForm.value.gender as string,
      height: this.bodymeasurementDetailsForm.value.height,
      isTraining: this.bodymeasurementDetailsForm.value.isTraining,
      leanLbs: this.bodymeasurementDetailsForm.value.leanLbs,
      leanMassPercent: this.bodymeasurementDetailsForm.value.leanMassPercent,
      note: this.bodymeasurementDetailsForm.value.note,
      residualLungValue: this.bodymeasurementDetailsForm.value.residualLungValue,
      restingMetabolicRate: this.bodymeasurementDetailsForm.value.restingMetabolicRate,
      salinity: this.bodymeasurementDetailsForm.value.salinity,
      uid: this.bodymeasurementDetailsForm.value.uid,
      updatedAt: this.bodymeasurementDetailsForm.value.updatedAt as string,
      waterLbs: this.bodymeasurementDetailsForm.value.waterLbs,
      waterTemperature: this.bodymeasurementDetailsForm.value.waterTemperature,
      wetKg: this.bodymeasurementDetailsForm.value.wetKg,
    });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  public calculateMeasurement() {
    console.log(`ManageBodymeasurementsFormComponent: calculateMeasurement - 167`);
    this.updateBodyMeasurement();
  }

  // public deleteMeasurement() {
  //   this.subscriptions.barrel = this.service
  //     .deleteBodyMeasurement(`${this.item()?.id}`)
  //     .pipe(
  //       tap(() => console.log(`deleteMeasurement - ${this.item()?.id}`)),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }

  get form() {
    return this.bodymeasurementDetailsForm.controls;
  }
}
