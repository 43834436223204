<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="orderDetailsForm">
  @if(item()) {
    <div class="row">
      <div class="col-md-8">
        <div class="row pb-2">
          <div class="col-sm-10 mb-4">
            <div class="mb-3 position-relative">
              <label for="firstName" class="form-label fs-base">First Name</label>
              <input
                type="text"
                for="firstName"
                [ngClass]="{ 'is-invalid': formsubmit && form.firstName.errors }"
                class="form-control form-control-lg"
                maxlength="20"
                formControlName="firstName"
                #firstName />
              <div *ngIf="formsubmit && form.firstName.errors" class="invalid-tooltip">
                <span *ngIf="form.firstName.errors['required']">First Name is required.</span>
              </div>
            </div>
          </div>
          <div class="col-sm-10 mb-4">
            <div class="mb-3 position-relative">
              <label for="address1" class="form-label fs-base">Address 1</label>
              <input
                type="text"
                for="address1"
                [ngClass]="{ 'is-invalid': formsubmit && form.address1.errors }"
                class="form-control form-control-lg"
                maxlength="20"
                formControlName="address1"
                #address1 />
              <div *ngIf="formsubmit && form.address1.errors" class="invalid-tooltip">
                <span *ngIf="form.address1.errors['required']">Address 1 is required.</span>
              </div>
            </div>
          </div>

          <div class="col-sm-10 mb-4">
            <div class="mb-3 position-relative">
              <label for="address2" class="form-label fs-base">Address 2</label>
              <input
                type="text"
                for="address2"
                [ngClass]="{ 'is-invalid': formsubmit && form.address2.errors }"
                class="form-control form-control-lg"
                maxlength="20"
                formControlName="address2"
                #address2 />
              <div *ngIf="formsubmit && form.address2.errors" class="invalid-tooltip">
                <span *ngIf="form.address2.errors['required']">Address 2 is required.</span>
              </div>
            </div>
          </div>
          <div class="col-sm-4 mb-4">
            <div class="mb-3 position-relative">
              <label for="city" class="form-label fs-base">City</label>
              <input
                type="text"
                for="city"
                [ngClass]="{ 'is-invalid': formsubmit && form.city.errors }"
                class="form-control form-control-lg"
                placeholder="Doe"
                maxlength="20"
                formControlName="city"
                #city />
              <div *ngIf="formsubmit && form.city.errors" class="invalid-tooltip">
                <span *ngIf="form.city.errors['required']">Last name is required.</span>
                <span *ngIf="form.city.errors['pattern']">Please enter a valid last name.</span>
              </div>
            </div>
          </div>
          <div class="col-sm-3 mb-4">
            <div class="mb-3 position-relative">
              <label for="stateDropdown" class="form-label fs-base">State</label>
              <select
                id="stateDropdown"
                class="form-control form-select form-select-lg"
                name="state"
                formControlName="state"
                [ngClass]="{ 'is-invalid': formsubmit && form.state.errors }">
                <option value="null">Please Select</option>
                <option *ngFor="let state of stateList" [value]="state.stateCode">
                  {{ state.stateName }}
                </option>
              </select>
              <div *ngIf="formsubmit && form.state.errors" class="invalid-tooltip">
                <span *ngIf="form.state.errors['required']">State is required.</span>
              </div>
            </div>
          </div>

          <div class="col-sm-4 mb-4">
            <div class="mb-3 position-relative">
              <label for="zipCode" class="form-label fs-base">Zipcode</label>
              <input
                type="text"
                id="zipCode"
                class="form-control form-control-lg"
                formControlName="zipCode"
                #zipCode
                maxlength="10"
                [ngClass]="{ 'is-invalid': formsubmit && form.zipCode.errors }" />
              <div *ngIf="formsubmit && form.zipCode.errors" class="invalid-tooltip">
                <span *ngIf="form.zipCode.errors['required']">Zipcode is required.</span>
                <span *ngIf="form.zipCode.errors['pattern']">Please enter a valid zip code.</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="mb-3 position-relative">
              <label for="phoneNumber" class="form-label fs-base"
                >Phone number <small class="text-muted">(optional)</small></label
              >
              <input
                type="tel"
                id="phoneNumber"
                [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
                class="form-control form-control-lg"
                placeholder="(555)-555-5555"
                maxlength="11"
                formControlName="phoneNumber"
                #phoneNumber />

              <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
                <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
                <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="clubInRegionId" class="form-label fs-base">Region</label>
              <input
                type="text"
                name="title"
                id="clubInRegionId"
                formControlName="clubInRegionId"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your clubInRegionId!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="clubLogo" class="form-label fs-base">Logo</label>
              <input
                type="text"
                name="clubLogo"
                id="clubLogo"
                formControlName="clubLogo"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your clubLogo!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="signupUrl" class="form-label fs-base">Signup Url</label>
              <!-- http://bodyanalyticstx.com/GymEvents/Cleburne-Test-Site-Bethel-Temple-Assembly-of-God -->
              <input
                type="text"
                name="signupUrl"
                id="signupUrl"
                formControlName="signupUrl"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your signupUrl!</div>
            </div>
          </div>
          <div class="mt-5">
            <h4 class="h5">Commission Details</h4>
          </div>
          <div class="col-md-12">
            <div>
              <label for="commission" class="form-label fs-base">Commission</label>
              <input
                type="text"
                name="commission"
                id="commission"
                formControlName="commission"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your commission!</div>
            </div>
          </div>
          <div class="mt-5">
            <h4 class="h5">Contact Details</h4>
          </div>

          <div class="col-md-12">
            <div>
              <label for="contactName" class="form-label fs-base">First Name</label>
              <!-- http://bodyanalyticstx.com/GymEvents/Cleburne-Test-Site-Bethel-Temple-Assembly-of-God -->
              <input
                type="text"
                name="contactName"
                id="contactName"
                formControlName="contactName"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your contactName!</div>
            </div>
          </div>

          <div class="col-sm-6 mb-4">
            <div class="mb-3 position-relative">
              <label for="phoneNumber" class="form-label fs-base"
                >Phone number <small class="text-muted">(optional)</small></label
              >
              <input
                type="tel"
                id="phoneNumber"
                [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
                class="form-control form-control-lg"
                placeholder="(555)-555-5555"
                maxlength="11"
                formControlName="phoneNumber"
                #phoneNumber />

              <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
                <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
                <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6 mb-4">
            <div class="mb-3 position-relative">
              <label for="emailAddress" class="form-label fs-base">Email address</label>
              <input
                type="email"
                id="emailAddress"
                [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
                class="form-control form-control-lg"
                placeholder="john@mail.com"
                maxlength="50"
                formControlName="emailAddress"
                #emailAddress />
              <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
                <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
                <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="contactTitle" class="form-label fs-base">Title</label>
              <input
                type="text"
                name="contactTitle"
                id="contactTitle"
                formControlName="contactTitle"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your contactTitle!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="note" class="form-label fs-base">Note</label>
              <input type="text" name="note" id="note" formControlName="note" class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your note!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="list-group">
          <button type="button" class="list-group-item list-group-item-action" [routerLink]="[signupsDetail, item().id]">
            View Signups
          </button>
          <button type="button" class="list-group-item list-group-item-action" [routerLink]="[eventsDetail, item().id]">
            View Events
          </button>
          <button type="button" class="list-group-item list-group-item-action" (click)="openDeleteDialog(item().id)">
            Delete Gym
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex mb-3">
      <!-- <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button> -->
      <button type="submit" class="btn btn-primary btn-lg" (click)="updateClub()">Save changes</button>
    </div>
    }
</form>
