import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-email-ui',
  templateUrl: './email-ui.component.html',
  styleUrls: ['./email-ui.component.scss'],
})
export class EmailUiComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;

  constructor(private router: Router) {
    super();
  }
  // public testingEventDetailsForm = FormGroup;

  public createEmail() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS_CREATE_EMAIL}`);
  }

  public reviewEmail() {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    const id = '2';
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS_REVIEW_EMAIL}`);
  }
}
