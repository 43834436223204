<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="testingEventDetailsForm">
  <ng-container>
    @if (item()) {
      <div class="row pb-2">
        <div class="col-md-8">
          <div class="form-group row mt-2">
            <!-- - start typing gym name -->
            <label for="drpClubInput" class="col-sm-4 col-form-label">Club: </label>
            <!-- <div class="col-sm-8">
          <select
            id="drpClubInput"
            class="form-control form-select form-select-lg"
            name="clubName"
            formControlName="testEventAtClubId"
            [ngClass]="{ 'is-invalid': formsubmit && form.testEventAtClubId.errors }">
            <option value="null">Please Select</option>
            <option *ngFor="let event of eventLocation" [value]="event.id">
              {{ event?.clubName }}
            </option>
          </select>
          <div *ngIf="formsubmit && form.testEventAtClubId.errors" class="invalid-tooltip mb-3">
            <span *ngIf="form.testEventAtClubId.errors['required']">Club is required</span>
          </div>
        </div> -->
            <div class="col-sm-8">
              <div class="mb-3 position-relative">
                <!-- <label for="clubName" class="form-label fs-base">Club Name</label> -->
                <input
                  type="text"
                  for="clubName"
                  [ngClass]="{ 'is-invalid': formsubmit && form.clubName.errors }"
                  class="form-control form-control-lg"
                  maxlength="20"
                  formControlName="clubName"
                  #clubName />
                <div *ngIf="formsubmit && form.clubName.errors" class="invalid-tooltip">
                  <span *ngIf="form.clubName.errors['required']">Club Name is required.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="testEventDateInput" class="col-sm-4 col-form-label">Date: </label>
            <div class="col-sm-8 position-relative mb-4">
              <input
                type="date"
                name="testEventDateInput"
                id="testEventDateInput"
                [ngClass]="{ 'is-invalid': formsubmit && form.testEventDate.errors }"
                formControlName="testEventDate"
                class="form-control form-control-lg" />
              <div *ngIf="formsubmit && form.testEventDate.errors" class="invalid-tooltip">
                <span *ngIf="form.testEventDate.errors['required']">Date is required.</span>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <!-- operators -->
            <label for="drpOperator" class="col-sm-4 col-form-label">Assign to:</label>
            <div class="col-sm-8">
              <select
                id="drpOperator"
                class="form-control form-select form-select-lg"
                name="assignedOperatorId"
                [ngClass]="{ 'is-invalid': formsubmit && form.assignedOperatorId.errors }"
                formControlName="assignedOperatorId">
                <option value="null">Please Select</option>
                <option *ngFor="let operator of operators" [value]="operator.id">
                  {{ operator.operatorUserName }}
                </option>
              </select>
              <div *ngIf="formsubmit && form.assignedOperatorId.hasError('required')" class="invalid-tooltip">
                <span *ngIf="form.assignedOperatorId.hasError('required')">Assign operator is required.</span>
              </div>
            </div>

            <!-- <div *ngIf="formsubmit && form.operators.errors" class="invalid-tooltip">
          <span *ngIf="form.operators.errors['required']">Assign operator is required.</span>
        </div> -->
          </div>
          <div class="form-group row mt-2">
            <label for="drpTruck" class="col-sm-4 col-form-label">Assign truck: </label>
            <div class="col-sm-8">
              <select
                id="drpTruck"
                class="form-control form-select form-select-lg"
                name="trucks"
                [ngClass]="{ 'is-invalid': formsubmit && form.assignedTruckId.errors }"
                formControlName="assignedTruckId">
                <option value="null">Please Select</option>
                <option *ngFor="let truck of trucks" [value]="truck.id">
                  {{ truck.truckName }}
                </option>
              </select>
            </div>
            <div *ngIf="formsubmit && form.assignedTruckId.errors" class="invalid-tooltip">
              <span *ngIf="form.assignedTruckId.errors['required']">Assign truck is required.</span>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="drpStartTime" class="col-sm-4 col-form-label">Start Time: </label>
            <div class="col-sm-8">
              <select
                id="drpStartTime"
                class="form-control form-select form-select-lg"
                name="startTime"
                [ngClass]="{ 'is-invalid': formsubmit && form.startTime.errors }"
                formControlName="startTime">
                <option value="null">Please Select</option>
                <option *ngFor="let times of eventTimesJson" [value]="times.timeValue">
                  {{ times.timeLabel }}
                </option>
              </select>
              <div *ngIf="formsubmit && form.startTime.errors" class="invalid-tooltip">
                <span *ngIf="form.startTime.errors['required']">Start time is required.</span>
              </div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="drpEndTime" class="col-sm-4 col-form-label">End Time: </label>
            <div class="col-sm-8">
              <select
                id="drpEndTime"
                class="form-control form-select form-select-lg"
                name="endTime"
                [ngClass]="{ 'is-invalid': formsubmit && form.endTime.errors }"
                formControlName="endTime">
                <option value="null">Please Select</option>
                <option *ngFor="let times of eventTimesJson" [value]="times.timeValue">
                  {{ times.timeLabel }}
                </option>
              </select>
            </div>
            <div *ngIf="formsubmit && form.endTime.errors" class="invalid-tooltip">
              <span *ngIf="form.endTime.errors['required']">End time is required.</span>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="drpSignupInterval" class="col-sm-4 col-form-label">Signup Time Interval:</label>
            <div class="col-sm-8">
              <select
                id="drpSignupInterval"
                [ngClass]="{ 'is-invalid': formsubmit && form.signupInterval.errors }"
                class="form-select form-select-lg mb-3"
                name="drpSignupInterval"
                formControlName="signupInterval">
                <option value="null">Please Select</option>
                <option *ngFor="let singupTime of signupIntervalJson" [value]="singupTime.intervalId">
                  {{ singupTime.intervalValue }}
                </option>
              </select>
            </div>
            <div *ngIf="formsubmit && form.signupInterval.errors" class="invalid-tooltip">
              <span *ngIf="form.signupInterval.errors['required']">Signup interval is required.</span>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="testEventDescriptionInput" class="col-sm-4 col-form-label">Event Description:</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="testEventDescriptionInput"
                class="form-control form-control-lg"
                formControlName="testEventDescription"
                #testEventDescription />
              <div class="invalid-feedback">Please provide a valid testEventDescription!</div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <label for="testEventPhotoInput" class="col-sm-4 col-form-label">Event Photo:</label>
            <div class="col-sm-8">
              <input
                type="text"
                id="testEventPhotoInput"
                class="form-control form-control-lg"
                formControlName="testEventPhoto"
                #testEventPhoto />
              <div class="invalid-feedback">Please provide a valid testEventPhoto!</div>
            </div>
          </div>
          <div class="form-group row mt-2">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="isAllowedSignupOnlineCheckbox"
                name="isAllowedSignupOnlineCheckbox"
                formControlName="isAllowedSignupOnline" />
              <label class="form-check-label" for="isAllowedSignupOnlineCheckbox">Allow Online Signup?</label>
            </div>
          </div>
          <div class="form-group row mt-2">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="isPaymentRequiredCheckbox"
                name="isPaymentRequiredCheckbox"
                formControlName="isPaymentRequired" />
              <label class="form-check-label" for="isPaymentRequiredCheckbox">Payment Required?</label>
            </div>
          </div>
          <div class="form-group row mt-2 mb-5">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                id="isTestingEventPrivateCheckbox"
                name="isTestingEventPrivateCheckbox"
                formControlName="isTestingEventPrivate" />
              <label class="form-check-label" for="isTestingEventPrivateCheckbox">Private Event?</label>
            </div>
          </div>
          <!-- <div class="col-md-8">
        <div>
          <label for="title" class="form-label fs-base">Event Name</label>
          <input type="text" name="title" id="title" formControlName="testEventName" class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your event name!</div>
        </div>
      </div> -->
        </div>
        <div class="col-md-4">
          <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
              View Signups
            </button>

            <button type="button" class="list-group-item list-group-item-action" (click)="viewClubDetails()">
              View Club Details
            </button>

            <button type="button" class="list-group-item list-group-item-action" (click)="showSignupList()">
              View Signup List
            </button>

            <button type="button" class="list-group-item list-group-item-action" (click)="sendEmailReminder()">
              Email All
            </button>

            <!-- <button type="button" class="list-group-item list-group-item-action" (click)="sendSmsReminder()">
            Send Text Message
          </button> -->

            <button type="button" class="list-group-item list-group-item-action" (click)="duplicateEvent()">
              Create Duplicate Event
            </button>

            <button type="button" class="list-group-item list-group-item-action" (click)="openDeleteDialog(item().id)">
              Cancel Event
            </button>
          </div>
        </div>
      </div>

      <div class="d-flex mb-3">
        <!-- <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button> -->
        <button type="submit" class="btn btn-primary btn-lg" (click)="updateTestingEvent()">Save changes</button>
      </div>
    }
  </ng-container>
</form>
