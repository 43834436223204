import { Routes } from '@angular/router';
import { MobileTestingCalendarComponent } from './containers/mobile-testing-calendar/mobile-testing-calendar.component';
import { CalendarSearchComponent } from './containers/calendar-search/calendar-search.component';
import { SignupComponent } from './containers/signup/signup.component';
import { MobileTestingResolver } from './guards/testing-events.resolver';
import { SignupCheckoutComponent } from './containers/signup-checkout/signup-checkout.component';
// import { AuthGuard } from '@auth0/auth0-angular';
import { OrderConfirmationComponent } from './containers/order-confirmation/order-confirmation.component';
import { TesteventsDetailPageComponent } from './containers/testevents-detail-page/testevents-detail-page.component';
import { OrderConfirmResolver } from './guards/order.resolver';
import { BookingResolver } from 'libs/front/customer/booking-ui/src/lib/guards/booking.resolver';
import { RecheduleVerifyPageComponent } from './containers/rechedule-verify-page/rechedule-verify-page.component';
import { RescheduleConfirmationPageComponent } from './containers/reschedule-confirmation-page/reschedule-confirmation-page.component';
import { authPermissionsGuard } from '@bodyanalytics/ui-core';
import { AbxAuthGuard } from './guards/auth-abx.guard';
import { SignupVerifyPageComponent } from './containers/signup-verify-page/signup-verify-page.component';


export const calendarRoutes: Routes = [
  {
    path: '',
    component: MobileTestingCalendarComponent,
    children: [
      // { path: '**', redirectTo: 'search' },
      { path: '', pathMatch: 'full', redirectTo: 'search' },
      {
        path: 'search',
        component: CalendarSearchComponent,
        pathMatch: 'full',
      },
      {
        path: 'search/:id',
        component: CalendarSearchComponent,
        resolve: {
          booking: BookingResolver,
        },
      },
      {
        path: 'event-details/:id',
        component: TesteventsDetailPageComponent,
        resolve: {
          eventData: MobileTestingResolver,
        },
        pathMatch: 'full',
      },
      {
        path: 'signup/:id',
        component: SignupComponent,
        resolve: {
          eventData: MobileTestingResolver,
        },
      },
      {
        path: 'signup-verify/:id',
        component: SignupVerifyPageComponent,
        canActivate: [AbxAuthGuard], // Protected route
      },
      {
        path: 'rechedule-verify/:id',
        component: RecheduleVerifyPageComponent,
        resolve: {
          eventData: MobileTestingResolver,
        },
      },
      {
        path: 'reschedule-confirmation/:id',
        component: RescheduleConfirmationPageComponent,
        resolve: {
          orderData: OrderConfirmResolver,
        },
      },
      {
        path: 'checkout/:id',
        component: SignupCheckoutComponent,
        resolve: {
          eventData: MobileTestingResolver,
        },
        canActivate: [ AbxAuthGuard], // Protected route
        canLoad: [AbxAuthGuard]

      },
      {
        path: 'order-confirmation/:id',
        component: OrderConfirmationComponent,
        resolve: {
          orderData: OrderConfirmResolver,
        },
        canActivate: [AbxAuthGuard], // Protected route
      },
    ],
  },
];
