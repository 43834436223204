import { Component, ChangeDetectionStrategy, output, signal } from '@angular/core';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TestingFormValue } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-customer-testing-form',
  standalone: true,
  templateUrl: './customer-testing-form.component.html',
  styleUrls: ['./customer-testing-form.component.scss'],
  imports: [ReactiveFormsModule, FormsModule, MatButtonModule, MatInputModule],
})
export class CustomerTestingFormComponent extends BodyanalyticsBaseComponent {
  calcChange = output<TestingFormValue>();
  completeChange = output<TestingFormValue>();
  protected softTestCompleted = signal(false);

  calc(value: TestingFormValue): void {
    this.softTestCompleted.set(true);
    this.calcChange.emit(value);
  }

  complete(value: TestingFormValue): void {
    this.completeChange.emit(value);
  }
}
