import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { CreateRegionComponent } from './create-region/create-region.component';
import { ManageRegionsDashboardComponent } from './manage-regions-dashboard/manage-regions-dashboard.component';
import { ManageRegionsDetailComponent } from './manage-regions-detail/manage-regions-detail.component';
import { ManageRegionsComponent } from './manage-regions/manage-regions.component';
import { ManageRegionService, provideRegions } from '@bodyanalytics/front/admin/manage-regions/domain';


export const manageRegionsUiRoutes: Route =
  {
    path: 'auth/manage-regions',
    component: ManageRegionsComponent,
    canActivate: [AuthGuard],
    providers:[provideRegions(), ManageRegionService],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-region',
        component: CreateRegionComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: ManageRegionsDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ManageRegionsDetailComponent,
      },
    ],
  };
