import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductI, ServicesI, SignupI, SlotI, SlotNI, StateI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import {
  ApiLocalRoutesEnum,
  SessionStorageService,
  SharedErrorService,
  SharedLogService,
} from '@bodyanalytics/ui-core';
import { Observable, Subject, catchError, tap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class SignupService {
  private eventSlotsAllUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_GENERATE_SLOTS}`;
  private productUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS_PRODUCTS}`;
  private productsApiUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS_PRODUCTS}`;
  private reserveUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_RESERVE_SLOTS}`;
  private testingEventsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS_AVAILABILE_SLOTS}`;
  private statesUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS_STATES}`;

  public userDataSubject = new Subject<any>();
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private sharedErrorService: SharedErrorService,
    private sharedLogService: SharedLogService,
  ) {}

  signupData = new Subject<Array<any>>();

  private _signupDataList: Array<any> = [];

  public get signupDataList(): Array<any> {
    return this._signupDataList;
  }
  public set signupDataList(value: Array<any>) {
    this._signupDataList = value;
  }

  getSignupDetails() {
    const savedUserData = this.sessionStorageService.getBookingData()!.userInfo;

    return savedUserData;
  }

  getBookingDetails() {
    const savedUserData = this.sessionStorageService.getBookingData();

    return savedUserData;
  }

  public setUserData(data: any) {
    this.userDataSubject.next(data);
  }

  public removeUserData() {
    this.userDataSubject.next(null);
  }
  signupForEvent(signup: SignupI) {
    const url = `${this.testingEventsUrl}${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ADDRESS}`;

    return this.http.post(url, signup);
  }

  getServicesByProductId(productId: string) {
    const url = `${this.appConfig.api}/${this.productsApiUrl}/${productId}/services`;

    return this.http.get<ServicesI[]>(url);
  }

  createReservationOrder(order: any): Observable<any> {
    const url = `${this.appConfig.api}/${this.reserveUrl}`;

    return this.http.post<any>(url, order, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added order w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('createReservationOrder')),
    );
  }

  getProducts(eventId: string): Observable<ProductI[]> {
    const url = `${this.appConfig.api}/${this.productUrl}/${eventId}`;
    return this.http.get<ProductI[]>(url).pipe(
      tap(_ => console.log('fetched product')),
      catchError(this.sharedErrorService.handleError<ProductI[]>('getProducts')),
    );
  }
  generateSlots(eventDate: string, startTime: string, endTime: string, interval: number): Observable<SlotNI[]> {
    const url = `${this.appConfig.api}/${this.eventSlotsAllUrl}`;

    const options = new HttpParams()
      .set('eventDate', eventDate)
      .set('startTime', startTime)
      .set('endTime', endTime)
      .set('interval', interval);

    return this.http.get<SlotNI[]>(url, { params: options });
  }

  getAllSlotsByEventNew(id: string): Observable<SlotI[]> {
    const url = `${this.appConfig.api}/${this.eventSlotsAllUrl}/${id}`;
    return this.http.get<SlotI[]>(url).pipe(
      tap(_ => console.log('fetched eventSlotsUrl')),
      catchError(this.sharedErrorService.handleError<SlotI[]>('getAvaliableSlots', [])),
    );
  }
  getAllSlotsByEvent(id: string): Observable<SlotI[]> {
    const url = `${this.appConfig.api}/${this.eventSlotsAllUrl}/${id}`;
    return this.http.get<SlotI[]>(url).pipe(
      tap(_ => console.log('fetched eventSlotsUrl')),
      catchError(this.sharedErrorService.handleError<SlotI[]>('getAvaliableSlots', [])),
    );
  }

  getAvaliableSlots(id: string): Observable<SlotNI[]> {
    const url = `${this.appConfig.api}/${this.testingEventsUrl}/${id}`;
    return this.http.get<SlotNI[]>(url).pipe(
      tap(_ => console.log('fetched eventSlotsUrl')),
      catchError(this.sharedErrorService.handleError<SlotNI[]>('getAvaliableSlots', [])),
    );
  }

  getStates(): Observable<StateI[]> {
    const url = `${this.appConfig.api}/${this.statesUrl}`;
    return this.http.get<StateI[]>(url).pipe(
      tap(_ => console.log('fetched eventSlotsUrl')),
      catchError(this.sharedErrorService.handleError<StateI[]>('getAvaliableSlots', [])),
    );
  }
}
