<div *ngIf="!isCreditAvailable">
  <ngx-stripe-payment [clientSecret]="clientSecret" (click)="updatePaymentDetails()">
    <span style="color: green" *ngxStripeLoadingTemplate> Loading Stripe Card... </span>
  </ngx-stripe-payment>
  <div class="col-12 my-2 text-center mt-5">
    <div class="d-grid mt-4 col-4 mx-auto">
      <button class="btn btn-primary mb-0" (click)="pay()" [disabled]="isDislayPaymentFlag">Confirm Booking</button>
    </div>
  </div>
</div>
