import { signalStore, type, withMethods, patchState, withHooks, withState } from '@ngrx/signals';
import { BodyFatReportI, TestingEventI, OperatorI, TruckI, ClubI } from '@bodyanalytics/data-models-ui';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, pipe, tap } from 'rxjs';
import { inject } from '@angular/core';
import { ManageBodymeasurementService } from './manage-bodymeasurement.service';
import { tapResponse } from '@ngrx/operators';
import { withLocalStorage } from '@bodyanalytics/ui-core';
import { State } from './manage-bodymeasurement.interfaces';
import { Router } from '@angular/router';

const state: State = {
  clientHistory: [],
  assessmentForm: {
    club: '',
    event: '',
    operator: '',
    truck: '',
    waterTemperature: 0,
  },
  checkInCompleted: false,
  customers: [],
  selectedReportLoading: false,
  selectedReviewReport: undefined,
  selectedTestingReport: undefined,
};

export const ManageBodymeasurementStore = signalStore(
  withState(state),
  withLocalStorage('abx', 'assessmentForm'),
  withEntities({ collection: 'bodymeasurement', entity: type<BodyFatReportI>() }),
  withEntities({ collection: 'event', entity: type<TestingEventI>() }),
  withEntities({ collection: 'operator', entity: type<OperatorI>() }),
  withEntities({ collection: 'truck', entity: type<TruckI>() }),
  withEntities({ collection: 'club', entity: type<ClubI>() }),

  withMethods((s, service = inject(ManageBodymeasurementService), router = inject(Router)) => ({
    patchReportingForm: (assessmentForm: Partial<typeof state.assessmentForm>) => patchState(s, { assessmentForm }),
    loadBodymeasurements: rxMethod<void>(
      pipe(
        switchMap(() => service.loadReports()),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities, { collection: 'bodymeasurement' })),
          error: error => console.error(error),
        }),
      ),
    ),
    loadEvents: rxMethod<void>(
      pipe(
        switchMap(() => service.loadEvents()),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities, { collection: 'event' })),
          error: error => console.error(error),
        }),
      ),
    ),
    loadOperators: rxMethod<void>(
      pipe(
        switchMap(() => service.loadOperators()),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities, { collection: 'operator' })),
          error: error => console.error(error),
        }),
      ),
    ),
    loadTrucks: rxMethod<void>(
      pipe(
        switchMap(() => service.loadTrucks()),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities, { collection: 'truck' })),
          error: error => console.error(error),
        }),
      ),
    ),
    loadClubs: rxMethod<void>(
      pipe(
        switchMap(() => service.loadClubs()),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities, { collection: 'club' })),
          error: error => console.error(error),
        }),
      ),
    ),

    customerCheckIn: rxMethod<string>(
      pipe(
        switchMap(email =>
          service.customerCheckIn(email).pipe(
            tapResponse({
              next: customers => patchState(s, { customers }),
              error: error => console.error(error),
              finalize: () => patchState(s, { checkInCompleted: true }),
            }),
          ),
        ),
      ),
    ),
    loadCompletedTestReport: rxMethod<string>(
      pipe(
        switchMap(id =>
          service.loadBodyMeasurementReview(id).pipe(
            tapResponse({
              next: selectedReviewReport => patchState(s, { selectedReviewReport }),
              error: error => console.error(error),
            }),
          ),
        ),
      ),
    ),
    loadBodyMeasurementReport: rxMethod<string>(
      pipe(
        switchMap(id =>
          service.loadBodyMeasurementReport(id).pipe(
            tapResponse({
              next: selectedTestingReport => patchState(s, { selectedTestingReport }),
              error: error => console.error(error),
            }),
          ),
        ),
      ),
    ),
    loadClientHistory: rxMethod<string>(
      switchMap(email =>
        service.loadClientHistory(email).pipe(
          tapResponse({
            next: clientHistory => patchState(s, { clientHistory }),
            error: error => console.error(error),
          }),
        ),
      ),
    ),
    createCompleteTestRecord: rxMethod<{ id: string; route: string }>(
      pipe(
        switchMap(({ id, route }) =>
          service.createCompleteTestRecord(id).pipe(tap(res => router.navigateByUrl(`${route}/${res.id}`))),
        ),
      ),
    ),
    sendReport: rxMethod<{ id: string; email: string }>(
      pipe(
        switchMap(data =>
          service.sendReport(data).pipe(
            tapResponse({
              next: () => console.log('Report sent'),
              error: error => console.error(error),
            }),
          ),
        ),
      ),
    ),
    downloadPdfReport: rxMethod<{ email: string; id: string }>(
      pipe(
        switchMap(({ email, id }) =>
          service.downloadPdfReport(id).pipe(
            tapResponse({
              next: buffer => {
                const url = URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement('a');

                link.href = url;
                link.download = `${email}-${id}.pdf`;
                link.click();
                URL.revokeObjectURL(url);
              },
              error: error => console.error(error),
            }),
          ),
        ),
      ),
    ),
  })),
  withHooks({
    onInit: s => {
      s.loadBodymeasurements();
      s.loadEvents();
      s.loadOperators();
      s.loadTrucks();
      s.loadClubs();
    },
  }),
);
