<section class="bg-light bg-darker bg-size-cover bg-position-center py-5">
  <div class="container py-md-4">
    <h1 class="text-lg-start pt-3 text-dark">Testing Events</h1>
    <p class="lead mb-3">Find a mobile testing event near you.</p>
  </div>
</section>
<section class="bg-white">
  <div class="container py-sm-5">
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <ng-container *ngIf="bookingData; else noBooking">
            <div class="row">
              <div class="py-sm-2" *ngIf="bookingData && !loadingSession">
                <h3>Reschedule Your Signup</h3>
                <div class="current-booking-details">
                  <div class="row">
                    <div class="col-md-8 border bg-warning p-3 mb-4 text-dark bg-opacity-10">
                      <button class="btn btn-link float-end" (click)="closeReschedule()">
                        <i class="bx bx-x bx-md"></i>
                      </button>
                      <h5>Current Signup:</h5>
                      <p>
                        <strong>Event:</strong>
                        {{
                          bookingData.event.testEventName
                            ? bookingData.event.testEventName
                            : bookingData.event.testEventAtClub.clubName
                        }}
                      </p>
                      <p>
                        <strong>Date & Time:</strong> {{ bookingData.event.testEventDate | date: 'fullDate' }} at

                        {{ selectedTime }}.
                      </p>
                      <p>
                        <strong>Location:</strong><br />
                        {{ bookingData.event.testEventAtClub.clubName }} <br />
                        {{ bookingData.event.testEventAtClub.address1 }} <br />
                        <ng-container *ngIf="bookingData.event.testEventAtClub.address2">
                          {{ bookingData.event.testEventAtClub.address2 }} <br />
                        </ng-container>
                        {{ bookingData.event.testEventAtClub.city }}, {{ bookingData.event.testEventAtClub.state }}
                        {{ bookingData.event.testEventAtClub.zipCode }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="testEventsData$ | async as testEvents; else noEvents">
            <bodyanalytics-loading-spinner
              *ngIf="loadingSession"
              spinnerTitle="Finding events"></bodyanalytics-loading-spinner>

            <div *ngIf="!loadingSession">
              <bodyanalytics-testevents-list
                [testEvents]="testEvents"
                (select)="onSelect($event)"
                (delete)="onDelete($event)"></bodyanalytics-testevents-list>
            </div>
          </ng-container>

          <!-- ng-template for the no-events scenario -->
          <ng-template #noEvents>
            <div class="no-events row">
              <div class="col-md-4">
                <div class="alert alert-info" role="alert">
                  <i class="bx bxs-info-circle"></i> No events available at the moment.
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #noBooking></ng-template>
        </div>
      </div>
      <!-- <div class="col-md-4"></div> -->
    </div>
  </div>
</section>
