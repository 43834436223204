<form
  class="needs-validation pb-3"
  [formGroup]="accountDetailsForm"
  fxLayout="column"
  (ngSubmit)="formSubmit()"
  fxLayoutAlign="center none">
  <div class="row pb-2">
    <div class="col-sm-6 mb-4">
      <div class="mb-3 position-relative">
        <label for="firstName" class="form-label fs-base">First name</label>
        <input
          type="text"
          for="firstName"
          [ngClass]="{ 'is-invalid': formsubmit && form.firstName.errors }"
          class="form-control form-control-lg"
          placeholder="John"
          maxlength="20"
          formControlName="firstName"
          #firstName />
        <div *ngIf="formsubmit && form.firstName.errors" class="invalid-tooltip">
          <span *ngIf="form.firstName.errors['required']">First name is required.</span>
          <span *ngIf="form.firstName.errors['pattern']">Please enter a valid first name.</span>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-4">
      <div class="mb-3 position-relative">
        <label for="lastName" class="form-label fs-base">Last name</label>
        <input
          type="text"
          for="lastName"
          [ngClass]="{ 'is-invalid': formsubmit && form.lastName.errors }"
          class="form-control form-control-lg"
          placeholder="Doe"
          maxlength="20"
          formControlName="lastName"
          #lastName />
        <div *ngIf="formsubmit && form.lastName.errors" class="invalid-tooltip">
          <span *ngIf="form.lastName.errors['required']">Last name is required.</span>
          <span *ngIf="form.lastName.errors['pattern']">Please enter a valid last name.</span>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-4">
      <div class="mb-3 position-relative">
        <label for="emailAddress" class="form-label fs-base">Email address</label>
        <input
          type="email"
          id="emailAddress"
          [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
          class="form-control form-control-lg"
          placeholder="john@mail.com"
          maxlength="50"
          formControlName="emailAddress"
          #emailAddress />
        <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
          <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
          <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-4">
      <div class="mb-3 position-relative">
        <label for="phoneNumber" class="form-label fs-base"
          >Phone number <small class="text-muted">(optional)</small></label
        >
        <input
          type="tel"
          id="phoneNumber"
          [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
          class="form-control form-control-lg"
          placeholder="(555)-555-5555"
          maxlength="11"
          formControlName="phoneNumber"
          #phoneNumber />

        <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
          <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
          <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mb-3">
    <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="updateAccountDetails()">Save changes</button>
  </div>
</form>
