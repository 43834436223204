<section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <h4 class="h5">Review Event / Create signup</h4>

            Create operator available times Add more details
            <!-- <p class="card-title-desc">To get started with testing, please answer the following:</p> -->
          </div>
          <div class="row">
            <div class="col-md-8">
              <div *ngIf="!loadingSession">
                <div class="review-container">
                  <h2 class="card-title mb-4"></h2>

                  <div>
                    <div class="mt-3 mb-3">
                      <h6>
                        Event Date:
                        <span class="text-muted">{{ testingEventData()?.testEventDate | date : 'fullDate' }}</span>
                      </h6>
                      <h6>
                        Event start time:
                        <span class="text-muted">{{ testingEventData()?.startTime }}</span>
                      </h6>
                      <h6>
                        Event end time:
                        <span class="text-muted">{{ testingEventData()?.endTime }}</span>
                      </h6>
                      <h6>
                        Signup Interval:
                        <span class="text-muted">{{ testingEventData()?.signupInterval }}</span>
                      </h6>
                      <h6>
                        Location:// <span class="text-muted">{{ testingEventData()?.testEventAtClub?.clubName }}</span>
                      </h6>
                      <h6>
                        Operator: <span class="text-muted"> {{ testingEventData()?.assignedOperatorId }}</span>
                      </h6>
                      <!-- <h6>
                      Amount Paid:// <span class="text-muted">{{ testingEventData.amountPaid }}</span>
                    </h6> -->
                    </div>
                    <div class="mt-3 mb-3">
                      <h5 class="card-title border-top py-2 mb-2">Event Details</h5>
                      <!-- <h5 class="card-title border-top py-2 mb-2">Organizer Details</h5> -->
                      <h6>
                        Contact Name:
                        <!--// <span class="text-muted">{{
                          testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!.firstName
                        }}</span> -->
                      </h6>
                      <h6>
                        Contact Phone:
                        <span class="text-muted">{{ testingEventData()?.testEventAtClub?.phoneNumber }}</span>
                      </h6>
                      <h6>
                        Email:
                        <span class="text-muted">{{ testingEventData()?.testEventAtClub?.emailAddress }}</span>
                      </h6>
                      <h6>
                        Operator Assigned:
                        <!--// <span class="text-muted">{{ testingEventData.testDetails.updatedAt | date : 'fullDate' }}</span> -->
                      </h6>
                      <h6>
                        Assigned truck:
                        <!--// <span class="text-muted">{{ testingEventData.testDetails.updatedAt | date : 'fullDate' }}</span> -->
                      </h6>
                      <h6>
                        Availbility:
                        <!--// <span class="text-muted">{{ testingEventData.testDetails.updatedAt | date : 'fullDate' }}</span> -->
                      </h6>
                      <h6>
                        Slots Created:
                        <!--// <span class="text-muted">{{ testingEventData.testDetails.updatedAt | date : 'fullDate' }}</span> -->
                      </h6>
                    </div>
                    <div class="mt-3 pt-3 mb-3 border-top">
                      <h5 class="card-title">Club Details</h5>

                      <h6>
                        Contact Name:
                        <!--// <span class="text-muted">{{
                          testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!.firstName
                        }}</span> -->
                      </h6>
                      <h6>
                        Contact Phone:
                        <!-- // <span class="text-muted">{{
                          testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!.phoneNumber
                        }}</span> -->
                      </h6>
                      <h6>
                        Email:
                        <!-- // <span class="text-muted">{{
                          //  testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!
                            .emailAddress
                        }}</span> -->
                      </h6>
                      <h5 class="card-title">Signup Details</h5>
                      <div class="pt-1">
                        <h6>
                          Event Owned and Created by: AppliedBioX
                          <!--// <span class="text-muted">{{
                            testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!.firstName
                          }}</span> -->
                        </h6>
                        <h6>
                          Stripe Configuration:
                          <!-- // <span class="text-muted">{{
                            testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!.phoneNumber
                          }}</span> -->
                        </h6>
                        <h6>
                          Notifications: Send Email / Send SMS /
                          <!-- // <span class="text-muted">{{
                            //  testingEventData.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer!
                              .emailAddress
                          }}</span> -->
                        </h6>
                        <!-- <p class="fw-bold">Body Fat</p> -->
                        <!-- <h6 class="my-1 h6">
                        Weight (lb):// <span class="text-muted">{{ testingEventData.testDetails.fatLbs | number: '1.0-2' }}</span>
                      </h6>
                      <p class="my-1 h6">
                        Percentage:// <span class="text-muted">{{ testingEventData.testDetails.fatPercent | number: '1.0-2' }}</span>
                      </p>-->
                      </div>

                      <p>
                        <button
                          type="button"
                          class="btn btn-outline-success mt-5 float-right"
                          (click)="createEventComplete()">
                          Complete
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <bodyanalytics-event-create-form (submitFormEvent)="createTestingEvent($event)"></bodyanalytics-event-create-form> -->
              </div>
              <!-- <bodyanalytics-create-event></bodyanalytics-create-event> -->

              <!-- <div *ngIf="!loadingSetupSession">
                <bodyanalytics-setup-form
                  [item]="testingEventData"
                  (submitFormEvent)="saveSetupTestDetails($event)"></bodyanalytics-setup-form>
              </div> -->
              <bodyanalytics-loading-spinner *ngIf="loadingSession"></bodyanalytics-loading-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
