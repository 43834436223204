import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, GenericFormComponent, LoadingSpinnerComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { Location, NgFor, NgIf } from '@angular/common';
import { ManageClubService } from '@bodyanalytics/front/admin/manage-clubs/domain';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { CustomerCheckinFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';

@Component({
  selector: 'bodyanalytics-create-club',
  templateUrl: './create-club.component.html',
  styleUrls: ['./create-club.component.scss'],
  standalone: true,
  imports: [
    GenericFormComponent,
    ReactiveFormsModule,
    MatCardModule,
    LoadingSpinnerComponent,
    CustomerCheckinFormComponent,
    MatRadioModule,
    NgIf,
    NgFor,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateClubComponent extends BodyanalyticsBaseComponent implements OnInit {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private manageClubService: ManageClubService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.populateFormOptions();
  }
  clubFields: FormField<any>[] = [
    {
      key: 'clubName',
      label: 'Gym Name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'address1',
      label: 'Address 1',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'address2',
      label: 'Address 2',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'city',
      label: 'City',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'state',
      label: 'State',
      type: 'select',
      options: [

      ],
      validation: [Validators.required],
    },
    {
      key: 'zipCode',
      label: 'Zip code',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'clubInRegionId',
      label: 'Region',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },

    {
      key: 'contactName',
      label: 'Contact name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    { key: 'emailAddress', label: 'Email Address', type: 'email', validation: [Validators.required, Validators.email] },

    {
      key: 'contactTitle',
      label: 'Contact Title',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastAmount',
      label: 'lastAmount',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'commission',
      label: 'Commission',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    {
      key: 'clubLogo',
      label: 'logo',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'signupUrl', //http://bodyanalyticstx.com/GymEvents/Beast-Body
      label: 'Signup URL',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    { key: 'note', label: 'Notes', type: 'textarea', validation: [Validators.required] },
  ];
  contactFields: FormField<any>[] = [
    {
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    { key: 'contactEmail', label: 'Email Address', type: 'email', validation: [Validators.required, Validators.email] },
    // ... other fields
  ];

  private populateFormOptions():void {
    this.subscriptions.barrel = this.manageClubService.getStatesList().subscribe((states: { stateCode: { toString: () => any; }; stateName: any; }[]) => {
      const stateOptions = states.map((op: { stateCode: { toString: () => any; }; stateName: any; }) => ({ value: op.stateCode.toString(), label: op.stateName }));
      this.clubFields.find(field => field.key === 'state')!.options = stateOptions;
    });
    this.subscriptions.barrel = this.manageClubService.getRegions().subscribe(regions => {
      const regionsOptions = regions.map(op => ({ value: op.id.toString(), label: op.regionName }));
      this.clubFields.find(field => field.key === 'clubInRegionId')!.options = regionsOptions;
    });

  }
  onFormSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.manageClubService
      .addClub(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
