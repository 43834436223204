import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
 imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class DiscountComponent
{
  protected createDiscountLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_CREATE_CLUB;

}
// extends BodyanalyticsBaseComponent {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;

//   constructor(private router: Router) {
//     super();
//   }
//   // public testingEventDetailsForm = FormGroup;

//   public createDiscount() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_DISCOUNTS_CREATE_DISCOUNT}`);
//   }
// }
