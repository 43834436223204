
 import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ManageCustomersStore } from '@bodyanalytics/front/admin/manage-customers/domain';
import { ManageCustomersTableComponent } from '@bodyanalytics/front/admin/manage-customers/ui';


@Component({
  selector: 'bodyanalytics-manage-customers-dashboard',
  templateUrl: './manage-customers-dashboard.component.html',
  styleUrls: ['./manage-customers-dashboard.component.scss'],
  standalone: true,
  imports: [ManageCustomersTableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageCustomersDashboardComponent {

  entities = inject(ManageCustomersStore).entities;
}

