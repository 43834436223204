import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { OrderConfirmationI } from '@bodyanalytics/data-models-ui';
import { CheckoutService } from '../../services/checkout.service';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'bodyanalytics-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
  providers: [DatePipe],
})
export class OrderConfirmationComponent extends BodyanalyticsBaseComponent implements OnInit {
  public orderConfirmationData!: OrderConfirmationI;
  public timeSlotSelected= '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private checkoutService: CheckoutService,
    private datePipe: DatePipe,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }
  ngOnInit(): void {
    // this.signupService.removeUserData();
    this.checkoutService.clearSession();
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ orderData }) => {
        if (orderData) {
          this.orderConfirmationData = orderData;

          this.$gaService.pageView(
            'order-confirmation/' + this.orderConfirmationData.id,
            'Order Confirmation',
          );

          if (this.orderConfirmationData.booking.slot.slotTime) {
           this.timeSlotSelected =  this.convertToCentralTime(
              this.orderConfirmationData.booking.slot.slotTime,
            );
          }
        }
      });
    }
  }

  goToDashboard() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_DASHBOARD}`);
  }
  convertToCentralTime(utcDateString: string) {
    const date = new Date(utcDateString);

    return this.datePipe.transform(date, 'shortTime') as string;
  }
}
