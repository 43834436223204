import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CheckoutDataI,
  CreateRescheduleI,
  OrderTotalsI,
  ProductI,
  PromoCodeI,
  ServicesI,
  SignupFormI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';
import {
  ApiLocalRoutesEnum,
  SessionStorageService,
  SharedErrorService,
  SharedLogService,
} from '@bodyanalytics/ui-core';
import { PaymentIntent } from '@stripe/stripe-js';
import moment from 'moment';
import { Observable, catchError, of, tap } from 'rxjs';
import { ENV } from '@bodyanalytics/app-config';
@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private paymentUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS}`;
  private discountUrl = `${ApiLocalRoutesEnum.API_SUFFIX_DISCOUNTS_VALIDATE}`;
  private bookingUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS_BOOKING}`;
  private bookingRescheduleUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS_BOOKING_RESCHEDULE}`;
  private bookingWithCreditUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PROCESS_BOOKING_CREDIT}`;

  private cartItems: any[] = [];

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private sharedErrorService: SharedErrorService,
    private sharedLogService: SharedLogService,
  ) {}
  processPayment(paymentData: any): Observable<any> {
    // Construct the endpoint URL for payment processing
    const url = `${this.appConfig.api}/${this.paymentUrl}/process-payment`;

    // Send the payment data to your backend
    return this.http.post(url, paymentData);
  }

  createPaymentIntent(): Observable<PaymentIntent> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_STRIPE_CHECKOUT_PAYMENT_INTENT}`;
    const userData = this.sessionStorageService.getBookingData()!.userInfo;
    const serviceData = this.sessionStorageService.getBookingData()!.selectedService;
    const productData = this.sessionStorageService.getBookingData()!.selectedProduct;
    const orderData = this.sessionStorageService.getBookingData()!.orderTotal;

    const serviceSlotData = this.sessionStorageService.getBookingData()!.selectedTimeSlot;
    const amountInCents = Math.round(orderData!.total) * 100;

    const createPaymentDto = {
      customer: '',
      emailAddress: '',

      phoneNumber: '',
      amount: 0,
      currency: '',
      serviceDescription: '',
      serviceSuffix: '',
    };

    // createPaymentDto.emailAddress = userData?.emailAddress!;
    createPaymentDto.phoneNumber = userData?.phoneNumber!;
    createPaymentDto.emailAddress = userData?.emailAddress!;
    createPaymentDto.currency = 'usd';
    createPaymentDto.serviceDescription = 'Body Comp.' + 'Testing';
    createPaymentDto.serviceSuffix = 'Body Comp.' + 'Testing';
    createPaymentDto.amount = amountInCents;

    return this.http.post<PaymentIntent>(url, createPaymentDto, {
      headers,
    });
  }

  calculateOrder(
    checkoutData: CheckoutDataI,
  ): Observable<{ taxAmount: number; totalPrice: number; serviceFee: number }> {
    const url = `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_CHECKOUT_TOTAL}/calculate`;

    const userData = this.sessionStorageService.getBookingData()!.userInfo;

    checkoutData.tax_state = userData!.state;

    return this.http.post<{ taxAmount: number; totalPrice: number; serviceFee: number }>(url, checkoutData);
  }

  processOrderReschedule(data: CreateRescheduleI) {
    const url = `${this.appConfig.api}/${this.bookingRescheduleUrl}`;
    const fullDateTimeString: string = this.convertDateTimeString(data.slotTime!.toString(), data.testEventDate);

    const createRescheduleOrderDto = {
      existingOrderId: data.existingOrderId,
      existingBookingId: data.existingBookingId,
      newEventId: data.newEventId,

      slotTime: fullDateTimeString,
    };

    return this.http.post<any>(url, createRescheduleOrderDto, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added processOrderReschedule w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('processOrderReschedule')),
    );
  }

  processOrderWithPromo() {
    const userData = this.sessionStorageService.getBookingData()!.userInfo;
    const orderTotalData = this.sessionStorageService.getBookingData()!.bookingTotalWithTaxServiceFee;
    const promoData = this.sessionStorageService.getBookingData()!.promotion;

    // const serviceData = this.sessionStorageService.getBookingData()!.selectedService;
    const serviceSlotData = this.sessionStorageService.getBookingData()!.selectedTime;
    const productData = this.sessionStorageService.getBookingData()!.selectedProduct;

    const selectedService = this.sessionStorageService.getBookingData()!.selectedService!;
    const selectedProduct = this.sessionStorageService.getBookingData()!.selectedProduct!;
    const selectedEvent = this.sessionStorageService.getBookingData()!.selectedEvent!;
    const selectedSlot = this.sessionStorageService.getBookingData()!.selectedTime;
    // const userData = this.sessionStorageService.getBookingData()!.userInfo;
    const workflow = this.sessionStorageService.getBookingData()!.workflowStep;
    const promotion = this.sessionStorageService.getBookingData()!.promotion;

    //  //if (bookingDataJson) {serviceSlotData
    const createOrderDto = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      emailAddress: '',
      city: '',
      state: '',
      country: 'US',
      zipCode: '',
      sex: '',
      gender: '',
      subtotal: 0,
      salesTax: 0,
      amount: 0,
      serviceFee: 0,
      eventId: '',
      dob: new Date(),
      slotTime: new Date(),
      customerId: '',
      productId: '',
      paymentId: 'NA', //paymentInfoId,
      status: 'PAID_CREDIT', //paymentInfoStatus,
      promocode: '',
      race: '',
      isReminderEmail:false,
      isReminderSms:false,
    };

    createOrderDto.firstName = userData!.firstName;
    createOrderDto.lastName = userData!.lastName;
    createOrderDto.phoneNumber = userData!.phoneNumber;
    createOrderDto.emailAddress = userData!.emailAddress;
    createOrderDto.city = userData!.city;
    createOrderDto.zipCode = userData!.zipCode;
    createOrderDto.country = 'US';
    createOrderDto.state = userData!.state;

    createOrderDto.eventId = selectedEvent.id;
    createOrderDto.slotTime = moment(serviceSlotData).toDate();

    createOrderDto.amount = orderTotalData;
    createOrderDto.salesTax = orderTotalData;
    createOrderDto.subtotal = orderTotalData;
    createOrderDto.serviceFee = orderTotalData;

    createOrderDto.paymentId = 'NA';
    createOrderDto.status = 'PAID_CREDIT';

    createOrderDto.productId = productData!.id;
    createOrderDto.gender = userData!.gender;
    createOrderDto.sex = userData!.sex;
    createOrderDto.dob = new Date(userData!.dob);
    createOrderDto.promocode = promoData!.discountCode;

    createOrderDto.firstName = userData!.firstName;
    createOrderDto.lastName = userData!.lastName;
    createOrderDto.phoneNumber = userData!.phoneNumber;

    createOrderDto.city = userData!.city;
    createOrderDto.zipCode = userData!.zipCode;
    createOrderDto.country = 'US';

    createOrderDto.state = userData!.state;

    createOrderDto.emailAddress = userData!.emailAddress;

    const fullDateTimeString: string = this.convertDateTimeString(
      serviceSlotData!.toString(),
      selectedEvent!.testEventDate,
    );

    createOrderDto.slotTime = new Date(fullDateTimeString);

    // createOrderDto.amount = orderTotalData!.total;
    // createOrderDto.salesTax = orderTotalData!.salesTax;
    // createOrderDto.subtotal = orderTotalData!.subtotal;
    // createOrderDto.serviceFee = orderTotalData!.serviceFee;

    // createOrderDto.paymentId = paymentInfoId;
    // createOrderDto.status = paymentInfoStatus;

    createOrderDto.productId = productData!.id;
    createOrderDto.gender = userData!.gender;
    createOrderDto.race = userData!.race;
    // createOrderDto.dob = new Date(userData!.dob);

    createOrderDto.sex = userData!.sex;
    // createOrderDto.quantity = 1;
    createOrderDto.isReminderEmail = userData!.isRemindByEmail;
    createOrderDto.isReminderSms = userData!.isRemindBySms;
    createOrderDto.promocode  = promotion!.discountCode;

    const url = `${this.appConfig.api}/${this.bookingWithCreditUrl}`;

    return this.http.post<any>(url, createOrderDto, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added createReservationOrder w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('createReservationOrder')),
    );
  }

  processOrder(paymentInfoId: string, paymentInfoStatus: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.appConfig.api}/${this.bookingUrl}`;
    const orderTotalData = this.sessionStorageService.getBookingData()!.orderTotal;
    const eventData = this.sessionStorageService.getBookingData()!.selectedEvent;

    const userData = this.sessionStorageService.getBookingData()!.userInfo;
    // const serviceData = this.sessionStorageService.getBookingData()!.selectedService;
    const serviceSlotData = this.sessionStorageService.getBookingData()!.selectedTime;
    const productData = this.sessionStorageService.getBookingData()!.selectedProduct;

    //  //if (bookingDataJson) {serviceSlotData
    const createOrderDto = {
      firstName: '',
      phoneNumber: '',
      lastName: '',
      emailAddress: '',
      address1: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'US',
      sex: '',
      race: '',
      gender: '',
      subtotal: 0,
      salesTax: 0,
      amount: 0,
      serviceFee: 0,
      eventId: '',
      dob: new Date(),
      slotTime: new Date(),
      customerId: '',
      productId: '',
      discountAmount: 0,
      quantity: 0,
      paymentId: paymentInfoId,
      paymentIntentId: paymentInfoStatus.status?.paymentIntent?.id,
      status: paymentInfoStatus.status?.paymentIntent?.status,
      isReminderEmail: false,
      isReminderSms: false,
      promoCode: '',
    };

    const fullDateTimeString: string = this.convertDateTimeString(
      serviceSlotData!.toString(),
      eventData!.testEventDate,
    );

    createOrderDto.firstName = userData!.firstName;
    createOrderDto.lastName = userData!.lastName;
    createOrderDto.phoneNumber = userData!.phoneNumber;
    createOrderDto.address1 = userData!.address1;

    createOrderDto.city = userData!.city;
    createOrderDto.zipCode = userData!.zipCode;
    createOrderDto.country = 'US';

    createOrderDto.state = userData!.state;

    createOrderDto.emailAddress = userData!.emailAddress;
    createOrderDto.eventId = eventData!.id;

    createOrderDto.slotTime = new Date(fullDateTimeString);

    createOrderDto.amount = orderTotalData!.total;
    createOrderDto.salesTax = orderTotalData!.salesTax;
    createOrderDto.subtotal = orderTotalData!.subtotal;
    createOrderDto.serviceFee = orderTotalData!.serviceFee;

    createOrderDto.paymentId = paymentInfoId;
    createOrderDto.status = paymentInfoStatus;

    createOrderDto.productId = productData!.id;
    createOrderDto.gender = userData!.gender;
    createOrderDto.race = userData!.race;
    createOrderDto.dob = new Date(userData!.dob);

    createOrderDto.sex = userData!.sex;
    createOrderDto.quantity = 1;
    createOrderDto.isReminderEmail = userData!.isRemindByEmail;
    createOrderDto.isReminderSms = userData!.isRemindBySms;
    createOrderDto.promoCode = orderTotalData!.promocode;

    return this.http.post<any>(url, createOrderDto, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added createReservationOrder w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('createReservationOrder')),
    );
  }
  // Step 3: Display confirmation and review
  displayConfirmation() {
    // Display order confirmation and review to the user
    console.log('Order confirmation: Your booking has been confirmed.');
  }

  // Step 4: Send booking confirmation
  sendBookingConfirmation(email: string) {
    // Send booking confirmation email
    console.log(`Booking confirmation email has been sent to ${email}`);
  }

  validatePromo(promoCode: string): Observable<PromoCodeI> {
    const url = `${this.appConfig.api}/${this.discountUrl}/${promoCode}`;
    return this.http.get<PromoCodeI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched discount id=${promoCode}`)),
      catchError(this.sharedErrorService.handleError<PromoCodeI>(`discount id=${promoCode}`)),
    );
  }

  clearSession() {
    this.sessionStorageService.clearBookingData();
  }

  // Step 1: Add service to cart
  addToProductToCart(product: ProductI, event: TestingEventI) {
    console.log('addToProductToCart', product);
    this.sessionStorageService.saveBookingData(null, product, null, null, 0, null, event, null, null, null, 'signup');
    this.cartItems.push(product);
  }

  addUserInfoToCart(userData: SignupFormI, bookingTotal: number, serviceFee: number, salesTax: number) {
    if (this.sessionStorageService.getBookingData()) {
      const selectedProduct = this.sessionStorageService.getBookingData()!.selectedProduct!;
      const selectedEvent = this.sessionStorageService.getBookingData()!.selectedEvent!;

      const selectedService = this.sessionStorageService.getBookingData()!.selectedService;
      const selectedSlot = this.sessionStorageService.getBookingData()!.selectedTime;
      const workflow = this.sessionStorageService.getBookingData()!.workflowStep;

      this.sessionStorageService.saveBookingData(
        selectedSlot,
        selectedProduct,
        null,
        userData,
        0,
        null,
        selectedEvent,
        null,
        null,
        null,
        workflow,
      );
      this.cartItems.push(selectedService, selectedSlot, userData);
    }
  }
  addBookingTotal(orderTotal: OrderTotalsI) {
    if (this.sessionStorageService.getBookingData()) {
      const selectedService = this.sessionStorageService.getBookingData()!.selectedService!;
      const selectedProduct = this.sessionStorageService.getBookingData()!.selectedProduct!;
      const selectedEvent = this.sessionStorageService.getBookingData()!.selectedEvent!;
      const selectedSlot = this.sessionStorageService.getBookingData()!.selectedTime;
      const userData = this.sessionStorageService.getBookingData()!.userInfo;
      const workflow = this.sessionStorageService.getBookingData()!.workflowStep;
      const promotion = this.sessionStorageService.getBookingData()!.promotion;

      this.sessionStorageService.saveBookingData(
        selectedSlot,
        this.sessionStorageService.getBookingData()!.selectedProduct!,
        this.sessionStorageService.getBookingData()!.selectedService!,
        userData,
        0,
        null,
        selectedEvent,
        null,
        orderTotal,
        promotion,
        'checkout',
      );
    }
  }

  addSignupVerifyStep(promotion: PromoCodeI) {
    if (this.sessionStorageService.getBookingData()) {
      const selectedService = this.sessionStorageService.getBookingData()!.selectedService!;
      const selectedProduct = this.sessionStorageService.getBookingData()!.selectedProduct!;
      const selectedEvent = this.sessionStorageService.getBookingData()!.selectedEvent!;
      const selectedSlot = this.sessionStorageService.getBookingData()!.selectedTime;
      const userData = this.sessionStorageService.getBookingData()!.userInfo;
      const workflow = this.sessionStorageService.getBookingData()!.workflowStep;
      // const promotion = this.sessionStorageService.getBookingData()!.promotion;
      const orderTotal = this.sessionStorageService.getBookingData()!.orderTotal;

      this.sessionStorageService.saveBookingData(
        selectedSlot,
        this.sessionStorageService.getBookingData()!.selectedProduct!,
        this.sessionStorageService.getBookingData()!.selectedService!,
        userData,
        0,
        null,
        selectedEvent,
        null,
        orderTotal,
        promotion,
        'signup-verify',
      );
    }
  }
  addBookingToCart(slot: any) {
    // this.sessionStorageService.getBookingData().selectedService;
    if (this.sessionStorageService.getBookingData()) {
      const selectedService = this.sessionStorageService.getBookingData()!.selectedService!;
      const selectedProduct = this.sessionStorageService.getBookingData()!.selectedProduct!;
      const selectedEvent = this.sessionStorageService.getBookingData()!.selectedEvent!;
      const workflow = this.sessionStorageService.getBookingData()!.workflowStep;
      const promotion = this.sessionStorageService.getBookingData()!.promotion;

      const selectedTotalWithTaxServiceFee =
        this.sessionStorageService.getBookingData()!.bookingTotalWithTaxServiceFee!;
      // const serviceFeeTotal = this.sessionStorageService.getBookingData()!.serviceFee!;
      // const salesTaxFeeTotal = this.sessionStorageService.getBookingData()!.salesTax!;

      this.sessionStorageService.saveBookingData(
        slot,
        this.sessionStorageService.getBookingData()!.selectedProduct!,
        this.sessionStorageService.getBookingData()!.selectedService!,
        null,
        selectedTotalWithTaxServiceFee,
        null,
        selectedEvent,
        null,
        null,
        promotion,
        workflow,
      );
      this.cartItems.push(selectedService, slot);
    }
  }

  public convertDateTimeString(slotTime: string, eventDate: string) {
    // Get the current date

    console.log('slotTime:', slotTime);
    console.log('eventDate:', eventDate);

    let currentDate = new Date(eventDate);
    // Extract the year, month, and day from the current date
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1; // getMonth() is zero-indexed
    let day = currentDate.getDate();

    // Construct a date string in the format: "YYYY-MM-DD"
    let dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

    // Convert 12-hour time string to 24-hour time format
    let [time, modifier]: string[] = slotTime.split(' ');
    let [hours, minutes]: string[] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM' && parseInt(hours, 10) < 12) {
      hours = (parseInt(hours, 10) + 12).toString();
    }
    // Construct the full date-time string in ISO format: "YYYY-MM-DDTHH:mm:ss"
    const fullDateTimeString: string = `${dateString}T${hours.padStart(2, '0')}:${minutes}:00`;
    console.log('fullDateTimeString:', fullDateTimeString);

    return fullDateTimeString;
  }
}
