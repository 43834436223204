<section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <h4 class="h5">Review Email</h4>
          </div>
          <div class="row">
            <div class="col-md-8">
              <!-- <div *ngIf="!loadingSession"> -->
              <!-- <bodyanalytics-event-create-form (submitFormEvent)="createTestingEvent($event)"></bodyanalytics-event-create-form> -->
              <!-- </div> -->

              <bodyanalytics-loading-spinner *ngIf="loadingSession"></bodyanalytics-loading-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
