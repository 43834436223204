import { Component, ChangeDetectionStrategy, isDevMode, inject, input, signal, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CustomerI, TestSessionCheckInI } from '@bodyanalytics/data-models-ui';
import {
  BodyanalyticsBaseComponent,
  RouterUrlsEnum,
  LoadingSpinnerComponent,
  GoogleActionsEnum,
  TestingSessionStatus,
} from '@bodyanalytics/ui-core';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { CustomerCheckinFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import {
  ManageBodymeasurementService,
  ManageBodymeasurementStore,
} from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { NgFor, NgIf } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-customer-checkin',
  standalone: true,
  templateUrl: './customer-checkin.component.html',
  styleUrls: ['./customer-checkin.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatCardModule,
    LoadingSpinnerComponent,
    CustomerCheckinFormComponent,
    MatRadioModule,
    NgIf,
    NgFor,
  ],
})
export class CustomerCheckinComponent extends BodyanalyticsBaseComponent implements OnInit {
  eventId = input.required<string>();
  #store = inject(ManageBodymeasurementStore);
  protected checkInCompleted = this.#store.checkInCompleted;
  protected customers = this.#store.customers;
  protected checkInData?: TestSessionCheckInI;
  protected optionsFormGroup = this.formBuilder.group({
    customer: '',
  });
  protected loadingCheckInSession = false;
  protected eventLocation: any[] = [];

  constructor(
    private router: Router,
    private manageBodyMeasurementService: ManageBodymeasurementService,
    private formBuilder: FormBuilder,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
   }
  ngOnInit(): void {
      this.$gaService.pageView('manage-bodymeasurements/check-in/' + this.eventId(), 'Customer Check-in');

  }

  public startTest(customerData: CustomerI) {
    this.$gaService.event('startTest_customer_' + customerData.id, GoogleActionsEnum.USER_CLICKED_BTN);

    this.selectCustomer(customerData);

    const eventId = this.eventId();
    this.loadingCheckInSession = true;
    isDevMode() && console.log('startTest: customer', customerData.id, 'event: ', eventId);
    this.subscriptions.barrel = this.manageBodyMeasurementService
      .processCheckIn({
        bodyFatMeasureTestSessionSetupId: eventId,
        bodyFatTestForCustomerId: customerData.id,
        status: TestingSessionStatus.OK, //advance check-in, present
      })
      .subscribe(response => {
        this.checkInData = response; /// Save and create a report BodyFatTestI
        this.loadingCheckInSession = false;
        this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_START_TEST}/${this.checkInData.id}`);
      });
  }

  public processCheckinUpdate(email: string): void {
    this.#store.customerCheckIn(email);

    // this.subscriptions.barrel = this.manageBodyMeasurementService.findCustomerByEmail(email).subscribe(results => {
    //   if (this.customers.length > 0) {
    //     this.isResultVisible = true;
    //   }
    //   this.loadingCheckInSession = false;
    // });
  }

  public selectCustomer(customerData: CustomerI) {
    // const selectedUser = this.user.filter((customer: CustomerI) => customer.id === id);
    const selectedUser = customerData;
    this.$gaService.event('select_customer_' + customerData.id, GoogleActionsEnum.USER_CLICKED_BTN);

    for (let i = 0; i < this.manageBodyMeasurementService.testDataList.length; i++) {
      this.manageBodyMeasurementService.testDataList[i].clientDetails = {
        bodyFatTestForCustomerId: selectedUser.id,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        fullName: selectedUser.firstName + ' ' + selectedUser.lastName,
        emailAddress: selectedUser.emailAddress,
        phoneNumber: selectedUser.phoneNumber,
        age: selectedUser.age, // TODO: calculate age for this before displaying
        gender: selectedUser.gender,
        sex: selectedUser.gender,
        ethnicity: selectedUser.ethnicity,
        birthDate: selectedUser.birthDate,
      };
    }

    this.manageBodyMeasurementService.testSessionData.next(this.manageBodyMeasurementService.testDataList[0]);
  }
}
