import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';

import { catchError, Observable, of, tap } from 'rxjs';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import {
  TestingEventI,
  OperatorI,
  TruckI,
  ClubI,
  ProductI,
  ProductDataI,
  RegionsI,
  BreakTimesI,
  SignupSetupI,
  ServicesI,
  CreateTestEventI,
} from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';

@Injectable()
export class ManageTestingEventService {
  private env = inject(ENV);
  private testingEventsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS}`;
  private testingEventDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_DETAILS}`;
  private testingEventListUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_LIST}`;
  private testingEventCancelEventUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_CANCEL_EVENT}`;

  private regionsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}`;
  private signupAvailabilityUrl = `${ApiLocalRoutesEnum.API_SUFFIX_SIGNUP_AVAILABILITY}`;
  private signupUrl = `${ApiLocalRoutesEnum.API_SUFFIX_SIGNUP_SETTINGS}`;
  private servicesAvailableUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PRODUCTS_SERVICES}`;

  private productsListUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PRODUCTS}`;
  private operatorsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_OPERATORS}`;
  private fleetTrucksUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TRUCKS}`;
  private clubsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CLUBS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  public getOperatorsList(): Observable<OperatorI[]> {
    const url = `${this.env.api}/${this.operatorsUrl}`;
    return this.http.get<OperatorI[]>(url);
  }

  public getProductsList(): Observable<ProductI[]> {
    const url = `${this.env.api}/${this.productsListUrl}`;
    return this.http.get<ProductI[]>(url);
  }

  public getClubsList(): Observable<ClubI[]> {
    const url = `${this.env.api}/${this.clubsUrl}`;

    return this.http.get<ClubI[]>(url);
  }

  public getTruckList(): Observable<TruckI[]> {
    const url = `${this.env.api}/${this.fleetTrucksUrl}`;

    return this.http.get<TruckI[]>(url);
  }
  getTestingEvent(id: string): Observable<TestingEventI> {
    const url = `${this.env.api}/${this.testingEventDetailsUrl}/${id}`;
    return this.http.get<TestingEventI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI>(`getTestingEvent id=${id}`)),
    );
  }

  getEventsByClubId(id: string): Observable<TestingEventI[]> {
    const url = `${this.env.api}/${this.testingEventListUrl}/${id}`;
    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>(`getTestingEvent id=${id}`)),
    );
  }

  duplicateTestingEvent(id: string, testingEvent: TestingEventI): Observable<any> {
    const url = `${this.env.api}/${this.testingEventDetailsUrl}/${id}`;
    return this.http.patch(url, testingEvent, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateTestingEvent')),
    );
  }

  updateTestingEvent(id: string, testingEvent: TestingEventI): Observable<any> {
    const url = `${this.env.api}/${this.testingEventDetailsUrl}/${id}`;
    return this.http.patch(url, testingEvent, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateTestingEvent')),
    );
  }
  createProductWithServices(productData: ProductDataI): Observable<ProductDataI> {
    const url = `${this.env.api}/${this.productsListUrl}`;

    return this.http.post<ProductDataI>(url, productData);
  }

  deleteTestingEvent(id: string): Observable<TestingEventI> {
    const url = `${this.env.api}/${this.testingEventCancelEventUrl}/${id}`;

    return this.http.get<TestingEventI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI>('deleteTestingEvent')),
    );
  }

  load(): Observable<TestingEventI[]> {
    const url = `${this.env.api}/${this.testingEventsUrl}`;
    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }

  createTestingEvent(testingEvent: CreateTestEventI): Observable<CreateTestEventI> {
    const url = `${this.env.api}/${this.testingEventsUrl}`;

    return this.http.post<CreateTestEventI>(url, testingEvent, this.httpOptions).pipe(
      tap((newTestingEvent: CreateTestEventI) =>
        this.sharedLogService.log(`added createTestingEvent w/ id=${newTestingEvent.id}`),
      ),
      catchError(this.sharedErrorService.handleError<CreateTestEventI>('createTestingEvent')),
    );
  }

  addTestingEvent(testingEvent: TestingEventI): Observable<TestingEventI> {
    const url = `${this.env.api}/${this.testingEventsUrl}`;

    return this.http.post<TestingEventI>(url, testingEvent, this.httpOptions).pipe(
      tap((newTestingEvent: TestingEventI) =>
        this.sharedLogService.log(`added testingEvent w/ id=${newTestingEvent.id}`),
      ),
      catchError(this.sharedErrorService.handleError<TestingEventI>('addTestingEvent')),
    );
  }

  sendSms(testingEvent: TestingEventI): Observable<TestingEventI> {
    const url = `${this.env.api}/${this.testingEventsUrl}`;

    return this.http.post<TestingEventI>(url, testingEvent, this.httpOptions).pipe(
      tap((newTestingEvent: TestingEventI) =>
        this.sharedLogService.log(`added testingEvent w/ id=${newTestingEvent.id}`),
      ),
      catchError(this.sharedErrorService.handleError<TestingEventI>('addTestingEvent')),
    );
  }

  searchTestingEvents(term: string): Observable<TestingEventI[]> {
    const url = `${this.env.api}/${this.testingEventsUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty testingEvent array.
      return of([]);
    }

    return this.http.get<TestingEventI[]>(`${url}`).pipe(
      tap(x =>
        x.length
          ? console.log(`found testingEvents matching "${term}"`)
          : console.log(`no testingEvents matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('searchTestingEvents', [])),
    );
  }
  getAllProducts(): Observable<ProductI[]> {
    const url = `${this.env.api}/${this.productsListUrl}`;

    return this.http.get<ProductI[]>(url);
  }

  getAllServices(): Observable<ServicesI[]> {
    const url = `${this.env.api}/${this.servicesAvailableUrl}`;

    return this.http.get<ServicesI[]>(url);
  }

  getAllRegions(): Observable<RegionsI[]> {
    const url = `${this.env.api}/${this.regionsUrl}`;

    return this.http.get<RegionsI[]>(url);
  }

  createSignupSetup(signupSetup: SignupSetupI) {
    const url = `${this.env.api}/${this.signupUrl}`;

    return this.http.post<ProductDataI>(url, signupSetup);
  }
  createSignupAvailability(testEventId: string) {
    const url = `${this.env.api}/${this.signupAvailabilityUrl}`;

    return this.http.post<ProductDataI>(url, testEventId);
  }
  createSignupBreakTimes(breaktTimes: BreakTimesI) {
    const url = `${this.env.api}/${this.signupAvailabilityUrl}`;

    return this.http.post<ProductDataI>(url, breaktTimes);
  }
}
