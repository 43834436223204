import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NavigationHubService, SessionStorageService } from '@bodyanalytics/ui-core';
import { LoginService } from 'libs/front/shared/authentication-ui/src/lib/services/login.service';
import { Observable, firstValueFrom, map, tap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AbxAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navHubService: NavigationHubService,
    private sessionStorageService: SessionStorageService
  ) {}

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.isUserLoggedIn();
  }

  public async canLoad(route: Route): Promise<boolean> {
    return await this.isUserLoggedIn();
  }

  private async isUserLoggedIn(): Promise<boolean> {
    try {
      const loggedIn = await firstValueFrom(this.authService.isAuthenticated$);
      if (loggedIn) {
        return true;
      } else {
        this.navHubService.routeToLogin();
        // this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
        return false;
      }
    } catch (error) {
      console.error('Error checking login status', error);
      this.navHubService.routeToLogin();
      // this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
      return false;
    }
  }
    // this.authService.isAuthenticated$.pipe(
    //   map(loggedIn => {
    //     if (loggedIn) {
    //       return true;
    //     } else {
    //       this.navHubService.routeToLogin();
    //       return false;
    //       // return this.router.createUrlTree(['/login'], {
    //       //   queryParams: { returnUrl: state.url },
    //       // });
    //     }
    //   }),
    // );
    // return false;

  }
