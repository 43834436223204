import { DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { ClubI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { animations } from './manage-clubs-table.animations';

@Component({
  selector: 'bodyanalytics-manage-clubs-table',
  templateUrl: './manage-clubs-table.component.html',
  styleUrls: ['./manage-clubs-table.component.scss'],
  standalone:true,
  animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe],

})
export class ManageClubsTableComponent extends BodyanalyticsBaseComponent{
  protected clubDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS;

  protected displayedColumns = [ 'clubName', 'address1', 'city' , 'state' ];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: ClubI;

  entities = input.required<ClubI[]>();
}

//   isClubIdSelected = '';



//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${target.value}`);
//     }
//   }



//   deleteClub() {
//     this.subscriptions.barrel = this.manageClubService
//       .deleteClub(this.isClubIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */


