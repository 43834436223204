import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BookingI } from '@bodyanalytics/data-models-ui';
import { Observable, catchError, mergeMap, of, take, throwError } from 'rxjs';
import { BookingPublicService } from '../services/booking.service';

@Injectable({ providedIn: 'root' })
export class BookingResolver implements Resolve<BookingI> {
  constructor(private service: BookingPublicService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<BookingI> | Observable<never> | Promise<BookingI> | BookingI {
    // return this.service.getTestingEvent(route.paramMap.get('id') as string).pipe(
    //   take(1),
    //   mergeMap((testEvent: BookingI) => {
    //     if (testEvent) {
    //       return of(testEvent);
    //     } else {
    //       return throwError(() => new Error(` No data found for ${testEvent} `));
    //     }
    //   }),
    //   catchError((failure: any) => {
    //     // this.navHubService.routeToHome();
    //     return throwError(() => new Error(` Error:  ${failure} `));
    //   })
    // );

    return this.service.getBooking(route.paramMap.get('id') as string).pipe(
      catchError((failure: any) => {
        // this.navHubService.routeToHome();
        return throwError(() => new Error(` Error:  ${failure} `));
      }),
    );
  }
}
