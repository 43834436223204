import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { RegionsI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { Location, NgClass, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { tap, catchError, of, filter, switchMap } from 'rxjs';
import { ManageRegionService } from '@bodyanalytics/front/admin/manage-regions/domain';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageRegionsFormComponent } from '@bodyanalytics/front/admin/manage-regions/ui';

@Component({
  selector: 'bodyanalytics-manage-regions-detail',
  templateUrl: './manage-regions-detail.component.html',
  styleUrls: ['./manage-regions-detail.component.scss'],
  imports: [ ManageRegionsFormComponent, ReactiveFormsModule, NgClass, NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageRegionsDetailComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageRegionService.getManageRegion(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageRegionService: ManageRegionService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processRegionUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageRegionService
      .updateManageRegion(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processRegionUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

// extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   regionsData!: RegionsI | null;

//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private location: Location,
//     private manageRegionService: ManageRegionService,
//   ) {
//     super();
//   }
//   public goBack() {
//     this.location.back();
//   }
//   ngOnInit(): void {
//     this.getRegion();
//   }

//   getRegion(): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageRegionService
//       .getManageRegion(id)
//       .subscribe(regions => ((this.regionsData as RegionsI) = regions));
//     // );
//   }

//   public processRegionUpdate(signupDetails: any): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageRegionService
//       .updateManageRegion(id, signupDetails)
//       .pipe(
//         tap(() => {
//           console.log('here: processRegionUpdate');
//           // this.modalService.dismissAll();
//           // this.submitted = true;
//         }),
//         catchError((error: any) => {
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
