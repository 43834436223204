import { Injectable } from '@angular/core';
import { BookingI } from '@bodyanalytics/data-models-ui';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RescheduleStore {
  private isRescheduleFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private selectedBooking: BehaviorSubject<BookingI | null> = new BehaviorSubject<BookingI | null>(null);

  public setIsRescheduleFlagState(state: boolean): void {
    this.isRescheduleFlag.next(state);
  }

  public getIsRescheduleFlagState(): Observable<boolean> {
    return this.isRescheduleFlag.asObservable();
  }

  public clearIsRescheduleFlagState(): void {
    this.isRescheduleFlag.next(false);
  }

  public setSelectedBooking(selectedBooking: BookingI): void {
    this.selectedBooking.next(selectedBooking);
  }

  public getSelectedBooking(): Observable<BookingI | null> {
    return this.selectedBooking.asObservable();
  }

  public clearSelectedBooking(): void {
    this.selectedBooking.next(null);
  }
}
