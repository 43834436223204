<div class="container p-2">
  <div class="row">
    <div class="col-md-8 mx-auto">
      <div class="card checkout-order-summary border-0">
        <div class="card-body border-0">
          <div class="bg-white mb-2 m-a-1 text-xs-center">
            <h2 class="h4 text-primary mb-4 text-capatilize">Order Confirmation</h2>

            Hey {{ orderConfirmationData.firstName }}!
            <p>
              Thank you for choosing AppliedBioX. We have received your signup for testing at
              {{ orderConfirmationData.ordersForTestingEvent.testEventName }}

              on
              {{ orderConfirmationData.ordersForTestingEvent.testEventDate | date: 'fullDate' }} at
              {{ timeSlotSelected }}.
            </p>

            <!-- <p>
              A calendar file is attached for your convenience. Simply open the file if you would like to add this to
              your calendar.
            </p> -->

            <p>
              If you need to make any changes or cancel your signup, please go to
              <a routerLink="/booking" routerLinkActive="active">https://appliedbiox.com/booking/lookup</a>
              and enter the email address you used during the signup process. Please remember there are no refunds for
              cancellations. We will work with you to reschedule if possible.
            </p>

            <p class="mb-5">Please review our <a href="https://appliedbiox.com/faq"> frequently asked questions</a>.</p>
            <!--
            What Is The Test Like?

            <p>
              Submerge yourself in water. You will submerge your whole body underwater leaving only a small portion of
              you mouth and nose above the water. The water is heated to between 89° and 94°, depending on the season.
              The water is also chemically treated for cleanliness to meet all local swimming pool and hot tub
              requirements of the local health department. We also replace the water daily. We have a private dressing
              room on board and for your comfort the lab is air conditioned in the summer and heated in the winter.
            </p>

            <p>
              A trained technician will be with you every second of the test. You will have privacy and confidentiality
              during the entire test, which takes approximately 10 minutes.
            </p>

            <p>
              After the test, you will receive an email or hard copy printout of your results, showing you where you
              are, where you should be, and what it will take to get there. You will then have a realistic baseline to
              establish your fitness goals.
            </p> -->

            <!-- What Should I Bring? Bring a swimsuit and a towel. Change in the
            privacy of our dressing room located inside the lab. Can I Eat Prior To Testing? Don’t eat anything 2 hours
            prior. Eating a big meal within 2 hours before the test may affect your results by up to 1 1/2%, but no
            more. Therefore, we advise you not to eat within two hours before the test. Working out and drinking water
            is okay. A small snack or protein shake is ok , just no large meal. How Should I Prepare For Testing? You
            should try to use the restroom and take a shower if possible. Please bring swim cap and remove as much hair
            gel or hair products as possible. What Is The Test Like? Submerge yourself in water. You will submerge your
            whole body underwater leaving only a small portion of you mouth and nose above the water. The water is
            heated to between 89° and 94°, depending on the season. The water is also chemically treated for cleanliness
            to meet all local swimming pool and hot tub requirements of the local health department. We also replace the
            water daily. We have a private dressing room on board and for your comfort the lab is air conditioned in the
            summer and heated in the winter. A trained technician will be with you every second of the test. You will
            have privacy and confidentiality during the entire test, which takes approximately 10 minutes. After the
            test, you will receive an email or hard copy printout of your results, showing you where you are, where you
            should be, and what it will take to get there. You will then have a realistic baseline to establish your
            fitness goals. -->

            <!-- REVIEW THIS STORE
            Share your thoughts on your order with fellow customers and Hard Eight BBQ. -->

            <!-- WHAT TO DO WHEN YOU ARRIVE

Skip the line! Go straight to the restaurant's pick-up counter.


Show the staff your DoorDash app or receipt to claim your order.


Before heading out, double check to confirm you've received all items.

Track Your Order -->

            <h2 class="h5 text-primary">Order Details</h2>
            <!-- <p>
              <strong>{{ eventData?.testEventName }}</strong> ON {{ eventData?.testEventName | date : 'EEEE, MMMM d' }}TH
            </p>

            <strong>Date / Time</strong>
            <p>{{ eventData.testEventDate | date : 'EEEE, MMMM d' }} at 01:30 PM CDT</p>

            <strong>Location</strong>
            <p>
              {{ eventData.testEventAtClub.address }}, {{ eventData.testEventAtClub.city }},
              {{ eventData.testEventAtClub.state }}
            </p> -->

            <!-- <h2 class="h5 text-primary mb-4">NEXT STEPS:
            1.  YOUR QR CODE BELOW IS REQUIRED FOR PACKET PICKUP. SAVE IT ON YOUR PHONE OR PRINT IT NOW!
           2.  Follow us on Instagram
        3.   Share your unique referral link below with friends and family to receive cash back & rewards! How It Works
4.Join the Team Challenge
5. To update or make any changes to your registration select the "MANAGE REGISTRATION" link below. The deadline is 30 days before race day.
6. Frequently Asked Questions

* Remember to invite your friends with your special URL (https://runsignup.com/Race/TX/Irving/CincoDeMayo5k10k131?raceRefCode=86HvUYjn). If they sign up from that link, you are eligible for a refund up to $5.00 after 1 friend sign up. -->

            <!-- <h2 class="h6 text-primary mt-2">Registrant</h2> -->
            <div class="row">
              <div class="col-md-8">
                <b>Name: </b>{{ orderConfirmationData.firstName }} {{ orderConfirmationData.lastName }} <br />
                <b>Email:</b>
                {{ orderConfirmationData.emailAddress }}<br />
                <b>Selected Date & Time:</b>
                {{ orderConfirmationData.ordersForTestingEvent.testEventDate | date: 'fullDate' }}
                {{ timeSlotSelected }}.
                <div class="my-2">
                  <div class="location-details">
                    <strong>Location</strong>
                    <p>
                      {{ orderConfirmationData.ordersForTestingEvent.testEventAtClub.clubName }} <br />
                      {{ orderConfirmationData.ordersForTestingEvent.testEventAtClub.address1 }} <br />
                      {{ orderConfirmationData.ordersForTestingEvent.testEventAtClub.city }},
                      {{ orderConfirmationData.ordersForTestingEvent.testEventAtClub.state }}
                      {{ orderConfirmationData.ordersForTestingEvent.testEventAtClub.zipCode }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <!-- <add-to-calendar-button
                  name="Title"
                  options="'Apple','Google'"
                  location="World Wide Web"
                  startDate="2023-08-13"
                  endDate="2023-08-13"
                  startTime="10:15"
                  endTime="23:30"
                  timeZone="America/Los_Angeles"></add-to-calendar-button> -->
              </div>
            </div>
            <!-- <div class="row">
              <qr-code value="Yo world!" size="200" errorCorrectionLevel="M"></qr-code>
            </div> -->
          </div>
          <h5 class="font-size-14 mb-3 mt-2">
            Order Summary <span class="float-right ml-2">#{{ orderConfirmationData.orderNumber }}</span>
          </h5>
          <div class="table-responsive">
            <table class="table table-centered mb-4 table-nowrap">
              <thead>
                <tr>
                  <th class="border-top-0" scope="col">Description</th>
                  <th class="border-top-0" scope="col">Unit Price</th>
                  <th class="border-top-0" scope="col">Qty</th>
                  <th class="border-top-0" scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    {{ orderConfirmationData.orderDetails[0].productsPurchased.stripeProductName }}
                  </th>

                  <td>{{ orderConfirmationData.orderDetails[0].price | currency }}</td>
                  <td>{{ orderConfirmationData.orderDetails[0].quantity }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td colspan="3">
                    <h6 class="m-0 text-right">Total:</h6>
                  </td>
                  <td>{{ orderConfirmationData.orderDetails[0].totalAmount | currency }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
