import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Location, NgClass, NgIf } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of, filter, switchMap } from 'rxjs';
import { ManageDiscountService } from '@bodyanalytics/front/admin/manage-discounts/domain';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ManageDiscountsFormComponent } from '@bodyanalytics/front/admin/manage-discounts/ui';
import { ReactiveFormsModule } from '@angular/forms';
 @Component({
  selector: 'bodyanalytics-discount-details',
  templateUrl: './discount-details.component.html',
  styleUrls: ['./discount-details.component.scss'],
  imports:[ManageDiscountsFormComponent, ReactiveFormsModule, NgClass, NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDetailsComponent
extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageDiscountService.getDiscount(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageDiscountService: ManageDiscountService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processClubUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageDiscountService
      .updateDiscount(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processClubUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

// extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   discountData!: DiscountI | null;
//   // public discounts: DiscountI[] = [];
//   // public $discounts!: Observable<DiscountI[]>;
//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private location: Location,
//     private manageDiscountService: ManageDiscountService,
//   ) {
//     super();
//   }
//   public goBack() {
//     this.location.back();
//   }
//   ngOnInit(): void {
//     this.getDiscount();
//   }

//   getDiscount(): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageDiscountService
//       .getDiscount(id)
//       .subscribe((discount: DiscountI) => ((this.discountData as DiscountI) = discount));
//     // );
//   }

//   public processDiscountUpdate(signupDetails: any): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageDiscountService
//       .updateDiscount(id, signupDetails)
//       .pipe(
//         tap(() => {
//           console.log('here: processDiscountUpdate');
//           // this.modalService.dismissAll();
//           // this.submitted = true;
//         }),
//         catchError((error: any) => {
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
