import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorI } from '@bodyanalytics/data-models-ui';
import { OperatorService } from '../../services/manage-operators.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';

@Component({
  selector: 'bodyanalytics-manage-operators-form',
  templateUrl: './manage-operators-form.component.html',
  styleUrls: ['./manage-operators-form.component.scss'],
})
export class ManageOperatorsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Input()
  item!: OperatorI | null;
  public EVENT_ID = '';
  public CLUB_ID = '';

  public operators: OperatorI[] = [];
  // public operatorDetailsForm: FormGroup = {} as FormGroup;

  public operatorDetailsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    operatorUserName: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    operatorPriority: new FormControl(0, [Validators.required]),
  });

  constructor(
    // private modalService: NgbModal,
    private router: Router,
    private manageTestingEventService: OperatorService,
  ) {
    super();
  }

  ngOnInit(): void {
    // this.subscriptions.barrel = this.manageTestingEventService.getOperatorsList().subscribe(response => {
    //   this.operators = response;

    //   // If operators were fetched, set the default value to the first one
    //   // if (this.operators.length > 0) {
    //   //   this.operatorDetailsForm.get('operators')?.setValue(this.operators[0].id);
    //   // }
    // });
    this.getTestingEvent();
    // this.EVENT_ID = this.item?.testingevent_id as string;
    // this.CLUB_ID = this.item?.club_testingevent_id as string;
  }

  public getTestingEvent(): void {
    // if (this.operatorDetailsForm.valid) {
    this.operatorDetailsForm.patchValue({
      operatorUserName: this.item?.operatorUserName,
      emailAddress: this.item?.emailAddress,
      firstName: this.item?.firstName,
      lastName: this.item?.lastName,
      operatorPriority: this.item?.operatorPriority,
    });
    // }
  }

  public updateOperator(): void {
    this.submitFormEvent.emit({
      operatorUserName: this.operatorDetailsForm.value.operatorUserName,
      emailAddress: this.operatorDetailsForm.value.emailAddress,
      firstName: this.operatorDetailsForm.value.firstName,
      lastName: this.operatorDetailsForm.value.lastName,
      operatorPriority: this.operatorDetailsForm.value.operatorPriority,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  protected goToSignupPage() {
    // console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    // this.router.navigateByUrl(`${RouterUrlsEnum.}/${this.EVENT_ID}`);
  }
  protected duplicateOperator() {
    this.subscriptions.barrel = this.manageTestingEventService
      .addOperator(this.item as OperatorI)
      .pipe(
        tap(() => {
          console.log(`duplicateTestingEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelOperator() {
    this.subscriptions.barrel = this.manageTestingEventService
      .deleteOperator(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }
  closeModal() {
    console.log('modal');
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-events`);
    // this.modalService.dismissAll();
  }
}
