import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AccountDetailsI,
  AddressDetailsI,
  LoginI,
  NotificationI,
  ProfileDetailsI,
} from '@bodyanalytics/data-models-ui';
import { ApiLocalRoutesEnum } from '@bodyanalytics/ui-core';
import { ENV } from '@bodyanalytics/app-config';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(@Inject(ENV) private appConfig: any, private readonly http: HttpClient) {}

  createAddressDetails(address: AddressDetailsI) {
    return this.http.post(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ADDRESS} `, address);
  }
  getAddressDetails() {
    return this.http.get(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ADDRESS} `);
  }
  updateAddressDetails(address: AddressDetailsI): Observable<any> {
    return this.http.patch(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ADDRESS} `, address);
  }

  createAccountDetails(account: AccountDetailsI) {
    return this.http.post(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ACCOUNT} `, account);
  }
  getAccountDetails() {
    return this.http.get(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ACCOUNT} `);
  }
  updateAccountDetails(account: AccountDetailsI): Observable<any> {
    return this.http.patch(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_ACCOUNT} `, account);
  }

  createNotificationSettings(notifications: NotificationI) {
    return this.http.post(
      `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_NOTIFICATIONS}`,
      notifications,
    );
  }
  getNotificationSettings() {
    return this.http.get(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_NOTIFICATIONS}`);
  }
  updateNotifications(notifications: NotificationI): Observable<any> {
    return this.http.patch(
      `${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_NOTIFICATIONS} `,
      notifications,
    );
  }

  createSecuritySettings(login: LoginI) {
    return this.http.post(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_SECURITY} `, login);
  }
  getSecuritySettings() {
    return this.http.get(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_SECURITY} `);
  }
  updateSecurity(login: LoginI): Observable<any> {
    return this.http.patch(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_SECURITY} `, login);
  }
  createProfile(profile: ProfileDetailsI) {
    return this.http.post(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE} `, profile);
  }
  getProfile() {
    return this.http.get(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE}`);
  }
  updateProfileDetails(profile: ProfileDetailsI): Observable<any> {
    return this.http.patch(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE} `, profile);
  }
  createProfileForCustomer(emailAddress: string): Observable<any> {
    return this.http.patch(`${this.appConfig.api}/${ApiLocalRoutesEnum.API_SUFFIX_PROFILE_CUSTOMER} `, emailAddress);
  }
}
