import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
 import { tap, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { GenericFormComponent } from '@bodyanalytics/ui-core';
import { ManageRegionService } from '../../../../../manage-regions/domain/src/lib/manage-regions.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-create-product',
  standalone: true,
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss'],
  imports: [MatStepperModule, GenericFormComponent],
})
export class CreateProductComponent extends BodyanalyticsBaseComponent {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private manageRegionService: ManageRegionService,
  ) {
    super();
  }

  fields: FormField<any>[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'state',
      label: 'State',
      type: 'select',
      options: [
        { value: 'CA', label: 'California' },
        { value: 'TX', label: 'Texas' },
        // ...
      ],
      validation: [Validators.required],
    },
    { key: 'email', label: 'Email', type: 'email', validation: [Validators.required, Validators.email] },
    // {
    // key: 'gender',
    // label: 'Gender',
    // type: 'radio',
    // options: [
    // { value: 'male', label: 'Male' },
    // { value: 'female', label: 'Female' },
    // { value: 'other', label: 'Other' },
    // ],
    // },
    { key: 'startTime', label: 'StartTime', type: 'time', validation: [Validators.required] },
    { key: 'age', label: 'Age', type: 'number', validation: [Validators.required] },
    { key: 'birthdate', label: 'Birthdate', type: 'date', validation: [Validators.required] },
    { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
    // ... other fields
  ];
  serviceFields: FormField<any>[] = [
    {
      key: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'state',
      label: 'State',
      type: 'select',
      options: [
        { value: 'CA', label: 'California' },
        { value: 'TX', label: 'Texas' },
        // ...
      ],
      validation: [Validators.required],
    },
    { key: 'email', label: 'Email', type: 'email', validation: [Validators.required, Validators.email] },
    // {
    // key: 'gender',
    // label: 'Gender',
    // type: 'radio',
    // options: [
    // { value: 'male', label: 'Male' },
    // { value: 'female', label: 'Female' },
    // { value: 'other', label: 'Other' },
    // ],
    // },
    { key: 'startTime', label: 'StartTime', type: 'time', validation: [Validators.required] },
    { key: 'age', label: 'Age', type: 'number', validation: [Validators.required] },
    { key: 'birthdate', label: 'Birthdate', type: 'date', validation: [Validators.required] },
    { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
    // ... other fields
  ];

  onFormSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.manageRegionService
      .addManageRegion(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
