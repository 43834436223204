import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RouterOutlet, RouterLink } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements',
  standalone: true,
  templateUrl: './manage-bodymeasurements.component.html',
  styleUrls: ['./manage-bodymeasurements.component.scss'],
  imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class ManageBodymeasurementsComponent {
  protected testsLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS;
  protected setupTestLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_SETUP_TEST;
}
