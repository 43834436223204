import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { PaymentI } from '@bodyanalytics/data-models-ui';
import { Location, NgClass, NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of, filter, switchMap } from 'rxjs';
 import { ReactiveFormsModule } from '@angular/forms';
 import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ManagePaymentsFormComponent } from '@bodyanalytics/front/admin/manage-payments/ui';
import { PaymentService } from '@bodyanalytics/front/admin/manage-payments/domain';

@Component({
  selector: 'bodyanalytics-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
  standalone: true,
  imports: [ManagePaymentsFormComponent, ReactiveFormsModule, NgClass, NgIf],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentDetailsComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  paymentData!: PaymentI | null;
  // public payments: PaymentI[] = [];
  // public $payments!: Observable<PaymentI[]>;

  id = input<string>();
  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.managePaymentService.getPayment(id)),
    ),
  );


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private managePaymentService: PaymentService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }
  ngOnInit(): void {
    // this.getPayment();
  }


  public processPaymentUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.managePaymentService
      .updatePayment(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processPaymentUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          // doc.file.hasError = true;
          // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
          // doc.file.uploading = false;
          // doc.file.uploaded = false;
          return of(error);
        }),
      )
      .subscribe();
  }
}
