<!-- Page content -->
<section>
  <div>
    <!-- Account security -->
    <div>
      <div class="ps-md-3 ps-lg-0 mt-md-2 py-md-4">
        <div>
          <bodyanalytics-profile-form (submitFormEvent)="update($event)"></bodyanalytics-profile-form>
        </div>
      </div>
    </div>
  </div>
</section>
