import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BodyanalyticsBaseComponent, LoadingSpinnerComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
 import {
  CreateTestEventI,
  ProductDataI,
  ProductI,
  SetupTestingI,
  SlotI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';
import { Router } from '@angular/router';
import { tap, catchError, of } from 'rxjs';
import { BreakTimes } from '@prisma/client';
import { MatStepper } from '@angular/material/stepper';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import { EventCreateFormComponent } from '@bodyanalytics/front/admin/manage-events/ui';
import { CommonModule } from '@angular/common';

@Component({
  standalone:true,
  selector: 'bodyanalytics-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss'],
  imports: [EventCreateFormComponent, MaterialModule, LoadingSpinnerComponent, ReactiveFormsModule, CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEventComponent extends BodyanalyticsBaseComponent {
  // Inject the stepper instance used in the template
  @ViewChild('stepper') private myStepper!: MatStepper;

  completedSteps: number[] = []; // Keeps track of completed steps
  eventId!: string; // Stores the generated event ID for subsequent steps
  selectedEventId!: string; // Stores the generated event ID for subsequent steps
  createdEventDetails!: TestingEventI;
  selectedProducts: ProductI[] = []; // Holds selected products from product selection
  selectedTimes: Date[] = []; // Stores chosen times from availability calendar
  selectedBreaks: BreakTimes[] = []; // Keeps track of assigned breaks
  createdSlots: SlotI[] = []; // Array of created event slots

  @Output() submitFormEvent = new EventEmitter<any>();

  formsubmit = false;

   bodyMeasurementData!: SetupTestingI;
  public submitted = false;
  public loadingSession = false;
  sessionDetails!: any;

  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private testingService: ManageTestingEventService, // private location: Location,
  ) {
    super();
  }

  goBack(): void {
    // this.location.back();
  }

  onActivate() {
    // this.testingService.activatedSetupStep.next(true);
    // this.testingService.stepUpdated.next(this.testingStep);
  }

  public proceedToReview() {
    //
  }

  public notificationsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    // isPauseAllNotifications: new FormControl('', [Validators.required]),
    // isActivityNoticesEnabled: new FormControl('', [Validators.required]),
    // isPartnerNoticesEnabled: new FormControl('', [Validators.required]),
    // isAccountNoticesEnabled: new FormControl('', [Validators.required]),
    // isPromotionsNoticesEnabled: new FormControl('', [Validators.required]),
    // isDailySummaryEnabled: new FormControl('', [Validators.required]),
    isEmailOptIn: new FormControl(false, [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      emailAddress: this.notificationsForm.value.emailAddress as string,
      phoneNumber: this.notificationsForm.value.phoneNumber as string,
      isEmailOptIn: this.notificationsForm.value.isEmailOptIn,
      // isPauseAllNotifications:  this.notificationsForm.value.isPauseAllNotifications as string,
      // isActivityNoticesEnabled:  this.notificationsForm.value.isActivityNoticesEnabled as string,
      // isPartnerNoticesEnabled:  this.notificationsForm.value.isPartnerNoticesEnabled as string,
      // isAccountNoticesEnabled:  this.notificationsForm.value.isAccountNoticesEnabled as string,
      // isPromotionsNoticesEnabled:  this.notificationsForm.value.isPromotionsNoticesEnabled as string,
      // isDailySummaryEnabled:  this.notificationsForm.value.isDailySummaryEnabled as string,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.notificationsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  createTestingEvent(event: CreateTestEventI) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.testingService
      .createTestingEvent(event)
      .pipe(
        tap(response => {
          this.loadingSession = false;
          this.eventId = response.createdEvent?.id as string;
          this.myStepper.next();
          this.createdEventDetails = response.createdEvent as TestingEventI;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  resetStepper() {
    this.completedSteps = [];
    // this.eventId = null;
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DASHBOARD}`);
    this.selectedProducts = [];
    this.selectedTimes = [];
    this.selectedBreaks = [];
    this.createdSlots = [];
    // this.stepper!.reset();
  }

  onProductsSelected(productData: ProductDataI): void {
    this.testingService.createProductWithServices(productData).subscribe(
      response => {
        // Handle successful creation, e.g., show a success message or navigate to another page
        console.log('Product created successfully:', response);
        // Assuming you want to advance to the next step after product creation
        // this.stepper!.selectedIndex = 3;
        // this.stepper?.next();
        this.myStepper.next();
      },
      error => {
        // Handle error, e.g., display an error message
        console.error('Error creating product:', error);
      },
    );
  }
  onFormSubmitted(formData: any) {
    // Process the submitted form data from the child component
    console.log('Received form data in parent:', formData);
  }
  onTableEvent(event: any) {
    // Handle table events (e.g., row selection, sorting)
    console.log('Table event:', event);
    if (event.action === 'edit') {
      // Handle row editing
    } else if (event.action === 'delete') {
      // Handle row deletion (e.g., update data source)
    } else if (event.action === 'checkboxChange') {
      // Handle row deletion (e.g., update data source)
    }
  }
}
