import { Component,Input } from '@angular/core';
import {   TestingEventI,   } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-event-information-card',
  templateUrl: './event-information-card.component.html',
  styleUrl: './event-information-card.component.scss',
})
export class EventInformationCardComponent {
  @Input()
  item!: TestingEventI | null;
}
