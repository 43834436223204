// import { createFeatureSelector, createSelector } from '@ngrx/store';
// import { State } from './test-event.reducer';

import { createSelector } from '@ngrx/store';
import { AppStateInterface } from '../types/appState.interface';
import { TestingEventI } from '@bodyanalytics/data-models-ui';

export function calculateTestEventGrossEarnings(testEvents: TestingEventI[]) {
  return testEvents.reduce((total, testEvent) => {
    return total + parseInt(`${testEvent.earnings}`, 10) || 0;
  }, 0);
}

export const selectEventsFeature = (state: AppStateInterface) => state.testEvents;
export const getTestEventSearch = createSelector(selectEventsFeature, state => state.query);

// export const getTestEventSearchResults = createSelector(
//   getTestEventSearch,
//   (search: TestEventSearchI) => state.collection
// );

export const selectTestEventState = createSelector(selectEventsFeature, state => state.collection);
export const selectAllTestEvents = createSelector(selectEventsFeature, state => state.collection);

export const selectActiveTestEventId = createSelector(selectEventsFeature, state => state.currentTestEventId);
export const selectActiveTestEvent = createSelector(
  selectAllTestEvents,
  selectActiveTestEventId,
  // selectEventsFeature,
  (testEvents, activeTestEventId) => testEvents.find(testEvent => testEvent.id === activeTestEventId) || null,
);
export const selectTestEventEarningsTotals = createSelector(
  // selectEventsFeature,
  selectAllTestEvents,
  calculateTestEventGrossEarnings,
);
export const isLoadingSelector = createSelector(selectEventsFeature, state => state.isLoading);
export const testEventsSelector = createSelector(selectEventsFeature, state => state.collection);
export const errorSelector = createSelector(selectEventsFeature, state => state.error);

export const testEventSearchSelector = createSelector(selectEventsFeature, state => state.query);

// export const selectEvents = (state: State) => state.events;
// export const selectLoading = (state: State) => state.loading;
// export const selectError = (state: State) => state.error;
