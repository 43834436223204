import { Component, output, input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RouterUrlsEnum, TestingSessionStep } from '@bodyanalytics/ui-core';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { Router } from '@angular/router';
import { filter, switchMap } from 'rxjs';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';

@Component({
  selector: 'bodyanalytics-review-event',
  templateUrl: './review-event.component.html',
  styleUrls: ['./review-event.component.scss'],
})
export class ReviewEventComponent {
  id = input<string>();
  submitFormEvent = output<any>();

  protected formsubmit = false;
  protected bodyMeasurementData!: TestingEventI;
  protected submitted = false;
  protected loadingSession = false;
  testingEventData = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageTestingEventService.getTestingEvent(id)),
    ),
  );

  constructor(
    private router: Router,
    private manageTestingEventService: ManageTestingEventService, // private location: Location,
  ) {}

  onActivate() {
    // this.manageTestingEventService.activatedSetupStep.next(true);
    // this.manageTestingEventService.stepUpdated.next(this.testingStep);
  }

  saveSetupTestDetails(signupDetails: any): void {}

  public proceedToReview() {
    //
  }

  public notificationsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    // isPauseAllNotifications: new FormControl('', [Validators.required]),
    // isActivityNoticesEnabled: new FormControl('', [Validators.required]),
    // isPartnerNoticesEnabled: new FormControl('', [Validators.required]),
    // isAccountNoticesEnabled: new FormControl('', [Validators.required]),
    // isPromotionsNoticesEnabled: new FormControl('', [Validators.required]),
    // isDailySummaryEnabled: new FormControl('', [Validators.required]),
    isEmailOptIn: new FormControl(false, [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      emailAddress: this.notificationsForm.value.emailAddress as string,
      phoneNumber: this.notificationsForm.value.phoneNumber as string,
      isEmailOptIn: this.notificationsForm.value.isEmailOptIn,
      // isPauseAllNotifications:  this.notificationsForm.value.isPauseAllNotifications as string,
      // isActivityNoticesEnabled:  this.notificationsForm.value.isActivityNoticesEnabled as string,
      // isPartnerNoticesEnabled:  this.notificationsForm.value.isPartnerNoticesEnabled as string,
      // isAccountNoticesEnabled:  this.notificationsForm.value.isAccountNoticesEnabled as string,
      // isPromotionsNoticesEnabled:  this.notificationsForm.value.isPromotionsNoticesEnabled as string,
      // isDailySummaryEnabled:  this.notificationsForm.value.isDailySummaryEnabled as string,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.notificationsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  createEventComplete() {
    const id = 1;
    //  console.log(
    //   'createTestReport: this.manageBodyMeasurementService.testDataList.length: ',
    //   this.manageTestingEventService.getTestingEvent(id),
    // );

    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_COMPLETE_EVENT}/${id}`);

    //const id = this.route.snapshot.paramMap.get('id') as string;
    //this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_COMPLETE_TEST}/${id}`);

    //   this.subscriptions.barrel = this.manageTestingEventService
    // .createTestingEventCompleted({
    //   reportId: id
    // })
    // .subscribe(response => {
    //   this.testDataCompleted = response;
    //     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_COMPLETE_TEST}/${this.testDataCompleted.id}`);

    // });
  }

  // createTestingEvent(event: CreateTestEventI) {
  //   this.loadingSession = true;
  //   this.subscriptions.barrel = this.manageTestingEventService
  //     .createTestingEvent(event)
  //     .pipe(
  //       tap(response => {
  //         this.loadingSession = false;
  //         console.log('response:', response);
  //         this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_REVIEW_EVENT}/${response.id}`);
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
}
