import { Route } from '@angular/router';
import { ManageBodymeasurementsComponent } from './manage-bodymeasurements/manage-bodymeasurements.component';
import { ManageBodymeasurementsDashboardComponent } from './dashboard/manage-bodymeasurements-dashboard.component';
import { ManageBodymeasurementsDetailsComponent } from './bodymeasurements-details/manage-bodymeasurements-details.component';
import { StartAssementComponent } from './start-assement/start-assement.component';
import { TestingReportComponent } from './testing-report/testing-report.component';
import { TestingSetupComponent } from './testing-setup/testing-setup.component';
import { CustomerCheckinComponent } from './customer-checkin/customer-checkin.component';
import { TestingCompleteComponent } from './testing-complete/testing-complete.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { provideBodymeasurements } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';

export const manageBodymeasurementsRoute: Route = {
  path: 'auth/manage-bodymeasurements',
  component: ManageBodymeasurementsComponent,
  canActivate: [AuthGuard],
  providers: [provideBodymeasurements(), ManageTestingEventService],
  children: [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
      path: 'setup-test',
      component: TestingSetupComponent,
      pathMatch: 'full',
      canLoad: [AuthGuard],
    },
    {
      path: 'check-in/:eventId',
      component: CustomerCheckinComponent,
      pathMatch: 'full',
      canLoad: [AuthGuard],
    },
    {
      path: 'start-test/:id',
      component: StartAssementComponent,
      pathMatch: 'full',
      canLoad: [AuthGuard],
    },

    { path: 'complete-test/:id', component: TestingCompleteComponent, canLoad: [AuthGuard] },
    { path: 'report-test/:id', component: TestingReportComponent, canLoad: [AuthGuard] },

    {
      path: 'dashboard',
      component: ManageBodymeasurementsDashboardComponent,
      pathMatch: 'full',
      canLoad: [AuthGuard],
    },
    {
      path: 'detail/:id',
      component: ManageBodymeasurementsDetailsComponent,
      canLoad: [AuthGuard],
    },
  ],
};
