<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="operatorDetailsForm">
  <ng-container>
    <div class="row pb-2">
      <div class="col-md-8">
        <div>
          <label for="firstName" class="form-label fs-base">First Name</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            formControlName="firstName"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your FirstName!</div>
        </div>
      </div>
      <div class="col-md-8">
        <div>
          <label for="lastName" class="form-label fs-base">Last Name</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            formControlName="lastName"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your lastName!</div>
        </div>
      </div>
      <div class="col-md-8">
        <div>
          <label for="operatorUserName" class="form-label fs-base">Operator Username</label>
          <input
            type="text"
            name="operatorUserName"
            id="operatorUserName"
            formControlName="operatorUserName"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your operatorUserName!</div>
        </div>
      </div>
      <div class="col-md-8">
        <div>
          <label for="emailAddress" class="form-label fs-base">Operator emailAddress</label>
          <input
            type="text"
            name="emailAddress"
            id="emailAddress"
            formControlName="emailAddress"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your emailAddress!</div>
        </div>
      </div>
      <div class="col-md-8">
        <div>
          <label for="operatorPriority" class="form-label fs-base">Operator Priority</label>
          <input
            type="text"
            name="operatorPriority"
            id="operatorPriority"
            formControlName="operatorPriority"
            class="form-control form-control-lg" />
          <div class="invalid-feedback">Please enter your operatorPriority!</div>
        </div>
      </div>
      <div class="col-md-4">
        <!-- <div class="list-group">
          <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
            View Signups
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="viewClubDetails()">
            View Club Details
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="showSignupList()">
            View Signup List
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="sendEmailReminder()">
            Email All
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="sendSmsReminder()">
            Send Text Message
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="duplicateEvent()">
            Create Duplicate Event
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="cancelEvent()">
            Cancel Event
          </button>
        </div> -->
      </div>
    </div>

    <div class="d-flex mb-3">
      <!-- <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button> -->
      <button type="submit" class="btn btn-primary btn-lg" (click)="updateOperator()">Save changes</button>
    </div>
  </ng-container>
</form>
