<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="bodymeasurementDetailsForm">
  @if(item()) {
  <div class="row pb-2">
    <div class="col-md-8">
      <div class="row">
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="testDate" class="form-label fs-base">Test Date</label>
            <input
              type="date"
              id="testDate"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.testDate.errors }"
              formControlName="testDate"
              autocomplete="off" />
            @if(form.testDate.errors){
            <div class="invalid-tooltip">
              @if(form.testDate.errors['required']){<span>Test date is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="dryWeight" class="form-label fs-base">Weight (lbs)</label>
            <input
              type="number"
              id="dryWeight"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.dryWeight.errors }"
              formControlName="dryWeight"
              autocomplete="off" />
            @if(form.dryWeight.errors){
            <div class="invalid-tooltip">@if(form.dryWeight.errors['required']){<span>Weight is required.</span>}</div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="height" class="form-label fs-base">Height (in)</label>
            <input
              type="number"
              id="height"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.height.errors }"
              formControlName="height"
              autocomplete="off" />
            @if(form.height.errors){
            <div class="invalid-tooltip">@if(form.height.errors['required']){<span>Height is required.</span>}</div>
            }
          </div>
        </div>

        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="waterTemperature" class="form-label fs-base">Water Temperature (° F)</label>
            <input
              type="number"
              id="waterTemperature"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.waterTemperature.errors }"
              formControlName="waterTemperature"
              autocomplete="off" />
            @if(form.waterTemperature.errors){
            <div class="invalid-tooltip">
              @if(form.waterTemperature.errors['required']){<span>Water Temperature is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="wetKg" class="form-label fs-base">Wet Weight (kg)</label>
            <input
              type="number"
              id="wetKg"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.wetKg.errors }"
              formControlName="wetKg"
              autocomplete="off" />
            @if(form.wetKg.errors){
            <div class="invalid-tooltip">@if(form.wetKg.errors['required']){<span>Wet Weight is required.</span>}</div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="residualLungValue" class="form-label fs-base">Residual Lung Volume (mL)</label>
            <input
              type="number"
              id="residualLungValue"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.residualLungValue.errors }"
              formControlName="residualLungValue"
              autocomplete="off" />
            @if(form.residualLungValue.errors){
            <div class="invalid-tooltip">
              @if(form.residualLungValue.errors['required']){<span>Residual Lung Volume is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="fatLbs" class="form-label fs-base">Fat Lbs</label>
            <input
              type="number"
              id="fatLbs"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.fatLbs.errors }"
              formControlName="fatLbs"
              autocomplete="off" />
            @if(form.fatLbs.errors){
            <div class="invalid-tooltip">@if(form.fatLbs.errors['required']){<span>Fat Lbs is required.</span>}</div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="fatPercent" class="form-label fs-base">Fat %</label>
            <input
              type="number"
              id="fatPercent"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.fatPercent.errors }"
              formControlName="fatPercent"
              autocomplete="off" />
            @if(form.fatPercent.errors){
            <div class="invalid-tooltip">@if(form.fatPercent.errors['required']){<span>Fat % is required.</span>}</div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="leanLbs" class="form-label fs-base">Lean Lbs </label>
            <input
              type="number"
              id="leanLbs"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.leanLbs.errors }"
              formControlName="leanLbs"
              autocomplete="off" />
            @if(form.leanLbs.errors){
            <div class="invalid-tooltip">@if(form.leanLbs.errors['required']){<span>Lean Lbs is required.</span>}</div>
            }
          </div>
        </div>

        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="leanMassPercent" class="form-label fs-base">Lean % </label>
            <input
              type="number"
              id="leanMassPercent"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.leanMassPercent.errors }"
              formControlName="leanMassPercent"
              autocomplete="off" />
            @if(form.leanMassPercent.errors){
            <div class="invalid-tooltip">
              @if(form.leanMassPercent.errors['required']){<span>Lean % is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="waterLbs" class="form-label fs-base">Water Lbs</label>
            <input
              type="number"
              id="waterLbs"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.waterLbs.errors }"
              formControlName="waterLbs"
              autocomplete="off" />
            @if(form.waterLbs.errors){
            <div class="invalid-tooltip">
              @if(form.waterLbs.errors['required']){<span>Water Lbs is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="assignedTruck" class="form-label fs-base">Truck Number</label>
            <input
              type="number"
              readonly
              id="assignedTruck"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.assignedTruck.errors }"
              formControlName="assignedTruck"
              autocomplete="off" />
            @if(form.assignedTruck.errors){
            <div class="invalid-tooltip">
              @if(form.assignedTruck.errors['required']){<span>Truck Number is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="restingMetabolicRate" class="form-label fs-base">Resting Metabolic Rate</label>
            <input
              type="number"
              id="restingMetabolicRate"
              readonly
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.restingMetabolicRate.errors }"
              formControlName="restingMetabolicRate"
              autocomplete="off" />
            @if(form.restingMetabolicRate.errors){
            <div class="invalid-tooltip">
              @if(form.restingMetabolicRate.errors['required']){<span>Resting Metabolic Rate is required.</span>}
            </div>
            }
          </div>
        </div>
        <div class="col-sm-6 mb-4">
          <div class="mb-3 position-relative">
            <label for="note" class="form-label fs-base">Note</label>
            <input
              type="text"
              id="note"
              class="form-control form-control-lg"
              [ngClass]="{ 'is-invalid': form.note.errors }"
              formControlName="note"
              autocomplete="off" />
            @if(form.note.errors){
            <div class="invalid-tooltip">@if(form.note.errors['required']){<span>Note is required.</span>}</div>
            }
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="list-group">
        <button
          type="button"
          class="list-group-item list-group-item-action list-group-item-light"
          (click)="calculateMeasurement()">
          <i class="bx bx-calculator"></i> Calculate
        </button>

        <button
          type="button"
          class="list-group-item list-group-item-action list-group-item-light"
          (click)="updateBodyMeasurement()">
          <i class="bx bxs-save"></i> Save
        </button>

        <button type="button" class="list-group-item list-group-item-action list-group-item-danger">
          <i class="bx bxs-message-rounded-x"></i> Delete
        </button>
      </div>
    </div>
  </div>
  }
</form>
