import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SignupI, TestingEventI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-signup-details',
  templateUrl: './signup-details-card.component.html',
  styleUrls: ['./signup-details-card.component.scss'],
})
export class SignupDetailsComponent extends BodyanalyticsBaseComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Input()
  item!: SignupI[];

  public isSearchLookup = true;
  constructor() {
    super();
  }
  public signupForm = new FormGroup({
    testingevent_id: new FormControl('', [Validators.required]),
  });

  public updateSignupDetails(id: string): void {
    this.signupForm.patchValue({
      testingevent_id: id,
    });

    this.submitFormEvent.emit({
      eventId: id as string,
    });
  }
  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }
}
