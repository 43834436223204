import { Component, ChangeDetectionStrategy, input, inject, effect, Signal } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';
import { ManageBodymeasurementStore } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { BodyFatTestForCustomerI, BodyFatTestReportI } from '@bodyanalytics/data-models-ui';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { type ClientHistory } from '@bodyanalytics/manage-reports-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-testing-report',
  standalone: true,
  templateUrl: './testing-report.component.html',
  imports: [DecimalPipe, DatePipe],
})
export class TestingReportComponent {
  id = input.required<string>();
  #store = inject(ManageBodymeasurementStore);

  protected report: Signal<BodyFatTestReportI | undefined> = this.#store.selectedTestingReport;
  protected clientHistory: Signal<ClientHistory[]> = this.#store.clientHistory;

  constructor(private $gaService: GoogleAnalyticsService) {
    effect(() => {
      const id = this.id();

      this.#store.loadBodyMeasurementReport(id);
      this.$gaService.pageView('manage-bodymeasurements/report-test/' + id, 'Report');
    });

    effect(() => {
      const report = this.report();

      report && this.#store.loadClientHistory(report.customer.emailAddress);
    });
  }

  printReport(): void {
    print();
  }

  sendReport(id: string, { emailAddress }: BodyFatTestForCustomerI): void {
    this.#store.sendReport({ id, email: emailAddress });
  }

  protected downloadPdfReport(email: string): void {
    this.#store.downloadPdfReport({ email, id: this.id() });
  }

  // public processBodyMeasurementUpdate(signupDetails: any): void {
  //   const id = this.route.snapshot.paramMap.get('id') as string;
  //   this.subscriptions.barrel = this.service
  //     .updateBodyMeasurement(id, signupDetails)
  //     .pipe(
  //       tap(() => {
  //         console.log('here: processBodyMeasurementUpdate');
  //         // this.modalService.dismissAll();
  //         // this.submitted = true;
  //       }),
  //       catchError((error: any) => {
  //         // doc.file.hasError = true;
  //         // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
  //         // doc.file.uploading = false;
  //         // doc.file.uploaded = false;
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
}
