<section class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white">
        <div class="form w-100">
          <a (click)="location.back()" class="mt-2" mat-button>
            <mat-icon>chevron_left</mat-icon>
            Go back
          </a>

          <!--
          <ul class="nav nav-pills justify-content-center">
            <li class="nav-item" [ngClass]="{ active: currentStep === 1 }">
              <a class="nav-link" (click)="setCurrentStep(1)">Start Test</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: currentStep === 2 }">
              <a class="nav-link" (click)="setCurrentStep(2)">Review</a>
            </li>
            <li class="nav-item" [ngClass]="{ active: currentStep === 3 }">
              <a class="nav-link" (click)="setCurrentStep(3)">Complete</a>
            </li>
          </ul> -->

          <bodyanalytics-customer-testing (onSubmitEvent)="handleStepCompletion($event)" />
          <!--
          <div *ngIf="currentStep === 1">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Customer Testing</h5>
                <bodyanalytics-customer-testing
                  (complete)="handleStepCompletion($event)"></bodyanalytics-customer-testing>
              </div>
            </div>
          </div>
          <div *ngIf="currentStep === 2">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Review</h5>

                <bodyanalytics-testing-review></bodyanalytics-testing-review>
              </div>
            </div>
          </div>
          <div *ngIf="currentStep === 3">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Complete</h5>

                <bodyanalytics-testing-complete></bodyanalytics-testing-complete>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
