<section id="manage-bodymeasurements-checkin" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <!-- <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            > -->

            <h4 class="h5">Customer Check-in</h4>
            <p class="card-title-desc">Please select search method option:</p>
            <div class="checkin-container mb-5">
              <bodyanalytics-customer-checkin-form (emailChange)="processCheckinUpdate($event)" />
            </div>

            @if (checkInCompleted()) {
            <div class="testing-checkin-container">
              <div class="row border-top">
                <div class="col-md-4 bg-white mt-5">
                  <mat-card class="rounded-3 shadow">
                    <mat-card-content>
                      <div class="radio">
                        <form [formGroup]="optionsFormGroup">
                          <div class="form-group">
                            <div class="">
                              <div class="card-deck">
                                <!-- <mat-radio-group
                                  aria-labelledby="select-customer-radio-group-label"
                                  class="select-customer-radio-group"
                                  formControlName="customer"
                                  name="search-customer-list"
                                  [required]="true"> -->
                                  @for(user of customers(); track user.id){
                                  <!-- <mat-radio-button
                                    class="customer-card-radio-button mb-5"
                                    [value]="user.id"
                                    (click)="selectCustomer(user.id)"> -->
                                    <div class="row">
                                      <div class="col-sm-8">
                                        <!-- <h5 class="card-title">Name: {{ user. firstName}}</h5> -->
                                        <span class="card-title">Name: {{ user.firstName }} {{ user.lastName }}</span><br>
                                        <!-- <ul> -->
                                          <ng-container *ngFor="let booking of user.booking"  >
                                            <div>
                                              <!-- TODO:  Do not show booking with booking.status === 'COMPLETED' -->
                                              <ng-container *ngIf="booking && booking.isPaid && !booking.isCancelled && booking.status === 'BOOKED' ">
                                                <i class='bx bx-check icon-lg bg-success-subtle rounded-circle'></i> Is Paid
                                              </ng-container>
                                              <!-- //TODO: only show customer with bookings matching event selected at previous setup screen -->
                                              <!-- <ng-container *ngIf="!booking.isPaid">
                                                <i class='bx bx-x icon-lg bg-danger-subtle rounded-circle'></i> Not Paid
                                              </ng-container>
                                              <ng-container *ngIf="booking.isCancelled || booking.status === 'CANCELLED'">
                                                <i class='bx bx-block icon-lg bg-danger-subtle rounded-circle'></i> Not signed up. Cancelled
                                              </ng-container> -->
                                            </div>
                                          </ng-container>

                                        <!-- </ul> -->


                                        <!-- <i class='bx bx-message-x'></i> Not paid -->
                <!-- {{ user.booking.isPaid}} -->
                                        <!-- <h6 class="my-1">
                                          Phone:<span class="text-muted"> {{ user.phoneNumber }}</span>
                                        </h6> -->
                                      </div>
                                      <div class="col-sm-2">
                                        <button class="btn btn-success btn-sm" (click)="startTest(user)">
                                          Start
                                        </button>
                                      </div>
                                    </div>
                                  <!-- </mat-radio-button> -->
                                  }
                                <!-- </mat-radio-group> -->
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
