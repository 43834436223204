<form #f="ngForm" (keyup.control.enter)="complete(f.value)" (ngSubmit)="calc(f.value)">
  <div class="row">
    <div class="col-md-4">
      <div class="form-group mb-4">
        <mat-form-field>
          <mat-label>Height (in)</mat-label>
          <input matInput min="0" name="height" ngModel required type="number" />
          <mat-error>Please provide a valid height.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group mb-4">
        <mat-form-field>
          <mat-label>Dry Weight (lbs)</mat-label>
          <input matInput min="0" name="dryWeight" ngModel required type="number" />
          <mat-error>Please provide a valid dry weight.</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group mb-4">
        <mat-form-field>
          <mat-label>Wet Weight (kg)</mat-label>
          <input matInput  name="wetKg" ngModel required type="number" />
          <mat-error>Please provide a valid water weight.</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <button [disabled]="f.invalid" color="accent" mat-stroked-button>Calculate</button>
  <button
    (click)="complete(f.value)"
    [disabled]="f.invalid || !softTestCompleted()"
    color="primary"
    mat-flat-button
    type="button">
    Complete
  </button>
</form>
