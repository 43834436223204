import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterOutlet } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-manage-regions',
  templateUrl: './manage-regions.component.html',
  styleUrls: ['./manage-regions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class ManageRegionsComponent {
  protected createRegionLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_CREATE_REGION;
}

// extends BodyanalyticsBaseComponent {
//   public regionDetailsForm = new FormGroup({});
//   constructor(
//     // private modalService: NgbModal,
//     public formBuilder: FormBuilder,
//     private manageRegionService: ManageRegionService,
//     private router: Router, // private loaderService: LoaderService
//   ) {
//     super();
//   }
//   openModal(content: any) {
//     //this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//   }

//   public createRegion() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_CREATE_REGION}`);
//   }
//   // saveData() {
//   //   if (this.regionDetailsForm.valid) {
//   //     this.createRegion(this.regionDetailsForm.value);
//   //   }
//   // }
//   public saveData(signupDetails: any): void {
//     // this.loaderService.setLoading(true);
//     this.subscriptions.barrel = this.manageRegionService
//       .addManageRegion(signupDetails)
//       .pipe(
//         tap(() => {
//           delay(1000), console.log('test created');
//           // //this.modalService.dismissAll();
//           // this.submitted = true;
//           // this.loaderService.setLoading(false);
//         }),
//         catchError((error: any) => {
//           // this.loaderService.setLoading(false);
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   cancelModal() {
//     //this.modalService.dismissAll();
//   }
//   closeModal() {
//     console.log('modal');
//     // this.router.navigateByUrl(`/auth/manage-regions`);
//     //this.modalService.dismissAll();
//   }
// }
