<div class="invalid-feedback">Error: Please check</div>

<form [formGroup]="testingEventDetailsForm" class="row g-3">
  <div class="row pb-2">
    <div class="form-group row mt-2">
      <label for="drpStartTime" class="col-sm-4 col-form-label">Start Time: </label>
      <div class="col-sm-8">
        <select
          id="drpStartTime"
          class="form-control form-select form-select-lg"
          name="startTime"
          [ngClass]="{ 'is-invalid': formsubmit && form.startTime.errors }"
          formControlName="startTime">
          <option value="null">Please Select</option>
          <option *ngFor="let times of eventTimesJson" [value]="times.timeValue">
            {{ times.timeLabel }}
          </option>
        </select>
        <div *ngIf="formsubmit && form.startTime.errors" class="invalid-tooltip">
          <span *ngIf="form.startTime.errors['required']">Start time is required.</span>
        </div>
      </div>
    </div>

    <div class="form-group row mt-2">
      <label for="drpEndTime" class="col-sm-4 col-form-label">End Time: </label>
      <div class="col-sm-8">
        <select
          id="drpEndTime"
          class="form-control form-select form-select-lg"
          name="endTime"
          [ngClass]="{ 'is-invalid': formsubmit && form.endTime.errors }"
          formControlName="endTime">
          <option value="null">Please Select</option>
          <option *ngFor="let times of eventTimesJson" [value]="times.timeValue">
            {{ times.timeLabel }}
          </option>
        </select>
      </div>
      <div *ngIf="formsubmit && form.endTime.errors" class="invalid-tooltip">
        <span *ngIf="form.endTime.errors['required']">End time is required.</span>
      </div>
    </div>
  </div>

  <div class="d-flex mb-3">
    <!-- <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button>
      <button type="submit" class="btn btn-primary btn-lg" (click)="updateTestingEvent()">Save changes</button> -->
  </div>
</form>
