import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CustomerI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'bodyanalytics-manage-customers-table',
  templateUrl: './manage-customers-table.component.html',
  styleUrls: ['./manage-customers-table.component.scss'],
  standalone: true,
  // animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, CommonModule, NgClass, NgIf, NgFor, RouterLink],
})
export class ManageCustomersTableComponent extends BodyanalyticsBaseComponent {
  protected customerDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS_DETAILS;

  protected displayedColumns = ['firstName', 'emailAddress'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: CustomerI;

  entities = input.required<CustomerI[]>();
}

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS_DETAILS}/${target.value}`);
//     }
//   }
