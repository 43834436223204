<section id="manage-customers-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-6 bg-white p-2">
        <bodyanalytics-generic-form
          [fields]="customerFields"
          (formSubmitted)="onFormSubmitted($event)"></bodyanalytics-generic-form>
      </div>
    </div>
  </div>
</section>
