import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OrderConfirmationI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import {
  SharedLogService,
  SessionStorageService,
  SharedErrorService,
  ApiLocalRoutesEnum,
} from '@bodyanalytics/ui-core';
import { Observable, tap, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderServicePub {
  private publicOrdersUrl = `${ApiLocalRoutesEnum.API_SUFFIX_ORDERS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sessionStorageService: SessionStorageService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getOrderConfirmation(id: string): Observable<OrderConfirmationI> {
    const url = `${this.appConfig.api}/${this.publicOrdersUrl}/${id}`;
    return this.http.get<OrderConfirmationI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched orderConfirmation id=${id}`)),
      catchError(this.sharedErrorService.handleError<OrderConfirmationI>(`orderConfirmation id=${id}`)),
    );
  }
}
