import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { BookingDashboardComponent } from './dashboard/dashboard.component';
import { BookCreateComponent } from './book-create/book-create.component';
import { BookingDetailComponent } from './booking-detail/booking-detail.component';
import { BookingListComponent } from './booking-list/booking-list.component';
import { BookingHistoryListComponent } from './booking-history-list/booking-history-list.component';
import { provideBookings, BookingService } from '@bodyanalytics/front/admin/manage-bookings/domain';

export const manageBookingUiRoutes: Route = {
  path: 'auth/manage-bookings',
  component: ManageBookingComponent,
  canActivate: [AuthGuard],
  providers: [provideBookings(), BookingService],
  children: [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
      path: 'create-booking',
      component: BookCreateComponent,
      // pathMatch: 'full',
    },

    {
      path: 'dashboard',
      component: BookingDashboardComponent,
      // pathMatch: 'full',
    },
    {
      path: 'detail/:id',
      component: BookingDetailComponent,
      // pathMatch: 'full',
    },
    {
      path: 'list/:id',
      component: BookingListComponent,
      // pathMatch: 'full',
    },
    {
      path: 'history/:id',
      component: BookingHistoryListComponent,
      // pathMatch: 'full',
    },
  ],
};
