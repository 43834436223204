<div class="py-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="align-middle gap-3">
          <!-- Your Order START -->
          <div class="card bg-transparent">
            <!-- Card header -->
            <div class="card-header pb-3">
              <h4 class="card-title mb-0">Your Order</h4>
            </div>

            <!-- Card body -->
            <div class="card-body p-0">
              <!-- Product item START -->
              <div class="bg-light px-4 py-4 rounded-2">
                <div class="row align-items-center">
                  <!-- Product detail -->
                  <div class="col-md-10">
                    <div class="row">
                      <div class="col-sm-6 mb-3 mb-sm-0">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">Summary</h5>
                            <div *ngIf="bookingData!.selectedProduct!.name">
                              <small class="text-body-secondary">
                                <strong
                                  ><span class="text-muted">Service: </span>
                                  {{ bookingData!.selectedProduct!.name }}</strong
                                >
                                <br />
                              </small>
                            </div>
                            <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6 mb-3 mb-sm-0">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">Date and time</h5>

                            <small class="text-body-secondary">
                              {{ eventData.testEventDate | date: 'fullDate' }}<br />
                              <span class="badge text-bg-warning">
                                <strong>Selected Time Slot:</strong>
                                {{ bookingData!.selectedTime }}
                              </span>
                            </small>

                            <!-- <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                <a href="#" class="btn btn-primary">Go somewhere</a> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Your Order END -->

          <hr />
          <!-- Divider -->

          <!-- Billing address START -->
          <div class="card bg-transparent mb-5 border-0">
            <!-- Card header -->

            <!-- Card body -->
            <div class="card-body p-0 mt-2">
              <!-- Payment Options START -->
              <div class="card bg-transparent mb-5 border-top border-0">
                <!-- Card header -->
                <div class="card-header pb-3">
                  <h4 class="card-title mb-0">Payment Information</h4>
                </div>

                <!-- Card body -->
                <div class="card-body bg-transparent rounded-2 p-4">
                  <!-- Form START -->
                  <!-- <h5 class="card-title">Payment Information</h5> -->
                  <bodyanalytics-signup-payment-form
                    *ngIf="clientSecret"
                    [isCreditAvailable]="isCustomerCreditAvailable"
                    [clientSecret]="clientSecret"
                    (submitFormEvent)="processPaymentForm($event)"></bodyanalytics-signup-payment-form>

                  <!-- Form END -->
                </div>
              </div>
              <!-- Payment Options END -->
            </div>
          </div>
        </div>
      </div>
      <!-- Right Side START -->
      <div class="col-lg-4">
        <!-- Coupon code END -->

        <bodyanalytics-order-summary-sidebar
          [item]="bookingData!.selectedProduct"></bodyanalytics-order-summary-sidebar>

        <!-- Order summary END -->
        <p class="tiny mb-0 text-center mt-2">
          By completing your purchase, you agree to these
          <a href="https://appliedbiox.com/terms-of-service">Terms of Service</a> and
          <a href="https://appliedbiox.com/privacy-policy">Privacy</a>
        </p>
        <!-- <div class="card my-5">

          <div class="card-body p-4">
            <div class="mb-4">
              <i
                class="bx bxs-error icon-shape icon-lg bg-warning-subtle rounded-circle text-warning-emphasis"></i>
            </div>

            <h4 class="h5">Refund Policy</h4>
            <p class="mx-0 fw-bolder fs-6">
              <small class="text-body-secondary"> Contact the organizer to request a refund. </small>
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
