<section id="manage-bodymeasurements-complete" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-8 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <!-- <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            > -->

            <h4 class="h5">Session Complete</h4>
            <!--            <p class="card-title-desc">Test results have been successfully been saved for {{ data()?.emailAddress }}</p>-->
            <p class="card-title-desc">
              Customer can now access results on the AppliedBioX portal. Ask customer if they would like to review
              results, or send report via email?
            </p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3" *ngIf="report() as reportData">
              <p>
                <button
                  [routerLink]="[bfTestsReportReview, id()]"
                  class="btn btn-link me-md-2 fw-bold text-decoration-none"
                  routerLinkActive="router-link-active"
                  type="button">
                  Review results
                </button>
              </p>
              <p>
                <button class="btn btn-outline-primary" type="button" (click)="reportClick()">Send report</button>
              </p>
            </div>
          </div>
          <!-- <div *ngIf="bodyMeasurementData">
            <bodyanalytics-manage-bodymeasurements-form
              [item]="bodyMeasurementData"
              (submitFormEvent)="processBodyMeasurementUpdate($event)"></bodyanalytics-manage-bodymeasurements-form>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
