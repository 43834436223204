import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MobileTestingCalendarComponent } from './containers/mobile-testing-calendar/mobile-testing-calendar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { calendarRoutes } from './lib.routes';
import { CalendarSearchComponent } from './containers/calendar-search/calendar-search.component';
import { EventRegionSearchFormComponent } from './components/region-event-search-form/region-event-search-form.component';
import { DataModelsUiModule } from '@bodyanalytics/data-models-ui';
import { SignupComponent } from './containers/signup/signup.component';
import { OrderSummarySidebarComponent } from './components/order-summary-sidebar/order-summary-sidebar.component';
import { SignupCheckoutComponent } from './containers/signup-checkout/signup-checkout.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { SignupPaymentFormComponent } from './components/signup-payment-form/signup-payment-form.component';
import { NgxStripeModule } from 'ngx-stripe';
import { TestingEventDetailsComponent } from './components/testing-event-details-card/testing-event-details.component';
import { SignupDetailsComponent } from './components/signup-details-card/signup-details-card.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthInterceptor } from './guards/auth.interceptor';
import { OrderConfirmationComponent } from './containers/order-confirmation/order-confirmation.component';
import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';
import { EventSelectionComponent } from './components/eventSelection/event-selection.component';
import { StoreModule } from '@ngrx/store';
import { testEventReducer } from './containers/store/test-event.reducer';

import { TesteventsListComponent } from './containers/testevents-list/testevents-list.component';
import { SearchEventsBoxComponent } from './containers/search-events-box/search-events-box.component';
import { TesteventsDetailPageComponent } from './containers/testevents-detail-page/testevents-detail-page.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { RecheduleVerifyPageComponent } from './containers/rechedule-verify-page/rechedule-verify-page.component';
import { RescheduleConfirmationPageComponent } from './containers/reschedule-confirmation-page/reschedule-confirmation-page.component';
import { EventInformationCardComponent } from './components/event-information-card/event-information-card.component';
import { EventHeaderComponent } from './components/event-header/event-header.component';
import { ENV } from '@bodyanalytics/app-config';
import { environment } from '../../../../../../apps/front/calendar/src/environments/environment';
import { AuthenticationUiModule } from '@bodyanalytics/authentication-ui';

@NgModule({
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard, DecimalPipe,
    { provide: ENV, useValue: environment },
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DataModelsUiModule,
    RouterModule.forChild(calendarRoutes),
    UiCoreModule,
    StoreModule.forFeature('testEvents', testEventReducer),
    MaterialModule,
    GoogleMapsModule,
    NgxStripeModule.forRoot(
      environment.stripePKey,
    ),
    GoogleMapsModule,
    AuthenticationUiModule,
  ],
  declarations: [
    MobileTestingCalendarComponent,
    CalendarSearchComponent,
    EventRegionSearchFormComponent,
    SignupComponent,
    TestingEventDetailsComponent,
    SignupDetailsComponent,
    OrderSummarySidebarComponent,
    SignupCheckoutComponent,
    SignupFormComponent,
    SignupPaymentFormComponent,
    OrderConfirmationComponent,
    EventSelectionComponent,
    TesteventsListComponent,
    SearchEventsBoxComponent,
    TesteventsDetailPageComponent,
    OrderSummaryComponent,
    RecheduleVerifyPageComponent,
    RescheduleConfirmationPageComponent,
    EventInformationCardComponent,
    EventHeaderComponent,
  ],
})
export class CalendarUiModule {}
