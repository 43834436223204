import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
 import { tap, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { OperatorService } from '../../services/manage-operators.service';

@Component({
  selector: 'bodyanalytics-create-operator',
  templateUrl: './create-operator.component.html',
  styleUrls: ['./create-operator.component.scss'],
})
export class CreateOperatorComponent extends BodyanalyticsBaseComponent implements OnInit {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private operatorService: OperatorService,
  ) {
    super();
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  fields: FormField<any>[] = [
    { key: 'emailAddress', label: 'Email address', type: 'email', validation: [Validators.required, Validators.email] },

    {
      key: 'operatorUserName',
      label: 'Operator username',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    {
      key: 'firstName',
      label: 'First name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastName',
      label: 'Last name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone number',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'operatorPriority',
      label: 'Priority Level',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
  ];

  onOperatorFormSubmitted(operatorDetails: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.operatorService
      .addOperator(operatorDetails)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_OPERATORS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
