import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bodyanalytics-notifications-form',
  templateUrl: './notifications-form.component.html',
  styleUrls: ['./notifications-form.component.scss'],
})
export class NotificationsFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();

  formsubmit: boolean = false;
  constructor() {
    //
  }

  public notificationsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    // isPauseAllNotifications: new FormControl('', [Validators.required]),
    // isActivityNoticesEnabled: new FormControl('', [Validators.required]),
    // isPartnerNoticesEnabled: new FormControl('', [Validators.required]),
    // isAccountNoticesEnabled: new FormControl('', [Validators.required]),
    // isPromotionsNoticesEnabled: new FormControl('', [Validators.required]),
    // isDailySummaryEnabled: new FormControl('', [Validators.required]),
    isEmailOptIn: new FormControl(false, [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      emailAddress: this.notificationsForm.value.emailAddress as string,
      phoneNumber: this.notificationsForm.value.phoneNumber as string,
      isEmailOptIn: this.notificationsForm.value.isEmailOptIn,
      // isPauseAllNotifications:  this.notificationsForm.value.isPauseAllNotifications as string,
      // isActivityNoticesEnabled:  this.notificationsForm.value.isActivityNoticesEnabled as string,
      // isPartnerNoticesEnabled:  this.notificationsForm.value.isPartnerNoticesEnabled as string,
      // isAccountNoticesEnabled:  this.notificationsForm.value.isAccountNoticesEnabled as string,
      // isPromotionsNoticesEnabled:  this.notificationsForm.value.isPromotionsNoticesEnabled as string,
      // isDailySummaryEnabled:  this.notificationsForm.value.isDailySummaryEnabled as string,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.notificationsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
