import { Component, ChangeDetectionStrategy, input, signal, inject, effect, Signal } from '@angular/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { BodyFatTestForCustomerI, BodyFatTestReportI } from '@bodyanalytics/data-models-ui';
import { ManageBodymeasurementStore } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { RouterUrlsEnum, TestingSessionStep } from '@bodyanalytics/ui-core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgIf } from '@angular/common';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-testing-complete',
  standalone: true,
  templateUrl: './testing-complete.component.html',
  styleUrls: ['./testing-complete.component.scss'],
  imports: [RouterLinkActive, RouterLink, NgIf],
})
export class TestingCompleteComponent {
  id = input<string>();
  #store = inject(ManageBodymeasurementStore);
  protected bfTestsReportReview = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_REPORT_REVIEW;
  protected testingStep = TestingSessionStep.COMPLETE;

  protected report: Signal<BodyFatTestReportI | undefined> = this.#store.selectedTestingReport;

  constructor(private $gaService: GoogleAnalyticsService) {
    effect(() => {
      const id = this.id();

      this.#store.loadBodyMeasurementReport(id as string);
      this.$gaService.pageView('manage-bodymeasurements/report-test/' + id, 'Report');
    });

    effect(() => {
      const report = this.report();

      report && this.#store.loadClientHistory(report.customer.emailAddress);

    });
  }
  sendReport(id: string, emailAddress: string): void {
    this.#store.sendReport({ id, email: emailAddress });
  }

  reportClick(){
    const report = this.report();
    if (report) {
      this.sendReport(report.id, report.customer.emailAddress);
    }
  }

}
