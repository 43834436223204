import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterOutlet } from '@angular/router';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-manage-payments',
  templateUrl: './manage-payments.component.html',
  styleUrls: ['./manage-payments.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
 imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class ManagePaymentsComponent {
  protected createPaymentLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_PAYMENTS;
}
