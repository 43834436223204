import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { PaymentI } from '@bodyanalytics/data-models-ui';
import { MatTableModule } from '@angular/material/table';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { RouterLink } from '@angular/router';
import { CurrencyPipe, DatePipe, JsonPipe, NgIf } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'bodyanalytics-manage-payments-table',
  templateUrl: './manage-payments-table.component.html',
  styleUrls: ['./manage-payments-table.component.scss'],
  standalone:true,
  // animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe, CurrencyPipe, NgIf],
})
export class ManagePaymentsTableComponent extends BodyanalyticsBaseComponent{
    protected paymentDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_PAYMENTS_DETAILS;

    protected displayedColumns = [ 'createdAt', 'amount', 'bookingId' ];
    protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
    protected expandedElement?: PaymentI;

    entities = input.required<PaymentI[]>();
  }
// extends BodyanalyticsBaseComponent implements AfterViewInit {
//   @Input()
//   item!: PaymentI[];

//   submitted = false;
//   checked = '';
//   dataSource: MatTableDataSource<PaymentI> = new MatTableDataSource<PaymentI>();

//   public displayedColumns: string[] = ['id', 'paymentForBodyFatTestAmount'];
//   public paymentsForm!: FormGroup;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;
//   isPaymentTableEnabled = true;

//   isPaymentIdSelected = '';
//   public testingEventTableData: PaymentI[] = [];
//   // public $testingEvent!: Observable<PaymentI[]>;
//   constructor(private paymentService: PaymentService, private fb: FormBuilder, private router: Router) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource = new MatTableDataSource(this.item);
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_PAYMENTS_DETAILS}/${target.value}`);
//     }
//   }

//   openModal(deleteContent: any, id: string) {
//     // const id = this.route.snapshot.paramMap.get('id') as string;
//     // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//     this.isPaymentIdSelected = id;
//   }

//   deletePayment() {
//     this.subscriptions.barrel = this.paymentService
//       .deletePayment(this.isPaymentIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */

//   public createPayment(signupDetails: any): void {
//     this.subscriptions.barrel = this.paymentService
//       .addPayment(signupDetails)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
