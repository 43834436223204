import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { SignupI, TestingEventI } from '@bodyanalytics/data-models-ui';
import { Observable, catchError, mergeMap, of, take, throwError } from 'rxjs';
import { MobileTestingCalendarService } from '../services/mobile-testing.service';

@Injectable({ providedIn: 'root' })
export class MobileTestingResolver implements Resolve<TestingEventI> {
  constructor(private service: MobileTestingCalendarService) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<TestingEventI> | Observable<never> | Promise<TestingEventI> | TestingEventI {
    // return this.service.getTestingEvent(route.paramMap.get('id') as string).pipe(
    //   take(1),
    //   mergeMap((testEvent: TestingEventI) => {
    //     if (testEvent) {
    //       return of(testEvent);
    //     } else {
    //       return throwError(() => new Error(` No data found for ${testEvent} `));
    //     }
    //   }),
    //   catchError((failure: any) => {
    //     // this.navHubService.routeToHome();
    //     return throwError(() => new Error(` Error:  ${failure} `));
    //   })
    // );

    return this.service.getTestingEvent(route.paramMap.get('id') as string).pipe(
      catchError((failure: any) => {
        // this.navHubService.routeToHome();
        return throwError(() => new Error(` Error:  ${failure} `));
      }),
    );
  }
}
