<!-- <div *ngFor="let service of selectedServices" class="card border-0">
  <div class="card-body ">
    <h5 class="card-title">{{ service.name }}</h5>
    <p class="card-text m-0">Slot: {{ service. | date : 'mediumDate' }}   {{ service.slot  | date : 'h:mm a' }}</p>
    <p class="card-text">Price: {{ service.price | currency }}</p>
    <button class="btn btn-outline-secondary" (click)="editService(service.id)">
      <i class="bi bi-pencil"></i> Edit
    </button>
  </div>
</div>

<div class="card border-0">
  <div class="card-body">
    <h5 class="card-title">Total Cost: {{ totalCost | currency }}</h5>
    <button class="btn btn-primary" (click)="addService()">
      Add Service
    </button>
  </div>
</div> -->
<div class="order-summary">
  <h3>Your Order</h3>
  <div *ngFor="let service of selectedServices" class="mb-3">
    <p><strong>Service:</strong> {{ service.serviceName }}</p>
    <p><strong>Price:</strong> ${{ service.price }}</p>
    <!-- <p><strong>Provider:</strong> {{ service.provider }}</p> -->
    <p>
      <strong>Time Slot:</strong> {{ service.selectedSlot.startTime | date : 'shortTime' }} -
      {{ service.selectedSlot.endTime | date : 'shortTime' }}
    </p>
    <!-- Display additional requests if available -->
    <p *ngIf="service.additionalRequests"><strong>Requests:</strong> {{ service.additionalRequests }}</p>
  </div>
  <p class="total"><strong>Total:</strong> ${{ calculateTotal() }}</p>

  <!-- Add other order summary details -->
</div>
