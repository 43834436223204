<bodyanalytics-header />

<main class="col-12">
  <div *ngIf="auth.isLoading$ | async; else loaded" class="container">
    <div class="row py-5">
      <bodyanalytics-loading-spinner spinnerTitle="Loading" />
    </div>
  </div>

  <ng-template #loaded>
    <div class="flex-grow-1 mx-auto">
      <div class="main">
        <router-outlet />
      </div>
    </div>
  </ng-template>
</main>
<bodyanalytics-footer />
