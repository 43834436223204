import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { ServerErrorComponent, homeRoute } from '@bodyanalytics/ui-core';
import { dashboardUiRoute } from '@bodyanalytics/dashboard-ui';
import { adminDashboardUiRoute } from '@bodyanalytics/admin-dashboard-ui';
import { manageBodymeasurementsRoute } from '@bodyanalytics/front/admin/manage-bodymeasurements/feature';
 import { manageClubsUiRoutes } from '@bodyanalytics/front/admin/manage-clubs/feature';
import { LoginComponent } from '@bodyanalytics/authentication-ui';
import { manageCustomersUiRoutes } from '@bodyanalytics/front/admin/manage-customers/feature';
import { manageBookingUiRoutes } from '@bodyanalytics/front/admin/manage-bookings/feature';
import { manageOrdersUiRoutes } from '@bodyanalytics/front/admin/manage-orders/feature';
import { manageRegionsUiRoutes } from '@bodyanalytics/front/admin/manage-regions/feature';
import { managePaymentsUiRoutes } from '../../../../../libs/front/admin/manage-payments/feature/src/lib/lib.routes';
import { manageDiscountsUiRoutes } from '@bodyanalytics/front/admin/manage-discounts/feature';
  export default [
  homeRoute,

  { path: 'login', component: LoginComponent },
  { path: 'error', component: ServerErrorComponent },
  adminDashboardUiRoute,
  dashboardUiRoute,
  manageBodymeasurementsRoute,
  manageClubsUiRoutes,
  manageCustomersUiRoutes,
  manageBookingUiRoutes,
  manageOrdersUiRoutes,
  manageRegionsUiRoutes,
  managePaymentsUiRoutes,
  manageDiscountsUiRoutes,
  {
    path: 'access-denied',
    loadComponent: () => import('@bodyanalytics/ui-core').then(mod => mod.AccessDeniedComponent),
  },
  { path: '**', redirectTo: homeRoute.path },
] satisfies Routes;
