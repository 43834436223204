import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { OperatorService } from '../../services/manage-operators.service';
@Component({
  selector: 'bodyanalytics-manage-operators-dashboard',
  templateUrl: './manage-operators-dashboard.component.html',
  styleUrls: ['./manage-operators-dashboard.component.scss'],
})
export class ManageOperatorsDashboardComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  public operatorData: OperatorI[] = [];

  constructor(private router: Router, private operatorService: OperatorService) {
    super();
  }
  ngOnInit(): void {
    this._fetchData();
  }

  private _fetchData() {
    this.subscriptions.barrel = this.operatorService.load().subscribe((results: OperatorI[]) => {
      this.operatorData = results;
    });
  }
}
