import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedServiceI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent {
  @Input() selectedServices: SelectedServiceI[] = [];
  totalCost: number = 0;

  constructor(private router: Router) {}

  ngOnChanges(): void {
    this.totalCost = this.selectedServices.reduce((sum, service) => sum + service.price, 0);
  }

  editService(serviceId: number): void {
    this.router.navigate(['/calendar/event-details', serviceId]);
  }

  addService(): void {
    // Assuming the ID for adding a new service is handled similarly
    this.router.navigate(['/calendar/event-details']);
  }
  calculateTotal(): number {
    return this.selectedServices.reduce((total, service) => total + service.price, 0);
  }
}
