import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'bodyanalytics-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class ManageCustomersComponent extends BodyanalyticsBaseComponent {
  protected createCustomerLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS_CREATE_CUSTOMER;

}
//  {
//   public regionDetailsForm = new FormGroup({});
//   constructor(
//     // private modalService: NgbModal,
//     public formBuilder: FormBuilder,
//     private manageCustomerService: ManageCustomerService,
//     private router: Router, // private loaderService: LoaderService
//   ) {
//     super();
//   }
//   openModal(content: any) {
//     //this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//   }

//   public createCustomer() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS_CREATE_CUSTOMER}`);
//   }
//   // saveData() {
//   //   if (this.regionDetailsForm.valid) {
//   //     this.createRegion(this.regionDetailsForm.value);
//   //   }
//   // }
//   public saveData(signupDetails: any): void {
//     // this.loaderService.setLoading(true);
//     this.subscriptions.barrel = this.manageCustomerService
//       .addCustomer(signupDetails)
//       .pipe(
//         tap(() => {
//           delay(1000), console.log('test created');
//           // //this.modalService.dismissAll();
//           // this.submitted = true;
//           // this.loaderService.setLoading(false);
//         }),
//         catchError((error: any) => {
//           // this.loaderService.setLoading(false);
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   cancelModal() {
//     //this.modalService.dismissAll();
//   }
//   closeModal() {
//     console.log('modal');
//     // this.router.navigateByUrl(`/auth/manage-regions`);
//     //this.modalService.dismissAll();
//   }
// }
