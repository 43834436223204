import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, GenericFormComponent, LoadingSpinnerComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { Location, NgFor, NgIf } from '@angular/common';
import { ManageCustomerService } from '@bodyanalytics/front/admin/manage-customers/domain';


@Component({
  selector: 'bodyanalytics-create-customers',
  templateUrl: './create-customers.component.html',
  styleUrls: ['./create-customers.component.scss'],
  standalone: true,
  imports: [
    GenericFormComponent,
    ReactiveFormsModule,
    // MatCardModule,
    LoadingSpinnerComponent,
    // CustomerCheckinFormComponent,
    MaterialModule,
    // MatRadioModule,
    NgIf,
    NgFor,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCustomersComponent extends BodyanalyticsBaseComponent implements OnInit {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private manageCustomerService: ManageCustomerService,
  ) {
    super();
  }
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }
  clubFields: FormField<any>[] = [
    {
      key: 'gymName',
      label: 'Gym Name',
      type: 'text',
      placeholder: 'Enter gym name',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'gymAddress1',
      label: 'Address',
      type: 'text',
      placeholder: 'Enter gym address',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'city',
      label: 'City',
      type: 'text',
      placeholder: 'Enter city',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'state',
      label: 'State',
      type: 'select',
      options: [
        { value: 'CA', label: 'California' },
        { value: 'TX', label: 'Texas' },
        // ...
      ],
      validation: [Validators.required],
    },
    {
      key: 'zipCode',
      label: 'Zip code',
      type: 'text',
      placeholder: 'Enter zip code',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'region',
      label: 'Region',
      type: 'select',
      options: [
        { value: 'DAL', label: 'Dallas' },
        { value: 'HOU', label: 'Houston' },
        { value: 'SAN', label: 'San Antonio' },
        // ...
      ],
      validation: [Validators.required],
    },
    {
      key: 'logo',
      label: 'logo',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'signupUrl', //http://bodyanalyticstx.com/GymEvents/Beast-Body
      label: 'Signup URL',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    // { key: 'email', label: 'Email', type: 'email', validation: [Validators.required, Validators.email] },
    // {
    // key: 'gender',
    // label: 'Gender',
    // type: 'radio',
    // options: [
    // { value: 'male', label: 'Male' },
    // { value: 'female', label: 'Female' },
    // { value: 'other', label: 'Other' },
    // ],
    // },
    // ... other fields
  ];
  customerFields: FormField<any>[] = [
    {
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Enter your name',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: 'Enter phone number',
      tooltip: 'Your name is important',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    { key: 'contactEmail', label: 'Email Address', type: 'email', validation: [Validators.required, Validators.email] },
    { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
    // ... other fields
  ];

  onFormSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.manageCustomerService
      .addCustomer(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
