<!-- <div *ngIf="isEditEnabled; else infoTile"> -->
<form [formGroup]="signupForm" (ngSubmit)="formSubmit()">
  <div class="row">
    <div class="col-md-12">
      <p class="text-muted">
        Please fill out the form below with accurate and up-to-date information. This information
        essential for getting the most accurate report.
        </p>
      <!-- <h2 class="h6 text-primary mb-2">Personal Information</h2> -->
      <div
        class="overflow-hidden card my-2 border-0"
        style="min-width: 15rem"
        *ngFor="let services of mobileTestingService; let i = index">
        <div class="position-relative card-body p-0">
          <div class="row">
            <div class="col-sm-6 mb-2">
              <div class="mb-3 position-relative">
                <label for="firstName" class="form-label fs-base">First name</label>
                <input
                  type="text"
                  for="firstName"
                  name="firstName"
                  [ngClass]="{ 'is-invalid': formsubmit && form.firstName.errors }"
                  class="form-control"
                  placeholder="John"
                  required
                  maxlength="20"
                  formControlName="firstName"
                  #firstName />
                <div *ngIf="formsubmit && form.firstName.errors" class="invalid-tooltip">
                  <span *ngIf="form.firstName.errors['required']">First name is required.</span>
                  <span *ngIf="form.firstName.errors['pattern']">Please enter a valid first name.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-6 mb-2">
              <div class="mb-3 position-relative">
                <label for="lastName" class="form-label fs-base">Last name</label>
                <input
                  type="text"
                  for="lastName"
                  name="lastName"
                  [ngClass]="{ 'is-invalid': formsubmit && form.lastName.errors }"
                  class="form-control"
                  placeholder="Doe"
                  maxlength="20"
                  required
                  formControlName="lastName"
                  #lastName />
                <div *ngIf="formsubmit && form.lastName.errors" class="invalid-tooltip">
                  <span *ngIf="form.lastName.errors['required']">Last name is required.</span>
                  <span *ngIf="form.lastName.errors['pattern']">Please enter a valid last name.</span>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-2">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="emailAddress" class="form-label fs-base">Email address</label>
                    <input
                      type="email"
                      id="emailAddress"
                      [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
                      class="form-control"
                      placeholder="john@mail.com"
                      maxlength="50"
                      formControlName="emailAddress"
                      #emailAddress />
                    <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
                      <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
                      <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="phoneNumber" class="form-label fs-base">Phone number</label>
                    <input
                      type="text"
                      id="phoneNumber"
                      [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
                      class="form-control"
                      placeholder="(555)-555-5555"
                      maxlength="14"
                      abxPhoneMask
                      formControlName="phoneNumber"
                      #phoneNumber />

                    <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
                      <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
                      <span *ngIf="form.phoneNumber.errors['maxlength']">Phone number must be less than 14 characters.</span>
                      <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-2">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3 position-relative">
                    <label for="dob" class="form-label fs-base">Date of Birth</label>
                    <input
                      type="date"
                      id="dob"
                      class="form-control"
                      [ngClass]="{ 'is-invalid': formsubmit && form.dob.errors }"
                      formControlName="dob"
                      #dob
                      autocomplete="off"
                      placeholder="mm/dd/yyyy" />
                    <div *ngIf="formsubmit && form.dob.errors" class="invalid-tooltip">
                      <span *ngIf="form.dob.errors['required']">Date of birth is required.</span>
                      <span *ngIf="form.dob.errors['minimumAge']">You must be at least 15 years old.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="sexDropdown" class="form-label fs-base">Sex</label>
                    <select
                      id="sexDropdown"
                      class="form-control form-select"
                      name="sex"
                      formControlName="sex"
                      [ngClass]="{ 'is-invalid': formsubmit && form.sex.errors }">
                      <option value="null">Please Select</option>
                      <option *ngFor="let sex of sexList" [value]="sex.name">
                        {{ sex.name }}
                      </option>
                    </select>
                    <div *ngIf="formsubmit && form.sex.errors" class="invalid-tooltip">
                      <span *ngIf="form.sex.errors['required']">Sex is required.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="mb-3 position-relative">
                    <label for="race" class="form-label fs-base">Race</label>
                    <select
                      id="raceDropdown"
                      class="form-control form-select"
                      name="race"
                      formControlName="race"
                      [ngClass]="{ 'is-invalid': formsubmit && form.race.errors }">
                      <option value="null">Please Select</option>
                      <option *ngFor="let race of raceList" [value]="race.name">
                        {{ race.name }}
                      </option>
                    </select>
                    <div *ngIf="formsubmit && form.race.errors" class="invalid-tooltip">
                      <span *ngIf="form.race.errors['required']">Race is required.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 my-2">
              <div class="row">
                <div class="col">
                  <h3 class="h6 fw-bold">Billing Address</h3>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-2">
                  <div class="position-relative">
                    <label for="address1" class="form-label fs-base">Address</label>
                    <input
                      type="text"
                      id="address1"
                      [ngClass]="{ 'is-invalid': formsubmit && form.address1.errors }"
                      class="form-control"
                      maxlength="50"
                      formControlName="address1"
                      #address1 />
                    <div *ngIf="formsubmit && form.address1.errors" class="invalid-tooltip">
                      <span *ngIf="form.address1.errors['required']">Address is required.</span>
                      <!-- <span *ngIf="form.address1.errors['pattern']">This value should be a valid email.</span> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="city" class="form-label fs-base">City</label>
                    <input
                      type="text"
                      for="city"
                      [ngClass]="{ 'is-invalid': formsubmit && form.city.errors }"
                      class="form-control"
                      maxlength="20"
                      formControlName="city"
                      #city />
                    <div *ngIf="formsubmit && form.city.errors" class="invalid-tooltip">
                      <span *ngIf="form.city.errors['required']">This field is required.</span>
                      <span *ngIf="form.city.errors['pattern']">Please enter a valid city.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="stateDropdown" class="form-label fs-base">State</label>
                    <select
                      id="stateDropdown"
                      class="form-control form-select"
                      name="state"
                      formControlName="state"
                      [ngClass]="{ 'is-invalid': formsubmit && form.state.errors }">
                      <option value="null">Please Select</option>
                      <option *ngFor="let state of stateList" [value]="state.stateCode">
                        {{ state.stateName }}
                      </option>
                    </select>
                    <div *ngIf="formsubmit && form.state.errors" class="invalid-tooltip">
                      <span *ngIf="form.state.errors['required']">State is required.</span>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <div class="mb-3 position-relative">
                    <label for="zipCode" class="form-label fs-base">Zipcode</label>
                    <input
                      type="text"
                      id="zipCode"
                      class="form-control"
                      formControlName="zipCode"
                      #zipCode
                      maxlength="10"
                      [ngClass]="{ 'is-invalid': formsubmit && form.zipCode.errors }" />
                    <div *ngIf="formsubmit && form.zipCode.errors" class="invalid-tooltip">
                      <span *ngIf="form.zipCode.errors['required']">Zipcode is required.</span>
                      <span *ngIf="form.zipCode.errors['pattern']">Please enter a valid zip code.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 my-2">
              <div class="row">
                <div class="col">
                  <h3 class="h6 fw-bold">Reminders</h3>
                  <p class="text-muted">
                    Please select at least one reminder option.
                    </p>
                </div>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label" for="isRemindByEmail">
                  <input
                    type="checkbox"
                    formControlName="isRemindByEmail"
                    class="form-check-input p-2"
                    id="isRemindByEmail" />
                  Remind by Email
                </label>
                <div *ngIf="form.isRemindByEmail.errors && form.isRemindByEmail.touched">
                  <div *ngIf="form.isRemindByEmail.errors['required']" class="text-danger">This field is required.</div>
                  <div *ngIf="form.isRemindByEmail.errors['requiredTrue']" class="text-danger">
                    You must agree to be reminded by email.
                  </div>
                </div>
              </div>

              <div class="form-check form-check-inline">
                <label class="form-check-label" for="isRemindBySms">
                  <input
                    type="checkbox"
                    formControlName="isRemindBySms"
                    class="form-check-input p-2"
                    id="isRemindBySms" />
                  Remind by SMS
                </label>
                <div *ngIf="form.isRemindBySms.errors && form.isRemindBySms.touched">
                  <div *ngIf="form.isRemindBySms.errors['required']" class="text-danger">This field is required.</div>
                  <div *ngIf="form.isRemindBySms.errors['requiredTrue']" class="text-danger">
                    You must agree to be reminded by SMS.
                  </div>
                </div>
              </div>
              <div
              *ngIf="formsubmit && signupForm.errors?.['atLeastOneRequired']"
              class="invalid-feedback d-block text-danger">
              You must select at least one reminder option.
            </div>
            </div>
            <!-- </div> -->

            <div class="col-12 my-2 text-center">
              <div class="d-grid mb-5 col-4 mx-auto">
                <button type="submit" class="btn btn-primary" (click)="updateSignupDetails()">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
