<section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <mat-horizontal-stepper [linear]="false" #stepper>
          <mat-step [completed]="completedSteps.includes(1)">
            <ng-template matStepLabel>Event Details</ng-template>

            <bodyanalytics-event-create-form
              *ngIf="!loadingSession"
              (submitFormEvent)="createTestingEvent($event)"></bodyanalytics-event-create-form>
            <bodyanalytics-loading-spinner *ngIf="loadingSession"></bodyanalytics-loading-spinner>
          </mat-step>

          <mat-step [completed]="completedSteps.includes(2)">
            <ng-template matStepLabel>Review & Publish</ng-template>
            <div *ngIf="createdEventDetails">
              <h5>Event Details</h5>
              <pre>{{ createdEventDetails | json }}</pre>
            </div>

            <!-- <button mat-raised-button (click)="resetStepper()">Preview Event</button> -->
            <button type="button" class="btn btn-primary" (click)="resetStepper()">Complete</button>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</section>
