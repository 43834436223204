
<div class="my-3 event-information" *ngIf="item">
  <h3 class="mb-3">Event information</h3>
  <!-- address -->
  <!--card-->
  <div class="card mb-4" >
    <!--card body-->
    <div class="card-body p-4">
      <div class="mb-4">
        <i class="bx bxs-map icon-shape icon-lg bg-info-subtle rounded-circle text-secondary"></i>
      </div>

      <h4 class="h5">Address</h4>
      <span class="fw-bold"> {{ item.testEventAtClub.clubName }}</span> <br />
      <p class="mb-0 mt-0">
        {{ item.testEventAtClub.address1 }} <br />
        {{ item.testEventAtClub.city }}, {{ item.testEventAtClub.state }}
        {{ item.testEventAtClub.zipCode }}
      </p>
    </div>
  </div>
</div>
