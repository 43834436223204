<section id="manage-bodymeasurements-complete" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-8 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <!-- <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            > -->

            <h4 class="h5">Event Creation Complete!</h4>

            <div class="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
              <p>
                <!-- <button
                  type="button"
                  class="btn btn-link me-md-2 fw-bold text-decoration-none"
                  (click)="navigateToReview()"
                  routerLinkActive="router-link-active">
                  Review results
                </button> -->
              </p>
              <p>
                <!-- <button type="button" class="btn btn-outline-primary" (click)="sendReport()">Send report</button> -->
              </p>
            </div>
          </div>
          <!-- <div *ngIf="bodyMeasurementData">
            <bodyanalytics-manage-bodymeasurements-form
              [item]="bodyMeasurementData"
              (submitFormEvent)="processBodyMeasurementUpdate($event)"></bodyanalytics-manage-bodymeasurements-form>
          </div> -->
        </div>
      </div>
      <div class="col-md-4">
        <div class="list-group">
          <!-- <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-light"
            (click)="printReport()">
            <i class="bx bx-printer"></i> Print Report
          </button>-->
          <!-- <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-light"
            (click)="sendEmail()">
            <i class="bx bx-envelope"></i> Email
          </button> -->
          <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-light"
            (click)="navigateToDashboard()">
            <i class="bx bxs-dashboard"></i>Admin Dashboard
          </button>
          <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-light"
            (click)="navigateToCustomerDashboard()">
            <i class="bx bxs-dashboard"></i>Customer Dashboard
          </button>
          <!--  <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-light"
            (click)="updateBodyMeasurement()">
            <i class="bx bxs-save"></i> Save
          </button>

          <button
            type="button"
            class="list-group-item list-group-item-action list-group-item-danger"
            (click)="deleteMeasurement()">
            <i class="bx bxs-message-rounded-x"></i> Delete
          </button> -->
        </div>
      </div>
    </div>
  </div>
</section>
