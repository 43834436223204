import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  protected auth = inject(AuthService);
}
