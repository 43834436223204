<form [formGroup]="notificationsForm" fxLayout="column" fxLayoutAlign="center none" (ngSubmit)="formSubmit()">
  <div class="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
    <h1 class="h2 pt-xl-1 pb-3">Notifications</h1>
  </div>
  <div class="row align-items-end pb-3 mb-2 mb-sm-4">
    <div class="col-lg-6 col-sm-7">
      <div class="mb-3 position-relative">
        <label for="emailAddress" class="form-label fs-base">Preferred email address</label>
        <input
          type="email"
          id="emailAddress"
          [ngClass]="{ 'is-invalid': formsubmit && form.emailAddress.errors }"
          class="form-control form-control-lg"
          placeholder="john@mail.com"
          maxlength="50"
          formControlName="emailAddress"
          #emailAddress />
        <div *ngIf="formsubmit && form.emailAddress.errors" class="invalid-tooltip">
          <span *ngIf="form.emailAddress.errors['required']">Email address is required.</span>
          <span *ngIf="form.emailAddress.errors['pattern']">This value should be a valid email.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6 mb-4">
    <div class="mb-3 position-relative">
      <label for="phoneNumber" class="form-label fs-base">Preferred Phone number</label>
      <input
        type="tel"
        id="phoneNumber"
        [ngClass]="{ 'is-invalid': formsubmit && form.phoneNumber.errors }"
        class="form-control form-control-lg"
        placeholder="(555)-555-5555"
        maxlength="11"
        formControlName="phoneNumber"
        #phoneNumber />

      <div *ngIf="formsubmit && form.phoneNumber.errors" class="invalid-tooltip">
        <span *ngIf="form.phoneNumber.errors['required']">Phone number is required.</span>
        <span *ngIf="form.phoneNumber.errors['pattern']">This value should be a valid phone number.</span>
      </div>
    </div>
  </div>
  <div class="row align-items-end pb-3 mb-2 mb-sm-4">
    <div class="col-lg-6 col-sm-7">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="isEmailOptInCheckbox" name="isEmailOptInCheckbox" />
        <label class="form-check-label" for="isEmailOptInCheckbox">Opt out fo marketing?</label>
      </div>
    </div>
  </div>

  <div class="d-flex pt-3 mb-3">
    <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="updateSettings()">Save changes</button>
  </div>
</form>
