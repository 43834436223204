import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
 import { tap, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { ManageRegionService } from '@bodyanalytics/front/admin/manage-regions/domain';

@Component({
  selector: 'bodyanalytics-create-discount',
  templateUrl: './create-discount.component.html',
  styleUrls: ['./create-discount.component.scss'],
})
export class CreateDiscountComponent extends BodyanalyticsBaseComponent implements OnInit {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private manageRegionService: ManageRegionService,
  ) {
    super();
  }
  ngOnInit(): void {
    console.log('work needed. see notes');
  }
  discountFields: FormField<any>[] = [
    {
      key: 'discountEmailAddress',
      label: 'Email Address',
      type: 'email',
      placeholder: 'Enter email address for discount',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'discountCode',
      label: 'Discount Code',
      type: 'text',
      placeholder: 'Enter code for discount',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'discountAmt',
      label: 'Discount Amount',
      type: 'number',
      placeholder: 'Enter amount for discount',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'discountType',
      label: 'Discount Type',
      type: 'select',
      options: [
        { value: 'Test', label: 'Test' },
        { value: 'OneTime', label: 'One Time' },
        { value: 'MealPlan', label: 'Meal Plan' },
      ],
      validation: [Validators.required],
    },
    {
      key: 'assignDiscountToEvent',
      label: 'Events',
      type: 'select',
      options: [
        // { value: 'Test', label: 'Test' },
        // { value: 'OneTime', label: 'One Time' },
        // { value: 'MealPlan', label: 'Meal Plan' },
      ],
      validation: [],
    },
    {
      key: 'isDiscountForCustomer',
      label: 'Customer Discount?',
      type: 'select',
      options: [
        { value: 'True', label: 'Yes' },
        { value: 'False', label: 'No' },
      ],
      validation: [Validators.required],
    },
    // {
    //   key: 'assignDiscountToEvent',
    //   label: 'Event (optional)',
    //   type: 'email',
    //   placeholder: 'Enter email address for discount',
    //   tooltip: '',
    //   tooltipPosition: 'right',
    //   validation: [Validators.required],
    // },
    // { key: 'email', label: 'Email', type: 'email', validation: [Validators.required, Validators.email] },

    { key: 'expiredate', label: 'Expiration Date', type: 'date', validation: [Validators.required] },
    { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
    // ... other fields
  ];

  onFormSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.manageRegionService
      .addManageRegion(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
