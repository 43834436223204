<section class="container pt-2">
  <div class="row">
    <div class="col-lg-3 col-md-4 border-end pb-5 mt-n5">
      <bodyanalytics-profile-sidebar></bodyanalytics-profile-sidebar>
    </div>

    <!-- Account details -->
    <div class="col-md-8 offset-lg-1 pb-5 mb-2 mb-lg-4 pt-md-5 mt-n3 mt-md-0">
      <router-outlet> </router-outlet>
    </div>
  </div>
</section>
