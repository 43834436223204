import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentI } from '@bodyanalytics/data-models-ui';
import { ManagePaymentsStore } from '@bodyanalytics/front/admin/manage-payments/domain';
import { ManagePaymentsTableComponent } from '@bodyanalytics/front/admin/manage-payments/ui';

@Component({
  selector: 'bodyanalytics-manage-payments-dashboard',
  templateUrl: './manage-payments-dashboard.component.html',
  styleUrls: ['./manage-payments-dashboard.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ManagePaymentsTableComponent],
})
export class ManagePaymentsDashboardComponent {
  entities = inject(ManagePaymentsStore).entities;
}

// extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   public paymentsData: PaymentI[] = [];
//   constructor(private router: Router, private paymentService: PaymentService) {
//     super();
//   }
//   ngOnInit(): void {
//     this._fetchData();
//   }

//   private _fetchData() {
//     this.subscriptions.barrel = this.paymentService.getPayments().subscribe((results: PaymentI[]) => {
//       this.paymentsData = results;
//     });
//   }
// }
