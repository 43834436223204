import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { RegionsI } from '@bodyanalytics/data-models-ui';
import { RouterLink } from '@angular/router';
import { DatePipe, JsonPipe } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'bodyanalytics-manage-regions-table',
  templateUrl: './manage-regions-table.component.html',
  styleUrls: ['./manage-regions-table.component.scss'],
  standalone: true,
  // animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe],
})
export class ManageRegionsTableComponent extends BodyanalyticsBaseComponent {
  protected regionDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_DETAILS;

  protected displayedColumns = ['regionName', 'regionTerritoryType'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: RegionsI;

  entities = input.required<RegionsI[]>();
}
// extends BodyanalyticsBaseComponent implements AfterViewInit {
//   @Input()
//   item!: RegionsI[];

//   submitted = false;
//   checked = '';
//   dataSource: MatTableDataSource<RegionsI> = new MatTableDataSource<RegionsI>();

//   public displayedColumns: string[] = ['id', 'regionName', 'regionDescription', 'regionStatus'];
//   public regionsForm!: FormGroup;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;
//   isRegionTableEnabled = true;

//   isRegionIdSelected = '';

//   constructor(private manageRegionService: ManageRegionService, private fb: FormBuilder, private router: Router) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource = new MatTableDataSource(this.item);
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_DETAILS}/${target.value}`);
//     }
//   }

//   deleteRegion() {
//     this.subscriptions.barrel = this.manageRegionService
//       .deleteManageRegion(this.isRegionIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }

//   public createRegion(signupDetails: any): void {
//     this.subscriptions.barrel = this.manageRegionService
//       .addManageRegion(signupDetails)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
