import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ClubI,
  CreateTestEventI,
  OperatorI,
  ProductI,
  ProductServiceI,
  ProductsResponseI,
  RegionsI,
  StateI,
  TestingEventI,
  TruckI,
} from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedLogService, SharedErrorService } from '@bodyanalytics/ui-core';
import { TruckStatus } from '@prisma/client';
import { Observable, catchError, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminSetupService {
  private clubUrl = `${ApiLocalRoutesEnum.API_SUFFIX_CLUBS}`;
  private operatorsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_OPERATORS}`;
  private productUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PRODUCTS}`;
  private regionUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}`;
  private statesUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}/us-states`;
  private migrationUrl = `${ApiLocalRoutesEnum.API_SUFFIX_MIGRATION}`;

  private servicesUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PRODUCTS_SERVICES}`;
  private truckUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TRUCKS}`;

  private paymentTypeUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS}/payment-types`;

  private truckDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TRUCKS_DETAILS}`;
  private testingEventsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS}`;
  private truckStatusUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TRUCKS_STATUS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private httpClient: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}


  getStatesList(): Observable<StateI[]> {
    const url = `${this.appConfig.api}/${this.statesUrl}`;
    return this.httpClient.get<StateI[]>(url);
  }
  getTruckStatus(): Observable<TruckStatus[]> {
    const url = `${this.appConfig.api}/${this.truckStatusUrl}`;
    return this.httpClient.get<TruckStatus[]>(url);
  }
  getClubs(): Observable<ClubI[]> {
    const url = `${this.appConfig.api}/${this.clubUrl}`;
    return this.httpClient.get<ClubI[]>(url);
  }
  getRegions(): Observable<RegionsI[]> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;
    return this.httpClient.get<RegionsI[]>(url);
  }

  getStates(): Observable<StateI[]> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;
    return this.httpClient.get<StateI[]>(url);
  }

  getEvents(): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventsUrl}`;
    return this.httpClient.get<TestingEventI[]>(url);
  }
  getOperators(): Observable<OperatorI[]> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}`;
    return this.httpClient.get<OperatorI[]>(url);
  }
  getProducts(): Observable<ProductI[]> {
    const url = `${this.appConfig.api}/${this.productUrl}`;
    return this.httpClient.get<ProductI[]>(url);
  }

  addProduct(product: ProductI): Observable<ProductI> {
    const url = `${this.appConfig.api}/${this.productUrl}`;
    return this.httpClient.post<ProductI>(url, product, this.httpOptions).pipe(
      tap((newProduct: ProductI) => this.sharedLogService.log(`added product w/ id=${newProduct.id}`)),
      catchError(this.sharedErrorService.handleError<ProductI>('addProduct')),
    );
  }

  addEvent(testingEvent: CreateTestEventI): Observable<CreateTestEventI> {
    const url = `${this.appConfig.api}/${this.testingEventsUrl}`;
    return this.httpClient.post<CreateTestEventI>(url, testingEvent, this.httpOptions).pipe(
      tap((newTestingEvent: CreateTestEventI) =>
        this.sharedLogService.log(`added createTestingEvent w/ id=${newTestingEvent.id}`),
      ),
      catchError(this.sharedErrorService.handleError<CreateTestEventI>('createTestingEvent')),
    );
  }
  addTruck(truck: TruckI): Observable<TruckI> {
    const url = `${this.appConfig.api}/${this.truckUrl}`;
    return this.httpClient.post<TruckI>(url, truck, this.httpOptions).pipe(
      tap((newClub: TruckI) => this.sharedLogService.log(`added truck w/ id=${newClub.id}`)),
      catchError(this.sharedErrorService.handleError<TruckI>('addTruck')),
    );
  }
//TODO: make into truck status I
  addTruckStatus(truckStatus: string): Observable<string> {
    const url = `${this.appConfig.api}/${this.truckUrl}/truck-status`;
    return this.httpClient.post<string>(url, truckStatus, this.httpOptions).pipe(
      tap((truckStatus: string) => this.sharedLogService.log(`added truck status w/ id=${truckStatus}`)),
      catchError(this.sharedErrorService.handleError<string>('addTruckStatus')),
    );
  }

  addMigtationData(migrationStatus: string): Observable<string> {
    const url = `${this.appConfig.api}/${this.migrationUrl}`;
    return this.httpClient.post<string>(url, migrationStatus, this.httpOptions).pipe(
      tap((migrationStatus: string) => this.sharedLogService.log(`added migrationStatus w/ id=${migrationStatus}`)),
      catchError(this.sharedErrorService.handleError<string>('migrationStatus')),
    );
  }

  //TODO: make into payment type I

  addPaymentType(paymentType: string): Observable<string> {
    const url = `${this.appConfig.api}/${this.paymentTypeUrl}`;
    return this.httpClient.post<string>(url, paymentType, this.httpOptions).pipe(
      tap((paymentType: string) => this.sharedLogService.log(`added payment type w/ id=${paymentType}`)),
      catchError(this.sharedErrorService.handleError<string>('addPaymentType')),
    );
  }
  addProductService(truck: ProductServiceI): Observable<ProductServiceI> {
    const url = `${this.appConfig.api}/${this.servicesUrl}`;
    return this.httpClient.post<ProductServiceI>(url, truck, this.httpOptions).pipe(
      tap((productService: ProductServiceI) =>
        this.sharedLogService.log(`added productService w/ id=${productService.id}`),
      ),
      catchError(this.sharedErrorService.handleError<ProductServiceI>('add productService')),
    );
  }

  addOperator(operator: OperatorI): Observable<OperatorI> {
    const url = `${this.appConfig.api}/${this.operatorsUrl}`;

    return this.httpClient.post<OperatorI>(url, operator, this.httpOptions).pipe(
      tap((newOperatorI: OperatorI) => this.sharedLogService.log(`added operator w/ id=${newOperatorI.id}`)),
      catchError(this.sharedErrorService.handleError<OperatorI>('addOperator')),
    );
  }
  addClub(club: ClubI): Observable<ClubI> {
    const url = `${this.appConfig.api}/${this.clubUrl}`;
    return this.httpClient.post<ClubI>(url, club, this.httpOptions).pipe(
      tap((newClub: ClubI) => this.sharedLogService.log(`added club w/ id=${newClub.id}`)),
      catchError(this.sharedErrorService.handleError<ClubI>('addClub')),
    );
  }

  addManageRegion(region: RegionsI): Observable<RegionsI> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;
    return this.httpClient.post<RegionsI>(url, region, this.httpOptions).pipe(
      tap((newManageRegions: RegionsI) => this.sharedLogService.log(`added region w/ id=${newManageRegions.id}`)),
      catchError(this.sharedErrorService.handleError<RegionsI>('addManageRegions')),
    );
  }
}
