import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { ManageOperatorsComponent } from './containers/manage-operators/manage-operators.component';
import { ManageOperatorsDashboardComponent } from './containers/manage-operators-dashboard/manage-operators-dashboard.component';
import { ManageOperatorsDetailsComponent } from './containers/operators-details/operators-details.component';
import { CreateOperatorComponent } from './containers/create-operator/create-operator.component';

export const manageOperatorsUiRoutes: Route[] = [
  {
    path: 'auth/manage-operators',
    component: ManageOperatorsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-operator',
        component: CreateOperatorComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: ManageOperatorsDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ManageOperatorsDetailsComponent,
      },

      // {
      //   path: 'register',
      //   component: TestingOperatorsRegisterComponent,
      // },

      // {
      //   path: 'delete/:id',
      //   component: TestingEventDetailComponent,
      // },
      // {
      //   path: 'edit/:id',
      //   component: TestingEventDetailComponent,
      // },
    ],
  },
  // { path: 'auth/manage-events', component: ManageOperatorsComponent },
  // { path: 'auth/select-truck', component: SelectTruckComponent },
  // { path: 'auth/select-location', component: SelectLocationComponent },
  // { path: 'auth/customer-checkin', component: CustomerCheckinComponent },
  // { path: 'auth/customer-testing', component: CustomerTestingComponent },
];
