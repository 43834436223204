import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { CreateClubComponent } from './create-club/create-club.component';
import { ManageClubsComponent } from './manage-clubs/manage-clubs.component';
import { ManageClubsDashboardComponent } from './dashboard/dashboard.component';
import { ManageClubsDetailComponent } from './club-details/club-details.component';
import { ManageClubService, provideClubs } from '@bodyanalytics/front/admin/manage-clubs/domain';


export const manageClubsUiRoutes: Route =
  {
    path: 'auth/manage-clubs',
    component: ManageClubsComponent,
    canActivate: [AuthGuard],
    providers: [provideClubs(), ManageClubService],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-club',

        component: CreateClubComponent,

        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: ManageClubsDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ManageClubsDetailComponent,
      },
    ],
  };

