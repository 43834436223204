<section id="manage-bookings-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-6 bg-white p-2">
        <h4 class="h5">Create Booking</h4>
        <bodyanalytics-generic-form
          [fields]="clubFields"
          (formSubmitted)="onFormSubmitted($event)"></bodyanalytics-generic-form>
      </div>
    </div>
  </div>
</section>
