import { Component, input, ChangeDetectionStrategy, Input, inject, output } from '@angular/core';
import { TestingEventI, OperatorI, TruckI, ClubI } from '@bodyanalytics/data-models-ui';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { outputFromObservable } from '@angular/core/rxjs-interop';
import { FormValue } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-setup-form',
  standalone: true,
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss'],
  imports: [ReactiveFormsModule],
})
export class SetupFormComponent {
  #fb = inject(NonNullableFormBuilder);
  protected form = this.#fb.group({
    operator: ['', [Validators.required]],
    truck: ['', [Validators.required]],
    club: [''],
    event: ['', [Validators.required]],
    waterTemperature: [1, [Validators.required]],
  });

  // Sometimes decorators are more useful than signal inputs
  @Input()
  set value(value: FormValue) {
    this.form.patchValue(value);
  }

  events = input.required<TestingEventI[]>();
  operators = input.required<OperatorI[]>();
  clubs = input.required<ClubI[]>();
  trucks = input.required<TruckI[]>();
  formChange = outputFromObservable(this.form.valueChanges);
  formSubmit = output<FormValue>();

  submit() {
    this.formSubmit.emit(this.form.value);
  }
}
