import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PaymentI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private paymentUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS}`;
  private paymentDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PAYMENTS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getPayment(id: string): Observable<any> {
    const url = `${this.appConfig.api}/${this.paymentDetailsUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched payment id=${id}`)),
      catchError(this.sharedErrorService.handleError<PaymentI>(`getPaymentI id=${id}`)),
    );
  }

  updatePayment(id: string, payment: PaymentI): Observable<any> {
    const url = `${this.paymentDetailsUrl}/${id}`;
    return this.http.patch(url, payment, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated payment id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updatePaymentI')),
    );
  }
  load(): Observable<PaymentI[]> {
    const url = `${this.appConfig.api}/${this.paymentUrl}`;
    return this.http.get<PaymentI[]>(url).pipe(
      tap(_ => console.log('fetched clubs')),
      catchError(this.sharedErrorService.handleError<PaymentI[]>('getClubs', [])),
    );
  }
  deletePayment(id: string): Observable<PaymentI> {
    const url = `${this.paymentUrl}/${id}`;

    return this.http.delete<PaymentI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted payment id=${id}`)),
      catchError(this.sharedErrorService.handleError<PaymentI>('deletePaymentI')),
    );
  }

  getPayments(): Observable<PaymentI[]> {
    return this.http.get<PaymentI[]>(this.paymentUrl).pipe(
      tap(_ => console.log('fetched payment')),
      catchError(this.sharedErrorService.handleError<PaymentI[]>('getPaymentIs', [])),
    );
  }

  addPayment(payment: PaymentI): Observable<PaymentI> {
    return this.http.post<PaymentI>(this.paymentUrl, payment, this.httpOptions).pipe(
      tap((newPaymentI: PaymentI) => this.sharedLogService.log(`added payment w/ id=${newPaymentI.id}`)),
      catchError(this.sharedErrorService.handleError<PaymentI>('addPaymentI')),
    );
  }
  searchPayments(term: string): Observable<PaymentI[]> {
    const url = `${this.paymentUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty payment array.
      return of([]);
    }

    return this.http.get<PaymentI[]>(`${url}`).pipe(
      tap(x =>
        x.length ? console.log(`found payment matching "${term}"`) : console.log(`no payment matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<PaymentI[]>('searchPaymentIs', [])),
    );
  }
}
