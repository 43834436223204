<div class="container">
  <div class="py-5">
    <div class="row">
      <div class="col-lg-10 col-md-12 col-12 mb-3 mb-lg-0 event-page">
        <div class="event-header">
          <h1 class="display-6 fw-bold text-dark">Rescheduling verification</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container p-2">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <h5 class="py-2">Confirm your updated event details</h5>
        <div class="col-md-6">
          <div class="current-booking-details border bg-warning p-2 text-dark bg-opacity-10" *ngIf="bookingData">
            <h5>Existing booking:</h5>
            <p>
              <strong>Event:</strong>
              {{
                bookingData.event.testEventName
                  ? bookingData.event.testEventName
                  : bookingData.event.testEventAtClub.clubName
              }}
            </p>
            <p>
              <strong>Date & Time:</strong> {{ bookingData.event.testEventDate | date : 'fullDate' }} at
              {{existingSlotTime}}
            </p>
            <p>
              <strong>Location:</strong><br />
              {{ bookingData.event.testEventAtClub.clubName }} <br />
              {{ bookingData.event.testEventAtClub.address1 }} <br />
              <ng-container *ngIf="bookingData.event.testEventAtClub.address2">
                {{ bookingData.event.testEventAtClub.address2 }} <br />
              </ng-container>
              {{ bookingData.event.testEventAtClub.city }}, {{ bookingData.event.testEventAtClub.state }}
              {{ bookingData.event.testEventAtClub.zipCode }}
            </p>
          </div>
        </div>

        <div class="col-md-6">
          <div class="current-booking-details border bg-info p-2 text-dark bg-opacity-10" *ngIf="eventData">
            <h5>New booking:</h5>
            <p>
              <strong>Event:</strong>
              {{ eventData.testEventName ? eventData.testEventName : eventData.testEventAtClub.clubName }}
            </p>
            <p>
              <strong>Date & Time:</strong> {{ eventData.testEventDate | date : 'fullDate' }} at
              {{ newTimeSlot   }}
            </p>

            <p>
              <strong>Location:</strong><br />
              {{ eventData.testEventAtClub.clubName }} <br />
              {{ eventData.testEventAtClub.address1 }} <br />
              <ng-container *ngIf="eventData.testEventAtClub.address2">
                {{eventData.testEventAtClub.address2 }} <br />
              </ng-container>
              {{eventData.testEventAtClub.city }}, {{eventData.testEventAtClub.state }}
              {{ eventData.testEventAtClub.zipCode }}
            </p>
          </div>

        </div>
      </div>
      <div class="py-5">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-4 mb-lg-3">
          <button type="submit" class="btn btn-link" (click)="routeToSearch()">Cancel</button>

          <button type="submit" class="btn btn-primary" (click)="processReschedule()">Confirm Rescheduling</button>
        </div>
      </div>
    </div>
  </div>
</div>
