<section class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-3">
            <h4 class="h4">Test Session</h4>
            <p class="card-title-desc">Enter the following details for test:</p>
          </div>

          <bodyanalytics-customer-testing-form
            (calcChange)="saveSetupTestDetails($event)"
            (completeChange)="complete($event)" />

          @if (isResultsVisible()) {
            <div class="row mt-5 mb-2">
              <div class="col-md-12">
                <h5>Results</h5>
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Fat Lbs</mat-label>
                      <input
                        [(ngModel)]="calculatedResults[0].fatLbs"
                        [ngModelOptions]="{ standalone: true }"
                        matInput
                        readonly
                        type="text" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Fat %</mat-label>
                      <input
                        [(ngModel)]="calculatedResults[0].fatPercent"
                        [ngModelOptions]="{ standalone: true }"
                        matInput
                        readonly
                        type="text" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Water Lbs</mat-label>
                      <input
                        [(ngModel)]="calculatedResults[0].waterLbs!"
                        [ngModelOptions]="{ standalone: true }"
                        matInput
                        readonly
                        type="text" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Lean Lbs</mat-label>
                      <input
                        [(ngModel)]="calculatedResults[0].leanLbs!"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        matInput
                        readonly
                        type="text" />
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Lean %</mat-label>
                      <input
                        [(ngModel)]="calculatedResults[0].leanMassPercent!"
                        [ngModelOptions]="{ standalone: true }"
                        class="form-control"
                        matInput
                        readonly
                        type="text" />
                    </mat-form-field>
                  </div>

                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Resting Metabolic Rate</mat-label>
                      <input
                        matInput
                        type="text"
                        readonly
                        [(ngModel)]="calculatedResults[0].restingMetabolicRate!"
                        [ngModelOptions]="{ standalone: true }" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field>
                      <mat-label>Residual Lung Volume (mL)</mat-label>
                      <input
                        matInput
                        type="text"
                        readonly
                        [(ngModel)]="calculatedResults[0].residualLungValue!"
                        [ngModelOptions]="{ standalone: true }" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
</section>
