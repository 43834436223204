import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountI, OperatorI } from '@bodyanalytics/data-models-ui';
 import { Router, RouterLink } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { ManageDiscountService } from '@bodyanalytics/front/admin/manage-discounts/domain';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'bodyanalytics-manage-discounts-form',
  templateUrl: './manage-discounts-form.component.html',
  styleUrls: ['./manage-discounts-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink],
})
export class ManageDiscountsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Input()
  item!: DiscountI | null;
  public EVENT_ID = '';
  public CLUB_ID = '';

  public operators: OperatorI[] = [];

  public discountDetailsForm = new FormGroup({
    discountCode: new FormControl('', [Validators.required]),
    discountType: new FormControl('', [Validators.required]),
    discountAmount: new FormControl(0, [Validators.required]),
    discountExpireDate: new FormControl('', [Validators.required]),
    discountContactId: new FormControl('', [Validators.required]),
    discountContactName: new FormControl('', [Validators.required]),
    discountContactEmail: new FormControl('', [Validators.required]),
    discountForEventId: new FormControl('', [Validators.required]),
    isCustomerDiscount: new FormControl(false, [Validators.required]),
    discountDescription: new FormControl('', [Validators.required]),
  });

  constructor(private router: Router, private manageDiscountService: ManageDiscountService) {
    super();
  }

  ngOnInit(): void {
    this.getDiscount();
  }

  public getDiscount(): void {
    // if (this.discountDetailsForm.valid) {
    if (this.item) {
      this.discountDetailsForm.patchValue({
        discountCode: this.item?.discountCode,
        discountType: this.item?.discountType,
        discountAmount: this.item?.discountAmount,
        discountExpireDate: this.item?.discountExpireDate,
        discountContactId: this.item?.discountContactId,
        discountContactName: this.item?.discountContactName,
        discountContactEmail: this.item?.discountContactEmail,
        discountForEventId: this.item?.discountForEventId,
        isCustomerDiscount: this.item?.isCustomerDiscount,
        discountDescription: this.item?.discountDescription,
      });
    }
  }

  public updateDiscount(): void {
    this.submitFormEvent.emit({
      discountCode: this.discountDetailsForm.value.discountCode,
      discountType: this.discountDetailsForm.value.discountType,
      discountAmount: this.discountDetailsForm.value.discountAmount,
      discountExpireDate: this.discountDetailsForm.value.discountExpireDate,
      discountContactId: this.discountDetailsForm.value.discountContactId,
      discountContactName: this.discountDetailsForm.value.discountContactName,
      discountContactEmail: this.discountDetailsForm.value.discountContactEmail,
      discountForEventId: this.discountDetailsForm.value.discountForEventId,
      isCustomerDiscount: this.discountDetailsForm.value.isCustomerDiscount,
      discountDescription: this.discountDetailsForm.value.discountDescription,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  protected duplicateEvent() {
    this.subscriptions.barrel = this.manageDiscountService
      .addDiscount(this.item as DiscountI)
      .pipe(
        tap(() => {
          console.log(`duplicateDiscount - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageDiscountService
      .deleteDiscount(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }
  closeModal() {
    console.log('modal');
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-events`);
    // this.modalService.dismissAll();
  }
}
