<section id="manage-bodymeasurements-container" class="border-bottom bg-primary-subtile bg-gradient">
  <div class="container">
    <div class="row align-items-center text-black">
      <div class="col-md-6 intros text-start py-5 px-3">
        <h1 class="display-6 fw-bold text-capitalize my-5 px-2">Body Assesments</h1>
      </div>
      <div class="col-md-6 text-end">
        <button [routerLink]="testsLink" mat-stroked-button><mat-icon>list</mat-icon> Assessments</button>
        <button [routerLink]="setupTestLink" mat-stroked-button>
          <mat-icon>directions_run</mat-icon> Start Assessment
        </button>

        <!-- <button class="btn btn-outline-cancel my-5" (click)="startAssement()" ><i class='bx bx-run' ></i> Find Hydrostatic Assesment</button> -->

        <!-- <button class="btn btn-outline-cancel my-5" (click)="startAssement()" ><i class='bx bx-run' ></i> Cancel Hydrostatic Assesment</button> -->
      </div>
    </div>
  </div>
</section>

<div>
  <router-outlet />
</div>
