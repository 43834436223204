import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ProductDetailsComponent } from './containers/product-details/product-details.component';
import { CreateProductComponent } from './containers/create-product/create-product.component';
import { authPermissionsGuard } from '@bodyanalytics/ui-core';
import { ProductsPermissions } from '../../../../../back/api/products-api/src/lib/products.permissions';

export const manageProductsUiRoutes: Route[] = [
  {
    path: 'auth/manage-products',
    loadComponent: () => import('./containers/manage-products/manage-products.component'),
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-product',
        component: CreateProductComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./containers/manage-products-dashboard/manage-products-dashboard.component'),
        pathMatch: 'full',
        canMatch: [authPermissionsGuard],
        data: { permissions: [ProductsPermissions.READ] },
      },
      {
        path: 'detail/:id',
        component: ProductDetailsComponent,
      },
    ],
  },
];
