import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, delay, tap, throwError } from 'rxjs';
import { BookingI, SlotNI, TestingEventI, TestingEventRequiredProps } from '@bodyanalytics/data-models-ui';
import {
  ApiLocalRoutesEnum,
  SessionStorageService,
  SharedErrorService,
  SharedLogService,
} from '@bodyanalytics/ui-core';
import { ENV } from '@bodyanalytics/app-config';

@Injectable({
  providedIn: 'root',
})
export class MobileTestingCalendarService {
  private testingEventsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS}`;
  private publictestingEventsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_PUB_TEST_EVENTS}/public-events`;

  private testingEventSearchUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_SEARCH}`;
  private testingEventSearchGymUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_SEARCH_GYM}`;
  private bookingUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING_DETAILS}`;
  private signupUrl = `${ApiLocalRoutesEnum.API_SUFFIX_SIGNUP_SEARCH}`;

  latitude = 32.7766642;
  longitude = -96.7969879;

  private reserveUrl = `${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_RESERVE_SLOTS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sessionStorageService: SessionStorageService,
    private sharedErrorService: SharedErrorService,
  ) {}

  public clearRescheduleData() {
    this.sessionStorageService.clearRescheduleBookingData();
  }
  public setRescheduleBookingData(existingBookingData: BookingI | null, existingOrderId: string | null) {
    this.sessionStorageService.saveRescheduleBookingData(existingBookingData, existingOrderId, null, null);
  }
  public isReschedule(): boolean {
    return this.sessionStorageService.getRescheduleFlag();
  }

  public getVerifyRescheduleData() {
    return this.sessionStorageService.getRescheduleBookingData();
  }
  public getVerifySignupData() {
    return this.sessionStorageService.getBookingData();
  }

  public addNewEventToReschedule(newEvent: TestingEventI) {
    if (this.sessionStorageService.getRescheduleBookingData()) {
      const existingBooking = this.sessionStorageService.getRescheduleBookingData()!.existingBooking;
      const newSlot = this.sessionStorageService.getRescheduleBookingData()!.selectedTimeSlot;
      const existingOrderId = this.sessionStorageService.getRescheduleBookingData()!.existingOrderId;

      this.sessionStorageService.saveRescheduleBookingData(existingBooking, existingOrderId, newEvent, newSlot);
    }
  }
  public saveTimeslotRescheduleBookingData(timeSlot: SlotNI) {
    if (this.sessionStorageService.getRescheduleBookingData()) {
      const existingBooking = this.sessionStorageService.getRescheduleBookingData()!.existingBooking;
      const existingOrderId = this.sessionStorageService.getRescheduleBookingData()!.existingOrderId;

      this.sessionStorageService.saveRescheduleBookingData(existingBooking, existingOrderId, null, timeSlot.slotTime  as Date);
    }
  }
  /**
   *saveTimeslotSignupBookingData -- Not for reschedule only for normal checkout
   * @param timeSlot
   */
  public saveTimeslotSignupBookingData(timeSlot: SlotNI) {
    if (this.sessionStorageService.getBookingData()) {
      const existingBooking = this.sessionStorageService.getBookingData()!.selectedTimeSlot;
    }
  }

  public saveRescheduleBookingData(timeSlot: SlotNI, newEvent: TestingEventI) {
    if (this.sessionStorageService.getRescheduleBookingData()) {
      const existingBooking = this.sessionStorageService.getRescheduleBookingData()!.existingBooking;
      const existingOrderId = this.sessionStorageService.getRescheduleBookingData()!.existingOrderId;

      this.sessionStorageService.saveRescheduleBookingData(
        existingBooking,
        existingOrderId,
        newEvent,
        timeSlot.slotTime as Date,
      );
    }
    // console.log("getRescheduleBookingData:" + this.sessionStorageService.getRescheduleBookingData()!.id)
    //  return this.sessionStorageService.getRescheduleBookingData();
  }

  public getBookingById(id: string): Observable<BookingI> {
    const url = `${this.appConfig.api}/${this.bookingUrl}/${id}`;
    return this.http.get<BookingI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched booking id=${id}`)),
      catchError(this.sharedErrorService.handleError<BookingI>(`booking id=${id}`)),
    );
  }
  createTestEventReservation(testEvent: TestingEventRequiredProps): Observable<any> {
    return this.http.post<any>(this.reserveUrl, testEvent, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added testEvent w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('createTestEventReservation')),
    );
  }
  getAllEventsForRegion(regionId: string) {
    return this.http.get(`${this.testingEventsUrl}${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_ADDRESS}`);
  }
  getAllEventsForGym(gymId: string) {
    return this.http.get(`${this.testingEventsUrl}${ApiLocalRoutesEnum.API_SUFFIX_TEST_EVENTS_ADDRESS}`);
  }

  deleteTestEventReservation(id: string): Observable<TestingEventI> {
    const url = `${this.appConfig.api}/${this.reserveUrl}/${id}`;

    return this.http.delete<TestingEventI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted reserveUrl id=${id}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI>('deleteTestEventReservation')),
    );
  }

  updateTestEventReservation(id: string, testEvent: TestingEventI): Observable<any> {
    return this.http.post<any>(this.reserveUrl, testEvent, this.httpOptions).pipe(
      tap((newOrders: any) => this.sharedLogService.log(`added testEvent w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<any>('createTestEventReservation')),
    );
  }

  getTestingEvent(id: string): Observable<TestingEventI> {
    const url = `${this.appConfig.api}/${this.publictestingEventsUrl}${ApiLocalRoutesEnum.API_SUFFIX_DETAILS}${id}`;
    return this.http.get<TestingEventI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched testingEvent id=${id}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI>(`getTestingEvent id=${id}`)),
    );
  }

  getTestingEventsByLocation(long: number, lat: number): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventsUrl}/long/${long}/lat/${lat}`;
    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched testingEvent near long=${long}/lat/${lat}`)),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>(`getTestingEvents long=${long}/lat/${lat}`)),
    );
  }

  getCurrentLocationObservable() {
    // GeolocationCoordinates is global interface
    return new Observable<GeolocationCoordinates>(observer => {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          // Observable will call the Observer’s next(value) method to provide data.
          observer.next(position.coords);
          // observer.complete() marks the observable as done. It means we can no longer emit any more values out of this observable
          observer.complete();
        },
        // observer.error puts observable into an error state. It means we can no longer emit any more values out of this observable
        err => observer.error(err),
      );
    }).pipe(
      // When retry receives the error, it will resubscribe to that observable with the same kind of pipe. In rxjs, all observables are cold and unicast. That means whenever we subscribe to an observable, all the logic inside there is going to be executed right away. When retry operator resubscribes to this observable, that really means, all the source observable code re executes, because we are adding a second subcription to it. It does not somehow just magically get that logic to rerun. it actually resubscribes to that observable.
      // the argument to retry is an integer. `retry(1)` it is the number of times you want to retry your logic or really resubscribe. if you do not provide a number, and then it is going to try to resubscribe an infinite number of times.
      //   retry(1), // OPTIONAL
      // "TAP" generally used for notification or logging system. tap executes if the observable emits value which will be the first arg of tab. if it emits error, second arg of "tab" will run
      // the second argument is a function that gets called any time an error comes out
      // third argument can be a function that will be invoked any time the observable is marked as complete

      tap(
        () => {
          // create a notification service or console.log it
          //  this.notificationsService.addSuccess('Got your location');
        },
        // instead of this, use catchError which returns an observable
        // (err) => {
        //   this.notificationsService.addError('failed to get your location');
        // }
      ),
      // it will be invoked if our source observable emits an error.
      catchError(error => {
        // inside here we always two different things
        // 1st handle the error
        //      this.notificationsService.addError('failed to get your location');
        // #2 return a new observable. that is the requirement of catchError operator
        // why do we return a new observable. that's the reason is why we want to use the catch operator instead of second arg to the tap function.
        // if we cannot fetch anything, we could show a default location
        // or we want to pass the error to the rest of the pipeline.

        return throwError(error);
      }),
    );
  }

  getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.getTestingEventsByLocation(this.longitude, this.latitude);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  getTestingEvents(): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.publictestingEventsUrl}`;

    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      delay(2000),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }
  getTestingEventsBySearchRegion(regionName: string, gymName?: string): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventSearchGymUrl}/${regionName}`;

    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }

  getTestingEventsBySearchQuery(searchTerm?: string): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventSearchGymUrl}/${searchTerm}`;

    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }

  getTestingEventsByGymName(
    gymName: string,
    regionName?: string,
    latitude?: number,
    longitude?: number,
    radius?: number,
    zipCode?: string,
  ): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventSearchGymUrl}/${gymName}`;

    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }

  getNearbyEvents(): Observable<TestingEventI[]> {
    const url = `${this.appConfig.api}/${this.testingEventsUrl}`;

    return this.http.get<TestingEventI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<TestingEventI[]>('getTestingEvents', [])),
    );
  }
}
