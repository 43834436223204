<section id="manage-club-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            >

            <h4 class="h5">Regions Details</h4>
          </div>


          <!-- @if(data(); as data) { -->
            <!-- <bodyanalytics-manage-clubs-form
              [item]="data"
              (submitFormEvent)="processClubUpdate($event)" /> -->
            <!-- } -->
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <mat-horizontal-stepper [linear]="false" #stepper>
          <mat-step>
            <ng-template matStepLabel>Region Details</ng-template>

            <bodyanalytics-generic-form
              [fields]="fields"
              (formSubmitted)="onFormSubmitted($event)"></bodyanalytics-generic-form>
          </mat-step>

        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</section> -->
