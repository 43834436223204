import { Component, input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of, filter, switchMap } from 'rxjs';
 import { Location } from '@angular/common';
import { ManageRegionService } from '@bodyanalytics/front/admin/manage-regions/domain';
import { toObservable } from '@angular/core/rxjs-interop';
import { ManageClubService } from '@bodyanalytics/front/admin/manage-clubs/domain';

@Component({
  selector: 'bodyanalytics-create-region',
  templateUrl: './create-region.component.html',
  styleUrls: ['./create-region.component.scss'],
})
export class CreateRegionComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageRegionService.getManageRegion(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageRegionService: ManageRegionService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processClubUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageRegionService
      .updateManageRegion(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processClubUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}


function toSignal(arg0: any) {
  throw new Error('Function not implemented.');
}
// extends BodyanalyticsBaseComponent implements OnInit {
//   public loadingSession = false;

//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private location: Location,
//     private manageRegionService: ManageRegionService,
//   ) {
//     super();
//   }
//   ngOnInit(): void {
//     // throw new Error('Method not implemented.');
//   }
//   fields: FormField<any>[] = [
//     {
//       key: 'regionName',
//       label: 'Region Name', // Region Group
//       type: 'select',
//       options: [
//         { value: 'NORTH_TEXAS', label: 'North Texas' },
//         { value: 'HOUSTON', label: 'Houston' },
//         { value: 'AUSTIN', label: 'Austin' },
//         { value: 'SAN_ANTONIO', label: 'San Antonio' },

//         { value: 'REGION_NOT_FOUND', label: 'Not on list' },
//       ],
//       validation: [Validators.required],
//     },
//     {
//       key: 'regionStatus',
//       label: 'Region Status', // Region Group
//       type: 'select',
//       options: [
//         { value: 'DECOMMISSIONED', label: 'Deactivated' },
//         { value: 'GOOD', label: 'Good' },
//       ],
//       validation: [Validators.required],
//     },
//     {
//       key: 'regionTerritoryType',
//       label: 'Territory Type', // Region Group
//       type: 'select',
//       options: [{ value: 'ACTIVE', label: 'Active' }],
//       validation: [Validators.required],
//     },

//     {
//       key: 'regionDescription',
//       label: 'Description',
//       type: 'text',
//       placeholder: '',
//       tooltip: '',
//       tooltipPosition: 'right',
//       validation: [Validators.required],
//     },
//     {
//       key: 'regionLogo',
//       label: 'Logo URL',
//       type: 'text',
//       placeholder: '',
//       tooltip: '',
//       tooltipPosition: 'right',
//       validation: [Validators.required],
//     },

//     { key: 'regionActivatedDate', label: 'Activated Date', type: 'date', validation: [Validators.required] },
//     // { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
//   ];

//   onFormSubmitted(booking: any) {
//     this.loadingSession = true;
//     this.subscriptions.barrel = this.manageRegionService
//       .addManageRegion(booking)
//       .pipe(
//         tap(() => {
//           this.loadingSession = false;
//           this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS_DASHBOARD}`);
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
