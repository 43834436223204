import { DatePipe, JsonPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, input, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { OrderI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';

@Component({
  selector: 'bodyanalytics-manage-orders-table',
  templateUrl: './manage-orders-table.component.html',
  styleUrls: ['./manage-orders-table.component.scss'],
  standalone:true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe],
})
export class ManageOrdersTableComponent extends BodyanalyticsBaseComponent{
  protected clubDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_ORDERS_DETAILS;

  protected displayedColumns = [ 'orderDate', 'firstName','city' , 'state' ];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: OrderI;

  entities = input.required<OrderI[]>();
}
// extends BodyanalyticsBaseComponent implements AfterViewInit {
//   @Input()
//   item!: OrderI[];

//   submitted = false;
//   checked = '';
//   dataSource: MatTableDataSource<OrderI> = new MatTableDataSource<OrderI>();

//   public displayedColumns: string[] = ['id', 'firstName'];
//   public ordersForm!: FormGroup;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;
//   isOrderTableEnabled = true;

//   isOrderIdSelected = '';
//   public orderTableData: OrderI[] = [];
//   constructor(
//     private manageOrderService: OrdersService,
//     private fb: FormBuilder,
//     private router: Router,
//   ) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource = new MatTableDataSource(this.item);
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_ORDERS_DETAILS}/${target.value}`);
//     }
//   }

//   openModal(deleteContent: any, id: string) {
//     // const id = this.route.snapshot.paramMap.get('id') as string;
//     // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//     this.isOrderIdSelected = id;
//   }

//   deleteOrder() {
//     this.subscriptions.barrel = this.manageOrderService
//       .deleteOrders(this.isOrderIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */

//   public createOrder(signupDetails: any): void {
//     this.subscriptions.barrel = this.manageOrderService
//       .addOrders(signupDetails)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
