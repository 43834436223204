import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GenderI } from '@bodyanalytics/data-models-ui';
import { genderData } from '@bodyanalytics/ui-core';
@Component({
  selector: 'bodyanalytics-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  readonly genderList: GenderI[] = [];

  formsubmit: boolean = false;
  constructor() {
    this.genderList = genderData;
  }

  public profileForm = new FormGroup({
    gender: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
  });

  public updateProfile(): void {
    this.submitFormEvent.emit({
      gender: this.profileForm.value.gender as string,
      dob: this.profileForm.value.dob as string,
      zipCode: this.profileForm.value.zipCode as string,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.profileForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
