import { Component, input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
 import { filter, switchMap, tap, catchError, of } from 'rxjs';
import { ManageEventsListComponent } from '../../../../ui/src/lib/manage-events-list/manage-events-list.component';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';

@Component({
  selector: 'bodyanalytics-event-list',
  standalone: true,
  imports: [CommonModule, ManageEventsListComponent],
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.scss',
})
export class EventListComponent  extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageTestingEventService.getEventsByClubId(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageTestingEventService: ManageTestingEventService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processBookingUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageTestingEventService
      .updateTestingEvent(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processBookingUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

