<section id="manage-bodymeasurements-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <h4 class="h5">Setup Session</h4>
            <p class="card-title-desc">To get started with testing, please answer the following:</p>
          </div>
          <div class="row">
            <div class="col-md-8">
              @if(loadingSetupSession){
              <bodyanalytics-loading-spinner />
              } @else {
              <bodyanalytics-setup-form
                (formChange)="store.patchReportingForm($event)"
                (formSubmit)="saveSetupTestDetails($event)"
                [value]="formValue"
                [events]="events()"
                [operators]="operators()"
                [clubs]="clubs()"
                [trucks]="trucks()" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
