import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { RouterLink } from '@angular/router';
import { DiscountI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { CurrencyPipe, DatePipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'bodyanalytics-manage-discounts-table',
  templateUrl: './manage-discounts-table.component.html',
  styleUrls: ['./manage-discounts-table.component.scss'],
  standalone: true,
  // animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe, CurrencyPipe, NgIf, NgFor],
})
export class ManageDiscountsTableComponent extends BodyanalyticsBaseComponent {
  protected discountDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_DISCOUNTS_DETAILS;

  protected displayedColumns = ['discountCode', 'discountType', 'discountAmount', 'discountDescription'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: DiscountI;

  entities = input.required<DiscountI[]>();
}

// extends BodyanalyticsBaseComponent implements AfterViewInit {
//   @Input()
//   item!: DiscountI[];

//   submitted = false;
//   checked = '';
//   dataSource: MatTableDataSource<DiscountI> = new MatTableDataSource<DiscountI>();

//   public displayedColumns: string[] = ['id', 'discountCode', 'discountType', 'discountAmount', 'discountExpireDate'];
//   public discountForm!: FormGroup;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;
//   isDiscountTableEnabled = true;

//   isDiscountIdSelected = '';
//   public testingEventTableData: DiscountI[] = [];
//   // public $testingEvent!: Observable<DiscountI[]>;
//   constructor(private manageDiscountService: ManageDiscountService, private fb: FormBuilder, private router: Router) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource = new MatTableDataSource(this.item);
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_DISCOUNTS_DETAILS}/${target.value}`);
//     }
//   }

//   openModal(deleteContent: any, id: string) {
//     // const id = this.route.snapshot.paramMap.get('id') as string;
//     // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//     this.isDiscountIdSelected = id;
//   }

//   deleteDiscount() {
//     this.subscriptions.barrel = this.manageDiscountService
//       .deleteDiscount(this.isDiscountIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */

//   public createDiscount(signupDetails: any): void {
//     this.subscriptions.barrel = this.manageDiscountService
//       .addDiscount(signupDetails)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
