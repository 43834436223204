export * from './lib/booking.service';
export * from './lib/manage-booking.signal-store-feature';
export * from './lib/manage-booking.store';

import { TypeProvider, Provider } from '@angular/core';

import { ManageBookingsStore } from './lib/manage-booking.store';

import { BookingService } from './lib/booking.service';

export const provideBookings = (): Provider[] => [BookingService, ManageBookingsStore];
