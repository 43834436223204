<aside class="" *ngIf="auth.user$ | async as user">
  <div class="position-sticky top-0">
    <div class="text-center pt-5">
      <div class="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
        <img
          [src]="user.picture"
          class="d-block rounded-circle img-fluid profile-picture"
          width="120"
          alt="User's profile picture" />
      </div>
      <div class="">
        <h3 class="h6 mb-1">{{ user.name }}</h3>
        <p class="py-2 fw-light text-muted">{{ user.email }}</p>
      </div>
      <button
        type="button"
        class="btn btn-secondary w-100 d-md-none mt-n2 mb-3"
        data-bs-toggle="collapse"
        data-bs-target="#account-menu">
        <i class="bx bxs-user-detail fs-xl me-2"></i>
        Account menu
        <i class="bx bx-chevron-down fs-lg ms-1"></i>
      </button>
      <div id="account-menu" class="list-group list-group-flush collapse d-md-block">
        <a [routerLink]="'./account'" class="list-group-item list-group-item-action d-flex align-items-center">
          <i class="bx bx-cog fs-xl opacity-60 me-2"></i>
          Account Details
        </a>
        <a [routerLink]="'./details'" class="list-group-item list-group-item-action d-flex align-items-center">
          <i class="bx bx-lock-alt fs-xl opacity-60 me-2"></i>
          Profile
        </a>
        <a [routerLink]="'./security'" class="list-group-item list-group-item-action d-flex align-items-center">
          <i class="bx bx-lock-alt fs-xl opacity-60 me-2"></i>
          Security
        </a>
        <a [routerLink]="'./notifications'" class="list-group-item list-group-item-action d-flex align-items-center">
          <i class="bx bx-bell fs-xl opacity-60 me-2"></i>
          Notifications
        </a>
      </div>
    </div>
  </div>
</aside>
