import { Component, inject, ChangeDetectionStrategy } from '@angular/core';
import { ManageBodymeasurementsTableComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import { ManageBodymeasurementStore } from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements-dashboard',
  standalone: true,
  templateUrl: './manage-bodymeasurements-dashboard.component.html',
  styleUrls: ['./manage-bodymeasurements-dashboard.component.scss'],
  imports: [ManageBodymeasurementsTableComponent],
})
export class ManageBodymeasurementsDashboardComponent {
  entities = inject(ManageBodymeasurementStore).bodymeasurementEntities;
}
