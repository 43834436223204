import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestingEventI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-manage-events-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './manage-events-list.component.html',
  styleUrl: './manage-events-list.component.scss',
})
export class ManageEventsListComponent   {
  @Input() item: TestingEventI[] = []; // Use @Input decorator and initialize with an empty array

}

