import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetupInitComponent } from './containers/setup-init/setup-init.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';
import { setupUiRoutes } from './lib.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UiCoreModule,
    RouterModule.forChild(setupUiRoutes),
  ],
  declarations: [SetupInitComponent],
})
export class AdminSetupUiModule {}
