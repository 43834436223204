import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiLocalRoutesEnum, SessionStorageService } from '@bodyanalytics/ui-core';
import { PaymentIntent, StripeElementsOptions } from '@stripe/stripe-js';
import { StripePaymentElementComponent, StripeService } from 'ngx-stripe';

@Component({
  selector: 'bodyanalytics-signup-payment-form',
  templateUrl: './signup-payment-form.component.html',
  styleUrls: ['./signup-payment-form.component.scss'],
})
export class SignupPaymentFormComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  @Input() isCreditAvailable: boolean = false;

  @Input() clientSecret!: string;

  @Input() isDislayPaymentFlag: boolean = false;

  @Output() submitFormEvent = new EventEmitter<any>();
  isExistingWallet = false;
  paying = false;

  public signupForm = new FormGroup({
    cardHolderName: new FormControl('', [Validators.required]),
    // lastName: new FormControl('', [Validators.required]),
    // emailAddress: new FormControl('', [Validators.required]),
    // phoneNumber: new FormControl('', [Validators.required]),
    // bio: new FormControl('', [Validators.required]),
  });

  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private stripeService: StripeService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {}

  public updateSignupPayment(): void {
    this.submitFormEvent.emit({
      cardHolderName: this.signupForm.value.cardHolderName as string,
    });
  }

  pay(): void {
    const userData = this.sessionStorageService.getBookingData()!.userInfo;
    const serviceData = this.sessionStorageService.getBookingData()!.selectedService;

    this.paying = true;
    this.stripeService
      .confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          return_url: window.location.origin,
          payment_method_data: {
            billing_details: {
              name: userData!.firstName + ' ' + userData!.lastName,
              email: userData!.emailAddress,
              phone: userData!.phoneNumber,
              address: {
                line1: userData?.address1 || '',
                postal_code: userData!.zipCode || '',
                city: userData!.city || '',
                state: userData!.state || '',
              },
            },
          },
        },
        redirect: 'if_required',
      })
      .subscribe(result => {
        this.paying = false;
        // console.log('Result:', result);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          console.log({ success: false, error: result.error.message });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            // alert({ success: true });

            this.submitFormEvent.emit({
              paymentIntent: result.paymentIntent,
            });
            // console.log('result.paymentIntent.status:', result.paymentIntent);
          }
        }
      });
  }

  public updatePaymentDetails(): void {
    this.submitFormEvent.emit({
      firstName: this.signupForm.value.cardHolderName as string,
    });
  }
}
