<div class="container">
  <div class="py-5">
    <div class="row">
      <div class="col-lg-10 col-md-12 col-12 mb-3 mb-lg-0 event-page">
        <div class="event-header">
          <h1 class="display-6 fw-bold text-dark">Signup verification</h1>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container p-2">
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="row">
        <h5 class="p-3">Confirm your updated signup details</h5>
        <div class="col-md-12">
          <div class="current-booking-details bg-info p-2 text-dark bg-opacity-10" *ngIf="signupData">
            <h5>Signup Details:</h5>
            <div>
              <h5 class="card-title">
                {{ signupData.firstName }} {{ signupData.lastName }}
              </h5>
              <!-- <p class="card-text">
                {{ signupData.city }}, {{ signupData.state }}
                {{ signupData.zipCode }}
              </p> -->

              <p class="card-text m-0">Email: {{ signupData.emailAddress }}</p>
              <p class="card-text m-0">Phone number: {{ signupData.phoneNumber }}</p>
              <p class="card-text m-0">Date of birth: {{ signupData.dob }}</p>
              <p class="card-text m-0">Sex: {{ signupData.sex }}</p>
            </div>


          </div>
        </div>

        <div class="col-md-12">
          <div class="current-booking-details bg-info p-2 text-dark bg-opacity-10" *ngIf="eventData">
            <!-- <h5>New booking:</h5> -->
             <hr>
            <p>
              <strong>Event:</strong>
              {{ eventData.testEventName ? eventData.testEventName : eventData.testEventAtClub.clubName }}
            </p>
            <p>
              <strong>Date & Time:</strong> {{ eventData.testEventDate | date : 'fullDate' }} at
              {{ newTimeSlot   }}
            </p>

            <p>
              <strong>Location:</strong><br />
              {{ eventData.testEventAtClub.clubName }} <br />
              {{ eventData.testEventAtClub.address1 }} <br />
              <ng-container *ngIf="eventData.testEventAtClub.address2">
                {{eventData.testEventAtClub.address2 }} <br />
              </ng-container>
              {{eventData.testEventAtClub.city }}, {{eventData.testEventAtClub.state }}
              {{ eventData.testEventAtClub.zipCode }}
            </p>
          </div>
          <div class="py-5">
            <div class="d-grid gap-2 d-md-flex justify-content-md-center mb-4 mb-lg-3">
              <button type="submit" class="btn btn-link" (click)="routeToSearch()">Cancel</button>

              <button type="submit" class="btn btn-primary" (click)="processBookingWithCredit()">Confirm Signup</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
