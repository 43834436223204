import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ManageClubsStore } from '@bodyanalytics/front/admin/manage-clubs/domain';
import { ManageClubsTableComponent } from '@bodyanalytics/front/admin/manage-clubs/ui';

@Component({
  selector: 'bodyanalytics-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ManageClubsTableComponent],
})
export class ManageClubsDashboardComponent {

   entities = inject(ManageClubsStore).entities;



// extends BodyanalyticsBaseComponent implements OnInit, AfterViewInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   @ViewChild(MatPaginator) paginator!: MatPaginator;

//   public clubData: ClubI[] = [];
//   // public bufferValue = 0;
//   public paginatorPageSize = 5;
//   public loadingSession = false;
//   public displayedColumns: string[] = ['id','clubName', 'address1', 'city', 'state', 'zipCode'];
//   protected dataSource = new MatTableDataSource<ClubI>();
//   // public $clubData!: Observable<ClubI[]>;
//   showFirstLastButtons = true;
//   constructor(private router: Router, private manageClubService: ManageClubService) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource = new MatTableDataSource(this.clubData);

//   }
//   ngOnInit(): void {
//     this._fetchData();
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${target.value}`);
//     }
//   }
//   private _fetchData() {
//     // this.loadingSession = true;
//     // this.bufferValue = 0; // Start buffer at 0
//     // let loadedData = 0;
//     // let totalData = 787;

//     this.subscriptions.barrel = this.manageClubService.getClubs().subscribe((results: ClubI[]) => {
//       // loadedData += results.length;
//       // this.bufferValue = (loadedData / totalData) * 100;
//       // if (loadedData >= totalData) {
//       //   this.loadingSession = false;
//       // }
//       this.loadingSession = false;
//       this.clubData = results;
//       console.log('results', results.length);

//     });
//   }
// }
}
