<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="paymentDetailsForm">
  <ng-container>
    @if (item()) {
      <div class="row pb-2">
        <div class="col-sm-8">
          <div class="mb-3 position-relative">
            <!-- <label for="amount" class="form-label fs-base">Club Name</label> -->
            <input
              type="text"
              for="amount"
              [ngClass]="{ 'is-invalid': formsubmit && form.amount.errors }"
              class="form-control form-control-lg"
              maxlength="20"
              formControlName="amount"
              #amount />
            <div *ngIf="formsubmit && form.amount.errors" class="invalid-tooltip">
              <span *ngIf="form.amount.errors['required']">Amount is required.</span>
            </div>
          </div>
        </div>
      </div>

    }
  </ng-container>
</form>
