import { Component } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { BodyanalyticsBaseComponent } from 'libs/front/shared/ui-core/src';
import { AccountDetailsI, AddressDetailsI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent extends BodyanalyticsBaseComponent {
  constructor(private profileService: ProfileService) {
    super();
  }

  updateAccount(account: AccountDetailsI) {
    this.subscriptions.barrel = this.profileService
      .updateAccountDetails(account)

      .subscribe();
  }
  updateAddress(address: AddressDetailsI) {
    this.subscriptions.barrel = this.profileService
      .updateAddressDetails(address)

      .subscribe();
  }
}
