<div class="invalid-feedback">Error: Please check</div>

<form class="row g-3" [formGroup]="discountDetailsForm">
  <ng-container>
    <div class="row">
      <div class="col-md-8">
        <div class="row pb-2">
          <div class="col-md-12">
            <div>
              <label for="discountCode" class="form-label fs-base">Discount Code</label>
              <input
                type="text"
                name="discountCode"
                id="discountCode"
                formControlName="discountCode"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your event name!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="discountAmount" class="form-label fs-base">Discount Amount</label>
              <input
                type="text"
                name="discountAmount"
                id="discountAmount"
                formControlName="discountAmount"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your amount!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="discountExpireDate" class="form-label fs-base">Expiration Date</label>
              <input
                type="text"
                name="discountExpireDate"
                id="discountExpireDate"
                formControlName="discountExpireDate"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your Expire Date!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="discountType" class="form-label fs-base">Type</label>
              <input
                type="text"
                name="discountType"
                id="discountType"
                formControlName="discountType"
                class="form-control form-control-lg" />
              <div class="invalid-feedback">Please enter your type!</div>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="discountForEventId" class="form-label fs-base">Event (optional)</label>
              <input
                type="text"
                name="discountForEventId"
                id="discountForEventId"
                formControlName="discountForEventId"
                class="form-control form-control-lg" />
              <!-- <div class="invalid-feedback">Please enter your event name!</div> -->
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <label for="isCustomerDiscount" class="form-label fs-base">Customer Discount?</label>
              <input
                type="text"
                name="isCustomerDiscount"
                id="isCustomerDiscount"
                formControlName="isCustomerDiscount"
                class="form-control form-control-lg" />
              <!-- <div class="invalid-feedback">Please enter your event name!</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="list-group">
          <button type="button" class="list-group-item list-group-item-action" (click)="goToSignupPage()">
            Delete Discount
          </button>

          <button type="button" class="list-group-item list-group-item-action" (click)="viewClubDetails()">
            Resend Code
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex mb-3">
      <button type="button" class="btn btn-secondary-outline text-black btn-lg" (click)="closeModal()">Cancel</button>
      <button type="submit" class="btn btn-primary btn-lg" (click)="updateDiscount()">Save changes</button>
    </div>
  </ng-container>
</form>
