import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { OperatorService } from '../../services/manage-operators.service';
@Component({
  selector: 'bodyanalytics-operators-details',
  templateUrl: './operators-details.component.html',
  styleUrls: ['./operators-details.component.scss'],
})
export class ManageOperatorsDetailsComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  operatorData!: OperatorI | null;
  // public operatorDatas: OperatorI[] = [];
  // public $operatorDatas!: Observable<OperatorI[]>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private operatorService: OperatorService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }
  ngOnInit(): void {
    this.getOperator();
  }

  getOperator(): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.operatorService
      .getOperator(id)
      .subscribe((operator: OperatorI) => ((this.operatorData as OperatorI) = operator));
    // );
  }

  public processOperatorUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.operatorService
      .updateOperator(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processOperatorUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          // doc.file.hasError = true;
          // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
          // doc.file.uploading = false;
          // doc.file.uploaded = false;
          return of(error);
        }),
      )
      .subscribe();
  }
}
