 import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ManageBookingsStore } from '@bodyanalytics/front/admin/manage-bookings/domain';
 import { ManageBookingsTableComponent } from '@bodyanalytics/front/admin/manage-bookings/ui'
@Component({
  selector: 'bodyanalytics-manage-booking-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ManageBookingsTableComponent],

})
export class BookingDashboardComponent {
  entities = inject(ManageBookingsStore).entities;
}
