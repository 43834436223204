import { Route } from '@angular/router';
import { ManageCustomersComponent } from './manage-customers/manage-customers.component';
import { ManageCustomersDashboardComponent } from './dashboard/manage-customers-dashboard.component';
import { CustomersDetailsComponent } from './customers-details/customers-details.component';

import { CreateCustomersComponent } from './create-customers/create-customers.component';
 import { AuthGuard } from '@auth0/auth0-angular';
import { ManageCustomerService } from '../../../domain/src/lib/manage-customers.service';
import { provideCustomers } from '../../../domain/src/index';


export const  manageCustomersUiRoutes: Route =
{
  path: 'auth/manage-customers',
  component: ManageCustomersComponent,
  canActivate: [AuthGuard],
  providers: [provideCustomers(), ManageCustomerService],
  children: [
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    {
      path: 'create-customer',
      component: CreateCustomersComponent,
      pathMatch: 'full',
    },
    {
      path: 'dashboard',
      component: ManageCustomersDashboardComponent,
      pathMatch: 'full',
    },
    {
      path: 'detail/:id',
      component: CustomersDetailsComponent,
    },
  ],
}
