import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

 import { Observable, catchError, of, tap } from 'rxjs';

 import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { BookingI } from '@bodyanalytics/data-models-ui';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private bookingsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING}`;
  private bookingSearchUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING_SEARCH}`;
  private bookingsDertailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING_DETAILS}`;
  private bookingsListUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING_EVENT_LIST}`;

  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  load(): Observable<BookingI[]> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}`;
    return this.http.get<BookingI[]>(url).pipe(
      tap(_ => console.log('fetched booking')),
      catchError(this.sharedErrorService.handleError<BookingI[]>('load customers', [])),
    );
  }

  getBookingDetails(id: string): Observable<BookingI> {
    const url = `${this.appConfig.api}/${this.bookingsDertailsUrl}/${id}`;
    return this.http.get<BookingI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched booking id=${id}`)),
      catchError(this.sharedErrorService.handleError<BookingI>(`getBookings id=${id}`)),
    );
  }

  updateBookings(id: string, email: BookingI): Observable<any> {
    const url = `${this.appConfig.api}/${this.bookingsDertailsUrl}/${id}`;
    return this.http.patch(url, email, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated email id=${id}`)),
      catchError(this.sharedErrorService.handleError<BookingI>('updateBookings')),
    );
  }

  deleteBookings(id: string): Observable<BookingI> {
    const url = `${this.appConfig.api}/${this.bookingsDertailsUrl}/${id}`;

    return this.http.delete<BookingI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted getBookings id=${id}`)),
      catchError(this.sharedErrorService.handleError<BookingI>('deleteBookings')),
    );
  }

  getBookings(): Observable<BookingI[]> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}`;

    return this.http.get<BookingI[]>(url).pipe(
      tap(_ => console.log('getBookings')),
      catchError(this.sharedErrorService.handleError<BookingI[]>('getBookings', [])),
    );
  }
  getBookingListById(id: string): Observable<BookingI[]> {
    const url = `${this.appConfig.api}/${this.bookingsListUrl}/${id}`;

    return this.http.get<BookingI[]>(url).pipe(
      tap(_ => console.log('getBookings')),
      catchError(this.sharedErrorService.handleError<BookingI[]>('getBookings', [])),
    );
  }

  addBookings(email: BookingI): Observable<BookingI> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}`;

    return this.http.post<BookingI>(url, email, this.httpOptions).pipe(
      tap((newBookings: BookingI) => this.sharedLogService.log(`added email w/ id=${newBookings}`)),
      catchError(this.sharedErrorService.handleError<BookingI>('addBookings')),
    );
  }
  /* GETemail whose name contains search term */
  searchBookings(term: string): Observable<BookingI[]> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}/search/emailAddress=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty email array.
      return of([]);
    }

    return this.http.get<BookingI[]>(`${url}`).pipe(
      tap(x => (x.length ? console.log(`found email matching "${term}"`) : console.log(`no email matching "${term}"`))),
      catchError(this.sharedErrorService.handleError<BookingI[]>('searchBookingss', [])),
    );
  }
}
