import { BodyFatReportI, CustomerI, BodyFatTestReportI } from '@bodyanalytics/data-models-ui';
import { type ClientHistory } from '@bodyanalytics/manage-reports-api';

export interface State {
  assessmentForm: FormValue;
  checkInCompleted: boolean;
  clientHistory: ClientHistory[];
  customers: CustomerI[];
  selectedTestingReport: BodyFatTestReportI | undefined;
  selectedReviewReport: BodyFatReportI | undefined;
  selectedReportLoading: boolean;
}

export type FormValue = Partial<{
  event: string;
  operator: string;
  truck: string;
  club: string;
  waterTemperature: number;
}>;

export interface TestingFormValue {
  dryWeight: number;
  height: number;
  wetKg: number;
}
