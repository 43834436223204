import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateEmailI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-review-email',
  templateUrl: './review-email.component.html',
  styleUrls: ['./review-email.component.scss'],
})
export class ReviewEmailComponent extends BodyanalyticsBaseComponent {
  @Output() submitFormEvent = new EventEmitter<any>();

  formsubmit = false;

  // public readonly testingStep = TestingSessionStep.setup;
  // bodyMeasurementData!: SetupTestingI;
  public submitted = false;
  public loadingSession = false;
  sessionDetails!: any;
  constructor(
    // private route: ActivatedRoute,
    private router: Router, // private testingService: ManageEmailService, // private location: Location,
  ) {
    super();
  }

  goBack(): void {
    // this.location.back();
  }

  onActivate() {
    // this.testingService.activatedSetupStep.next(true);
    // this.testingService.stepUpdated.next(this.testingStep);
  }
  public saveSetupTestDetails(signupDetails: any): void {
    //
  }

  public proceedToReview() {
    //
  }

  public notificationsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    // isPauseAllNotifications: new FormControl('', [Validators.required]),
    // isActivityNoticesEnabled: new FormControl('', [Validators.required]),
    // isPartnerNoticesEnabled: new FormControl('', [Validators.required]),
    // isAccountNoticesEnabled: new FormControl('', [Validators.required]),
    // isPromotionsNoticesEnabled: new FormControl('', [Validators.required]),
    // isDailySummaryEnabled: new FormControl('', [Validators.required]),
    isEmailOptIn: new FormControl(false, [Validators.required]),
  });

  public updateSettings(): void {
    this.submitFormEvent.emit({
      emailAddress: this.notificationsForm.value.emailAddress as string,
      phoneNumber: this.notificationsForm.value.phoneNumber as string,
      isEmailOptIn: this.notificationsForm.value.isEmailOptIn,
      // isPauseAllNotifications:  this.notificationsForm.value.isPauseAllNotifications as string,
      // isActivityNoticesEnabled:  this.notificationsForm.value.isActivityNoticesEnabled as string,
      // isPartnerNoticesEnabled:  this.notificationsForm.value.isPartnerNoticesEnabled as string,
      // isAccountNoticesEnabled:  this.notificationsForm.value.isAccountNoticesEnabled as string,
      // isPromotionsNoticesEnabled:  this.notificationsForm.value.isPromotionsNoticesEnabled as string,
      // isDailySummaryEnabled:  this.notificationsForm.value.isDailySummaryEnabled as string,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.notificationsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  sendEmail(event: CreateEmailI) {
    this.loadingSession = true;
    // this.subscriptions.barrel = this.testingService
    //   .createEmail(event)
    //   .pipe(
    //     tap((response) => {
    //       this.loadingSession = false;
    //       console.log('response:', response)
    //       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_REVIEW_EVENT}/${response.id}`);
    //     }),
    //     catchError((error: any) => {
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }
}
