import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductDetailsComponent } from './containers/product-details/product-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';
import { manageProductsUiRoutes } from './lib.routes';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiCoreModule,
    MaterialModule,
    RouterModule.forChild(manageProductsUiRoutes),
  ],
  declarations: [ProductDetailsComponent],
})
export class ManageProductsUiModule {}
