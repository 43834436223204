import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { AdminSetupService } from '../../services/admin-setup.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'bodyanalytics-setup-init',
  templateUrl: './setup-init.component.html',
  styleUrls: ['./setup-init.component.scss'],
})
export class SetupInitComponent extends BodyanalyticsBaseComponent implements OnInit {
  @ViewChild('stepper') private myStepper!: MatStepper;

  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private adminSetupService: AdminSetupService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.populateFormOptions();
    //throw new Error('Method not implemented.');
  }
  migrationfields: FormField<any>[] = [
    {
      key: 'migrateData',
      label: 'Migrate Data', // Region Group
      type: 'select',
      options: [
        { value: 'setup', label: 'setup' },
        { value: 'customer', label: 'customer' },
        { value: 'clubs', label: 'clubs' },
        { value: 'regions', label: 'regions' },
        { value: 'tests', label: 'tests' },
        { value: 'payments', label: 'payments' },
      ],
      validation: [Validators.required],
    },
  ];

  eventfields: FormField<any>[] = [
    {
      key: 'testEventName',
      label: 'EventName',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'testEventDescription',
      label: 'Description',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'testEventDate',
      label: 'EventDate',
      type: 'date',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    { key: 'startTime', label: 'Start Time', type: 'time', validation: [Validators.required] },
    { key: 'endTime', label: 'End Time', type: 'time', validation: [Validators.required] },
    {
      key: 'slug',
      label: 'slug',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'imageUrl',
      label: 'imageUrl',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'isAllowedSignupOnline',
      label: 'isAllowedSignupOnline',
      type: 'checkbox',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'isPaymentRequired',
      label: 'isPaymentRequired',
      type: 'checkbox',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'isTestingEventPrivate',
      label: 'isTestingEventPrivate',
      type: 'checkbox',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'products',
      label: 'products',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    {
      key: 'services',
      label: 'services',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    {
      key: 'regionId',
      label: 'region',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    {
      key: 'operators',
      label: 'operators',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    {
      key: 'trucks',
      label: 'trucks',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
  ];
  truckStatusfields: FormField<any>[] = [
    {
      key: 'status',
      label: 'Truck status name', // Region Group
      type: 'select',
      options: [{ value: 'OK', label: 'ok' }],
      validation: [Validators.required],
    },
  ];

  paymentTypefields: FormField<any>[] = [
    {
      key: 'paymentType',
      label: 'Payment type', // Region Group
      type: 'select',
      options: [{ value: 'Stripe', label: 'Stripe-Default' }],
      validation: [Validators.required],
    },
  ];

  truckfields: FormField<any>[] = [
    {
      key: 'truckOperatorId',
      label: 'Truck operator',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    {
      key: 'truckName',
      label: 'Truck name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'truckCode',
      label: 'Truck code',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'truckStatusId',
      label: 'Truck status',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },
    // {
    //   key: 'truckLocationId',
    //   label: 'Test Event',
    //   type: 'select',
    //   options: [],
    //   validation: [Validators.required],
    // },

    { key: 'truckNote', label: 'Notes', type: 'textarea'},
  ];
  productfields: FormField<any>[] = [
    {
      key: 'productName',
      label: 'Product name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'description',
      label: 'Product description',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    // {
    //   key: 'image',
    //   label: 'Product Img',
    //   type: 'text',
    //   placeholder: '',
    //   tooltip: '',
    //   tooltipPosition: 'right',
    //   validation: [Validators.required],
    // },
    {
      key: 'price',
      label: 'Price',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    // {
    //   key: 'sku',
    //   label: 'SKU',
    //   type: 'text',
    //   placeholder: '',
    //   tooltip: '',
    //   tooltipPosition: 'right',
    //   validation: [Validators.required],
    // },
  ];

  productServicesfields: FormField<any>[] = [
    {
      key: 'name',
      label: 'Service name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    // {
    //   key: 'description',
    //   label: 'Product description',
    //   type: 'text',
    //   placeholder: '',
    //   tooltip: '',
    //   tooltipPosition: 'right',
    //   validation: [Validators.required],
    // },
    {
      key: 'duration',
      label: 'Service duration',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'price',
      label: 'Price',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'serviceFee',
      label: 'Service Fee',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'productId',
      label: 'Product',
      type: 'select',
      options: [
        // { value: 'REPAIR', label: 'Repair' },
        // { value: 'GOOD', label: 'Good' },
        // ...
      ],
      validation: [Validators.required],
    },
    {
      key: 'primary',
      label: 'Is service primary?',
      type: 'select',
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
      validation: [Validators.required],
    },
    //     {
    // key: 'gender',
    // label: 'Primary service?',
    // type: 'radio',
    // options: [
    // { value: 'male', label: 'Male' },
    // { value: 'female', label: 'Female' },
    // { value: 'other', label: 'Other' },
    // ],
    // },
  ];
  regionFields: FormField<any>[] = [
    {
      key: 'regionName',
      label: 'Region name', // Region Group
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'regionId',
      label: 'Region id', // Region Group
      type: 'select',
      options: [
        { value: 'NORTH_TEXAS', label: 'North Texas' },
        { value: 'HOUSTON', label: 'Houston' },
        { value: 'AUSTIN', label: 'Austin' },
        { value: 'SAN_ANTONIO', label: 'San Antonio' },

        { value: 'REGION_NOT_FOUND', label: 'Not on list' },
      ],
      validation: [Validators.required],
    },
    {
      key: 'regionStatus',
      label: 'Region status', // Region Group
      type: 'select',
      options: [
        { value: 'DECOMMISSIONED', label: 'Deactivated' },
        { value: 'GOOD', label: 'Good' },
      ],
      validation: [Validators.required],
    },
    {
      key: 'regionTerritoryType',
      label: 'Territory type', // Region Group
      type: 'select',
      options: [{ value: 'ACTIVE', label: 'Active' }],
      validation: [Validators.required],
    },

    {
      key: 'regionDescription',
      label: 'Description',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'regionLogo',
      label: 'Logo URL',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    { key: 'regionActivatedDate', label: 'Activated Date', type: 'date', validation: [Validators.required] },
    // { key: 'notes', label: 'Notes', type: 'textarea', validation: [Validators.required] },
  ];
  clubFields: FormField<any>[] = [
    {
      key: 'clubName',
      label: 'Gym Name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'address1',
      label: 'Address 1',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'address2',
      label: 'Address 2',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'city',
      label: 'City',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'state',
      label: 'State',
      type: 'select',
      options: [
        // { value: 'CA', label: 'California' },
        // { value: 'TX', label: 'Texas' },
        // // ...
      ],
      validation: [Validators.required],
    },
    {
      key: 'zipCode',
      label: 'Zip code',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone Number',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'clubInRegionId',
      label: 'Region',
      type: 'select',
      options: [],
      validation: [Validators.required],
    },

    {
      key: 'contactName',
      label: 'Contact name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    { key: 'emailAddress', label: 'Email Address', type: 'email', validation: [Validators.required, Validators.email] },

    {
      key: 'contactTitle',
      label: 'Contact title',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastAmount',
      label: 'Last amount',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'commission',
      label: 'Commission',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    {
      key: 'clubLogo',
      label: 'Logo',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'signupUrl', //http://bodyanalyticstx.com/GymEvents/Beast-Body
      label: 'Signup URL',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    { key: 'note', label: 'Notes', type: 'textarea', validation: [Validators.required] },
  ];
  operatorFields: FormField<any>[] = [
    { key: 'emailAddress', label: 'Email address', type: 'email', validation: [Validators.required, Validators.email] },

    {
      key: 'operatorUserName',
      label: 'Operator username',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },

    {
      key: 'firstName',
      label: 'First name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'lastName',
      label: 'Last name',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'phoneNumber',
      label: 'Phone number',
      type: 'text',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    {
      key: 'operatorPriority',
      label: 'Priority level',
      type: 'number',
      placeholder: '',
      tooltip: '',
      tooltipPosition: 'right',
      validation: [Validators.required],
    },
  ];
  populateFormOptions() {
    this.subscriptions.barrel = this.adminSetupService.getOperators().subscribe(operators => {
      const operatorOptions = operators.map(op => ({ value: op.id, label: op.firstName + ' ' + op.lastName }));
      // this.truckfields[0].options = operatorOptions;
      this.truckfields.find(field => field.key === 'truckOperatorId')!.options = operatorOptions;
    });
    this.subscriptions.barrel = this.adminSetupService.getTruckStatus().subscribe(testingEvents => {
      const truckStatusOptions = testingEvents.map(op => ({ value: op.id.toString(), label: op.status as string }));
      this.truckfields.find(field => field.key === 'truckStatusId')!.options = truckStatusOptions;
    });

    this.subscriptions.barrel = this.adminSetupService.getProducts().subscribe(products => {
      const productServicesOptions = products.map(op => ({ value: op.id.toString(), label: op.name }));
      this.productServicesfields.find(field => field.key === 'productId')!.options = productServicesOptions;
    });

    this.subscriptions.barrel = this.adminSetupService.getRegions().subscribe(regions => {
      const regionsOptions = regions.map(op => ({ value: op.id.toString(), label: op.regionName }));
      this.clubFields.find(field => field.key === 'clubInRegionId')!.options = regionsOptions;
    });

    this.subscriptions.barrel = this.adminSetupService.getStatesList().subscribe(states => {
      const stateOptions = states.map(op => ({ value: op.id.toString(), label: op.stateName }));
      this.clubFields.find(field => field.key === 'state')!.options = stateOptions;
    });
    // this.subscriptions.barrel = this.adminSetupService.getEvents().subscribe(testingEvents => {
    //   const eventsOptions = testingEvents.map(op => ({ value: op.id, label: op.testEventAtClub.clubName }));
    //   this.truckfields.find(field => field.key === 'truckBodyFatTestEvent')!.options = eventsOptions;
    // });
    // this.subscriptions.barrel = this.adminSetupService.getClubs().subscribe(club => {
    //   const clubOptions = club.map(op => ({ value: op.id, label: op.clubName }));
    //   this.truckfields.find(field => field.key === 'truckLocationId')!.options = clubOptions;
    // });
  }

  onFormSubmittedService(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addProductService(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          // After the form is successfully submitted:
          this.myStepper.next();
          //  this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  onFormSubmittedOperator(operatorData: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addOperator(operatorData)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();

          //  this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  onFormSubmittedRegion(operatorData: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addManageRegion(operatorData)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();

          //  this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  onFormSubmittedLocation(operatorData: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addClub(operatorData)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();
          //  this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  onProductFormSubmitted(product: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addProduct(product)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();

          //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  onFormEventSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addEvent(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();
          //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }


  onFormPaymentTypeSubmitted(paymentType: string) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addPaymentType(paymentType)
      .pipe(
        tap(() => {
          this.loadingSession = false;
           this.myStepper.next();
         // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  onFormMigrationSubmitted(migrationType: string) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addMigtationData(migrationType)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          //  this.myStepper.next();
         // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  onFormTruckStatusSubmitted(status: string) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addTruckStatus(status)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          //  this.myStepper.next();
         // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  onFormTruckSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addTruck(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          //  this.myStepper.next();
          this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  onOperatorFormSubmitted(operatorDetails: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.adminSetupService
      .addOperator(operatorDetails)
      .pipe(
        tap(() => {
          this.loadingSession = false;
          this.myStepper.next();
          // this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_OPERATORS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
