<div class="card border-bottom">
  <!-- card-body START -->
  <div
    class="card-body px-4 py-3 pb-0"
    *ngFor="let data of item; let even = even; let odd = odd"
    [ngClass]="{ even: even, odd: odd }">
    <div class="row g-4">
      <!-- Air line name -->
      <div class="col-md-5">
        <!-- Image -->
        <!-- <img src="../../../assets/images/9B972364.jpg" alt="logo" class="w-80px mb-3" alt="" /> -->
        <!-- Title -->
        <h6 class="fw-bold mb-0">{{ data.testEventName }}</h6>
        <div *ngIf="data?.testEventAtClub">
          <span>{{ data?.testEventAtClub?.address1 }}</span
          ><br />
          <span
            >{{ data?.testEventAtClub?.city }}, {{ data.testEventAtClub.state }}
            {{ data.testEventAtClub.zipCode }}</span
          >
        </div>
        <!-- <h6 class="fw-normal mb-0">Operated by Body Analytics.</h6> -->
      </div>

      <!-- Airport detail -->
      <div class="col-md-4 text-center p-3">
        <h6 class="h6">{{ data!.testEventDate | date : 'EEEE, MMMM d YYYY' }}</h6>
        <!-- <h6 class="h6">{{ data?.startTime| date : 'h:mm a' }} - {{ data?.endTime| date : 'h:mm a' }}</h6> -->
      </div>

      <!-- Price -->
      <div class="col-md-3" *ngIf="data.testEventCustomerSignupsId">
        <!-- Price -->
        <!-- <h4>$18,500</h4> -->
        <a class="btn btn-dark w-100 mt-3" (click)="updateSignupDetails(data.id)">Sign up</a>
        <!-- <button class="btn btn-link text-decoration-underline p-0 mb-0 w-100 mb-3" (click)="openModal(testEventAtClubContent)" >
          <i class="bi bi-eye-fill me-1 mt-2"></i>testEventAtClub Details
        </button> -->
      </div>
    </div>
  </div>
  <!-- card-body END -->

  <!-- card footer -->
  <!-- <div class="card-footer p-4">
    <div class="bg-light p-2 rounded-2">
      <ul class="list-inline d-sm-flex justify-content-sm-between mb-0 mx-4">
        <li class="list-inline-item text-danger">Only 10 Seat Left</li>
        <li class="list-inline-item">Total time: 10h 35m</li>
        <li class="list-inline-item text-success">Refundable</li>
      </ul>
    </div>
  </div> -->
</div>
