<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <!-- Position Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <input type="checkbox" [value]="element.id" (change)="onCheckboxChange($event)" />
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="testEventName">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.testEventName }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="testEventDate">
      <th mat-header-cell *matHeaderCellDef>Event Date</th>
      <td mat-cell *matCellDef="let element">{{ element.testEventDate | date : 'shortDate' }}</td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef>Start</th>
      <td mat-cell *matCellDef="let element">{{ element.startTime }}</td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef>End</th>
      <td mat-cell *matCellDef="let element">{{ element.endTime }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <!-- <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
