import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import {
  BodyanalyticsBaseComponent,
  clubData,
  ethnicityData,
  genderData,
  raceData,
  sexData,
} from '@bodyanalytics/ui-core';
 import { CustomerI, EthnicityI, GenderI, RacesI, SexI, StateI, ClubI } from '@bodyanalytics/data-models-ui';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SignupService } from '@bodyanalytics/calendar-ui';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { ManageCustomerService } from '@bodyanalytics/front/admin/manage-customers/domain';


@Component({
  selector: 'bodyanalytics-manage-customers-form',
  templateUrl: './manage-customers-form.component.html',
  styleUrls: ['./manage-customers-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink],
})
export class ManageCustomersFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  @Input()
  item!: CustomerI | null;

  readonly ethnicityList: EthnicityI[] = [];
  readonly genderList: GenderI[] = [];
  readonly raceList: RacesI[] = [];
  readonly sexList: SexI[] = [];
  // readonly stateList: StateI[] = [];
  protected stateList: StateI[] = [];
  readonly clubList: ClubI[] = [];

  formsubmit = false;

  public customerDetailsForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    birthDate: new FormControl('', [Validators.required]),
    age: new FormControl(0, [Validators.required]),
    ethnicity: new FormControl('', [Validators.required]),
    emailAddress: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    sex: new FormControl('', [Validators.required]),
    gender: new FormControl('', [Validators.required]),
    height: new FormControl(0, [Validators.required]),
    updatedAt: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    zipCode: new FormControl('', [Validators.required]),
    club: new FormControl('', [Validators.required]),
    isEmployee: new FormControl(false, [Validators.required]),
    isAfricanAmerican: new FormControl(false, [Validators.required]),
    isSiri3: new FormControl(false, [Validators.required]),
    useSiri3: new FormControl(false, [Validators.required]),
  });

  constructor(private router: Router, private manageCustomerService: ManageCustomerService, private signupService: SignupService) {
    super();
    this.ethnicityList = ethnicityData;
    this.genderList = genderData;
    this.raceList = raceData;
    this.sexList = sexData;
    this.clubList = clubData;
  }

  ngOnInit(): void {
    this.getCustomer();
    this.getStates();
  }

  private getStates(){
    this.signupService.getStates().subscribe(state => {
      this.stateList = state;
    });
  }

  public getCustomer(): void {
    // if (this.testingEventDetailsForm.valid) {
    if (this.item) {
      this.customerDetailsForm.patchValue({
        firstName: this.item?.firstName as string,
        lastName: this.item?.lastName,
        birthDate: this.item?.birthDate,
        age: this.item?.age,
        ethnicity: this.item?.ethnicity,
        emailAddress: this.item?.emailAddress,
        phoneNumber: this.item?.phoneNumber,
        sex: this.item?.sex,
        gender: this.item?.gender,
        height: this.item?.height,
        address1: this.item?.address1,
        address2: this.item?.address2,
        city: this.item?.city,
        state: this.item?.state,
        zipCode: this.item?.zipCode,
        club: this.item?.club,
        isEmployee: this.item?.isEmployee,
        isAfricanAmerican: this.item?.isAfricanAmerican,
        isSiri3: this.item?.isSiri3,
        useSiri3: this.item?.useSiri3,
      });
    }
  }

  public updateCustomer(): void {
    this.submitFormEvent.emit({
      contact_name: this.customerDetailsForm.value.firstName,
      firstName: this.customerDetailsForm.value.firstName as string,
      lastName: this.customerDetailsForm.value.lastName,
      birthDate: this.customerDetailsForm.value.birthDate,
      age: this.customerDetailsForm.value.age,
      ethnicity: this.customerDetailsForm.value.ethnicity,
      emailAddress: this.customerDetailsForm.value.emailAddress,
      phoneNumber: this.customerDetailsForm.value.phoneNumber,
      sex: this.customerDetailsForm.value.sex,
      gender: this.customerDetailsForm.value.gender,
      height: this.customerDetailsForm.value.height,
      address1: this.customerDetailsForm.value.address1,
      address2: this.customerDetailsForm.value.address2,
      city: this.customerDetailsForm.value.city,
      state: this.customerDetailsForm.value.state,
      zipCode: this.customerDetailsForm.value.zipCode,
      club: this.customerDetailsForm.value.club,
      isEmployee: this.customerDetailsForm.value.isEmployee,
      isAfricanAmerican: this.customerDetailsForm.value.isAfricanAmerican,
      isSiri3: this.customerDetailsForm.value.isSiri3,
      useSiri3: this.customerDetailsForm.value.useSiri3,
    });
  }

  /**
   * Returns form
   */
  get form() {
    return this.customerDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }
}
