<form #f="ngForm" (ngSubmit)="emailChange.emit(f.value.email)">
  <!--<div class="mb-3">
    <mat-radio-group (change)="selected.set($event.value)" [required]="true" name="selected" ngModel>
      @for (option of options; track option) {
      <mat-radio-button [value]="option.name">{{ option.label }}</mat-radio-button>
      }
    </mat-radio-group>
  </div>
  @switch (selected()){ @case ('email'){-->
  <div class="form-group row mb-3">
    <div class="col-md-4">
      <input
        [email]="true"
        class="form-control"
        id="customer-email-address"
        name="email"
        ngModel
        placeholder="Enter email"
        required
        type="email" />
    </div>
    <div class="col-md-2">
      <button [disabled]="f.invalid" class="btn btn-primary">Search</button>
    </div>
  </div>
  <!--} @case ('phone') {
    <div class="form-group row mb-3">
      <div class="col-md-6">
        <input
          class="form-control"
          id="customer-phone-number"
          name="phone"
          ngModel
          placeholder="Enter Phone no."
          required />
      </div>
    </div>
    } }-->
</form>
