import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageOperatorsComponent } from './containers/manage-operators/manage-operators.component';
import { ManageOperatorsDashboardComponent } from './containers/manage-operators-dashboard/manage-operators-dashboard.component';
import { ManageOperatorsFormComponent } from './components/manage-operators-form/manage-operators-form.component';
import { ManageOperatorsTableComponent } from './components/manage-operators-table/manage-operators-table.component';
import { ManageOperatorsDetailsComponent } from './containers/operators-details/operators-details.component';
import { manageOperatorsUiRoutes } from './lib.routes';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, UiCoreModule } from '@bodyanalytics/ui-core';
import { CreateOperatorComponent } from './containers/create-operator/create-operator.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UiCoreModule,
    RouterModule.forChild(manageOperatorsUiRoutes),
  ],
  declarations: [
    ManageOperatorsComponent,
    ManageOperatorsDashboardComponent,
    ManageOperatorsDetailsComponent,
    ManageOperatorsFormComponent,
    ManageOperatorsTableComponent,
    CreateOperatorComponent,
  ],
})
export class ManageOperatorsUiModule {}
