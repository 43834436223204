import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-manage-booking',
  templateUrl: './manage-booking.component.html',
  styleUrls: ['./manage-booking.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
 imports: [RouterOutlet, RouterLink, MatButtonModule, MatIconModule],
})
export class ManageBookingComponent{
  protected createBookingLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_CREATE_BOOKING;

}

// extends BodyanalyticsBaseComponent {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;

//   constructor(private router: Router) {
//     super();
//   }

//   public createBooking() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_CREATE_BOOKING}`);
//   }

//   public reviewBooking() {
//     // const id = this.route.snapshot.paramMap.get('id') as string;
//     const id = '2';
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_REVIEW_BOOKING}`);
//   }
// }
