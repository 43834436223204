<section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-8 bg-white p-2">
        <mat-horizontal-stepper [linear]="false" #stepper>
          <bodyanalytics-loading-spinner *ngIf="loadingSession"></bodyanalytics-loading-spinner>
          <mat-step>
            <ng-template matStepLabel>Migration</ng-template>
            <div class="row">
              <div class="col-md-8">
                <bodyanalytics-generic-form
                [fields]="migrationfields"
                (formSubmitted)="onFormMigrationSubmitted($event)"></bodyanalytics-generic-form>
              </div>
            </div>
            </mat-step>
          <mat-step>
            <ng-template matStepLabel>Payment</ng-template>
            <div class="row">
              <div class="col-md-8">
                <bodyanalytics-generic-form
                [fields]="paymentTypefields"
                (formSubmitted)="onFormPaymentTypeSubmitted($event)"></bodyanalytics-generic-form>
              </div>
            </div>
          </mat-step>
          <!-- <mat-step>
            <ng-template matStepLabel>Product</ng-template>
            <div class="row">
              <div class="col-md-8">
                <bodyanalytics-generic-form
                  [fields]="productfields"
                  (formSubmitted)="onProductFormSubmitted($event)"></bodyanalytics-generic-form>
              </div>
            </div>
          </mat-step> -->
          <!-- <mat-step>
            <ng-template matStepLabel>Services</ng-template>

            <div class="row">
              <div class="col-md-8 mt-4">
                <bodyanalytics-generic-form
                  [fields]="productServicesfields"
                  (formSubmitted)="onFormSubmittedService($event)"></bodyanalytics-generic-form>
              </div>
            </div>
          </mat-step> -->
          <mat-step>
            <ng-template matStepLabel>Operators</ng-template>

            <div class="row">
              <div class="col-md-8 mt-4">
                <bodyanalytics-generic-form
                  [fields]="operatorFields"
                  (formSubmitted)="onFormSubmittedOperator($event)"></bodyanalytics-generic-form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <button mat-fab extended matStepperNext>
                  <mat-icon aria-hidden="false" aria-label="Add icon for pin email" fontIcon="mail"></mat-icon>

                  Send Verification Pin
                </button>
              </div>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Region</ng-template>

            <div class="row">
              <div class="col-md-8 mt-4">
                <bodyanalytics-generic-form
                  [fields]="regionFields"
                  (formSubmitted)="onFormSubmittedRegion($event)"></bodyanalytics-generic-form>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <!-- <button mat-fab extended matStepperNext>
                  <mat-icon aria-hidden="false" aria-label="Add icon for pin email" fontIcon="mail"></mat-icon>

                  Send Verification Pin
                </button> -->
              </div>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Locations</ng-template>

            <div class="row">
              <div class="col-md-8 mt-4">
                <bodyanalytics-generic-form
                  [fields]="clubFields"
                  (formSubmitted)="onFormSubmittedLocation($event)"></bodyanalytics-generic-form>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8">
                <!-- <button mat-fab extended matStepperNext>
                  <mat-icon aria-hidden="false" aria-label="Add icon for pin email" fontIcon="mail"></mat-icon>

                  Send Verification Pin
                </button> -->
              </div>
            </div>
          </mat-step>

          <!-- <mat-step>
            <ng-template matStepLabel>Events</ng-template>

            <bodyanalytics-generic-form
              [fields]="eventfields"
              (formSubmitted)="onFormEventSubmitted($event)"></bodyanalytics-generic-form>
          </mat-step> -->
          <mat-step>
            <ng-template matStepLabel>Truck Details</ng-template>

            <bodyanalytics-generic-form
              [fields]="truckStatusfields"
              (formSubmitted)="onFormTruckStatusSubmitted($event)"></bodyanalytics-generic-form>

            <bodyanalytics-generic-form
              [fields]="truckfields"
              (formSubmitted)="onFormTruckSubmitted($event)"></bodyanalytics-generic-form>
          </mat-step>

          <!-- <bodyanalytics-generic-table
[columns]="columns"
[rows]="rows"
(tableEvent)="onTableEvent($event)"></bodyanalytics-generic-table> -->
          <!-- <mat-step [completed]="completedSteps.includes(1)">
            <ng-template matStepLabel>Event Details</ng-template>


          </mat-step> -->

          <!-- <mat-step [completed]="completedSteps.includes(2)">
            <ng-template matStepLabel>Review & Publish</ng-template>
            <div *ngIf="createdEventDetails">
              <h5>Event Details</h5>
              <pre>{{ createdEventDetails | json }}</pre>
            </div>


          </mat-step> -->
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</section>
