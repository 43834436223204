import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OperatorI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { tap, catchError, of } from 'rxjs';
import { OperatorService } from '../../services/manage-operators.service';

@Component({
  selector: 'bodyanalytics-manage-operators-table',
  templateUrl: './manage-operators-table.component.html',
  styleUrls: ['./manage-operators-table.component.scss'],
})
export class ManageOperatorsTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit {
  @Input()
  item!: OperatorI[];

  submitted = false;
  checked = '';
  dataSource: MatTableDataSource<OperatorI> = new MatTableDataSource<OperatorI>();

  public displayedColumns: string[] = ['id', 'firstName', 'lastName', 'emailAddress'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  isOperatorIdSelected = '';
  public testingEventTableData: OperatorI[] = [];

  constructor(private operatorService: OperatorService, private fb: FormBuilder, private router: Router) {
    super();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.item);
  }

  onCheckboxChange($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (target.checked) {
      this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_OPERATORS_DETAILS}/${target.value}`);
    }
  }

  deleteOperator() {
    this.subscriptions.barrel = this.operatorService
      .deleteOperator(this.isOperatorIdSelected)
      .pipe(
        tap(() => {
          this.submitted = true;
          location.reload();
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }

  // public createOperator(signupDetails: any): void {
  //   this.subscriptions.barrel = this.manageTestingEventService
  //     .addTestingEvent(signupDetails)
  //     .pipe(
  //       tap(() => {
  //         this.submitted = true;
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
}
