<table [dataSource]="entities()" [multiTemplateDataRows]="true" class="mat-elevation-z8" mat-table matSort>
  <!-- Iterate over each column -->
  <ng-container *ngFor="let column of displayedColumns">
    <ng-container [matColumnDef]="column">
      <!-- Apply Title Case to column headers -->
      <th mat-header-cell *matHeaderCellDef>{{ transformToTitleCase(column) }}</th>

      <!-- Check if the column is discountAmount to format with currency sign -->
      <td mat-cell *matCellDef="let element">
        <span *ngIf="column === 'discountAmount'; else defaultCell">
          {{ element[column] | currency:'USD':'symbol':'1.0-0' }}
        </span>
        <ng-template #defaultCell>
          {{ element[column] }}
        </ng-template>
      </td>
    </ng-container>
  </ng-container>

  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
    <td mat-cell *matCellDef="let element">
      <button [routerLink]="[discountDetail, element.id]" class="list-group-item list-group-item-action">
        <i class="bx bxs-pencil"></i> Edit
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: columnsToDisplayWithExpand"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
</table>
