import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DiscountI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';

import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageDiscountService {
  private discountsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_DISCOUNTS}`;
  private discountsDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_DISCOUNTS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getDiscount(id: string): Observable<any> {
    const url = `${this.appConfig.api}/${this.discountsDetailsUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched discount id=${id}`)),
      catchError(this.sharedErrorService.handleError<DiscountI>(`getDiscount id=${id}`)),
    );
  }

  load(): Observable<DiscountI[]> {
    const url = `${this.appConfig.api}/${this.discountsUrl}`;
    return this.http.get<DiscountI[]>(url).pipe(
      tap(_ => console.log('fetched discounts')),
      catchError(this.sharedErrorService.handleError<DiscountI[]>('discounts', [])),
    );
  }

  updateDiscount(id: string, discount: DiscountI): Observable<any> {
    const url = `${this.appConfig.api}/${this.discountsDetailsUrl}/${id}`;
    return this.http.patch(url, discount, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated discount id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateDiscount')),
    );
  }

  deleteDiscount(id: string): Observable<DiscountI> {
    const url = `${this.appConfig.api}/${this.discountsUrl}/${id}`;

    return this.http.delete<DiscountI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted discount id=${id}`)),
      catchError(this.sharedErrorService.handleError<DiscountI>('deleteDiscount')),
    );
  }

  getDiscounts(): Observable<DiscountI[]> {
    const url = `${this.appConfig.api}/${this.discountsUrl}`;

    return this.http.get<DiscountI[]>(url).pipe(
      tap(_ => this.sharedLogService.log('fetched discounts')),
      catchError(this.sharedErrorService.handleError<DiscountI[]>('getDiscounts', [])),
    );
  }

  addDiscount(discount: DiscountI): Observable<DiscountI> {
    const url = `${this.appConfig.api}/${this.discountsUrl}`;

    return this.http.post<DiscountI>(url, discount, this.httpOptions).pipe(
      tap((newDiscount: DiscountI) => this.sharedLogService.log(`added discount w/ id=${newDiscount.id}`)),
      catchError(this.sharedErrorService.handleError<DiscountI>('addDiscount')),
    );
  }
  searchDiscounts(term: string): Observable<DiscountI[]> {
    const url = `${this.appConfig.api}/${this.discountsUrl}/search/?firstName=${term}`;
    this.sharedLogService.log('here: ' + term);
    if (!term.trim()) {
      // if not search term, return empty discount array.
      return of([]);
    }

    return this.http.get<DiscountI[]>(`${url}`).pipe(
      tap(x =>
        x.length
          ? this.sharedLogService.log(`found discounts matching "${term}"`)
          : this.sharedLogService.log(`no discounts matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<DiscountI[]>('searchDiscounts', [])),
    );
  }
}
