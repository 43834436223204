// export * from './lib/front-admin-manage-payments-domain/front-admin-manage-payments-domain.component';
export * from './lib/manage-payments.service';
export * from './lib/manage-payments.signal-store-feature';
export * from './lib/manage-payments.store';


import { TypeProvider, Provider } from '@angular/core';
import { PaymentService } from './lib/manage-payments.signal-store-feature';
import { ManagePaymentsStore } from './lib/manage-payments.store';

// export * from './lib/manage-payments.service';
// export * from './lib/manage-payments.store';

export const providePayments = (): Provider[] => [PaymentService, ManagePaymentsStore];
