import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of, filter, switchMap } from 'rxjs';

import { Location, NgClass, NgIf } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { BookingService } from '@bodyanalytics/front/admin/manage-bookings/domain';
import { ManageBookingFormComponent } from '../../../../ui/src/lib/manage-booking-form/manage-booking-form.component';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bodyanalytics-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss'],
  imports: [ ReactiveFormsModule, NgClass, NgIf, ManageBookingFormComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingDetailComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageBookingService.getBookingDetails(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageBookingService: BookingService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processBookingUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageBookingService
      .updateBookings(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processBookingUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

