import { Component, Input, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as EventActions from '../../containers/store/test-event.actions';
import * as fromEvent from '../../containers/store/test-event.reducer';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { Router } from '@angular/router';

@Component({
  selector: 'bodyanalytics-event-selection',
  templateUrl: './event-selection.component.html',
  styleUrls: ['./event-selection.component.scss'],
})
export class EventSelectionComponent {
  @Input()
  item!: TestingEventI[];
  // loading$ = this.store.pipe(select(EventSelectors.selectLoading));
  // error$ = this.store.pipe(select(EventSelectors.selectError));
  // testEvents$ = this.store.pipe(select(EventSelectors.selectEvents));

  // constructor(private store: Store<fromEvent.initial>, private router: Router) {}

  ngOnInit() {
    // this.store.dispatch(EventActions.getLoadTestEvents());
  }

  onEventSelect(event: TestingEventI) {
    // Dispatch an action to select an event
    // Navigate to the slot selection page or update the state with the selected event
    // this.store.dispatch(EventActions.selectEvent({ selectedEvent: event }));
    // this.router.navigate(['/slot-selection']); // Assuming '/slot-selection' is the route for the slot selection component
  }
}
