 export * from './lib/manage-orders.service';
export * from './lib/manage-orders.signal-store-feature';
export * from './lib/manage-orders.store';

import { TypeProvider,Provider } from '@angular/core';
import {ManageOrdersStore} from './lib/manage-orders.store';
import { ManageOrderService } from './lib/manage-orders.service';

export { ManageOrderService, ManageOrdersStore };

// import { OrdersService } from './lib/manage-orders.service';
// import { ManageOrdersStore } from './lib/manage-orders.store';
export const provideOrders = (): Provider[] => [ManageOrderService, ManageOrdersStore];

// export const provideClubs = (): Provider[] => [ManageClubService, ManageClubsStore];
