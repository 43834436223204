<section id="manage-products-container">
  <div class="container bg-light py-5 px-3">
    <div class="row align-items-center text-black">
      <div class="col-md-6 intros text-start">
        <h1 class="display-6 fw-bold text-capitalize my-5 px-5">Manage Operators</h1>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn btn-outline-primary my-5" (click)="createOperator()">
          <i class="bx bx-run"></i> Create Operator
        </button>
        <!-- <button class="btn btn-outline-primary my-5" ><i class="bx bx-book-add"></i>Create event</button> -->
      </div>
    </div>
  </div>
</section>

<div>
  <router-outlet></router-outlet>
</div>
