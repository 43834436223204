import { Component } from '@angular/core';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-manage-events',
  templateUrl: './manage-events.component.html',
  styleUrls: ['./manage-events.component.scss'],
})
export class ManageEventsComponent extends BodyanalyticsBaseComponent {
  protected createEventLink = RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_CREATE_EVENT;
}
