import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsFormComponent } from './components/account-details-form/account-details-form.component';
import { NotificationsFormComponent } from './components/notifications-form/notifications-form.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { SecurityFormComponent } from './components/security-form/security-form.component';
import { AccountDetailsComponent } from './containers/account-details/account-details.component';
import { NotificationsComponent } from './containers/notifications/notifications.component';
import { ProfileDetailsComponent } from './containers/profile-details/profile-details.component';
import { ProfileSidebarComponent } from './containers/profile-sidebar/profile-sidebar.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { SecurityComponent } from './containers/security/security.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { HttpClientModule } from '@angular/common/http';
import { DataModelsUiModule } from '@bodyanalytics/data-models-ui';

const routes: Routes = [
  {
    path: 'auth/profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'account',
        component: AccountDetailsComponent,
      },
      {
        path: 'details',
        component: ProfileDetailsComponent,
      },
      {
        path: 'security',
        component: SecurityComponent,
      },
      {
        path: 'notifications',
        component: NotificationsComponent,
      },
      {
        path: 'settings',
        component: ProfileDetailsComponent,
      },
      { path: '', pathMatch: 'full', redirectTo: 'account' },
    ],
  },
];

@NgModule({
  declarations: [
    ProfileComponent,
    SecurityComponent,
    NotificationsComponent,
    ProfileSidebarComponent,
    AccountDetailsComponent,
    ProfileFormComponent,
    NotificationsFormComponent,
    SecurityFormComponent,
    AccountDetailsFormComponent,
    ProfileDetailsComponent,
  ],
  exports: [ProfileComponent, ProfileSidebarComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataModelsUiModule,
  ],
})
export class ProfileUiModule {}
