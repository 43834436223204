import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { ManagePaymentsDashboardComponent } from './manage-payments-dashboard/manage-payments-dashboard.component';
import { ManagePaymentsComponent } from './manage-payments/manage-payments.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { providePayments, PaymentService } from '@bodyanalytics/front/admin/manage-payments/domain';


export const managePaymentsUiRoutes: Route =
  {
    path: 'auth/manage-payments',
    component: ManagePaymentsComponent,
    canActivate: [AuthGuard],
    providers:[providePayments(), PaymentService],

    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        component: ManagePaymentsDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: PaymentDetailsComponent,
      },
    ],
  };
