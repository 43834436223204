import { Component, ChangeDetectionStrategy, output, signal } from '@angular/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-customer-checkin-form',
  standalone: true,
  templateUrl: './customer-checkin-form.component.html',
  styleUrls: ['./customer-checkin-form.component.scss'],
  imports: [MatRadioModule, FormsModule],
})
export class CustomerCheckinFormComponent {
  emailChange = output<string>();
  protected selected = signal('');
  protected options = [
    { label: 'Email Address', name: 'email' },
    { label: 'Phone Number', name: 'phone' },
  ];
}
