<div
  class="bg-cover"
  style="
    background-image: linear-gradient(180deg, rgba(8, 1, 36, 0.4) 0%, rgba(30, 24, 53, 0.4) 90.16%),
      url(https://images.pexels.com/photos/841130/pexels-photo-841130.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
  ">
  <div class="container">
    <div class="py-5">
      <div class="row">
        <div class="col-lg-10 col-md-12 col-12 mb-3 mb-lg-0 event-page">
          <p class="fw-bolder fs-6 m-0 text-white">
            <small>{{ item!.testEventDate | date : 'fullDate' }}</small>
          </p>
          <div class="event-header">
            <h1 class="display-6 fw-bold text-white">Signup for {{ item!.testEventName }}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
