<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="form-group row mt-2">
    <label for="drpOperator" class="col-sm-4 col-form-label">Operators</label>
    <div class="col-sm-8">
      <select class="form-control" id="drpOperator" formControlName="operator">
        <option disabled selected>Please Select</option>
        @for(operator of operators(); track operator.id){
        <option [value]="operator.id">
          {{ operator.operatorUserName }}
        </option>
        }
      </select>
    </div>
  </div>
  <div class="form-group row mt-2">
    <label for="drpEvent" class="col-sm-4 col-form-label">Event</label>
    <div class="col-sm-8">
      <select class="form-control" id="drpEvent" formControlName="event">
        <option disabled selected>Please Select</option>
        @for(event of events(); track event.id){
        <option [value]="event.id">{{ event?.testEventName }}</option>
        }
      </select>
    </div>
  </div>
  <div class="form-group row mt-2">
    <label for="drpClub" class="col-sm-4 col-form-label">Club</label>
    <div class="col-sm-8">
      <select class="form-control" id="drpClub" formControlName="club">
        <option disabled selected>Please Select</option>
        @for(club of clubs(); track club.id){
        <option [value]="club.id">
          {{ club.clubName }}
        </option>
        }
      </select>
    </div>
  </div>
  <div class="form-group row mt-2">
    <label for="drpTruck" class="col-sm-4 col-form-label">Truck</label>
    <div class="col-sm-8">
      <select class="form-control" id="drpTruck" formControlName="truck">
        <option disabled selected>Please Select</option>
        @for(truck of trucks(); track truck.id){
        <option [value]="truck.id">
          {{ truck.truckName }}
        </option>
        }
      </select>
    </div>
  </div>

  <div class="form-group row mt-2">
    <label for="waterTemperatureInput" class="col-sm-4 col-form-label">Water Temperature</label>
    <div class="col-sm-8">
      <input
        class="form-control form-control-lg"
        formControlName="waterTemperature"
        id="waterTemperatureInput"
        min="1"
        type="number" />
      <div class="invalid-feedback">Please provide a valid waterTemperature!</div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <ul class="mb-0 mt-5 d-flex justify-content-end">
        <li class="next list-inline-item float-right">
          <button [disabled]="form.invalid" class="btn btn-primary">Next</button>
        </li>
      </ul>
    </div>
  </div>
</form>
