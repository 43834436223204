import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'bodyanalytics-email-dashboard',
  templateUrl: './email-dashboard.component.html',
  styleUrls: ['./email-dashboard.component.scss'],
})
export class EmailDashboardComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  public emailsData: EmailI[] = [];
  constructor(private router: Router, private manageEmailService: EmailService) {
    super();
  }
  ngOnInit(): void {
    this._fetchData();
  }

  private _fetchData() {
    this.subscriptions.barrel = this.manageEmailService.getEmails().subscribe((results: EmailI[]) => {
      this.emailsData = results;
    });
  }
}
