<form
  class="needs-validation border-bottom"
  novalidate=""
  [formGroup]="signupForm"
  fxLayout="column"
  fxLayoutAlign="center none">
  <section class="bg-light">
    <div class="container">
      <div class="row g-4 align-items-center p-3">
        <!-- Title -->
        <div class="col-lg-4">
          <h2 class="mb-4 display-6">Search events by region</h2>
          <!-- <button class="btn btn-primary mb-0">View all</button> -->
        </div>

        <!-- Listing -->
        <div class="col-lg-8">
          <div class="row g-4">
            <!-- Card item -->
            <div class="col-md-6" *ngFor="let region of regionsList">
              <div
                class="card card-body h-100 p-3"
                (click)="selectCard(region.name)"
                [ngClass]="{ active: selectedCard === region.name }">
                <div class="d-flex justify-content-between align-items-center">
                  <!-- Image and text -->
                  <div class="d-flex align-items-center">
                    <!-- <img src="assets/images/category/hotel/nearby/01.jpg" class="h-70px rounded-2" alt="" /> -->

                    <div class="ms-3">
                      <h5 class="mb-0">
                        <a href="#">{{ region.name }}</a>
                      </h5>
                      <!-- <span>25 events</span> -->
                    </div>
                  </div>
                  <!-- Button -->
                  <!-- <a  class="btn btn-primary-soft btn-round flex-shrink-0 ms-2 mb-0"
                    ><i class="bi bi-arrow-right"></i
                  ></a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="row bg-white p-2">
    <div class="col-md-6">
      <h6 class="h6 text-center">Find mobile testing events by region</h6>
    </div>
    <div class="col-md-6">
      <h6 class="h6 text-center">Find mobile testing events by gym name</h6>
    </div>
  </div> -->
</form>
