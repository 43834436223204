<table [dataSource]="entities()" [multiTemplateDataRows]="true" class="mat-elevation-z8" mat-table matSort>
  @for (column of displayedColumns; track $index){
  <ng-container matColumnDef="{{ column }}">
    <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
    <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
  </ng-container>
  }
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
    <td mat-cell *matCellDef="let element">
      <button
        class="btn btn-light"
        aria-label="expand row"
        (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()">
        @if(expandedElement === element) {
        <i class="bx bxs-up-arrow"></i>
        } @if(expandedElement !== element) {
        <i class="bx bxs-down-arrow"></i>
        }
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
        <div>
          <div class="row">
            <div class="col-md-4">
              <div class="example-element-testDate">
                <span class="fw-bold">Test Date:</span>
                {{ element.testDate | date : 'fullDate' }}
              </div>
              <div class="example-element-testTime">
                <span class="fw-bold">Time:</span> {{ element.testDate | date : 'h:mm a z' }}
              </div>

              <div class="example-element-dryWeight">
                <span class="fw-bold">Dry Weight:</span> {{ element.dryWeight }}
              </div>
              <div class="example-element-height"><span class="fw-bold">Height:</span> {{ element.height }}</div>
              <div class="example-element-salinity"><span class="fw-bold">Salinity:</span> {{ element.salinity }}</div>
              <div class="example-element-waterTemperature">
                <span class="fw-bold">Water Temperature:</span> {{ element.waterTemperature }}
              </div>
              <div class="example-element-wetKg"><span class="fw-bold">Wet Kg:</span> {{ element.wetKg }}</div>
              <div class="example-element-residualLungValue">
                <span class="fw-bold">Residual LungValue:</span>
                {{ element.residualLungValue }}
              </div>
              <div class="example-element-fatLbs"><span class="fw-bold">Fat Lbs:</span> {{ element.fatLbs }}</div>
              <div class="example-element-fatPercent">
                <span class="fw-bold">Fat Percent:</span> {{ element.fatPercent }}
              </div>
              <div class="example-element-leanLbs"><span class="fw-bold">Lean Lbs:</span> {{ element.leanLbs }}</div>
              <div class="example-element-leanMassPercent">
                <span class="fw-bold">Lean Mass Percent:</span> {{ element.leanMassPercent }}
              </div>
              <div class="example-element-waterLbs"><span class="fw-bold">Water Lbs:</span> {{ element.waterLbs }}</div>
              <div class="example-element-restingMetabolicRate">
                <span class="fw-bold">Resting Metabolic Rate:</span>
                {{ element.restingMetabolicRate }}
              </div>
              <div class="example-element-isTraining">
                <span class="fw-bold">Is Training:</span> {{ element.isTraining }}
              </div>
            </div>
            <div class="col-md-4">
              <div class="example-element-age"><span class="fw-bold">Age:</span> {{ element.age }}</div>
              <!--
              TODO @anyone: add user
              <div class="example-element-email">
                <span class="fw-bold">Email:</span> {{ element.user.emailAddress }}
              </div>
              <div class="example-element-name">
                <span class="fw-bold">name:</span> {{ element.user.username }} {{ element.user.lastname }}
              </div>-->
              @if(element.uid){
              <div class="example-element-uid"><span class="fw-bold">UID:</span> {{ element.uid }}</div>
              } @if(element.note){
              <div class="example-element-note"><span class="fw-bold">Note:</span> {{ element.note }}</div>
              }
              <div class="example-element-assignedTruck">
                <span class="fw-bold">Assigned Truck:</span> {{ element.assignedTruck }}
              </div>
              <div class="example-element-createdAt">
                <span class="fw-bold">Created At:</span>
                {{ element.createdAt | date : 'fullDate' }}
              </div>
              <div class="example-element-updatedAt">
                <span class="fw-bold">Updated At:</span>
                {{ element.updatedAt | date : 'fullDate' }}
              </div>
            </div>
            <div class="col-md-4">
              <div class="list-group">
                <!-- <button
                  type="button"
                  class="list-group-item list-group-item-action list-group-item-success"
                  (click)="calculateMeasurement()">
                  Calculate
                </button>
                <button
                  type="button"
                  class="list-group-item list-group-item-action list-group-item-danger"
                  (click)="deleteMeasurement()">
                  Delete
                </button> -->
                <button [routerLink]="[bfTestsDetail, element.id]" class="list-group-item list-group-item-action">
                  <i class="bx bxs-pencil"></i> Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: columnsToDisplayWithExpand"
    class="example-element-row"
    [class.example-expanded-row]="expandedElement === element"
    (click)="expandedElement = expandedElement === element ? null : element"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>
