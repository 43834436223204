import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'bodyanalytics-account-details-form',
  templateUrl: './account-details-form.component.html',
  styleUrls: ['./account-details-form.component.scss'],
})
export class AccountDetailsFormComponent {
  @Output() submitFormEvent = new EventEmitter<any>();
  // Form submition

  formsubmit: boolean = false;
  constructor() {
    //
  }

  public accountDetailsForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
    lastName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
    emailAddress: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$'),
    ]),
    phoneNumber: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]{11}')]),
    // bio: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]),
  });

  /**
   * Returns form
   */
  get form() {
    return this.accountDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  public updateAccountDetails(): void {
    this.submitFormEvent.emit({
      firstName: this.accountDetailsForm.value.firstName as string,
      lastName: this.accountDetailsForm.value.lastName as string,
      emailAddress: this.accountDetailsForm.value.emailAddress as string,
      phoneNumber: this.accountDetailsForm.value.phoneNumber as string,
      // bio: this.accountDetailsForm.value.bio as string,
    });
  }
}
