import { CommonModule, NgClass, NgIf, NgFor, DatePipe, JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, Input, ViewChild } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { RouterLink } from '@angular/router';
import { BookingI, ClubI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-manage-booking-table',
  templateUrl: './manage-booking-table.component.html',
  styleUrls: ['./manage-booking-table.component.scss'],
  standalone: true,
  // animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MaterialModule, CommonModule, NgClass, NgIf, NgFor, RouterLink],
})
export class ManageBookingsTableComponent extends BodyanalyticsBaseComponent{
  protected bookingDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DETAILS;

  protected displayedColumns = [ 'customerId', 'eventId' ];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: BookingI;

  entities = input.required<BookingI[]>();
}
// extends BodyanalyticsBaseComponent implements AfterViewInit {
//   @Input()
//   item!: BookingI[];

//   submitted = false;

//   dataSource: MatTableDataSource<BookingI> = new MatTableDataSource<BookingI>();

//   public displayedColumns: string[] = ['id', 'customerId', 'eventId', 'slotId'];

//   @ViewChild(MatPaginator) paginator!: MatPaginator;

//   isBookingIdSelected = '';

//   constructor(private bookingService: BookingService, private fb: FormBuilder, private router: Router) {
//     super();
//   }
//   ngAfterViewInit(): void {
//     this.dataSource.paginator = this.paginator;

//     this.dataSource = new MatTableDataSource(this.item);
//   }

//   onCheckboxChange($event: Event) {
//     const target: HTMLInputElement = $event.target as HTMLInputElement;
//     if (target.checked) {
//       this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DETAILS}/${target.value}`);
//     }
//   }

//   deleteBooking() {
//     this.subscriptions.barrel = this.bookingService
//       .deleteBookings(this.isBookingIdSelected)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//           location.reload();
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   /**
//    * Modal Open
//    * @param content modal content
//    */

//   public createBooking(signupDetails: any): void {
//     this.subscriptions.barrel = this.bookingService
//       .addBookings(signupDetails)
//       .pipe(
//         tap(() => {
//           this.submitted = true;
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
