import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RegionsI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageRegionService {
  private regionUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS}`;
  private regionDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_REGIONS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getManageRegion(id: string): Observable<RegionsI> {
    const url = `${this.appConfig.api}/${this.regionDetailsUrl}/${id}`;
    return this.http.get<RegionsI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched region id=${id}`)),
      catchError(this.sharedErrorService.handleError<RegionsI>(`getManageRegions id=${id}`)),
    );
  }
  load(): Observable<RegionsI[]> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;
    return this.http.get<RegionsI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<RegionsI[]>('getOrders', [])),
    );
  }
  updateManageRegion(id: string, region: RegionsI): Observable<any> {
    const url = `${this.appConfig.api}/${this.regionDetailsUrl}/${id}`;
    return this.http.patch(url, region, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated region id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateManageRegions')),
    );
  }

  deleteManageRegion(id: string): Observable<RegionsI> {
    const url = `${this.appConfig.api}/${this.regionUrl}/${id}`;

    return this.http.delete<RegionsI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted region id=${id}`)),
      catchError(this.sharedErrorService.handleError<RegionsI>('deleteManageRegions')),
    );
  }

  getManageRegions(): Observable<RegionsI[]> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;

    return this.http.get<RegionsI[]>(url).pipe(
      tap(_ => this.sharedLogService.log('fetched region')),
      catchError(this.sharedErrorService.handleError<RegionsI[]>('getManageRegionss', [])),
    );
  }

  addManageRegion(region: RegionsI): Observable<RegionsI> {
    const url = `${this.appConfig.api}/${this.regionUrl}`;

    return this.http.post<RegionsI>(url, region, this.httpOptions).pipe(
      tap((newManageRegions: RegionsI) => this.sharedLogService.log(`added region w/ id=${newManageRegions.id}`)),
      catchError(this.sharedErrorService.handleError<RegionsI>('addManageRegions')),
    );
  }
  /* GET region whose name contains search term */
  searchManageRegions(term: string): Observable<RegionsI[]> {
    const url = `${this.appConfig.api}/${this.regionUrl}/search/?firstName=${term}`;
    this.sharedLogService.log('here: ' + term);
    if (!term.trim()) {
      // if not search term, return empty region array.
      return of([]);
    }

    return this.http.get<RegionsI[]>(`${url}`).pipe(
      tap(x =>
        x.length
          ? this.sharedLogService.log(`found region matching "${term}"`)
          : this.sharedLogService.log(`no region matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<RegionsI[]>('searchManageRegions', [])),
    );
  }
}
