import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderI } from '@bodyanalytics/data-models-ui';
import { ManageOrderService, ManageOrdersStore } from '@bodyanalytics/front/admin/manage-orders/domain';
import { ManageOrdersTableComponent } from '@bodyanalytics/front/admin/manage-orders/ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { Observable, tap, delay, catchError, of } from 'rxjs';

@Component({
  selector: 'bodyanalytics-manage-orders-dashboard',
  templateUrl: './manage-orders-dashboard.component.html',
  styleUrls: ['./manage-orders-dashboard.component.scss'],
  standalone: true,
  imports:[ManageOrdersTableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageOrdersDashboardComponent{

  entities = inject(ManageOrdersStore).entities;


}

// extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   public ordersData: OrderI[] = [];
//   // public $orders!: Observable<OrderI[]>;
//   constructor(private router: Router, private ordersService: ManageOrderService) {
//     super();
//   }
//   ngOnInit(): void {
//     this._fetchData();
//   }

//   private _fetchData() {
//     this.subscriptions.barrel = this.ordersService.getOrders().subscribe((results: OrderI[]) => {
//       this.ordersData = results;
//     });
//   }
// }
