import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { catchError, of, tap } from 'rxjs';
 import { Router, RouterLink } from '@angular/router';
import { BodyanalyticsBaseComponent, MaterialModule, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import { NgClass, NgIf, NgFor, CommonModule } from '@angular/common';

@Component({
  selector: 'bodyanalytics-manage-events-table',
  templateUrl: './manage-events-table.component.html',
  styleUrls: ['./manage-events-table.component.scss'],
  standalone:true,
  imports: [ NgClass, NgIf,NgFor, RouterLink, MaterialModule, CommonModule]
})
export class ManageEventsTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit {
  @Input()
  item!: TestingEventI[];

  submitted = false;
  checked = '';
  dataSource: MatTableDataSource<TestingEventI> = new MatTableDataSource<TestingEventI>();

  public displayedColumns: string[] = ['id', 'testEventName', 'testEventDate', 'startTime', 'endTime', 'status'];
  public testingEventsForm!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isTestingEventTableEnabled = true;

  isTestingEventIdSelected = '';
  public testingEventTableData: TestingEventI[] = [];
  // public $testingEvent!: Observable<TestingEventI[]>;
  constructor(
    private manageTestingEventService: ManageTestingEventService,
    private fb: FormBuilder,
    private router: Router,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.item);
  }

  onCheckboxChange($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (target.checked) {
      this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS}/${target.value}`);
    }
  }

  openModal(deleteContent: any, id: string) {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
    this.isTestingEventIdSelected = id;
  }

  deleteTestingEvent() {
    this.subscriptions.barrel = this.manageTestingEventService
      .deleteTestingEvent(this.isTestingEventIdSelected)
      .pipe(
        tap(() => {
          this.submitted = true;
          location.reload();
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  /**
   * Modal Open
   * @param content modal content
   */

  public createTestingEvent(signupDetails: any): void {
    this.subscriptions.barrel = this.manageTestingEventService
      .addTestingEvent(signupDetails)
      .pipe(
        tap(() => {
          this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
