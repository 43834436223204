import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TestEventSearchI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-search-events-box',
  templateUrl: './search-events-box.component.html',
  styleUrls: ['./search-events-box.component.scss'],
})
export class SearchEventsBoxComponent {
  @Output() search = new EventEmitter<any>();
  @Input() query!: TestEventSearchI | null;
  // @Output() cancel = new EventEmitter();

  public searchForm = new FormGroup({
    searchTerm: new FormControl(''),
    preset: new FormControl(''),
    // earnings: new FormControl(0),
    // description: new FormControl(''),
  });
  searchEvent() {
    this.search.emit({
      searchTerm: this.searchForm.value.searchTerm,
      preset: this.searchForm.value.preset,
    });
  }
}
