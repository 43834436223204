<section id="manage-events-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <mat-horizontal-stepper [linear]="false" #stepper>
          <mat-step>
            <ng-template matStepLabel>Email Details</ng-template>

            <bodyanalytics-generic-form
              [fields]="fields"
              (formSubmitted)="onFormSubmitted($event)"></bodyanalytics-generic-form>
          </mat-step>

          <!-- <bodyanalytics-generic-table
[columns]="columns"
[rows]="rows"
(tableEvent)="onTableEvent($event)"></bodyanalytics-generic-table> -->
          <!-- <mat-step [completed]="completedSteps.includes(1)">
            <ng-template matStepLabel>Event Details</ng-template>


          </mat-step> -->

          <!-- <mat-step [completed]="completedSteps.includes(2)">
            <ng-template matStepLabel>Review & Publish</ng-template>
            <div *ngIf="createdEventDetails">
              <h5>Event Details</h5>
              <pre>{{ createdEventDetails | json }}</pre>
            </div>


          </mat-step> -->
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</section>
