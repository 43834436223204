/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { TestEventRepository } from '../../services/testingEventRepository/testingEventRepository.service';
// import { ProductClass, TestingEventI } from '@bodyanalytics/data-models-ui';
// import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';

@Component({
  selector: 'bodyanalytics-region-event-search-form',
  templateUrl: './region-event-search-form.component.html',
  styleUrls: ['./region-event-search-form.component.scss'],
})
export class EventRegionSearchFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  public selectedCard = '';
  public isShowSearch = false;
  public isEventSelected = false;
  public selectedRegion = '';
  public selectedSearchDistance = null;
  public selectedTestingEvent = null;
  public productsPerPage = 4;
  public selectedPage = 1;
  panels = ['First', 'Second', 'Third'];
  panelOpenState = false;
  items = ['  Find mobile testing events & locations', 'Find by gym name'];
  expandedIndex = 0;

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  constructor() { // private mobileTestingCalendarService: MobileTestingCalendarService // private repository: TestEventRepository,
    super();
    this.expandedIndex = 0;
  }

  public signupForm = new FormGroup({
    region: new FormControl('', [Validators.required]),
    distance: new FormControl('', [Validators.required]),
    gymName: new FormControl('', [Validators.required]),
    location: new FormControl('', [Validators.required]),
  });
  data: any = [
    {
      parentName: 'Find mobile testing events & locations',
      childProperties: [{ propertyName: 'Find mobile testing events by region' }, { propertyName: 'Find gym by name' }],
    },
    {
      parentName: 'Find by gym name',
      childProperties: [
        { propertyName: 'Property Three' },
        { propertyName: 'Property Four' },
        { propertyName: 'Property Five' },
      ],
    },
    {
      parentName: 'Parent Three',
      childProperties: [
        { propertyName: 'Property Six' },
        { propertyName: 'Property Seven' },
        { propertyName: 'Property Eight' },
      ],
    },
  ];
  public regionsList = [
    {
      id: 1,
      name: 'North Texas',
      nickName: 'north-texas',
      image: '',
      location: {
        latitude: 33.098641,
        longitude: -96.811081,
      },
    },
    {
      id: 2,
      name: 'Houston',
      nickName: 'houston',
      image: '',
      location: {
        latitude: 33.098641,
        longitude: -96.811081,
      },
    },
    {
      id: 3,
      name: 'San Antonio',
      nickName: 'san-antonio',
      image: '',
      location: {
        latitude: 33.098641,
        longitude: -96.811081,
      },
    },
    {
      id: 4,
      name: 'Austin',
      nickName: 'austin',
      image: '',
      location: {
        latitude: 33.098641,
        longitude: -96.811081,
      },
    },
  ];

  ngOnInit(): void {
    console.log('');
    //throw new Error('Method not implemented.');
  }

  public searchForTestingEvents(): void {
    this.submitFormEvent.emit({
      region: this.signupForm.value.region as string,
      gymName: this.signupForm.value.gymName as string,
      // location: this.signupForm.value.location as string,
    });
  }

  public toggleSearchByGymName() {
    this.isShowSearch = true;
  }

  // get scheduledTestEvents(): ProductClass[] {
  //   const pageIndex = (this.selectedPage - 1) * this.productsPerPage;
  //   return this.repository.getProducts(this.selectedRegion).slice(pageIndex, pageIndex + this.productsPerPage);
  // }
  // get regions(): ProductClass[] {
  //   const pageIndex = (this.selectedPage - 1) * this.productsPerPage;
  //   return this.repository.getProducts(this.selectedRegion).slice(pageIndex, pageIndex + this.productsPerPage);
  // }
  changeTestEvent(newCategory?: string) {
    this.selectedRegion = newCategory as string;
  }
  // changeRegion(newCategory?: string) {
  //   this.selectedRegion = newCategory as string;
  // }
  changePage(newPage: number) {
    this.selectedPage = newPage;
  }
  changePageSize(newSize: Event) {
    const newSizeVal = (newSize.target as HTMLInputElement).value;

    this.productsPerPage = Number(newSizeVal);

    this.changePage(1);
  }

  selectCard(card: string): void {
    this.selectedCard = card;

    this.signupForm.patchValue({
      region: card,
    });

    //https://bodyanalyticstx.com/GetClubDates?region=Dallas&zipcode=&gym=0&latitude=32.776664&longitude=-96.796988&radius=10000
    //     region: Dallas
    // zipcode:
    // gym: 0
    // latitude: 32.776664
    // longitude: -96.796988
    // radius: 10000
    this.submitFormEvent.emit({
      region: this.signupForm.value.region as string,
      gymName: this.signupForm.value.gymName as string,
      location: this.signupForm.value.location as string,
    });
    // this.dummyService.getOptions(card).subscribe(options => {
    //   // Do something with the options returned from the service
    // });
  }

  // get pageNumbers(): number[] {
  //   return Array(Math.ceil(this.repository.getProducts(this.selectedRegion).length / this.productsPerPage))
  //     .fill(0)
  //     .map((x, i) => i + 1);
  // }

  toggleAccordian(event: { target: any }, index: string | number) {
    // eslint-disable-next-line prefer-const
    let element = event.target;
    element.classList.toggle('active');
    if (this.data[index].isActive) {
      this.data[index].isActive = false;
    } else {
      this.data[index].isActive = true;
    }
    // eslint-disable-next-line prefer-const
    let panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
