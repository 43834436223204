import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of, filter, switchMap } from 'rxjs';
 import { Location, NgClass, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageOrderService } from '@bodyanalytics/front/admin/manage-orders/domain';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ManageOrdersFormComponent } from '@bodyanalytics/front/admin/manage-orders/ui';

@Component({
  selector: 'bodyanalytics-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  imports: [ ManageOrdersFormComponent,  ReactiveFormsModule, NgClass, NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OrderDetailsComponent  extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageOrderService.getOrder(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageOrderService: ManageOrderService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processOrderUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageOrderService
      .updateOrders(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processOrderUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

// export class OrderDetailsComponent extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   orderData!: OrderI;
//   // public orders: OrderI[] = [];
//   // public $orders!: Observable<OrderI[]>;
//   constructor(
//     private router: Router,
//     private route: ActivatedRoute,
//     private location: Location,
//     private manageOrderService: OrdersService,
//   ) {
//     super();
//   }
//   public goBack() {
//     this.location.back();
//   }
//   ngOnInit(): void {
//     this.getOrder();
//   }

//   getOrder(): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageOrderService.getOrder(id).subscribe((order: OrderI) => (this.orderData = order));
//     // );
//   }

//   public processOrderUpdate(signupDetails: any): void {
//     const id = this.route.snapshot.paramMap.get('id') as string;
//     this.subscriptions.barrel = this.manageOrderService
//       .updateOrders(id, signupDetails)
//       .pipe(
//         tap(() => {
//           console.log('here: processOrderUpdate');
//           // this.modalService.dismissAll();
//           // this.submitted = true;
//         }),
//         catchError((error: any) => {
//           // doc.file.hasError = true;
//           // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
//           // doc.file.uploading = false;
//           // doc.file.uploaded = false;
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
// }
