import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ManageRegionStore } from '@bodyanalytics/front/admin/manage-regions/domain';
 // import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageRegionsTableComponent } from '@bodyanalytics/front/admin/manage-regions/ui';

@Component({
  selector: 'bodyanalytics-manage-regions-dashboard',
  templateUrl: './manage-regions-dashboard.component.html',
  styleUrls: ['./manage-regions-dashboard.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ManageRegionsTableComponent],
})
export class ManageRegionsDashboardComponent{
  entities = inject(ManageRegionStore).entities;
}

// extends BodyanalyticsBaseComponent implements OnInit {
//   protected contentEnum = ContentEnum;
//   protected routerUrlsEnum = RouterUrlsEnum;
//   public regionData: RegionsI[] = [];

//   constructor(private router: Router, private manageRegionService: ManageRegionService) {
//     super();
//   }
//   ngOnInit(): void {
//     this._fetchData();
//   }

//   private _fetchData() {
//     this.subscriptions.barrel = this.manageRegionService.getManageRegions().subscribe((results: RegionsI[]) => {
//       this.regionData = results;
//     });
//   }
// }
