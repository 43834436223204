import { signalStore, withState, withMethods, patchState, withHooks } from '@ngrx/signals';
import { withEntities, setAllEntities } from '@ngrx/signals/entities';
 import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { inject } from '@angular/core';

 import { tapResponse } from '@ngrx/operators';
import { BookingService } from './booking.service';
import { BookingI } from '@bodyanalytics/data-models-ui';
 

export const ManageBookingsStore = signalStore(
  withState({ loading: false }),
  withEntities<BookingI>(),
  withMethods((s, service = inject(BookingService)) => ({
    load: rxMethod<void>(
      pipe(
        switchMap(() => (patchState(s, { loading: true }), service.load())),
        tapResponse({
          next: entities => patchState(s, setAllEntities(entities)),
          error: () => patchState(s, { loading: false }),
          finalize: () => patchState(s, { loading: false }),
        }),
      ),
    ),
  })),
  withHooks(s => ({
    onInit: () => s.load(),
  })),
);
