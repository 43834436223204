import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BodyFatTestI, BodyMeasurementI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ContentEnum, GoogleActionsEnum, RouterUrlsEnum, TestingSessionStep } from '@bodyanalytics/ui-core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomerTestingComponent } from '../customer-testing/customer-testing.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-start-assement',
  standalone: true,
  templateUrl: './start-assement.component.html',
  styleUrls: ['./start-assement.component.scss'],
  imports: [CustomerTestingComponent, MatButtonModule, MatIconModule],
})
export class StartAssementComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected firstFormGroup!: FormGroup;
  protected secondFormGroup!: FormGroup;
  protected testingStep = TestingSessionStep.TEST;
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  protected bodyMeasurementData: BodyMeasurementI | undefined;
  protected testData: any = [];

  constructor(
    protected location: Location,
    private router: Router,
    private _formBuilder: FormBuilder,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
    this.$gaService.pageView('manage-bodymeasurements/start-test', 'Test Session')

  }

  ngOnInit(): void {
    this.getBodyMeasurement();
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
  }
  public currentStep = 1;

  public selectedOption = 1;

  previousStep() {
    this.currentStep--;
  }

  nextStep() {
    this.currentStep++;
  }

  selectOption(option: number) {
    this.selectedOption = 202;
    console.log('select option');
  }

  setCurrentStep(step: number) {
    this.currentStep = step;
  }

  getBodyMeasurement(): void {
    // const id = "2a";
    // this.subscriptions.barrel = this.service
    //   .getBodyMeasurement(id)
    //   .subscribe(testingEvent => (this.bodyMeasurementData = testingEvent));
  }

  public isComplete(signupDetails: BodyFatTestI) {
    this.saveSetupTestDetails(signupDetails);
  }

  public saveSetupTestDetails(signupDetails: BodyFatTestI): void {
    // this.service.stepUpdated.next(this.testingStep);
    //
    // this.testData = {
    //   dryWeight: signupDetails.dryWeight,
    //   height: signupDetails.height,
    //   wetWeightKg: signupDetails.wetWeightKg,
    // };
    // console.log('start assessment- saveSetupTestDetails');
    // this.subscriptions.barrel = this.service
    //   .calculateTestData({
    //     testDate: new Date(Date.now()).toISOString(),
    //     testTime: new Date(Date.now()).toISOString(),
    //     dryWeight: signupDetails.dryWeight,
    //     height: signupDetails.height,
    //     ethnicity: signupDetails.ethnicity,
    //     wetWeightKg: signupDetails.wetWeightKg,
    //     age: this.service.testDataList[0].clientDetails.age,
    //     birthDate: this.service.testDataList[0].clientDetails.birthDate,
    //     gender: this.service.testDataList[0].clientDetails.gender,
    //     sex: this.service.testDataList[0].clientDetails.gender,
    //     emailAddress: this.service.testDataList[0].clientDetails.emailAddress,
    //     waterTemperature: this.service.testDataList[0].sessionDetails.waterTemperature,
    //     isTraining: false,
    //     note: null,
    //     uid: null,
    //   })
    //   .subscribe(response => {
    //     this.calculatedResults = response;
    //     this.isResultsVisable = true;
    //   });
    // this.service.testSessionData.next(this.service.testDataList[0]);
    // this.stepper?.next();
  }

  public processBodyMeasurementUpdate(signupDetails: any): void {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    // this.subscriptions.barrel = this.service
    //   .updateBodyMeasurement(id, signupDetails)
    //   .pipe(
    //     tap(() => {
    //       //  console.log('here: processBodyMeasurementUpdate');
    //       // this.modalService.dismissAll();
    //       // this.submitted = true;
    //     }),
    //     catchError((error: any) => {
    //       // doc.file.hasError = true;
    //       // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
    //       // doc.file.uploading = false;
    //       // doc.file.uploaded = false;
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }
  public handleStepCompletion($event: any) {
    console.log('onCustomerTestingComplete , ', $event);
    this.$gaService.event('submit_test_complete_' +  $event, 'handleStepCompletion', GoogleActionsEnum.USER_CLICKED_BTN);

    // const id = "1";
    // const target: HTMLButtonElement = $event.target as HTMLButtonElement;
    // if (target.onsubmit) {
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/${target.value}`);
    // }
    // console.log('event:,',$event)
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/${$event}`);
    // this.isCustomerTestingComplete = !target.value;
    // if (this.isCustomerTestingComplete) {
    // this.stepper?.next();
    // }
  }
}
