import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-manage-booking-history-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './booking-history-list.component.html',
  styleUrl: './booking-history-list.component.scss',
})
export class ManageBookingHistoryListComponent {
  @Input() item: BookingI[] = []; // Use @Input decorator and initialize with an empty array

}

