import { Component } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { BodyanalyticsBaseComponent } from 'libs/front/shared/ui-core/src';
import { LoginI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
})
export class SecurityComponent extends BodyanalyticsBaseComponent {
  constructor(private profileService: ProfileService) {
    super();
  }

  update(login: LoginI) {
    this.subscriptions.barrel = this.profileService.updateSecurity(login).subscribe();
  }
}
