import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Location, NgClass, NgIf } from '@angular/common';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { ActivatedRoute } from '@angular/router';

import { catchError, filter, of, switchMap, tap } from 'rxjs';
import { ReactiveFormsModule } from '@angular/forms';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ManageCustomerService } from 'libs/front/admin/manage-customers/domain/src/lib/manage-customers.service';
import { ManageCustomersFormComponent } from '@bodyanalytics/front/admin/manage-customers/ui';

@Component({
  selector: 'bodyanalytics-customers-details',
  templateUrl: './customers-details.component.html',
  styleUrls: ['./customers-details.component.scss'],
  imports: [ManageCustomersFormComponent, ReactiveFormsModule, NgClass, NgIf],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomersDetailsComponent extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageCustomerService.getCustomer(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageCustomerService: ManageCustomerService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processCustomerUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageCustomerService
      .updateCustomer(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processCustomerUpdate');
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
