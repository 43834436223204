import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-admin-dashboard',
  standalone: true,
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  imports: [RouterLink],
})
export default class AdminDashboardComponent extends BodyanalyticsBaseComponent {
  protected routes = RouterUrlsEnum;

  constructor(private router: Router) {
    super();
  }
  public isManageBookings() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}`]);
  }
  public isManageContent() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}`]);
  }

  public isManageCompany() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_COMPANY}`]);
  }

  public isManageClubs() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}`]);
  }
  public isManageCustomers() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CUSTOMERS}`]);
  }

  public isManageDiscounts() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_DISCOUNTS}`]);
  }
  public isManageEmails() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}`]);
  }
  public isManageEvents() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS}`]);
  }
  public isManageOperators() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_OPERATORS}`]);
  }

  public isManageOrders() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_ORDERS}`]);
  }
  public isManageProducts() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_PRODUCTS}`]);
  }

  public isManageRegions() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_REGIONS}`]);
  }

  public isManagePayments() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_PAYMENTS}`]);
  }
  public isManageSms() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}`]);
  }

  public isManageSignups() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}`]);
  }
  public isManageUsers() {
    this.router.navigate([`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_USERS}`]);
  }
}
