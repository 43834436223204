/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BodyanalyticsBaseComponent, SessionStorageService, StripeProductPriceEnum, productPricingData } from '@bodyanalytics/ui-core';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { BookingDataI, ProductI, ProductPricingI } from '@bodyanalytics/data-models-ui';
import { Observable, of } from 'rxjs';
import { ENV } from '@bodyanalytics/app-config';

@Component({
  selector: 'bodyanalytics-order-summary-sidebar',
  templateUrl: './order-summary-sidebar.component.html',
  styleUrls: ['./order-summary-sidebar.component.scss'],
})
export class OrderSummarySidebarComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() priceChangeEvent = new EventEmitter<any>();

  @Input()
  item!: ProductI | null;

 protected SERVICE_FEE_NICKNAME = StripeProductPriceEnum.SERVICE_FEE_ID;
 protected SINGLE_HYDRO_TEST_PRODUCT_NICKNAME = StripeProductPriceEnum.SINGLE_HYDROTEST_PRODUCT_ID;

  public orderTotal: number = 0;
  public taxAmount: number = 0;
  public serviceFee: number = 0;

  productPrice: ProductPricingI[] = productPricingData;
  isPromoValid = false;
  afterTaxTotal$: Observable<number> = of(0);
  taxTotal$: Observable<number> = of(0);

  public testDetailsForm = new FormGroup({
    testType: new FormControl('', [Validators.required]),
    discountCode: new FormControl(),
  });

  constructor(
    private mobileTestingCalendarService: MobileTestingCalendarService, // private router: Router, // private activatedRoute: ActivatedRoute, // private modalService: NgbModal, // public auth: AuthService, // private mapsAPILoader: MapsAPILoader,
    private sessionStorageService: SessionStorageService,
    @Inject(ENV) private appConfig: any,
  ) {
    // private http: HttpClient,
    // private _formBuilder: FormBuilder
    super();
  }
  ngOnInit(): void {
    this.testDetailsForm.patchValue({
      discountCode: '20FIT',
    });

    const orderData = this.sessionStorageService.getBookingData()!.orderTotal;
    console.log('orderData: ', orderData!);
    this.orderTotal = orderData!.total;
    this.taxAmount = orderData!.salesTax;
    this.serviceFee = orderData!.serviceFee;
  }

  public applyPromo() {
    this.isPromoValid = true;

    this.productPrice[0].serviceDiscountedPrice = 20;
    // console.log('promo applied', this.testDetailsForm.value.promoCode);
    // this.subscriptions.barrel = this.mobileTestingCalendarService
    //   .validatePromo(this.testDetailsForm.value.discountCode as string)
    //   .subscribe((response) => {
    //     if (response) {
    //       this.isPromoValid = true;
    //     }
    //   });
  }

  isMainPrice(priceId: string): boolean {
    const mainPriceIds = [
      this.appConfig.stripe_package_price_1,
      this.appConfig.stripe_package_price_2,
      this.appConfig.stripe_package_price_3,
      this.appConfig.stripe_package_price_4,
      this.appConfig.stripe_package_price_5,
      this.appConfig.stripe_package_price_6,
      this.appConfig.stripe_package_price_7
    ];
    return mainPriceIds.includes(priceId);
  }
  isServiceFee(priceId: string): boolean {
    return priceId === 'service-fee';
  }
}
