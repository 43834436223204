import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { OperatorI, RegionsI, StateI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { tap, catchError, of } from 'rxjs';
import { ManageRegionService } from '@bodyanalytics/front/admin/manage-regions/domain';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'bodyanalytics-manage-regions-form',
  templateUrl: './manage-regions-form.component.html',
  styleUrls: ['./manage-regions-form.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, NgIf, NgFor, RouterLink],
})
export class ManageRegionsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected signupsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/`;
  protected eventsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS}/list/`;
  protected eventsDetails = RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS;

  @Output() submitFormEvent = new EventEmitter<any>();
  item = input.required<RegionsI>();

  public EVENT_ID = '';
  public CLUB_ID = '';

  formsubmit = false;

  protected stateList: StateI[] = [];

  public regionDetailsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    regionName: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),

    zipCode: new FormControl('', [Validators.required]),
    clubLogo: new FormControl('', [Validators.required]),
    commission: new FormControl(0, [Validators.required]),
    contactName: new FormControl('', [Validators.required]),
    createdAt: new FormControl('', [Validators.required]),
    id: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    signupUrl: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    uid: new FormControl('', [Validators.required]),
    updatedAt: new FormControl('', [Validators.required]),

    clubInRegionId: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
    contactTitle: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private manageRegionService: ManageRegionService,
    // private signupService: SignupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getClub();

    // this.signupService.getStates().subscribe((state: StateI[]) => {
    //   this.stateList = state;
    // });
  }

  public getClub(): void {
    // console.log('this.item:', this.item!.regionName!)
    // if (this.regionDetailsForm.valid) {
    // if (this.item) {
    this.regionDetailsForm.patchValue({
      regionName: this.item().regionName as string,
      // // address1: this.item().address1,
      // emailAddress: this.item().emailAddress,
      // address2: this.item().address2,
      // city: this.item().city,
      // clubLogo: this.item().clubLogo,
      // commission: this.item().commission,
      // contactName: this.item().contactName,
      // phoneNumber: this.item().phoneNumber,
      // signupUrl: this.item().signupUrl,
      // state: this.item().state,
      // zipCode: this.item().zipCode,
      // clubInRegionId: this.item().clubInRegionId,
      // note: this.item().note,
      // contactTitle: this.item().contactTitle,
    });
  }

  public updateClub(): void {
    this.submitFormEvent.emit({
      regionName: this.regionDetailsForm.value.regionName,
      address1: this.regionDetailsForm.value.address1,
      emailAddress: this.regionDetailsForm.value.emailAddress,
      address2: this.regionDetailsForm.value.address2,
      city: this.regionDetailsForm.value.city,
      clubLogo: this.regionDetailsForm.value.clubLogo,
      commission: this.regionDetailsForm.value.commission,
      contactName: this.regionDetailsForm.value.contactName,
      phoneNumber: this.regionDetailsForm.value.phoneNumber,
      signupUrl: this.regionDetailsForm.value.signupUrl,
      state: this.regionDetailsForm.value.state,
      zipCode: this.regionDetailsForm.value.zipCode,
      clubInRegionId: this.regionDetailsForm.value.clubInRegionId,
      note: this.regionDetailsForm.value.note,
      contactTitle: this.regionDetailsForm.value.contactTitle,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  // protected duplicateEvent() {
  //   this.subscriptions.barrel = this.manageRegionService
  //     .duplicateClub(`${this.EVENT_ID}`, this.item as ClubI)
  //     .pipe(
  //       tap(() => {
  //         console.log(`duplicateClub - ${this.EVENT_ID}`);
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageRegionService
      .deleteManageRegion(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }

  /**
   * Returns form
   */
  get form() {
    return this.regionDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  openDeleteDialog(originalClubId: string): void {
    // const dialogRef = this.dialog.open(ManageClubDeleteDialogComponent, {
    //   width: '500px',
    //   // other configuration
    // });
    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result) {
    //     this.processCancellation(originalClubId);
    //   } else {
    //     // this.loadingCheckInSession = false;
    //   }
    // });
  }

  processCancellation(originalClubId: string) {
    // this.isSearchSearched = false;
    // this.spinnerTitle = 'Cancelling';

    this.subscriptions.barrel = this.manageRegionService.deleteManageRegion(originalClubId).subscribe({
      next: (response: any) => {
        // this.loadingCheckInSession = false;
        // this.manageSignup = this.manageSignup.filter(booking => booking.id !== originalBookingId);
      },
      error: (error: any) => {
        console.error('Error canceling booking:', error);
      },
    });
  }
  // protected goToSignupPage() {
  //   console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/${this.EVENT_ID}`);
  //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/${this.EVENT_ID}`);
  // }
}

// extends BodyanalyticsBaseComponent implements OnInit {
//   @Output() submitFormEvent = new EventEmitter<any>();
//   @Input()
//   item!: RegionsI | null;
//   public EVENT_ID = '';
//   public CLUB_ID = '';

//   public operators: OperatorI[] = [];
//   // public regionDetailsForm: FormGroup = {} as FormGroup;

//   public regionDetailsForm = new FormGroup({
//     regionName: new FormControl('', [Validators.required]),
//     regionLogo: new FormControl('', [Validators.required]),
//     regionDescription: new FormControl('', [Validators.required]),
//     regionTerritoryType: new FormControl('', [Validators.required]),
//     regionActivatedDate: new FormControl('', [Validators.required]),
//     regionStatus: new FormControl('', [Validators.required]),
//   });

//   constructor(
//     // private modalService: NgbModal,
//     private router: Router,
//     private manageRegionService: ManageRegionService,
//   ) {
//     super();
//   }

//   ngOnInit(): void {
//     // this.subscriptions.barrel = this.manageRegionService.getOperatorsList().subscribe(response => {
//     //   this.operators = response;

//     //   // If operators were fetched, set the default value to the first one
//     //   // if (this.operators.length > 0) {
//     //   //   this.regionDetailsForm.get('operators')?.setValue(this.operators[0].id);
//     //   // }
//     // });
//     this.getRegion();
//     // this.EVENT_ID = this.item?.testingevent_id as string;
//     // this.CLUB_ID = this.item?.club_testingevent_id as string;
//   }

//   public getRegion(): void {
//     // if (this.regionDetailsForm.valid) {
//     // if (this.item as RegionsI) {

//     this.regionDetailsForm.patchValue({
//       regionName: this.item?.regionName,
//       regionLogo: this.item?.regionLogo,
//       regionDescription: this.item?.regionDescription,
//       regionTerritoryType: this.item?.regionTerritoryType,
//       regionActivatedDate: this.item?.regionActivatedDate,
//       regionStatus: this.item?.regionStatus,
//     });
//     // }
//   }

//   public updateTestingEvent(): void {
//     this.submitFormEvent.emit({
//       regionName: this.regionDetailsForm.value.regionName,
//       regionLogo: this.regionDetailsForm.value.regionLogo,
//       regionDescription: this.regionDetailsForm.value.regionDescription,
//       regionTerritoryType: this.regionDetailsForm.value.regionTerritoryType,
//       regionActivatedDate: this.regionDetailsForm.value.regionActivatedDate,
//       regionStatus: this.regionDetailsForm.value.regionStatus,
//     });
//   }

//   openModal(content: any) {
//     // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//   }

//   convertTimeToISOString(time: string): string {
//     const dateToday: Date = new Date();
//     // Split the hours and minutes
//     const [hours, minutes] = time.split(':');

//     // Set the hours and minutes to the specified date
//     dateToday.setHours(Number(hours), Number(minutes), 0, 0);

//     // Return the ISO string
//     const testDate = new Date(dateToday);

//     return testDate.toISOString();
//   }

//   protected goToSignupPage() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
//   }
//   protected duplicateRegion() {
//     this.subscriptions.barrel = this.manageRegionService
//       .addManageRegion(this.item as RegionsI)
//       .pipe(
//         tap(() => {
//           console.log(`duplicateTestingEvent - ${this.EVENT_ID}`);
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   protected sendEmailReminder() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
//   }
//   protected sendSmsReminder() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
//   }
//   protected cancelRegion() {
//     this.subscriptions.barrel = this.manageRegionService
//       .deleteManageRegion(`${this.EVENT_ID}`)
//       .pipe(
//         tap(() => {
//           console.log(`cancelEvent - ${this.EVENT_ID}`);
//         }),
//         catchError((error: any) => {
//           return of(error);
//         }),
//       )
//       .subscribe();
//   }
//   protected viewClubDetails() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
//   }
//   protected showSignupList() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
//   }
//   closeModal() {
//     console.log('modal');
//     //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-events`);
//     // this.modalService.dismissAll();
//   }
// }
