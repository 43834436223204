import { Route } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { SetupInitComponent } from './containers/setup-init/setup-init.component';

export const setupUiRoutes: Route[] = [
  {
    path: 'auth/admin-setup',
    component: SetupInitComponent,
    canActivate: [AuthGuard],
  },

  // {
  //   path: 'auth/manage-contacts',
  //   component: ManageContactsComponent,
  //   children: [
  //     { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  //     {
  //       path: 'dashboard',
  //       component: ManageContactsDashboardComponent,
  //       pathMatch: 'full',
  //     },
  //     {
  //       path: 'detail/:id',
  //       component: ManageContactsDetailComponent,
  //     },
  //   ],
  // }
];
