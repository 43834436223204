import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EmailI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';

import { tap, catchError, of } from 'rxjs';
import { EmailService } from '../../services/email.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bodyanalytics-manage-emails-table',
  templateUrl: './manage-emails-table.component.html',
  styleUrls: ['./manage-emails-table.component.scss'],
})
export class ManageEmailsTableComponent extends BodyanalyticsBaseComponent implements AfterViewInit {
  @Input()
  item!: EmailI[];

  submitted = false;
  checked = '';
  dataSource: MatTableDataSource<EmailI> = new MatTableDataSource<EmailI>();

  public displayedColumns: string[] = ['id', 'testEventName', 'status'];
  public emailsForm!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  isEmailTableEnabled = true;

  isEmailIdSelected = '';
  public testingEventTableData: EmailI[] = [];

  constructor(private manageEmailService: EmailService, private fb: FormBuilder, private router: Router) {
    super();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource = new MatTableDataSource(this.item);
  }

  onCheckboxChange($event: Event) {
    const target: HTMLInputElement = $event.target as HTMLInputElement;
    if (target.checked) {
      this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS_DETAILS}/${target.value}`);
    }
  }

  openModal(deleteContent: any, id: string) {
    // const id = this.route.snapshot.paramMap.get('id') as string;
    // this.modalService.open(deleteContent, { backdropClass: 'light-blue-backdrop', size: 'lg' });
    this.isEmailIdSelected = id;
  }

  deleteEmail() {
    this.subscriptions.barrel = this.manageEmailService
      .deleteEmail(this.isEmailIdSelected)
      .pipe(
        tap(() => {
          this.submitted = true;
          location.reload();
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  /**
   * Modal Open
   * @param content modal content
   */

  public createEmail(signupDetails: any): void {
    this.subscriptions.barrel = this.manageEmailService
      .addEmail(signupDetails)
      .pipe(
        tap(() => {
          this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
