import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OrderI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { Observable, catchError, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManageOrderService {
  private ordersUrl = `${ApiLocalRoutesEnum.API_SUFFIX_ORDERS}`;
  private ordersDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_ORDERS_DETAILS}`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
  ) {}

  getOrder(id: string): Observable<any> {
    const url = `${this.appConfig.api}/${this.ordersDetailsUrl}/${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched order id=${id}`)),
      catchError(this.sharedErrorService.handleError<OrderI>(`getOrders id=${id}`)),
    );
  }

  load(): Observable<OrderI[]> {
    const url = `${this.appConfig.api}/${this.ordersUrl}`;
    return this.http.get<OrderI[]>(url).pipe(
      tap(_ => console.log('fetched testingEvents')),
      catchError(this.sharedErrorService.handleError<OrderI[]>('getOrders', [])),
    );
  }
  updateOrders(id: string, order: OrderI): Observable<any> {
    const url = `${this.appConfig.api}/${this.ordersDetailsUrl}/${id}`;
    return this.http.patch(url, order, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`updated order id=${id}`)),
      catchError(this.sharedErrorService.handleError<any>('updateOrders')),
    );
  }

  deleteOrders(id: string): Observable<OrderI> {
    const url = `${this.appConfig.api}/${this.ordersUrl}/${id}`;

    return this.http.delete<OrderI>(url, this.httpOptions).pipe(
      tap(_ => this.sharedLogService.log(`deleted order id=${id}`)),
      catchError(this.sharedErrorService.handleError<OrderI>('deleteOrders')),
    );
  }

  getOrders(): Observable<OrderI[]> {
    const url = `${this.appConfig.api}/${this.ordersUrl}`;
    return this.http.get<OrderI[]>(url).pipe(
      tap(_ => console.log('fetched orders')),
      catchError(this.sharedErrorService.handleError<OrderI[]>('getOrderss', [])),
    );
  }

  addOrders(order: OrderI): Observable<OrderI> {
    const url = `${this.appConfig.api}/${this.ordersUrl}`;
    return this.http.post<OrderI>(url, order, this.httpOptions).pipe(
      tap((newOrders: OrderI) => this.sharedLogService.log(`added order w/ id=${newOrders.id}`)),
      catchError(this.sharedErrorService.handleError<OrderI>('addOrders')),
    );
  }
  searchOrderss(term: string): Observable<OrderI[]> {
    const url = `${this.appConfig.api}/${this.ordersUrl}/search/?firstName=${term}`;
    console.log('here: ', term);
    if (!term.trim()) {
      // if not search term, return empty order array.
      return of([]);
    }

    return this.http.get<OrderI[]>(`${url}`).pipe(
      tap(x =>
        x.length ? console.log(`found orders matching "${term}"`) : console.log(`no orders matching "${term}"`),
      ),
      catchError(this.sharedErrorService.handleError<OrderI[]>('searchOrders', [])),
    );
  }
}
