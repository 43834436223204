import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
 import { filter, switchMap, tap, catchError, of } from 'rxjs';
import { BookingService } from '@bodyanalytics/front/admin/manage-bookings/domain';


@Component({
  selector: 'bodyanalytics-booking-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './booking-list.component.html',
  styleUrl: './booking-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class BookingListComponent  extends BodyanalyticsBaseComponent {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();

  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageBookingService.getBookingListById(id)),
    ),
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageBookingService: BookingService,
  ) {
    super();
  }
  public goBack() {
    this.location.back();
  }

  public processBookingUpdate(signupDetails: any): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageBookingService
      .updateBookings(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processBookingUpdate');
          // this.modalService.dismissAll();
          // this.submitted = true;
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}

