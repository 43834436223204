import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { OrderConfirmationI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent } from '@bodyanalytics/ui-core';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { SignupService } from '../../services/signup.service';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-reschedule-confirmation-page',
  templateUrl: './reschedule-confirmation-page.component.html',
  styleUrls: ['./reschedule-confirmation-page.component.scss'],
  providers: [DatePipe],
})
export class RescheduleConfirmationPageComponent extends BodyanalyticsBaseComponent implements OnInit {
  public orderConfirmationData!: OrderConfirmationI;
  public newSlotTime = '';
  constructor(
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private signupService: SignupService,
    private datePipe: DatePipe,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.signupService.removeUserData();
    this.mobileTestingCalendarService.clearRescheduleData();
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ orderData }) => {
        if (orderData) {
          this.orderConfirmationData = orderData;
          this.$gaService.pageView(
            'reschedule-confirmation/' + this.orderConfirmationData.id,
            'Reschedule Confirmation',
          );
          if(this.orderConfirmationData.booking.slot.slotTime){
            this.newSlotTime = this.convertToCentralTime(this.orderConfirmationData.booking.slot.slotTime);
          }
        }
      });
    }
  }

  convertToCentralTime(utcDateString: string) {
    const date = new Date(utcDateString);

    return this.datePipe.transform(date, 'shortTime') as string;
  }
}
