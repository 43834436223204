import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BodyanalyticsBaseComponent,
  RouterUrlsEnum,
  SessionStorageKeyEnum,
  SessionStorageService,
  StripeProductPriceEnum,
} from '@bodyanalytics/ui-core';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { DecimalPipe } from '@angular/common';

import { StripeElementsOptions } from '@stripe/stripe-js';
import {
  BookingDataI,
  CheckoutDataI,
  OrderTotalsI,
  ProductI,
  SignupFormI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';
import { CheckoutService } from '../../services/checkout.service';
import { SignupService } from '../../services/signup.service';
import { Observable, of, Subscription } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';


@Component({
  selector: 'bodyanalytics-signup-checkout',
  templateUrl: './signup-checkout.component.html',
  styleUrls: ['./signup-checkout.component.scss'],
})
export class SignupCheckoutComponent extends BodyanalyticsBaseComponent implements OnInit, OnDestroy {
  public selectedProduct: ProductI | null = null;
  public loadingSession = false;

  afterTaxTotal$: Observable<number> = of(0);
  taxTotal$: Observable<number> = of(0);

  public eventData!: TestingEventI;
  public elementsOptions: StripeElementsOptions = { locale: 'en' };
  bookingTotalForm: FormGroup;
  clientSecret!: string;

  bookingData: BookingDataI | null = null;
  savedPersonalInfo!: SignupFormI;
  personalInfo!: SignupFormI;

  isCustomerCreditAvailable = false;

  afterTaxPrice = 0;
  beforeTaxPrice = 0;
  finalTotal = 0;
  serviceFee = 3.0;
  saleTax = 0;
  taxRate = 0.0825;
  total = 0;
  format = '2.0-2';

  promoDiscount = 0;
  promoDiscountAmountOff = 0;
  promoDiscountPercentOff = 0;
  promoErrorMessage = '';
  promoSubmitted = false;

  promoValid = false;
  personalInfoSaved = false;
  isEditEnabled = false;


  constructor(
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private checkoutService: CheckoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public auth: AuthService,
    private signupService: SignupService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private decimalPipe: DecimalPipe,
    private sessionStorageService: SessionStorageService,
    private gaService: GoogleAnalyticsService,
  ) {
    super();

    this.bookingTotalForm = this.formBuilder.group({
      promoCode: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.initializeComponent();
  }

  override ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeComponent(): void {
    this.loadEventData();
    this.loadBookingData();
    this.initializePaymentIntent();
  }

  private loadEventData(): void {
    const routeDataSubscription = this.activatedRoute.data.subscribe(({ eventData }) => {
      if (eventData) {
        this.eventData = eventData;
        this.gaService.pageView(`checkout/${this.eventData.id}`, 'Checkout');
      }
    });
    this.subscriptions.add(routeDataSubscription);
  }

  private loadBookingData(): void {
    const bookingDataJson = sessionStorage.getItem(SessionStorageKeyEnum.BOOKING_TOKEN);
    if (bookingDataJson) {
      this.bookingData = JSON.parse(bookingDataJson);
      this.total = this.bookingData ? 59.99 : 0;
      this.beforeTaxPrice = this.bookingData ? +(this.decimalPipe.transform(this.total, this.format) ?? 0) : 0;
    }
  }

  private initializePaymentIntent(): void {
    const paymentIntentSubscription = this.checkoutService.createPaymentIntent().subscribe(pi => {
      if (pi.client_secret) {
        this.clientSecret = pi.client_secret;
        this.elementsOptions.clientSecret = this.clientSecret;
      }
    });
    this.subscriptions.add(paymentIntentSubscription);
  }

  public redeemPromo(): void {
    const promoCode = this.bookingTotalForm.get('promoCode')?.value;
    if (!promoCode) return;

    this.promoSubmitted = true;
    const validatePromoSubscription = this.checkoutService.validatePromo(promoCode).subscribe({
      next: response => this.handlePromoResponse(response),
      error: error => this.handlePromoError(error),
    });

    this.subscriptions.add(validatePromoSubscription);
  }

  private handlePromoResponse(response: any): void {
    if (response) {
      this.promoDiscountAmountOff = response.discountAmount / 100;
      this.promoDiscountPercentOff = response.percent_off;

      this.promoDiscount = this.promoDiscountPercentOff
        ? (this.promoDiscountPercentOff / 100) * this.finalTotal
        : this.promoDiscountAmountOff;

      this.finalTotal -= this.promoDiscount;
      this.promoValid = true;
      this.promoErrorMessage = '';
    } else {
      this.promoValid = false;
      this.promoErrorMessage = 'Promo is not valid. Please try again.';
    }
    this.calculateTotal();
  }

  private handlePromoError(error: any): void {
    console.error('Error validating promo code:', error);
    this.promoErrorMessage = 'Promo code is invalid. Please try again.';
  }

  public processPaymentForm(paymentDetails: any): void {
    if (paymentDetails.paymentIntent?.status === 'succeeded') {
      const paymentDetailsData = {
        status: paymentDetails.paymentIntent.status,
        amount: paymentDetails.paymentIntent.amount,
      };

      const processOrderSubscription = this.checkoutService.processOrder('', paymentDetailsData).subscribe(response => {
        if (response) {
          this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_ORDER_CONFIRMATION}/${response.id}`);
        }
      });

      this.subscriptions.add(processOrderSubscription);
    }
  }

  public calculateTotal(): void {
    this.loadingSession = true;
    const checkoutData: CheckoutDataI = {
      selectedProductId: this.selectedProduct?.id ?? '',
      quantity: 1,
      tax_state: '',
      serviceFeePriceId: '',
      productDefaultPriceId: '',
    };

    this.selectedProduct?.prices.forEach(price => {
      if (price.id === StripeProductPriceEnum.SERVICE_FEE_ID) {
        checkoutData.serviceFeePriceId = price.id;
      } else if (price.id === StripeProductPriceEnum.SINGLE_HYDROTEST_PRODUCT_ID) {
        checkoutData.productDefaultPriceId = price.id;
      }
    });

    const calculateOrderSubscription = this.checkoutService.calculateOrder(checkoutData).subscribe(totalData => {
      this.afterTaxTotal$ = of(totalData.totalPrice);
      this.taxTotal$ = of(totalData.taxAmount);

      const orderTotals: OrderTotalsI = {
        subtotal: 0,
        salesTax: totalData.taxAmount,
        total: totalData.totalPrice,
        serviceFee: totalData.serviceFee,
        promocode: '',
      };

      this.checkoutService.addBookingTotal(orderTotals);
      this.loadingSession = false;
    });

    this.subscriptions.add(calculateOrderSubscription);
  }
}
