import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { BodyFatTestI } from '@bodyanalytics/data-models-ui';
import { RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { animations } from './manage-bodymeasurements-table.animations';
import { MatTableModule } from '@angular/material/table';
import { DatePipe, JsonPipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';

@Component({
  animations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-manage-bodymeasurements-table',
  standalone: true,
  styleUrls: ['./manage-bodymeasurements-table.component.scss'],
  templateUrl: './manage-bodymeasurements-table.component.html',
  imports: [MatTableModule, DatePipe, RouterLink, MatSortModule, JsonPipe],
})
export class ManageBodymeasurementsTableComponent {
  protected bfTestsDetail = RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL;
  protected submitted = false;
  protected displayedColumns = ['id', /*'firstName', 'lastName',*/ 'fatPercent', 'dryweight', 'date'];
  protected columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  protected expandedElement?: BodyFatTestI;

  entities = input.required<BodyFatTestI[]>();

  // TODO
  // deleteBodyMeasurement() {
  //   this.subscriptions.barrel = this.manageBodyMeasurementService
  //     .deleteBodyMeasurement(this.isBodyMeasurementIdSelected)
  //     .pipe(
  //       tap(() => {
  //         this.submitted = true;
  //         location.reload();
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }

  // TODO
  // public createBodyMeasurement(signupDetails: BodyFatTestI): void {
  //   this.subscriptions.barrel = this.manageBodyMeasurementService
  //     .addBodyMeasurement(signupDetails)
  //     .pipe(
  //       tap(() => {
  //         this.submitted = true;
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
}
