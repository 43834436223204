// export * from './lib/front-admin-manage-regions-domain/front-admin-manage-regions-domain.component';
export * from './lib/manage-regions.service';
export * from './lib/manage-regions.signal-store-feature';
export * from './lib/manage-regions.store';

import { TypeProvider, Provider } from '@angular/core';
import { ManageRegionService } from './lib/manage-regions.service';
import { ManageRegionStore } from './lib/manage-regions.store';

export const provideRegions = (): Provider[] => [ManageRegionService, ManageRegionStore];
