<h2 class="h5 text-primary mb-4">Password</h2>
<form
  class="needs-validation pb-3 pb-lg-4"
  novalidate=""
  [formGroup]="securityForm"
  fxLayout="column"
  fxLayoutAlign="center none">
  <div class="row">
    <div class="col-sm-6 mb-4">
      <label for="currentPassword" class="form-label fs-base">Current password</label>
      <div class="password-toggle">
        <input
          class="form-control form-control-lg"
          placeholder=""
          type="password"
          formControlName="currentPassword"
          #currentPassword />

        <div class="invalid-tooltip position-absolute top-100 start-0">Incorrect password!</div>
      </div>
    </div>
  </div>
  <div class="row pb-2">
    <div class="col-sm-6 mb-4">
      <label for="newPassword" class="form-label fs-base">New password</label>
      <div class="password-toggle">
        <input
          class="form-control form-control-lg"
          [ngClass]="{ 'is-invalid': formsubmit && form.newPassword.errors }"
          placeholder=""
          type="password"
          formControlName="newPassword"
          #newPassword />
        <div class="invalid-tooltip position-absolute top-100 start-0">Incorrect password!</div>
      </div>
    </div>
    <div class="col-sm-6 mb-4">
      <label for="confirmPassword" class="form-label fs-base">Confirm new password</label>
      <div class="password-toggle">
        <input
          class="form-control form-control-lg"
          placeholder=""
          type="password"
          formControlName="confirmPassword"
          #confirmPassword />
        <div class="invalid-tooltip position-absolute top-100 start-0">Incorrect password!</div>
      </div>
    </div>
  </div>
  <div class="d-flex mb-3">
    <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="updateSettings()">Save changes</button>
  </div>
</form>
