// export * from './lib/front-admin-manage-discounts-domain/front-admin-manage-discounts-domain.component';
export * from './lib/manage-discounts.service';
export * from './lib/manage-discounts.signal-store-feature';
export * from './lib/manage-discounts.store';


import { TypeProvider, Provider } from '@angular/core';
import { ManageDiscountService } from './lib/manage-discounts.service';
import { ManageDiscountsStore } from './lib/manage-discounts.store';



export const provideDiscounts = (): Provider[] => [ManageDiscountService, ManageDiscountsStore];
