import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { OrderConfirmationI } from '@bodyanalytics/data-models-ui';
import { Observable, catchError, throwError } from 'rxjs';
import { OrderServicePub } from '../services/order.service';

@Injectable({ providedIn: 'root' })
export class OrderConfirmResolver implements Resolve<OrderConfirmationI> {
  constructor(private service: OrderServicePub) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<OrderConfirmationI> | Observable<never> | Promise<OrderConfirmationI> | OrderConfirmationI {
    // return this.service.getTestingEvent(route.paramMap.get('id') as string).pipe(
    //   take(1),
    //   mergeMap((testEvent: OrderConfirmationI) => {
    //     if (testEvent) {
    //       return of(testEvent);
    //     } else {
    //       return throwError(() => new Error(` No data found for ${testEvent} `));
    //     }
    //   }),
    //   catchError((failure: any) => {
    //     // this.navHubService.routeToHome();
    //     return throwError(() => new Error(` Error:  ${failure} `));
    //   })
    // );
    return this.service.getOrderConfirmation(route.paramMap.get('id') as string).pipe(
      catchError((failure: any) => {
        // this.navHubService.routeToHome();
        return throwError(() => new Error(` Error:  ${failure} `));
      }),
    );
  }
}
