<div class="row my-3">
  <div class="col-md-4 col-sm-12">
    <ul class="list-group">
      <ng-container *ngIf="item && item.length">
        <ng-container *ngFor="let testingEvent of item">
          <li class="list-group-item">
            {{testingEvent.testEventName}}
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="item && !item.length">
        <li class="list-group-item">No testingEvents found.</li>
      </ng-container>
    </ul>
  </div>
</div>
