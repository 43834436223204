<section id="manage-event-details" class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          <div class="my-5">
            <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            >

            <h4 class="h5">Payment Details</h4>
          </div>
          @if(data(); as data) {
            <bodyanalytics-manage-payments-form
              [item]="data"
              (submitFormEvent)="processPaymentUpdate($event)" />
            }
        </div>
      </div>
    </div>
  </div>
</section>
