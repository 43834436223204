import { Component, EventEmitter, OnInit, Output, input } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { ClubI, StateI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ManageClubDeleteDialogComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
 import { SignupService } from '@bodyanalytics/calendar-ui';
import { ManageClubService } from '@bodyanalytics/front/admin/manage-clubs/domain';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'bodyanalytics-manage-clubs-form',
  templateUrl: './manage-clubs-form.component.html',
  styleUrls: ['./manage-clubs-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink, ManageClubDeleteDialogComponent],
})
export class ManageClubsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected signupsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/`;
  protected eventsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS}/list/`;
  protected eventsDetails = RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS;


  @Output() submitFormEvent = new EventEmitter<any>();
   item = input.required<ClubI>();

  public EVENT_ID = '';
  public CLUB_ID = '';

  formsubmit = false;

  protected stateList: StateI[] = [];

  public clubDetailsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    clubName: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),

    zipCode: new FormControl('', [Validators.required]),
    clubLogo: new FormControl('', [Validators.required]),
    commission: new FormControl(0, [Validators.required]),
    contactName: new FormControl('', [Validators.required]),
    createdAt: new FormControl('', [Validators.required]),
    id: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    signupUrl: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    uid: new FormControl('', [Validators.required]),
    updatedAt: new FormControl('', [Validators.required]),

    clubInRegionId: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
    contactTitle: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private manageClubService: ManageClubService,
    private signupService: SignupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getClub();

    this.signupService.getStates().subscribe(state => {
      this.stateList = state;
    });
  }

  public getClub(): void {
    // console.log('this.item:', this.item!.clubName!)
    // if (this.clubDetailsForm.valid) {
    // if (this.item) {
    this.clubDetailsForm.patchValue({
      clubName: this.item().clubName as string,
      address1: this.item().address1,
      emailAddress: this.item().emailAddress,
      address2: this.item().address2,
      city: this.item().city,
      clubLogo: this.item().clubLogo,
      commission: this.item().commission,
      contactName: this.item().contactName,
      phoneNumber: this.item().phoneNumber,
      signupUrl: this.item().signupUrl,
      state: this.item().state,
      zipCode: this.item().zipCode,
      clubInRegionId: this.item().clubInRegionId,
      note: this.item().note,
      contactTitle: this.item().contactTitle,
    });

  }

  public updateClub(): void {
    this.submitFormEvent.emit({
      clubName: this.clubDetailsForm.value.clubName,
      address1: this.clubDetailsForm.value.address1,
      emailAddress: this.clubDetailsForm.value.emailAddress,
      address2: this.clubDetailsForm.value.address2,
      city: this.clubDetailsForm.value.city,
      clubLogo: this.clubDetailsForm.value.clubLogo,
      commission: this.clubDetailsForm.value.commission,
      contactName: this.clubDetailsForm.value.contactName,
      phoneNumber: this.clubDetailsForm.value.phoneNumber,
      signupUrl: this.clubDetailsForm.value.signupUrl,
      state: this.clubDetailsForm.value.state,
      zipCode: this.clubDetailsForm.value.zipCode,
      clubInRegionId: this.clubDetailsForm.value.clubInRegionId,
      note: this.clubDetailsForm.value.note,
      contactTitle: this.clubDetailsForm.value.contactTitle,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  // protected duplicateEvent() {
  //   this.subscriptions.barrel = this.manageClubService
  //     .duplicateClub(`${this.EVENT_ID}`, this.item as ClubI)
  //     .pipe(
  //       tap(() => {
  //         console.log(`duplicateClub - ${this.EVENT_ID}`);
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageClubService
      .deleteClub(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }

  /**
   * Returns form
   */
  get form() {
    return this.clubDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  openDeleteDialog(originalClubId: string): void {
    const dialogRef = this.dialog.open(ManageClubDeleteDialogComponent, {
      width: '500px',
      // other configuration
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.processCancellation(originalClubId);
      } else {
        // this.loadingCheckInSession = false;
      }
    });
  }

  processCancellation(originalClubId: string) {
    // this.isSearchSearched = false;
    // this.spinnerTitle = 'Cancelling';

    this.subscriptions.barrel = this.manageClubService.deleteClub(originalClubId).subscribe({
      next: response => {
        // this.loadingCheckInSession = false;
        // this.manageSignup = this.manageSignup.filter(booking => booking.id !== originalBookingId);
      },
      error: error => {
        console.error('Error canceling booking:', error);

      },
    });
  }
  // protected goToSignupPage() {
  //   console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/${this.EVENT_ID}`);
  //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/${this.EVENT_ID}`);
  // }
}
