export * from './lib/manage-clubs.service';
export * from './lib/manage-clubs.signal-store-feature';
export * from './lib/manage-clubs.store';


import { TypeProvider, Provider } from '@angular/core';
import { ManageClubService } from './lib/manage-clubs.service';
import { ManageClubsStore } from './lib/manage-clubs.store';



export const provideClubs = (): Provider[] => [ManageClubService, ManageClubsStore];
