import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BodyanalyticsBaseComponent, ContentEnum, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { catchError, filter, of, switchMap, tap } from 'rxjs';
import { Location, NgClass, NgIf } from '@angular/common';
import { ManageTestingEventService } from '@bodyanalytics/front/admin/manage-events/domain';
import { ReactiveFormsModule } from '@angular/forms';
import { ManageEventsFormComponent } from '@bodyanalytics/front/admin/manage-events/ui';
import { toSignal, toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'bodyanalytics-events-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  standalone: true,
  imports: [ ManageEventsFormComponent, ReactiveFormsModule, NgClass, NgIf],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageEventsDetailsComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected contentEnum = ContentEnum;
  protected routerUrlsEnum = RouterUrlsEnum;
  id = input<string>();
  protected data = toSignal(
    toObservable(this.id).pipe(
      filter(Boolean),
      switchMap(id => this.manageTestingEventService.getTestingEvent(id)),
    ),
  );
 
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private manageTestingEventService: ManageTestingEventService,
  ) {
    super();
  }

  goBack() {
    this.location.back();
  }

  ngOnInit(): void {
   }


  processTestingEventUpdate(signupDetails: TestingEventI): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    this.subscriptions.barrel = this.manageTestingEventService
      .updateTestingEvent(id, signupDetails)
      .pipe(
        tap(() => {
          console.log('here: processTestingEventUpdate');
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
