import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
 import { Router, RouterLink } from '@angular/router';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { BookingI, ClubI, OperatorI, StateI } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, ManageClubDeleteDialogComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SignupService } from '@bodyanalytics/calendar-ui';
import { ManageClubService } from '@bodyanalytics/front/admin/manage-clubs/domain';
import { BookingService } from '@bodyanalytics/front/admin/manage-bookings/domain';

@Component({
  selector: 'bodyanalytics-manage-booking-form',
  templateUrl: './manage-booking-form.component.html',
  styleUrls: ['./manage-booking-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink],
})
export class ManageBookingFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected signupsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/`;
  protected eventsDetail = `${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS}/list/`;
  protected eventsDetails = RouterUrlsEnum.AUTH_ADMIN_MANAGE_EVENTS_DETAILS;


  @Output() submitFormEvent = new EventEmitter<any>();
   item = input.required<BookingI>();

  public EVENT_ID = '';
  public CLUB_ID = '';

  formsubmit = false;

  protected stateList: StateI[] = [];

  public bookingDetailsForm = new FormGroup({
    emailAddress: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    address1: new FormControl('', [Validators.required]),
    address2: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),

    zipCode: new FormControl('', [Validators.required]),
    clubLogo: new FormControl('', [Validators.required]),
    commission: new FormControl(0, [Validators.required]),
    contactName: new FormControl('', [Validators.required]),
    createdAt: new FormControl('', [Validators.required]),
    id: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required]),
    signupUrl: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    uid: new FormControl('', [Validators.required]),
    updatedAt: new FormControl('', [Validators.required]),

    clubInRegionId: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
    contactTitle: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private manageBookingService: BookingService,
    private signupService: SignupService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getBooking();

    this.signupService.getStates().subscribe(state => {
      this.stateList = state;
    });
  }

  public getBooking(): void {
    // console.log('this.item:', this.item!.clubName!)
    // if (this.bookingDetailsForm.valid) {
    // if (this.item) {
    this.bookingDetailsForm.patchValue({
      firstName: this.item().customer.firstName as string,
      // address1: this.item().address1,
      // emailAddress: this.item().emailAddress,
      // address2: this.item().address2,
      // city: this.item().city,
      // clubLogo: this.item().clubLogo,
      // commission: this.item().commission,
      // contactName: this.item().contactName,
      // phoneNumber: this.item().phoneNumber,
      // signupUrl: this.item().signupUrl,
      // state: this.item().state,
      // zipCode: this.item().zipCode,
      // clubInRegionId: this.item().clubInRegionId,
      // note: this.item().note,
      // contactTitle: this.item().contactTitle,
    });

  }

  public updateBooking(): void {
    this.submitFormEvent.emit({
      firstName: this.bookingDetailsForm.value.firstName,
      address1: this.bookingDetailsForm.value.address1,
      emailAddress: this.bookingDetailsForm.value.emailAddress,
      address2: this.bookingDetailsForm.value.address2,
      city: this.bookingDetailsForm.value.city,
      clubLogo: this.bookingDetailsForm.value.clubLogo,
      commission: this.bookingDetailsForm.value.commission,
      contactName: this.bookingDetailsForm.value.contactName,
      phoneNumber: this.bookingDetailsForm.value.phoneNumber,
      signupUrl: this.bookingDetailsForm.value.signupUrl,
      state: this.bookingDetailsForm.value.state,
      zipCode: this.bookingDetailsForm.value.zipCode,
      clubInRegionId: this.bookingDetailsForm.value.clubInRegionId,
      note: this.bookingDetailsForm.value.note,
      contactTitle: this.bookingDetailsForm.value.contactTitle,
    });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }
  protected cancelBooking() {
    // this.subscriptions.barrel = this.bookingsService
    //   .deleteBookings(`${this.Booking_ID}`)
    //   .pipe(
    //     tap(() => {
    //       console.log(`cancelBooking - ${this.Booking_ID}`);
    //     }),
    //     catchError((error: any) => {
    //       return of(error);
    //     }),
    //   )
    //   .subscribe();
  }
  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  // protected duplicateEvent() {
  //   this.subscriptions.barrel = this.manageBookingService
  //     .duplicateClub(`${this.EVENT_ID}`, this.item as ClubI)
  //     .pipe(
  //       tap(() => {
  //         console.log(`duplicateClub - ${this.EVENT_ID}`);
  //       }),
  //       catchError((error: any) => {
  //         return of(error);
  //       }),
  //     )
  //     .subscribe();
  // }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelEvent() {
    this.subscriptions.barrel = this.manageBookingService
      .deleteBookings(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelEvent - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }

  /**
   * Returns form
   */
  get form() {
    return this.bookingDetailsForm.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  openDeleteDialog(originalClubId: string): void {
    const dialogRef = this.dialog.open(ManageClubDeleteDialogComponent, {
      width: '500px',
      // other configuration
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.processCancellation(originalClubId);
      } else {
        // this.loadingCheckInSession = false;
      }
    });
  }

  processCancellation(originalClubId: string) {
    // this.isSearchSearched = false;
    // this.spinnerTitle = 'Cancelling';

    this.subscriptions.barrel = this.manageBookingService.deleteBookings(originalClubId).subscribe({
      next: (response: any) => {
        // this.loadingCheckInSession = false;
        // this.manageSignup = this.manageSignup.filter(booking => booking.id !== originalBookingId);
      },
      error: (error: any) => {
        console.error('Error canceling booking:', error);

      },
    });
  }
  // protected goToSignupPage() {
  //   console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/${this.EVENT_ID}`);
  //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/${this.EVENT_ID}`);
  // }
}
// extends BodyanalyticsBaseComponent implements OnInit {
//   @Output() submitFormBooking = new EventEmitter<any>();
//   @Input()
//   item: BookingI = {} as BookingI;
//   public Booking_ID = '';
//   public CLUB_ID = '';

//   public operators: OperatorI[] = [];
//   // public BookingDetailsForm: FormGroup = {} as FormGroup;

//   public bookingDetailsForm = new FormGroup({
//     firstName: new FormControl('', [Validators.required]),
//     testBookingDate: new FormControl('', [Validators.required]),
//     startTime: new FormControl('', [Validators.required]),
//     endTime: new FormControl('', [Validators.required]),
//     signupInterval: new FormControl('', [Validators.required]),
//     isAllowedSignupOnline: new FormControl(false, [Validators.required]),
//     isPaymentRequired: new FormControl(false, [Validators.required]),
//     isBookingPrivate: new FormControl(false, [Validators.required]),
//     assigned: new FormControl('', [Validators.required]),
//     BookingStatus: new FormControl('', [Validators.required]),
//     note: new FormControl('', [Validators.required]),
//     operators: new FormControl('null'), // initially set to 'null', the value of your "Please Select" option
//     status: new FormControl('', [Validators.required]),
//     testBookingAtClubId: new FormControl('', [Validators.required]),
//   });

//   constructor(
//     // private modalService: NgbModal,
//     private router: Router,
//     private bookingsService: BookingService,
//   ) {
//     super();
//   }

//   ngOnInit(): void {
//     // this.subscriptions.barrel = this.manageBookingService.getOperatorsList().subscribe(response => {
//     //   this.operators = response;

//     //   // If operators were fetched, set the default value to the first one
//     //   // if (this.operators.length > 0) {
//     //   //   this.BookingDetailsForm.get('operators')?.setValue(this.operators[0].id);
//     //   // }
//     // });
//     this.getBooking();
//     // this.Booking_ID = this.item?.Booking_id as string;
//     // this.CLUB_ID = this.item?.club_Booking_id as string;
//   }

//   public getBooking(): void {
//     // if (this.BookingDetailsForm.valid) {
//     // if (this.item as BookingI) {
//     // const isoStartTime = this.convertTimeToISOString(this.item.startTime);
//     // const isoEndTime = this.convertTimeToISOString(this.item.endTime);
//     // const BookingDate = new Date(this.item.testBookingDate).toISOString().split('T')[0];
//     // this.Booking_ID = this.item?.id as string;
//     // this.CLUB_ID = this.item?.testBookingAtClub.id as string;
//     this.bookingDetailsForm.patchValue({
//       // firstName: this.item?.firstName,
//     });
//     //     testBookingName: this.item?.testBookingName,
//     //     // testBookingDate: BookingDate as string,
//     //     // startTime: isoStartTime,
//     //     // endTime: isoEndTime,
//     //     // signupInterval: this.item?.signupInterval as string,
//     //     // isAllowedSignupOnline: this.item?.isAllowedSignupOnline as boolean,
//     //     // isPaymentRequired: this.item?.isPaymentRequired as boolean,
//     //     // isBookingPrivate: this.item?.isBookingPrivate as boolean,
//     //     // assigned: this.item?.assignedOperator as string,
//     //     // status: this.item?.status as any,
//     //     // operators: this.item?.assignedOperator,
//     //     // testBookingAtClubId: this.item?.testBookingAtClub.id,
//     //     // note: this.item?. as string,
//     //   });
//     // }
//   }

//   public updateBooking(): void {
//     // const isoStartTime = this.convertTimeToISOString(this.BookingDetailsForm.value.startTime);
//     // const isoEndTime = this.convertTimeToISOString(this.BookingDetailsForm.value.endTime);
//     // const BookingDate = new Date(this.BookingDetailsForm.value.testBookingDate).toISOString().split('T')[0];
//     // this.submitFormBooking.emit({
//     //   title: this.BookingDetailsForm.value.title,
//     //   testBookingDate:BookingDate,
//     //   startTime: isoStartTime,
//     //   endTime: isoEndTime,
//     //   signupInterval: this.BookingDetailsForm.value.signupInterval,
//     //   isAllowedSignupOnline: this.BookingDetailsForm.value.isAllowedSignupOnline,
//     //   isPaymentRequired: this.BookingDetailsForm.value.isPaymentRequired,
//     //   isBookingPrivate: this.BookingDetailsForm.value.isBookingPrivate,
//     //   assigned: this.BookingDetailsForm.value.assigned,
//     //   status: this.BookingDetailsForm.value.status,
//     //   note: this.BookingDetailsForm.value.note,
//     //   bodyFatTestBookingClubId: this.BookingDetailsForm.value.testBookingAtClubId,
//     // });
//   }

//   openModal(content: any) {
//     // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
//   }

//   convertTimeToISOString(time: string): string {
//     const dateToday: Date = new Date();
//     // Split the hours and minutes
//     const [hours, minutes] = time.split(':');

//     // Set the hours and minutes to the specified date
//     dateToday.setHours(Number(hours), Number(minutes), 0, 0);

//     // Return the ISO string
//     const testDate = new Date(dateToday);

//     return testDate.toISOString();
//   }

//   protected goToSignupPage() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.Booking_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS}/history/${this.Booking_ID}`);
//   }
//   protected duplicateBooking() {
//     // this.subscriptions.barrel = this.bookingsService
//     //   .addBooking(this.item as BookingI)
//     //   .pipe(
//     //     tap(() => {
//     //       console.log(`duplicateBooking - ${this.Booking_ID}`);
//     //     }),
//     //     catchError((error: any) => {
//     //       return of(error);
//     //     }),
//     //   )
//     //   .subscribe();
//   }
//   protected sendEmailReminder() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.Booking_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.Booking_ID}`);
//   }
//   protected sendSmsReminder() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.Booking_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.Booking_ID}`);
//   }

//   protected viewClubDetails() {
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
//   }
//   protected showSignupList() {
//     console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.Booking_ID}`);
//     this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.Booking_ID}`);
//   }
//   closeModal() {
//     console.log('modal');
//     //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-Booking`);
//     // this.modalService.dismissAll();
//   }
// }
