<ng-container>
  <div class="card border-primary mt-4">
    <div class="card-header bg-primary text-white">Order Summary</div>
    <div class="card-body">
      <ul class="list-group list-group-flush">
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <span class="fw-bold">Description</span>
          <span class="fw-bold">Amount</span>
        </li>

        <ng-container *ngFor="let price of item!.prices">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <ng-container *ngIf="isMainPrice(price.id)">
              <p class="card-title">1 X {{ item?.name }}</p>
              {{ price?.unit_amount! / 100 | currency }}
            </ng-container>
            <ng-container *ngIf="isServiceFee(price.id)">
              <span>Service Fee</span>
              {{ price?.unit_amount! / 100 | currency }}
            </ng-container>

          </li>

        </ng-container>

        <!-- <li class="list-group-item d-flex justify-content-between align-items-center">
          <span>Tax 8.25% TX</span>
          <span>{{ taxAmount | currency }}</span>
        </li> -->
        <li class="list-group-item d-flex justify-content-between align-items-center">
          <span class="fw-bold">Total</span>
          <span class="fw-bold">{{ orderTotal | currency }}</span>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
