import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BookingI } from '@bodyanalytics/data-models-ui';
import { ENV } from '@bodyanalytics/app-config';
import { ApiLocalRoutesEnum, SharedErrorService, SharedLogService } from '@bodyanalytics/ui-core';
import { RescheduleStore } from 'libs/front/shared/calendar-ui/src/lib/containers/state/reschedule.store';
import { tap, catchError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BookingPublicService {
  private bookingsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING}`;
  private bookingsDetailsUrl = `${ApiLocalRoutesEnum.API_SUFFIX_BOOKING}/details`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    @Inject(ENV) private appConfig: any,
    private readonly http: HttpClient,
    private sharedLogService: SharedLogService,
    private sharedErrorService: SharedErrorService,
    private rescheduleStore: RescheduleStore,
  ) {}

  public saveRescheduleBookingData(booking: BookingI) {
    this.rescheduleStore.setIsRescheduleFlagState(true);
    this.rescheduleStore.setSelectedBooking(booking);
  }

  public saveBookingData(booking: BookingI) {
    this.rescheduleStore.setIsRescheduleFlagState(false);
    this.rescheduleStore.setSelectedBooking(booking);
  }

  searchBookings(term: string): Observable<BookingI[]> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}/search/${term}`;
    console.log('here: ', term);

    return this.http.get<BookingI[]>(`${url}`).pipe(
      tap(x => (x.length ? console.log(`found email matching "${term}"`) : console.log(`no email matching "${term}"`))),
      catchError(this.sharedErrorService.handleError<BookingI[]>('searchBookingss', [])),
    );
  }

  getBooking(bookingId: string) {
    const url = `${this.appConfig.api}/${this.bookingsDetailsUrl}/${bookingId}`;
    return this.http.get<BookingI>(url).pipe(
      tap(_ => this.sharedLogService.log(`fetched bookingId id=${bookingId}`)),
      catchError(this.sharedErrorService.handleError<BookingI>(`bookingId id=${bookingId}`)),
    );
  }

  cancelBooking(bookingId: string): Observable<any> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}/cancel-booking`;
    console.log('cancelBooking url:', url);

    const bookingData = {
      bookingId: bookingId,
    };

    return this.http.post<BookingI>(url, bookingData, this.httpOptions).pipe(
      tap(() => this.sharedLogService.log(`process cancelBooking w/ booking id=${bookingId}`)),
      catchError(this.sharedErrorService.handleError<BookingI>('BookingI')),
    );
  }

  rescheduleBooking(
    customerId: string,
    originalBookingId: string,
    newSlotId: string,
    newEventId: string,
    // originalBookingId: string, newBookingId: string
  ): Observable<any> {
    const url = `${this.appConfig.api}/${this.bookingsUrl}/reschedule-booking/${customerId}/${originalBookingId}/${newSlotId}/${newEventId}/`;

    return this.http.post<BookingI>(url, this.httpOptions).pipe(
      tap((newBodyMeasurementI: BookingI) =>
        this.sharedLogService.log(`added BookingI w/ id=${newBodyMeasurementI.id}`),
      ),
      catchError(this.sharedErrorService.handleError<BookingI>('BookingI')),
    );
  }
}
