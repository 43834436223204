// export * from './lib/front-admin-manage-customer-domain/front-admin-manage-customer-domain.component';
export * from './lib/manage-customers.service';
export * from './lib/manage-customers.signal-store-feature';
export * from './lib/manage-customers.store';


import { TypeProvider, Provider } from '@angular/core';
import { ManageCustomerService } from './lib/manage-customers.service';
import { ManageCustomersStore } from './lib/manage-customers.store';



export const provideCustomers = (): Provider[] => [ManageCustomerService, ManageCustomersStore];
