<section id="manage-emails-container">
  <div class="container bg-light py-5 px-3">
    <div class="row align-items-center text-black">
      <div class="col-md-6 intros text-start">
        <h1 class="display-6 fw-bold text-capitalize my-5 px-5">Manage Email</h1>
      </div>
      <div class="col-md-6 text-end">
        <button class="btn btn-outline-primary my-5" (click)="createEmail()">
          <i class="bx bx-run"></i> Send Test Email
        </button>
        <!-- <button class="btn btn-outline-primary my-5">
          <i class="bx bx-book-add" (click)="createEmail()"></i>Create email
        </button> -->
        <!-- <button class="btn btn-outline-primary my-5">
          <i class="bx bx-book-add" (click)="reviewEmail()"></i>Review email
        </button> -->

        <!-- <button class="btn btn-outline-primary my-5" ><i class="bx bx-book-add"></i>Create event</button> -->
      </div>
    </div>
  </div>
</section>

<div>
  <router-outlet></router-outlet>
</div>
