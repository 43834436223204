import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'bodyanalytics-profile-sidebar',
  templateUrl: './profile-sidebar.component.html',
  styleUrls: ['./profile-sidebar.component.scss'],
})
export class ProfileSidebarComponent implements OnInit {
  profileJson = '';

  constructor(public auth: AuthService) {
    //
  }

  ngOnInit(): void {
    this.auth.user$.subscribe(profile => (this.profileJson = JSON.stringify(profile, null, 2)));
  }
}
