<form
  class="needs-validation pb-3 pb-lg-4"
  novalidate=""
  [formGroup]="profileForm"
  fxLayout="column"
  fxLayoutAlign="center none"
  (ngSubmit)="formSubmit()">
  <div class=" ">
    <h1 class="h2 pt-xl-1 pb-3">Profile Details</h1>

    <div class="row pb-2">
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="gender" class="form-label fs-base">Gender</label>
          <select
            id="gender"
            type="text"
            class="form-control form-control-lg"
            name="gender"
            autocomplete="off"
            required
            formControlName="gender"
            [ngClass]="{ 'is-invalid': formsubmit && form.gender.errors }">
            <option [value]="undefined">Select an option</option>
            <option *ngFor="let gender of genderList" [value]="gender.value">{{ gender.name }}</option>
          </select>
          <div *ngIf="formsubmit && form.gender.errors" class="invalid-tooltip">
            <span *ngIf="form.gender.errors['required']">Gender is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="dob" class="form-label fs-base">Date of Birth</label>
          <input
            type="date"
            id="dob"
            class="form-control form-control-lg"
            [ngClass]="{ 'is-invalid': formsubmit && form.dob.errors }"
            formControlName="dob"
            #dob
            autocomplete="off"
            placeholder="mm/dd/yyyy" />
          <div *ngIf="formsubmit && form.dob.errors" class="invalid-tooltip">
            <span *ngIf="form.dob.errors['required']">Date of birth is required.</span>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-4">
        <div class="mb-3 position-relative">
          <label for="zipCode" class="form-label fs-base">Zipcode</label>
          <input
            type="text"
            id="zipCode"
            class="form-control form-control-lg"
            formControlName="zipCode"
            #zipCode
            maxlength="10"
            [ngClass]="{ 'is-invalid': formsubmit && form.zipCode.errors }" />
          <div *ngIf="formsubmit && form.zipCode.errors" class="invalid-tooltip">
            <span *ngIf="form.zipCode.errors['required']">Zipcode is required.</span>
            <span *ngIf="form.zipCode.errors['pattern']">Please enter a valid zip code.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-3">
      <button type="reset" class="btn btn-secondary bg-white border-0 me-3">Cancel</button>
      <button type="submit" class="btn btn-primary" (click)="updateProfile()">Save changes</button>
    </div>
  </div>
</form>
