<bodyanalytics-event-header [item]="eventData"></bodyanalytics-event-header>
<div class="container">
  <!-- Check if there are products -->
  <ng-container>
    <div class="row">
      <div class="col-md-6 py-5">
        <h2 class="h3">Product selection</h2>
        <!-- <hr /> -->

        <!-- Default product list -->
        <ng-container *ngIf="!selectedProduct; else selectedProductDetails">
          <div class="product-list">
            <div *ngFor="let product of products" class="card mb-3 shadow-sm" (click)="selectProducts(product)">
              <ng-container *ngIf="!hasRecurringPrice(product.prices)">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <img [src]="product.images" [alt]="product.name" [title]="product.images" width="100" />
                    </div>
                    <div class="col">
                      <p class="card-title">{{ product.name }}</p>
                      <div *ngFor="let price of product.prices">
                        <ng-container *ngIf="price.id !== 'service-fee'">
                          {{ price!.unit_amount! / 100 | currency }}
                          <span>{{
                            price.type === ONE_TIME_FEE_NICKNAME ? 'One time purchase' : 'Recurring payment'
                          }}</span>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <!-- Selected Product Details and Services List -->
        <ng-template #selectedProductDetails>
          <div class="selected-product-details">
            <div class="card mb-3 shadow-sm">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <p><strong>Selected Product:</strong> {{ selectedProduct!.name }}</p>
                  <!-- ... other selected product details ... -->
                  <button class="btn btn-outline-secondary" (click)="editProduct()">Edit</button>
                </div>
              </div>
            </div>

            <!-- Services for Selected Product -->
            <ng-container *ngIf="selectedProduct; else noTimeslotTemplate">
              <ng-container *ngIf="!selectedTimeSlot">
                <div class="time-selection">
                  <div class="h-100 pt-3 my-5 bg-white rounded-3">
                    <h2 class="h3">Select time</h2>

                    <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-5 g-2 mt-3">
                      <div class="col-md-3 col-sm-12" *ngFor="let timeSlot of availableSlots">
                        <button
                          type="button"
                          [ngClass]="{ 'btn-success': timeSlot.isSelected, 'btn-light': !timeSlot.isSelected }"
                          class="btn shadow-sm w-100 time-slot border"
                          (click)="selectTimeSlot(timeSlot)">
                          {{ timeSlot.startTime }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="selectedTimeSlot; else emptyBlock">
                <div class="time-selection">
                  <div class="h-100 pt-3 mt-5 bg-white rounded-3">
                    <h2 class="h3">Select time</h2>
                    <div class="card mb-3 shadow-sm">
                      <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                          <strong>Date & Time:</strong> {{ eventData.testEventDate | date: 'fullDate' }} at

                          {{ selectedTimeSlot.slotTime }}

                          <button class="btn btn-outline-secondary" (click)="editTimeSlot()">Edit</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="signup-information">
                  <div class="h-100 pt-3 mt-5 bg-white rounded-3">
                    <h2 class="h3">Signup information</h2>

                    <bodyanalytics-signup-form
                      (submitFormEvent)="processPersonalForm($event)"
                      *ngIf="!personalInfoSaved && isEditEnabled"
                      [initialData]="personalInfo"></bodyanalytics-signup-form>

                    <div *ngIf="personalInfoSaved">
                      <div class="card mb-5 shadow-sm">
                        <div class="card-body">
                          <div class="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <h5 class="card-title">
                                {{ savedPersonalInfo.firstName }} {{ savedPersonalInfo.lastName }}
                              </h5>
                              <p class="card-text">
                                {{ savedPersonalInfo.city }}, {{ savedPersonalInfo.state }}
                                {{ savedPersonalInfo.zipCode }}
                              </p>

                              <p class="card-text">Email: {{ savedPersonalInfo.emailAddress }}</p>
                              <p class="card-text">Phone number: {{ savedPersonalInfo.phoneNumber | phone}}</p>
                              <p class="card-text">Date of birth: {{ savedPersonalInfo.dob }}</p>
                              <p class="card-text">Sex: {{ savedPersonalInfo.sex }}</p>
                            </div>

                            <button class="btn btn-outline-secondary" (click)="enableEdit()">
                              <i class="bi bi-pencil"></i> Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
      <!-- Services and Time Selection Section -->
      <!-- Time Selection for Selected Service -->

      <div class="col-md-4 pt-5 px-4 mx-auto bg-light-purple border">
        <ng-container *ngIf="personalInfoSaved; else emptyBlock">
          <ng-container *ngIf="loadingSession; else content">
            <!-- Show loading spinner while calculating -->
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ng-container>
        </ng-container>
        <div class="">
          <bodyanalytics-event-information-card [item]="eventData"></bodyanalytics-event-information-card>
        </div>
        <!-- Empty block template to ensure layout consistency -->
        <ng-template #emptyBlock></ng-template>
      </div>
    </div>
  </ng-container>

  <!-- No Products Template -->
  <ng-template #noProductsTemplate>
    <div class="alert alert-warning my-3" role="alert">
      No products available at the moment. Please check back later.
    </div>
  </ng-template>
</div>
<!-- No Services Template -->
<ng-template #noTimeslotTemplate>
  <div class="alert alert-info" role="alert">
    No times available. Please check back later.
    <!-- No services available for the selected product. Please select a different product. -->
  </div>
</ng-template>
<ng-template #content>
  <!-- Show content once calculation is complete -->
  <ng-container>
    <div class="mb-4">
      <h5 class="mb-2">Enter Coupon Code</h5>
      <form [formGroup]="bookingTotalForm" (ngSubmit)="redeemPromo()">
        <div class="input-group">
          <input class="form-control form-control" placeholder="Coupon code" formControlName="promoCode" maxlength="20"  />
          <button type="submit" class="btn btn-dark">Apply</button>
        </div>
      </form>
      <small *ngIf="promoValid && promoSubmitted && promoDiscountPercentOff" class="fw-bold text-success px-2"
        >{{ promoDiscountPercentOff }} % Off Discount</small
      >

      <small *ngIf="promoValid && promoSubmitted && promoDiscountAmountOff" class="fw-bold text-success px-2"
        >{{ promoDiscountAmountOff | currency }} Amount Discount</small
      >

      <div *ngIf="!promoValid && promoSubmitted" class="alert alert-danger my-1">
        {{ promoErrorMessage }}
      </div>
    </div>
    <div class="card border-primary mt-4">
      <div class="card-header bg-primary text-white">Order Summary</div>
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="fw-bold">Description</span>
            <span class="fw-bold">Amount</span>
          </li>
          <ng-container *ngFor="let price of selectedProduct?.prices!">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              <ng-container *ngIf="isMainPrice(price!.id)">
                <p class="card-title">1 X {{ selectedProduct?.name }}</p>
                {{ price?.unit_amount! / 100 | currency }}
              </ng-container>
              <ng-container *ngIf="isServiceFee(price!.id)">
                <span>Service Fee</span>
                {{ price?.unit_amount! / 100 | currency }}
              </ng-container>

            </li>
          </ng-container>


          <li class="list-group-item d-flex justify-content-between align-items-center text-success" *ngIf="finalTotal">
            <span class="fw-bold">Discount</span>
            <span class="fw-bold">{{ finalTotal | currency }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="afterTaxTotal$">
            <span class="fw-bold">Total</span>
            <span class="fw-bold">{{ afterTaxTotal$ | async | currency }}</span>
          </li>

        </ul>
      </div>
    </div>

    <div *ngIf="finalTotal >= 0">
      <button class="btn btn-sm btn-danger mr-1 my-5 w-100" (click)="continueToCheckout()">Continue to payment</button>
    </div>
    <div *ngIf="finalTotal < 0">
      <button class="btn btn-sm btn-danger mr-1 my-5 w-100" (click)="continueReviewBooking()">Continue to review</button>
    </div>
  </ng-container>
</ng-template>
