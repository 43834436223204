export enum ProductsPermissions {
  CREATE = 'create:products',
  DELETE = 'delete:products',
  READ = 'read:products',
  UPDATE = 'update:products',
}

export enum ProductServicesPermissions {
  CREATE = 'create:services',
  DELETE = 'delete:services',
  READ = 'read:services',
  UPDATE = 'update:services',
}
