import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormField } from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
import { EmailService } from '../../services/email.service';
import { Location } from '@angular/common';

@Component({
  selector: 'bodyanalytics-create-email',
  templateUrl: './create-email.component.html',
  styleUrls: ['./create-email.component.scss'],
})
export class CreateEmailComponent extends BodyanalyticsBaseComponent implements OnInit {
  public loadingSession = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private emailService: EmailService,
  ) {
    super();
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  fields: FormField<any>[] = [
    {
      key: 'emailTo',
      label: 'To',
      type: 'email',
      placeholder: 'Enter email to send',

      tooltipPosition: 'right',
      validation: [Validators.required],
    },
    { key: 'emailSubject', label: 'Subject', type: 'textarea', validation: [Validators.required] },
    { key: 'emailContent', label: 'Email Content', type: 'textarea', validation: [Validators.required] },

  ];

  onFormSubmitted(booking: any) {
    this.loadingSession = true;
    this.subscriptions.barrel = this.emailService
      .addEmail(booking)
      .pipe(
        tap(() => {
          this.loadingSession = false;
       //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BOOKINGS_DASHBOARD}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
}
