import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OperatorService } from '../../services/manage-operators.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, delay, catchError, of } from 'rxjs';

@Component({
  selector: 'bodyanalytics-manage-operators',
  templateUrl: './manage-operators.component.html',
  styleUrls: ['./manage-operators.component.scss'],
})
export class ManageOperatorsComponent extends BodyanalyticsBaseComponent {
  public regionDetailsForm = new FormGroup({});
  constructor(
    // private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private operatorService: OperatorService,
    private router: Router, // private loaderService: LoaderService
  ) {
    super();
  }
  openModal(content: any) {
    //this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  public createOperator() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_OPERATORS_CREATE_OPERATOR}`);
  }
  // saveData() {
  //   if (this.regionDetailsForm.valid) {
  //     this.createOperator(this.regionDetailsForm.value);
  //   }
  // }
  public saveData(signupDetails: any): void {
    // this.loaderService.setLoading(true);
    this.subscriptions.barrel = this.operatorService
      .addOperator(signupDetails)
      .pipe(
        tap(() => {
          delay(1000), console.log('test created');
          // //this.modalService.dismissAll();
          // this.submitted = true;
          // this.loaderService.setLoading(false);
        }),
        catchError((error: any) => {
          // this.loaderService.setLoading(false);
          // doc.file.hasError = true;
          // doc.file.error = error.apiErrorMsg ? error.apiErrorMsg : this.genericErrorMessage;
          // doc.file.uploading = false;
          // doc.file.uploaded = false;
          return of(error);
        }),
      )
      .subscribe();
  }
  cancelModal() {
    //this.modalService.dismissAll();
  }
  closeModal() {
    console.log('modal');
    // this.router.navigateByUrl(`/auth/manage-regions`);
    //this.modalService.dismissAll();
  }
}
