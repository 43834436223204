import { Component, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { OperatorI, PaymentI } from '@bodyanalytics/data-models-ui';
 import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { tap, catchError, of } from 'rxjs';
 import { NgClass, NgIf, NgFor } from '@angular/common';
import { PaymentService } from '@bodyanalytics/front/admin/manage-payments/domain';

@Component({
  selector: 'bodyanalytics-manage-payments-form',
  templateUrl: './manage-payments-form.component.html',
  styleUrls: ['./manage-payments-form.component.scss'],
  standalone:true,
  imports: [ReactiveFormsModule, NgClass, NgIf,NgFor, RouterLink],
})
export class ManagePaymentsFormComponent extends BodyanalyticsBaseComponent implements OnInit {
  @Output() submitFormEvent = new EventEmitter<any>();
  // @Input()
  // item!: PaymentI | null;
  formsubmit = false;

  public EVENT_ID = '';
  public CLUB_ID = '';
  item = input.required<PaymentI>();
  public operators: OperatorI[] = [];
  // public paymentDetailsForm: FormGroup = {} as FormGroup;

  public paymentDetailsForm = new FormGroup({
    amount: new FormControl(0, [Validators.required]),
    testEventDate: new FormControl('', [Validators.required]),
    startTime: new FormControl('', [Validators.required]),
    endTime: new FormControl('', [Validators.required]),
    signupInterval: new FormControl('', [Validators.required]),
    isAllowedSignupOnline: new FormControl(false, [Validators.required]),
    isPaymentRequired: new FormControl(false, [Validators.required]),
    isPaymentPrivate: new FormControl(false, [Validators.required]),
    assigned: new FormControl('', [Validators.required]),
    eventStatus: new FormControl('', [Validators.required]),
    note: new FormControl('', [Validators.required]),
    operators: new FormControl('null'), // initially set to 'null', the value of your "Please Select" option
    status: new FormControl('', [Validators.required]),
    testEventAtClubId: new FormControl('', [Validators.required]),
  });

  constructor(
    // private modalService: NgbModal,
    private router: Router,
    private managePaymentService: PaymentService,
  ) {
    super();
  }


  ngOnInit(): void {
    // this.subscriptions.barrel = this.managePaymentService.getOperatorsList().subscribe(response => {
    //   this.operators = response;

    //   // If operators were fetched, set the default value to the first one
    //   // if (this.operators.length > 0) {
    //   //   this.paymentDetailsForm.get('operators')?.setValue(this.operators[0].id);
    //   // }
    // });
    this.getPayment();
    // this.EVENT_ID = this.item?.testingevent_id as string;
    // this.CLUB_ID = this.item?.club_testingevent_id as string;
  }

  public getPayment(): void {
    // if (this.paymentDetailsForm.valid) {
    // if (this.item as PaymentI) {
    // const isoStartTime = this.convertTimeToISOString(this.item.startTime);
    // const isoEndTime = this.convertTimeToISOString(this.item.endTime);
    // const eventDate = new Date(this.item.testEventDate).toISOString().split('T')[0];
    // this.EVENT_ID = this.item?.id as string;
    // this.CLUB_ID = this.item?.testEventAtClub.id as string;
    this.paymentDetailsForm.patchValue({
      amount: this.item().amount,
      // testEventDate: eventDate as string,
      // startTime: isoStartTime,
      // endTime: isoEndTime,
      // signupInterval: this.item?.signupInterval as string,
      // isAllowedSignupOnline: this.item?.isAllowedSignupOnline as boolean,
      // isPaymentRequired: this.item?.isPaymentRequired as boolean,
      // isPaymentPrivate: this.item?.isPaymentPrivate as boolean,
      // assigned: this.item?.assignedOperator as string,
      // status: this.item?.status as any,
      // operators: this.item?.assignedOperator,
      // testEventAtClubId: this.item?.testEventAtClub.id,
      // note: this.item?. as string,
    });
    // }
  }

  public updatePayment(): void {
    // const isoStartTime = this.convertTimeToISOString(this.paymentDetailsForm.value.startTime);
    // const isoEndTime = this.convertTimeToISOString(this.paymentDetailsForm.value.endTime);
    // const eventDate = new Date(this.paymentDetailsForm.value.testEventDate).toISOString().split('T')[0];
    // this.submitFormEvent.emit({
    //   title: this.paymentDetailsForm.value.title,
    //   testEventDate:eventDate,
    //   startTime: isoStartTime,
    //   endTime: isoEndTime,
    //   signupInterval: this.paymentDetailsForm.value.signupInterval,
    //   isAllowedSignupOnline: this.paymentDetailsForm.value.isAllowedSignupOnline,
    //   isPaymentRequired: this.paymentDetailsForm.value.isPaymentRequired,
    //   isPaymentPrivate: this.paymentDetailsForm.value.isPaymentPrivate,
    //   assigned: this.paymentDetailsForm.value.assigned,
    //   status: this.paymentDetailsForm.value.status,
    //   note: this.paymentDetailsForm.value.note,
    //   bodyFatTestEventClubId: this.paymentDetailsForm.value.testEventAtClubId,
    // });
  }

  openModal(content: any) {
    // this.modalService.open(content, { backdropClass: 'light-blue-backdrop', size: 'lg' });
  }

  convertTimeToISOString(time: string): string {
    const dateToday: Date = new Date();
    // Split the hours and minutes
    const [hours, minutes] = time.split(':');

    // Set the hours and minutes to the specified date
    dateToday.setHours(Number(hours), Number(minutes), 0, 0);

    // Return the ISO string
    const testDate = new Date(dateToday);

    return testDate.toISOString();
  }

  protected goToSignupPage() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/detail/${this.EVENT_ID}`);
  }
  protected duplicatePayment() {
    this.subscriptions.barrel = this.managePaymentService
      .addPayment(this.item())
      .pipe(
        tap(() => {
          console.log(`duplicatePayment - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected sendEmailReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_EMAILS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected sendSmsReminder() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SMS}/${this.EVENT_ID}`);
  }
  protected cancelPayment() {
    this.subscriptions.barrel = this.managePaymentService
      .deletePayment(`${this.EVENT_ID}`)
      .pipe(
        tap(() => {
          console.log(`cancelPayment - ${this.EVENT_ID}`);
        }),
        catchError((error: any) => {
          return of(error);
        }),
      )
      .subscribe();
  }
  protected viewClubDetails() {
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_CLUBS_DETAILS}/${this.CLUB_ID}`);
  }
  protected showSignupList() {
    console.log(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
    this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_SIGNUPS}/${this.EVENT_ID}`);
  }
  closeModal() {
    console.log('modal');
    //   this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_DETAIL}/auth/manage-events`);
    // this.modalService.dismissAll();
  }
    /**
   * Returns form
   */
    get form() {
      return this.paymentDetailsForm.controls;
    }

    /**
     * Bootstrap tooltip form validation submit method
     */
    formSubmit() {
      this.formsubmit = true;
    }
}
