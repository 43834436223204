import { Component } from '@angular/core';
import { ProfileService } from '../../services/profile.service';
import { BodyanalyticsBaseComponent } from 'libs/front/shared/ui-core/src';
import { ProfileDetailsI } from '@bodyanalytics/data-models-ui';

@Component({
  selector: 'bodyanalytics-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent extends BodyanalyticsBaseComponent {
  constructor(private profileService: ProfileService) {
    super();
  }

  update(profileUpdates: ProfileDetailsI) {
    this.subscriptions.barrel = this.profileService.updateProfileDetails(profileUpdates).subscribe();
  }
}
