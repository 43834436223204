import { TestingEventI } from '@bodyanalytics/data-models-ui';
import { createAction, emptyProps, props } from '@ngrx/store';

// export const TestEventActions = createActionGroup({
//   source: 'TestEvent',
//   events: {
//     'Load TestEvents': emptyProps(),
//     'Load TestEvents Success': props<{ data: unknown }>(),
//     'Load TestEvents Failure': props<{ error: unknown }>(),
//   }
// });

// Public - Event Page
export const enter = createAction('[Event Page] Events Enter');

export const testEventsLoaded = createAction('[Event Page] Load Events', props<{ testEvents: TestingEventI[] }>());
export const getTestEventsSuccess = createAction(
  '[Event API] Get Events Success',
  props<{ testEvents: TestingEventI[] }>(),
);
export const getTestEventsFailure = createAction('[Event API] Get Events Failure', props<{ error: any }>());
export const testEventsCreated = createAction('[Event API] Get Events Created', props<{ testEvents: TestingEventI }>());
export const testEventsUpdated = createAction(
  '[Event API] Get Events Updated',
  props<{ testEventId: string; changes: TestingEventI }>(),
);
export const testEventsDeleted = createAction('[Event API] Get Events Deleted', props<{ testEventId: string }>());
export const testEventsSearchedByGymName = createAction(
  '[Event API] Get Events Search',
  props<{ testEvents: TestingEventI[] }>(),
);
export const testEventsSearchedStart = createAction('[Event API] Get Events Search Start');
// export const testEventsSearchedStart = createAction('[Event API] Get Events Search Start',props<{toPayload:string}>());

export const selectEvent = createAction('[Event Page] Select Event', props<{ selectedEvent: TestingEventI }>());
export const selectedEventById = createAction('[Event Page] Select Event', props<{ testEventId: string }>());
export const clearSelectedEvent = createAction('[Event Page] Clear Selected Event');
export const createEvent = createAction('[Event Page] Create Event', props<{ testEvents: TestingEventI }>());
export const updateEvent = createAction(
  '[Event Page] Update Event',
  props<{ testEventId: string; changes: TestingEventI }>(),
);
export const deleteEvent = createAction('[Event Page] Delete Event', props<{ testEventId: string }>());
export const searchForEventAction = createAction(
  '[Event Page] Search Events',
  props<{ testEvents: TestingEventI[] }>(),
);
export const searchForEventByGymNameAction = createAction(
  '[Event Page] Search Events By Gym',
  props<{ gymName: string }>(),
);

// Private Admin - Event Page
// export const getPrivateTestEvents = createAction('[Event Page] Load Events');
// export const getPrivateTestEventsSuccess = createAction('[Event API] Load Events Success', props<{ testEvents: TestingEventI[] }>());
// export const getTestEventsFailure = createAction('[Event API] Load Events Failure', props<{ error: any }>());
// export const selectEvent = createAction('[Event Page] Select Event', props<{ selectedEvent: TestingEventI }>());
// export const createEvent = createAction('[Event Page] Update Event', props<{ selectedEvent: TestingEventI }>());
// export const updateEvent = createAction('[Event Page] Update Event', props<{ selectedEvent: TestingEventI }>());
// export const deleteEvent = createAction('[Event Page] Delete Event', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedEvent = createAction('[Event Page] Clear Event');
// export const searchForEventAction = createAction('[Event Page] Select Event', props<{ selectedEvent: TestingEventI }>());

// export const selectProductService = createAction('[Event Page] Select Slot', props<{ selectedEvent: TestingEventI }>());
// export const createProductService = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const updateProductService = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const deleteProductService = createAction('[Event Page] Delete Slot', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedProductService = createAction('[Event Page] Clear Slot');

// export const selectSlot = createAction('[Event Page] Select Slot', props<{ selectedEvent: TestingEventI }>());
// export const createSlot = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const updateSlot = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const deleteSlot = createAction('[Event Page] Delete Slot', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedSlot = createAction('[Event Page] Clear Slot');

// export const selectCart = createAction('[Event Page] Select Slot', props<{ selectedEvent: TestingEventI }>());
// export const createCart = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const addToCart = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const reviewCart = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());

// export const updateCart = createAction('[Event Page] Update Slot', props<{ selectedEvent: TestingEventI }>());
// export const deleteCart = createAction('[Event Page] Delete Slot', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedCart = createAction('[Event Page] Clear Slot');

// export const selectSignup = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const lookupSignup = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());

// export const createSignup = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updateSignup = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deleteSignup = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedSignup = createAction('[Event Page] Clear Booking');

// export const selectBooking = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const createBooking = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updateBooking = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deleteBooking = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedSlot = createAction('[Event Page] Clear Booking');
// export const transferBooking = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const rescheduleBooking = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());

// export const selectOrder = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const createOrder = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const processOrder = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updateOrder = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deleteOrder = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedOrder = createAction('[Event Page] Clear Booking');

// export const selectTest = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const createTest = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const procesTest = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updateTest = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deleteTest = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedTest = createAction('[Event Page] Clear Booking');

// export const selectTestReport = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const createTestReport = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const procesTestReport = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updateTestReport = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deleteTestReport = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedTestReport = createAction('[Event Page] Clear Booking');

// export const selectPayment = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
// export const createPayment = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const processPayment = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const updatePayment = createAction('[Event Page] Update Booking', props<{ selectedEvent: TestingEventI }>());
// export const deletePayment = createAction('[Event Page] Delete Booking', props<{ selectedEvent: TestingEventI }>());
// export const clearSelectedPayment = createAction('[Event Page] Clear Booking');

// export const updateTotal = createAction('[Event Page] Select Booking', props<{ selectedEvent: TestingEventI }>());
