import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { AppStateInterface } from '../types/appState.interface';
import { BodyanalyticsBaseComponent, RouterUrlsEnum, TimeFormatService } from '@bodyanalytics/ui-core';
import { AuthService } from '@auth0/auth0-angular';
import {
  CategoryI,
  ProviderI,
  SelectedServiceI,
  ServicesI,
  SlotNI,
  TestingEventI,
} from '@bodyanalytics/data-models-ui';
import { SignupService } from '../../services/signup.service';
import { DatePipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
@Component({
  selector: 'bodyanalytics-testevents-detail-page',
  templateUrl: './testevents-detail-page.component.html',
  styleUrls: ['./testevents-detail-page.component.scss'],
  providers: [DatePipe],
})
export class TesteventsDetailPageComponent extends BodyanalyticsBaseComponent implements OnInit {
  public eventData!: TestingEventI;
  availableSlots: SlotNI[] = []; // Assume you have an Event interface defined

  public categories: CategoryI[] = [];
  public services: ServicesI[] = []; // All available services
  public providers: ProviderI[] = [];
  public filteredServices: ServicesI[] = []; // Services filtered by category
  public selectedServices: SelectedServiceI[] = []; // User's selected services
  public alertMessage: string = '';
  protected alertType: 'success' | 'warning' | 'error' = 'success'; // Types of alerts
  public isLoading: boolean = true;
  public isRescheduleCheckout: boolean = false;
  public isBookingSelectionComplete = false;
  public isBookingServiceSelected = false;
  public isBookingSlotSelected = false;
  selectedIndex: number | null = null;
  hoveredIndex: number | null = null;
  mapOptions: google.maps.MapOptions = {
    center: { lat: 38.9987208, lng: -77.2538699 },
    zoom: 14,
  };
  selectSlot(index: number): void {
    this.selectedIndex = index;
    this.isBookingSelectionComplete = true;
  }

  constructor(
    private store: Store<AppStateInterface>,
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private signupService: SignupService,
    private timeFormatService: TimeFormatService,
    private datePipe: DatePipe,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ eventData }) => {
        if (eventData) {
          this.eventData = eventData;
          this.$gaService.pageView('event-details/' + this.eventData.id, 'Event Details' + this.eventData.testEventAtClub.clubName);
        }
      });
    }
    this.loadInitialData();

    this.getSlotAvailablityForService();
  }

  public loadInitialData(): void {
    this.isLoading = false;
    this.displayReschedule();
  }

  public displayReschedule() {
    this.isRescheduleCheckout = this.mobileTestingCalendarService.isReschedule();
  }
  continueToRescheuleBooking() {
    //1. Save timeslot and new event
    //2. Add to booking reschedule
    this.mobileTestingCalendarService.addNewEventToReschedule(this.eventData);
    //Save timeslot and eventId
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_SIGNUP_RESCHEDULE_VERIFY}/${this.eventData.id}`);
  }
  continueToBooking() {
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_SIGNUP}/${this.eventData.id}`);
  }

  selectTimeSlot(timeSlot: SlotNI) {
    this.availableSlots.forEach(timeSlot => {
      timeSlot.isSelected = false;
    });

    if (!timeSlot.isSelected) {
      // Handle the selection
      this.isBookingSlotSelected = true;
      timeSlot.isSelected = true;
      this.mobileTestingCalendarService.saveTimeslotRescheduleBookingData(timeSlot);
      console.log(`Time slot selected: ${timeSlot.startTime}`);
    }
  }

  public getSlotAvailablityForService() {
    const id = this.activatedRoute.snapshot.paramMap.get('id') as string;

    this.signupService
      .getAvaliableSlots(id)

      .subscribe({
        next: v => {
          this.availableSlots = v.map(slot => ({
            ...slot,
            startTime: this.convertToCentralTime(slot.startTime.toString()),
            slotTime: this.convertToCentralTime(slot.slotTime.toString()),
            endTime: this.convertToCentralTime(slot.endTime.toString()),
          }));
        },
        error: e => console.error(e),
        complete: () => console.info('complete'),
      });
  }
  // convertToCentralTime(utcDateString: string) {
  //   const date = new Date(utcDateString);

  //   return this.datePipe.transform(date, 'shortTime', 'UTC') as string;
  // }

    // Example function to convert ISO string to Central Time
    convertToCentralTime(utcDateTime: string): string {
      const timeZone = 'America/Chicago'; // Central Time Zone
      const date = parseISO(utcDateTime);
      // const localDate = utcToZonedTime(date, timeZone);
      // return format(localDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
      const zonedDate = utcToZonedTime(date, timeZone);
      const dattime= format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX');
      return this.datePipe.transform(dattime, 'shortTime') as string;
    }
}
