import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UiCoreModule } from '@bodyanalytics/ui-core';
import { ProfileUiModule } from '@bodyanalytics/profile-ui';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { AppComponent } from './app.component';
import { provideRouter, withComponentInputBinding, RouterOutlet } from '@angular/router';
import { provideHttpClient, withInterceptors, withFetch } from '@angular/common/http';
import appRoutes from './app.routes';
import { provideAuth0, authHttpInterceptorFn } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { ENV } from '@bodyanalytics/app-config';
import { provideAnimations } from '@angular/platform-browser/animations';
import { NgxEchartsModule } from 'ngx-echarts';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { ManageProductsUiModule } from '@bodyanalytics/manage-products-ui';
import { ManageEventsUiModule } from '@bodyanalytics/front/admin/manage-events/feature';
import { AdminSetupUiModule } from '@bodyanalytics/admin-setup-ui';

import { AuthenticationUiModule } from '@bodyanalytics/authentication-ui';
import { ManageEmailsUiModule } from '@bodyanalytics/manage-emails-ui';
// import { ManageRegionsUiModule } from '@bodyanalytics/manage-regions-ui';
//  import { ManageDiscountsUiModule } from '@bodyanalytics/manage-discounts-ui';
import { ManageOperatorsUiModule } from '@bodyanalytics/manage-operators-ui';


@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    UiCoreModule,
    AdminSetupUiModule,
    ProfileUiModule,

    AuthenticationUiModule,
    ManageProductsUiModule,
    ManageEventsUiModule,
    ManageEmailsUiModule,
    // ManageDiscountsUiModule,
    ManageOperatorsUiModule,
    // ManagePaymentsUiModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsMeasurementId),
    NgxGoogleAnalyticsRouterModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    RouterOutlet,
  ],
  providers: [
    { provide: ENV, useValue: environment },
    provideAnimations(),
    provideAuth0({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [`${environment.api}/*`],
      },
    }),
    provideHttpClient(withFetch(), withInterceptors([authHttpInterceptorFn])),
    provideRouter(appRoutes, withComponentInputBinding()),
    provideStore(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    provideRouterStore(),
    provideStoreDevtools(),
  ],
})
export class AppModule {}
