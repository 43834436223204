import { Component, Inject, OnInit } from '@angular/core';
import {
  TestingEventI,
  ProductI,
  SlotNI,
  CheckoutDataI,
  SignupFormI,
  OrderTotalsI,
  PromoCodeI,
} from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum, StripeProductPriceEnum } from '@bodyanalytics/ui-core';

import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from '../../services/checkout.service';

import { SignupService } from '../../services/signup.service';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ENV } from '@bodyanalytics/app-config';
import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'bodyanalytics-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [DatePipe],
})
export class SignupComponent extends BodyanalyticsBaseComponent implements OnInit {
  protected eventData!: TestingEventI;
  protected products: ProductI[] = []; // All available services
  protected selectedProduct: ProductI | null = null;
  protected selectedTimeSlot: SlotNI | null = null;
  protected availableSlots: SlotNI[] = []; // Assume you have an Event interface defined
  protected SERVICE_FEE_NICKNAME = StripeProductPriceEnum.SERVICE_FEE_ID;
  protected SINGLE_HYDRO_TEST_PRODUCT_NICKNAME = StripeProductPriceEnum.SINGLE_HYDROTEST_PRODUCT_ID;
  protected ONE_TIME_FEE_NICKNAME = StripeProductPriceEnum.ONE_TIME_FEE_ID;
  finalTotal: number = 0;
  public loadingSession = false;
  afterTaxTotal$: Observable<number> = of(0);

  promoDiscount: number = 0;
  private promotionData!: PromoCodeI;

  promoDiscountAmountOff: number = 0;
  promoDiscountPercentOff: number = 0;
  promoErrorMessage: string = '';
  promoSubmitted: boolean = false;
  bookingTotalForm: FormGroup;
  promoValid: boolean = false;
  personalInfoSaved: boolean = false;
  protected isEditEnabled = false;
  protected savedPersonalInfo!: SignupFormI;
  protected personalInfo!: SignupFormI;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private signupService: SignupService,
    private checkoutService: CheckoutService,
    private datePipe: DatePipe,
    private $gaService: GoogleAnalyticsService,
    private _formBuilder: FormBuilder,
    @Inject(ENV) private appConfig: any,
  ) {
    super();
    this.bookingTotalForm = this._formBuilder.group({
      promoCode: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.checkoutService.clearSession();
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ eventData }) => {
        if (eventData) {
          this.eventData = eventData;
          this.$gaService.pageView('signup/' + this.eventData.id, 'Signup' + this.eventData.testEventAtClub.clubName);
        }
      });
    }
    this.subscriptions.barrel = this.signupService.getProducts(this.eventData.id).subscribe(response => {
      this.products = response;
    });
  }

  editProduct() {
    this.selectedProduct = null;
  }
  editTimeSlot() {
    this.selectedTimeSlot = null;
  }
  public enableEdit(): void {
    this.isEditEnabled = true;
    this.personalInfoSaved = false;
    // this.savedPersonalInfo = this.personalInfo;
    this.savedPersonalInfo = this.signupService.getSignupDetails() as SignupFormI;
    this.personalInfo = this.savedPersonalInfo;
  }

  public getSlotAvailablityForService(testingEventId: string) {
    this.signupService.getAvaliableSlots(this.eventData.id).subscribe({
      next: v => {
        this.availableSlots = v.map(slot => ({
          ...slot,
          startTime: this.convertToCentralTime(slot.startTime.toString()),

          slotTime: this.convertToCentralTime(slot.slotTime.toString()),

          endTime: this.convertToCentralTime(slot.endTime.toString()),
        }));
      },
      error: e => console.error(e),
      complete: () => console.info('complete'),
    });
  }

  // Example function to convert ISO string to Central Time
  convertToCentralTime(utcDateTime: string): string {
    const timeZone = 'America/Chicago'; // Central Time Zone
    const date = parseISO(utcDateTime);
    // const localDate = utcToZonedTime(date, timeZone);
    // return format(localDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
    const zonedDate = utcToZonedTime(date, timeZone);
    const dattime= format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX');
    return this.datePipe.transform(dattime, 'shortTime') as string;
  }

  hasRecurringPrice(prices: any[]): boolean {
    // Check if any price is recurring
    // Don't show recurring products yet
    return prices.some(price => price.recurring !== null);
  }

  selectProducts(product: ProductI): void {
    this.selectedProduct = product;

    this.checkoutService.addToProductToCart(product, this.eventData);
    if (this.eventData.id) {
      this.getSlotAvailablityForService(this.eventData.id);
    }
  }
  selectTimeSlot(timeSlot: SlotNI) {
    this.availableSlots.forEach(timeSlot => {
      timeSlot.isSelected = false;
    });

    if (!timeSlot.isSelected) {
      // Handle the selection
      this.selectedTimeSlot = timeSlot;
      timeSlot.isSelected = true;
      const selectedDate = timeSlot.startTime;
      this.addBookingToCart(selectedDate);
      console.log(`Time slot selected: ${selectedDate}`);
    }
    this.personalInfoSaved = false;
    this.isEditEnabled = true;
  }

  addBookingToCart(booking: Date | string) {
    // Add the booking to cart in your service, if necessary
    this.checkoutService.addBookingToCart(booking);

    // Set a flag to display the total section, if needed
    // this.isDisplayTotalSection = true;
  }

  public processPersonalForm(signupDetails: SignupFormI): void {
    console.log('processPersonalForm');
    this.personalInfoSaved = true;
    this.savedPersonalInfo = signupDetails;
    this.isEditEnabled = true;

    this.checkoutService.addUserInfoToCart(this.savedPersonalInfo, 0, 0, 0);
    this.calculateTotal();
  }
  calculateTotal() {
    this.loadingSession = true;
    const checkoutData: CheckoutDataI = {
      selectedProductId: this.selectedProduct!.id,
      quantity: 1,
      tax_state: '',
      serviceFeePriceId: '',
      productDefaultPriceId: '',
    };

    this.selectedProduct!.prices.forEach(price => {
      if (price.id === StripeProductPriceEnum.SERVICE_FEE_ID) {
        // Populate serviceFeePriceId with the ID of the service fee price
        checkoutData.serviceFeePriceId = price.id.toString();
      } else if (price.id === StripeProductPriceEnum.SINGLE_HYDROTEST_PRODUCT_ID) {
        // Populate productDefaultPriceId with the ID of the default product price
        checkoutData.productDefaultPriceId = price.id.toString();
      }
    });

    console.log('checkoutData: ', checkoutData);
    const promoCode = this.bookingTotalForm.get('promoCode')?.value;

    this.subscriptions.barrel = this.checkoutService.calculateOrder(checkoutData).subscribe(totalData => {
      console.log('total: ', totalData);

      console.log('testing promo');
      if (this.promoDiscountAmountOff > 0) {
        this.afterTaxTotal$ = of(totalData.totalPrice - this.promoDiscountAmountOff);
      } else {
        this.afterTaxTotal$ = of(totalData.totalPrice);
      }

      const orderTotals: OrderTotalsI = {
        subtotal: 0,
        salesTax: 0, //included in price
        total: totalData.totalPrice,
        serviceFee: totalData.serviceFee,
        promocode: promoCode,
      };

      this.checkoutService.addBookingTotal(orderTotals);
      this.loadingSession = false;
    });
  }

  continueToCheckout() {
    this.processBooking();
  }
  public processBooking() {
    // Save order totals
    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_CHECKOUT}${this.eventData.id}`);
  }

  public redeemPromo(): void {
    const promoCode = this.bookingTotalForm.get('promoCode')?.value;
    console.log('redeemPromo:', promoCode);

    this.checkoutService.validatePromo(promoCode).subscribe({
      next: response => this.handlePromoResponse(response),
      error: () => {
        this.promoErrorMessage = 'Promo code is invalid. Please try again.';
      },
    });
  }
  private handlePromoResponse(response: any): void {
    this.promoSubmitted = true;
    if (response) {
      this.applyPromoDiscount(response);
      this.promoValid = true;
      this.promoErrorMessage = '';
    } else {
      this.promoValid = false;
      this.promoErrorMessage = 'Promo is not valid. Please try again.';
      this.bookingTotalForm.get('promoCode')?.patchValue('');
    }
    this.calculateTotal();
  }

  private applyPromoDiscount(response: PromoCodeI): void {
    console.log('applyPromoDiscount response:', response);
    const singleTest = 59.0;
    let isCalcualted = false;

    if (response.isPackage && response.maxRedeemption && !isCalcualted) {
      if (response.discountAmount) this.promoDiscountAmountOff = singleTest;
      isCalcualted = true;

    } else {
      if (!isCalcualted && response!.discountAmount! > 80) {
        this.promoDiscountAmountOff = Number(response.discountAmount) / 100;
        isCalcualted = true;
      }else{
        if(!isCalcualted)
        this.promoDiscountAmountOff = singleTest; // Number(response.discountAmount) - singleTest
        isCalcualted = true;
      }
      if (response.percent_off) {
        this.promoDiscountPercentOff = Number(response.percent_off);
      }
    }

    this.promoDiscount = this.promoDiscountPercentOff
      ? (this.promoDiscountPercentOff / 100) * this.finalTotal
      : this.promoDiscountAmountOff;

    console.log('Promo Discount:', this.promoDiscount);

    this.finalTotal = this.finalTotal - this.promoDiscount;

    console.log('Number(this.finalTotal) < 0', Number(this.finalTotal) < 0); ///true

    this.promotionData = response;

    console.log('Final Total after discount:', this.finalTotal);
    this.afterTaxTotal$ = of(this.finalTotal);
  }
  continueReviewBooking() {
    this.checkoutService.addSignupVerifyStep(this.promotionData);

    this.router.navigateByUrl(`${RouterUrlsEnum.CALENDAR_SIGNUP_VERIFY}/${this.eventData.id}`);
  }
  isMainPrice(priceId: string): boolean {
    const mainPriceIds = [
      this.appConfig.stripe_package_price_1,
      this.appConfig.stripe_package_price_2,
      this.appConfig.stripe_package_price_3,
      this.appConfig.stripe_package_price_4,
      this.appConfig.stripe_package_price_5,
      this.appConfig.stripe_package_price_6,
      this.appConfig.stripe_package_price_7,
    ];
    return mainPriceIds.includes(priceId);
  }
  isServiceFee(priceId: string): boolean {
    return priceId === 'service-fee';
  }
}
