import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { SetupTestingI } from '@bodyanalytics/data-models-ui';
import {
  TestingSessionStep,
  LoadingSpinnerComponent,
  RouterUrlsEnum,
  TestingSessionStatus,
  GoogleActionsEnum,
} from '@bodyanalytics/ui-core';
import { SetupFormComponent } from '@bodyanalytics/front/admin/manage-bodymeasurements/ui';
import {
  ManageBodymeasurementStore,
  ManageBodymeasurementService,
} from '@bodyanalytics/front/admin/manage-bodymeasurements/domain';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bodyanalytics-testing-setup',
  standalone: true,
  templateUrl: './testing-setup.component.html',
  styleUrls: ['./testing-setup.component.scss'],
  imports: [LoadingSpinnerComponent, SetupFormComponent],
})
export class TestingSetupComponent {
  protected store = inject(ManageBodymeasurementStore);
  protected formValue = this.store.assessmentForm();
  protected testingStep = TestingSessionStep.SETUP;
  protected bodyMeasurementData!: SetupTestingI;
  protected submitted = false;
  protected loadingSetupSession = false;
  protected events = this.store.eventEntities;
  protected operators = this.store.operatorEntities;
  protected trucks = this.store.truckEntities;
  protected clubs = this.store.clubEntities;

  constructor(
    private router: Router,
    private testingService: ManageBodymeasurementService,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.$gaService.pageView('manage-bodymeasurements/setup-test', 'Setup Session');
  }

  protected saveSetupTestDetails(signupDetails: any): void {
    this.$gaService.event('saveSetupTestDetails', 'bodyanalytics-setup-form', GoogleActionsEnum.USER_CLICKED_BTN);
    this.loadingSetupSession = true;
    this.testingService.stepUpdated.next(this.testingStep);
    this.testingService
      .startTestSession({
        bodyFatTestEventClubId: signupDetails.events,
        bodyFatTestEventAssignedTruckId: signupDetails.trucks,
        bodyFatTestEventOperatorId: signupDetails.operators,
        waterTemperature: signupDetails.waterTemperature,
        status: TestingSessionStatus.GOOD,
        workFlowStep: TestingSessionStep.SETUP,
      })
      .subscribe(res => {
        this.loadingSetupSession = false;
        this.router.navigateByUrl(`${RouterUrlsEnum.AUTH_ADMIN_MANAGE_BFTESTS_CHECK_IN}/${res.id}`);

        this.testingService.testDataList.push({
          clientHistory: {},
          clientDetails: {},
          sessionDetails: {
            testDate: Date.now().toString(),
            testTime: Date.now().toString(),
            bodyFatTestEventClubId: res.bodyFatTestEventClubId,
            bodyFatTestEventAssignedTruckId: res.bodyFatTestEventAssignedTruckId,
            bodyFatTestEventOperatorId: res.bodyFatTestEventOperatorId,
            waterTemperature: res.waterTemperature,
            testData: {},
          },
        });
      });
  }
}
