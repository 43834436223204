import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  TestingEventI,
  BookingI,
  ReschBookingDataI,
  OrderConfirmationI,
  CreateRescheduleI,
  BookingDataI,
  SignupFormI,
} from '@bodyanalytics/data-models-ui';
import { BodyanalyticsBaseComponent, RouterUrlsEnum } from '@bodyanalytics/ui-core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { CheckoutService } from '../../services/checkout.service';
import { MobileTestingCalendarService } from '../../services/mobile-testing.service';
import { SignupService } from '../../services/signup.service';

@Component({
  selector: 'bodyanalytics-signup-verify-page',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './signup-verify-page.component.html',
  styleUrls: ['./signup-verify-page.component.scss'], // Fix typo from 'styleUrl' to 'styleUrls'
})
export class SignupVerifyPageComponent extends BodyanalyticsBaseComponent implements OnInit {
  public eventData: TestingEventI | null = null;
  public signupData: SignupFormI | null = null;
  public newTimeSlot: Date | null = null;
  public rescheduleBookingData: BookingDataI | null = null;
  public orderConfirmationData!: OrderConfirmationI;

  constructor(
    private mobileTestingCalendarService: MobileTestingCalendarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private signupService: SignupService,
    private checkoutService: CheckoutService,
    private $gaService: GoogleAnalyticsService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.activatedRoute && this.activatedRoute.data) {
      this.subscriptions.barrel = this.activatedRoute.data.subscribe(({ eventData }) => {
        if (eventData) {
          this.eventData = eventData;
          this.$gaService.pageView('signup-verify/' + this.eventData?.id, 'Signup Verify');
        }
      });
    }

    this.rescheduleBookingData = this.mobileTestingCalendarService.getVerifySignupData();
    if (this.rescheduleBookingData) {
      this.getNewEventDetails();
      this.getExistingBookingDetails();
    } else {
      console.error('No reschedule booking data available.');
      // Handle the error, e.g., navigate to a safe page
      this.router.navigateByUrl('/error'); // Example error route
    }
  }

  getNewEventDetails(): void {
    if (this.rescheduleBookingData) {
      this.eventData = this.rescheduleBookingData.selectedEvent;
      this.newTimeSlot = this.rescheduleBookingData.selectedTime;
    }
  }

  getExistingBookingDetails(): void {
    if (this.rescheduleBookingData) {
      this.signupData = this.rescheduleBookingData.userInfo;
      // Uncomment and correct the Google Analytics tracking if needed
      // this.$gaService.pageView(`signup-verify/${this.signupData.id}`, 'Signup Verify');
    }
  }

  routeToSearch(): void {
    // Example routing logic, ensure the correct property is used
    // this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_EVENTS_SEARCH}/${this.bookingData?.id}`);
  }

  processBookingWithCredit(): void {
    if (this.checkoutService) {
      this.subscriptions.barrel = this.checkoutService.processOrderWithPromo().subscribe({
        next: response => {
          if (response) {
            this.router.navigateByUrl(`${RouterUrlsEnum.CALENDER_ORDER_CONFIRMATION}/${response.id}`);
          }
        },
        error: err => {
          console.error('Error processing order with promo:', err);
          // Handle the error, e.g., show a notification to the user
        },
      });
    }
  }

  public enableEdit(): void {
    // this.isEditEnabled = true;
    // this.personalInfoSaved = false;
    // Uncomment and fix this logic if needed
    // this.savedPersonalInfo = this.signupService.getSignupDetails() as SignupFormI;
    // this.personalInfo = this.savedPersonalInfo;
  }
}
