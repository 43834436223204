import { Route } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { CreateDiscountComponent } from './create-discount/create-discount.component';
import { DiscountDetailsComponent } from './discount-details/discount-details.component';
import { DiscountDashboardComponent } from './manage-discounts-dashboard/manage-discounts-dashboard.component';
import { DiscountComponent } from './manage-discounts/discount.component';
import { ManageDiscountService, provideDiscounts } from '@bodyanalytics/front/admin/manage-discounts/domain';


export const manageDiscountsUiRoutes: Route =
  {
    path: 'auth/manage-discounts',
    component: DiscountComponent,
    canActivate: [AuthGuard],
    providers:[provideDiscounts(), ManageDiscountService],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'create-discount',
        component: CreateDiscountComponent,
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DiscountDashboardComponent,
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: DiscountDetailsComponent,
      },
    ],
  };
