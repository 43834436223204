<section class="dashboard-container dashboard">
  <div class="container">
    <div class="row">
      <div class="col-md-12 bg-white p-2">
        <div class="form w-100 pb-2">
          @if (report(); as report) {
            <div class="row">
              <div class="col-md-8 p-3">
                <!-- <a type="link" class="btn btn-link text-decoration-none my-3" (click)="goBack()"
              ><i class="bx bx-arrow-back"></i>Go back</a
            > -->
                <!-- <h3>Body Assessments Report</h3> -->
                <h4 class="h6">Body Composition Analysis</h4>
                <!-- <p class="card-title-desc">Enter the following details for test:</p> -->
                <ul class="card-title-desc">
                  <li>Accurate measurement of your body fat % and lean mass</li>
                  <li>Detailed tracking of previous tests</li>
                  <li>A reference table showing where you are and where you should be</li>
                  <li>Calculation of your resting metabolic rate and total caloric burn</li>
                </ul>
                <div class="row py-2">
                  <!-- <p class="col-md-8 border-bottom">
                The human body is composed of metabolically active "lean" tissues like bone, muscle, internal organs,
                water, and adipose tissue (i.e., fat tissue). From both health and cosmetic standpoints, the relative
                amount of adipose tissue in your body is of concern. A variety of techniques have been developed to
                evaluate the total body fat percentage. Hydrostatic body fat testing is referred to as THE GOLD
                STANDARD, or the method by which all other methods measure their own accuracy. Our test uses a 3
                compartment model that separates lean mass, water and fat and is far more accurate than the older 2
                compartment model.
              </p> -->
                </div>
              </div>

              <div class="col-md-4">
                <div class="list-group">
                  <!-- <button class="list-group-item list-group-item-action list-group-item-light" (click)="printReport()">
                    <i class="bx bx-printer"></i> Print Report
                  </button> -->

                  <button
                    class="list-group-item list-group-item-action list-group-item-light"
                    (click)="
                      sendReport(
                        report.id,
                        report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer
                      )
                    ">
                    <i class="bx bx-envelope"></i> Email
                  </button>

                  <!--<a
                    class="list-group-item list-group-item-action list-group-item-light"
                    (click)="navigateToDashboard()">
                    <i class="bx bxs-dashboard"></i>Admin Dashboard
                  </a>

                  <button
                    class="list-group-item list-group-item-action list-group-item-light"
                    (click)="navigateToCustomerDashboard()">
                    <i class="bx bxs-dashboard"></i>Customer Dashboard
                  </button>-->

                  <button
                    (click)="downloadPdfReport(report.customer.emailAddress)"
                    class="list-group-item list-group-item-action list-group-item-light">
                    <i class="bx bxs-save"></i> Download Report as PDF
                  </button>

                  <!--<button class="list-group-item list-group-item-action list-group-item-danger">
                    <i class="bx bxs-message-rounded-x"></i> Delete
                  </button>-->
                </div>
              </div>
            </div>
            <div class="py-2">
              <h4 class="h5">Client Data</h4>
            </div>
            <div class="row py-2">
              <div class="col-6">
                <p>
                  <span class="fw-bold me-1">Name:</span>
                  <span>
                    {{ report.report.testDetails.bodyFatMeasureTestSessionCheckIn!.bodyFatTestForCustomer.firstName }}
                  </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Phone:</span>
                  <span>
                    {{ report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer.phoneNumber }}
                  </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Email:</span>
                  <span>
                    {{
                      report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer.emailAddress
                    }}</span
                  >
                </p>
                <p>
                  <span class="fw-bold me-1">Sex:</span>
                  <span> {{ report.report.testDetails.sex }}</span>
                </p>
                <p>
                  <span class="fw-bold me-1">Age:</span>
                  <span> {{ report.report.testDetails.age }}</span>
                </p>
              </div>
              <div class="border-top py-3">
                <h4 class="h5">Test Details</h4>
                <div class="row">
                  <div class="col-6">
                    <!-- <p>
                      <span class="fw-bold me-1">ID:</span>
                      <span>{{ report.id }}</span>
                    </p> -->
                    <p>
                      <span class="fw-bold me-1">Date:</span>
                      <span>{{ report.report.testDate || '' | date: 'fullDate' }} </span>
                    </p>
                    <p>
                      <span class="fw-bold me-1">Time:</span>
                      <span>{{ report.report.testDate || '' | date: 'h:mm a z' }}</span>
                    </p>
                    <p>
                      <span class="fw-bold me-1">Location:</span>
                      <span>{{
                        report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatMeasureTestSessionSetup
                          .bodyFatTestEvent.testEventName
                      }}</span>
                    </p>
                  </div>
                  <div class="col-6">
                    <p>
                      <span class="fw-bold me-1">Operator:</span>
                      <span>{{
                        report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatMeasureTestSessionSetup
                          .bodyFatTestEventOperator.operatorUserName
                      }}</span>
                    </p>
                    <p><span class="fw-bold me-1">Amount Paid:</span>
                      <!-- {{
                        report.report.testDetails.bodyFatMeasureTestSessionCheckIn.bodyFatTestForCustomer.orders[0].orderDetails[0].price.toNumber()
                      }} -->
                      <span>$.00 (None) </span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <p>
                  <span class="fw-bold me-1">Height (in):</span>
                  <span> {{ report.report.testDetails.height }}</span>
                </p>
                <p>
                  <span class="fw-bold me-1">Dry Weight (lb):</span>
                  <span>{{ report.report.testDetails.dryWeight }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Water Temp (F):</span>
                  <span> {{ report.report.testDetails.waterTemperature }} </span>
                </p>
              </div>
            </div>

            <div class="border-top py-3">
              <h4 class="h5">Test Results</h4>
            </div>

            <div class="row no-gutters">
              <div class="col-6">
                <p>
                  <span class="fw-bold me-1">Test Date:</span>
                  <span>{{ report.report.testDate || '' | date: 'fullDate' }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Height:</span>
                  <span>{{ report.report.testDetails.height || '' | number: '1.0-2' }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Dry Weight:</span>
                  <span>{{ report.report.testDetails.dryWeight || '' | number: '1.0-2' }} </span>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <span class="fw-bold me-1">Resting Metabolic Rate:</span>
                  <span>{{ report.report.testDetails.restingMetabolicRate || '' | number: '1.0-2' }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Lean Lbs:</span>
                  <span>{{ report.report.testDetails.leanLbs || '' | number: '1.0-2' }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Fat Lbs:</span>
                  <span>{{ report.report.testDetails.fatLbs || '' | number: '1.0-2' }} </span>
                </p>
                <p>
                  <span class="fw-bold me-1">Water Lbs:</span>
                  <span>{{ report.report.testDetails.waterLbs || '' | number: '1.0-2' }} </span>
                </p>
              </div>
            </div>

            @if (clientHistory(); as history) {
              <h4 class="border-top py-3 h5">Client History</h4>

              <div class="row no-gutters">
                <div class="col-12">
                  <table class="w-100 text-center">
                    <tr>
                      <th>Date</th>
                      <th>Height</th>
                      <th>Weight</th>
                      <th>Lean Lb.</th>
                      <th>Fat Lb.</th>
                      <th>Water Lb.</th>
                      <th>Body Fat %</th>
                      <th>Fat %<br />Change</th>
                      <th>Weight<br />Change</th>
                      <th>Lean Lb.<br />Change</th>
                      <th>Fat Lb.<br />Change</th>
                      <th>Fat %<br />Diff.</th>
                    </tr>
                    @for (item of history; track item.id) {
                      <tr>
                        <td>{{ item.date }}</td>
                        <td>{{ item.height }}</td>
                        <td>{{ item.dryWeight }}</td>
                        <td>{{ item.leanLbs }}</td>
                        <td>{{ item.fatLbs }}</td>
                        <td>{{ item.waterLbs }}</td>
                        <td>{{ item.fatPercent }}</td>
                        <td>{{ item.fatPercentDiff }}</td>
                        <td>{{ item.weightPercentDiff }}</td>
                        <td>{{ item.leanLbsDiff }}</td>
                        <td>{{ item.fatLbsDiff }}</td>
                        <td>{{ item.bodyFatPercentDiff }}</td>
                      </tr>
                    }
                  </table>
                </div>
              </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
</section>
